export const tmsConstant = {
  // code value
  HTMS_IS_YN: 'HTMS.IS_YN',
  HTMS_IS_YN1: 'HTMS.IS_YN1',
  HTMS_IS_YN2: 'HTMS.IS_YN2',
  HTMS_EVENT_TYPE: 'HTMS.EVENT_TYPE',
  HTMS_SHIPMENT_MARKS: 'HTMS.MONITOR_STATUS',
  HTMS_FLEET_ALTER_HISTORY_STATUS: 'HTMS.FLEET_ALTER_HISTORY.STATUS',
  HTMS_SHIPMENT_OP_STATUS: 'HTMS.SHIPMENT_OP_STATUS_NEW',
  HTMS_SHIPMENT_OP_STATUS_500: '500',
  HTMS_SHIPMENT_OP_STATUS_999: '999',
  HTMS_SHIPMENT_OP_STATUS_3000: '3000',
  HTMS_SHIPMENT_OP_STATUS_4000: '4000',
  HTMS_ORDER_MOVEMENT_OP_STATUS: 'HTMS.ORDER_MOVEMENT_OP_STATUS',
  HTMS_ORDER_MOVEMENT_OP_STATUS_500: '500',
  HTMS_ORDER_MOVEMENT_OP_STATUS_520: '520',
  HTMS_ORDER_MOVEMENT_OP_STATUS_550: '550',
  HTMS_ORDER_MOVEMENT_OP_STATUS_999: '999',
  HTMS_ORDER_MOVEMENT_OP_STATUS_3000: '3000',
  HTMS_ORDER_MOVEMENT_OP_STATUS_4000: '4000',
  HTMS_SHIPMENT_TYPE: 'SHIPMENT_TYPE',
  HTMS_LOCATION_TYPE: 'LOCATION_TYPE',
  HTMS_SHIPMENT_TYPE_F: 'F',
  HTMS_SHIPMENT_TYPE_P: 'P',
  HTMS_FUEL_TYPE: 'FUEL_TYPE',
  HTMS_GREEN_VEHICLE_CLASSIFICATION: 'GREEN_VEHICLE_CLASSIFICATION',
  HTMS_COUNTRY_CODE: 'HTMS.COUNTRY_CODE',
  HTMS_IMPORT_STATUS: 'HIMP.IMPORT_STATUS',
  HTMS_DATA_STATUS: 'HIMP.DATA_STATUS',
  HTMS_BUSINESS_UNIT: 'LF.BUSINESS_UNIT',
  HTMS_EQUIPMENT_TYPE: 'HTMS.EQUIPMENT_TYPE',
  HTMS_DEVICE_OP_STATUS: 'HTMS.DEVICE_OP_STATUS',
  HTMS_DEVICE_OP_STATUS_100: '100',
  HTMS_DEVICE_OP_STATUS_500: '500',
  HTMS_DEVICE_OP_STATUS_600: '600',
  HTMS_DEVICE_OP_STATUS_700: '700',
  HTMS_DEVICE_OP_STATUS_800: '800',
  HTMS_DEVICE_OP_STATUS_900: '900',
  HTMS_DEVICE_OP_STATUS_1000: '1000',
  HTMS_DEVICE_OP_STATUS_1100: '1100',
  HTMS_DEVICE_OP_STATUS_1200: '1200',
  HTMS_DEVICE_OP_STATUS_1300: '1300',
  HTMS_REPAIR_TYPE: 'HTMS.REPAIR_TYPE',
  HTMS_REPAIR_TYPE_IN: 'Repair in',
  HTMS_REPAIR_TYPE_OUT: 'Repair out',
  HTMS_HANDOVER_TYPE : 'HTMS.HANDOVER_TYPE',
  HTMS_HANDOVER_TYPE_IN : 'Handover in',
  HTMS_HANDOVER_TYPE_OUT: 'Handover out',
  HTMS_QUERY_RANGE: 'HTMS.QUERY_RANGE',
  HTMS_ORDER_RESCHEDULING_MARK: 'HTMS.ORDER_RESCHEDULING_MARK',
  HTMS_SETTING_TYPE: 'HTMS.SETTING_TYPE',
  HTMS_BINDING_STATUS: 'HTMS.BINDING_STATUS',
  HTMS_ORDER_OTM_STATUS: 'HTMS.ORDER_OTM_STATUS',

  // lov code
  HTMS_ORDER_TYPE_LOV: 'HTMS.ORDER_TYPE_LOV',
  HTMS_ORDER_TYPE_FORECAST: 'FORECAST', // VALUE
  HTMS_TRANSPORT_MODE_LOV: 'HTMS.TRANSPORT_MODE_LOV',
  HTMS_LOCATION_LOV: 'HTMS.LOCATION_LOV',
  HTMS_SERVPROV_LOV: 'HTMS.SERVPROV_LOV',
  HTMS_CUSTOMER_LOV: 'HTMS.CUSTOMER_LOV',
  HTMS_DRIVER_ALL_LOV: 'HTMS.DRIVER_ALL_LOV',
  
  DECLINE_REASONS: [
    { key: 'Truck Breakdown', value: 'Truck Breakdown' },
    { key: 'Truck turnaround still at customer', value: 'Truck turnaround still at customer' },
    { key: 'For truck maintenance', value: 'For truck maintenance' },
    { key: 'Driver/helper not available', value: 'Driver/helper not available' },
    { key: 'Hit by truck ban', value: 'Hit by truck ban' },
    { key: 'Response timeout', value: 'Response timeout' },
  ],


  // file template code
  HTMS_ORDER_TEMPLATE_CODE: 'LF-ORDERT2'

};


