import { Component, ViewChild, OnDestroy } from '@angular/core';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
// import { SearchableEntityDataEditorService } from '../../../../../../../shared/services/searchable-entity/searchable-entity-data-editor.service';
// import { PropertyChangeEvent, MANUAL_EDIT } from '../../../../../../../shared/models/property-change-event';
// import { GridViewKeyEventHandlerService } from '../../../../../../../shared/services/grid-view-key-event-handler.service';
// import { DatePickerConfigService } from '../../../../../../../../shared/components/datepicker/datepicker-config.service';
// import { DatePickerConfigService } from '../../../../../../../../shared//components/datepicker/datepicker-config.service';
import { DatePickerConfigService, NavigationService } from 'sce-core';
import { SearchableEntityDataEditorService } from 'src/app/shared/services/searchable-entity/searchable-entity-data-editor.service';
import { GridViewKeyEventHandlerService } from 'src/app/shared/services/grid-view-key-event-handler.service';
import { transToLocationTime } from 'src/assets/utils/transToLocationTime';
import { ParamsService } from 'src/app/features/reserve/params.service';
import { DialogService, DynamicDialogRef } from 'primeng-lts/dynamicdialog';
import { TruckNumberDetailComponent } from 'src/app/features/reserve/dashboard/dialog/truck-number-detail.component';
import { DriverDetailComponent } from 'src/app/features/reserve/dashboard/dialog/driver-detail.component';
import { TruckTypeDetailComponent } from 'src/app/features/reserve/dashboard/dialog/truck-type-detail.component';
// import { transToLocationTime } from '../../../../../../../../assets/utils/transToLocationTime'
// import {DatepickerComponent} from ''
@Component({
  selector: 'lfwms-basic-cell-editor',
  templateUrl: './basic-cell-editor.component.html',
  styleUrls: ['./basic-cell-editor.component.css']
})
export class BasicCellEditorComponent implements ICellEditorAngularComp, OnDestroy {

  public params: any;
  public isLoading: boolean = true;
  public isDropup: boolean = false;
  public offset: number = 0;
  // scrollHeight for dropdowns is half of grid height.
  public scrollHeight: string = '150px';
  public templateId: number = 0;
  public propertyName: string;
  public tableEditor: SearchableEntityDataEditorService;
  public keyEventHandler: GridViewKeyEventHandlerService;
  public currentRow: any;
  public dateFormat: string;
  public dateTimeFormat: string;
  public driverNumberVal:any=''
  @ViewChild('primeFocus')
  public primeFocus;

  public setPrimeFocus() {
    if (this.templateId === 1 || this.templateId === 11) {
      this.primeFocus.nativeElement.focus();
    } else if ((this.templateId === 3) || (this.templateId === 4) || (this.templateId === 9)) {
      this.primeFocus.setFocus();
    } else if (this.templateId === 2) {
      this.primeFocus.setFocusOnCell();
    }
  }

  constructor(public navService: NavigationService,
    public paramsService:ParamsService,public ref: DynamicDialogRef,
    public dialogService: DialogService,public dateConfigService: DatePickerConfigService) {
  }
  openTruck(e) {
    this.paramsService.onlyShow=true;
    if(e==1){
      this.ref=this.dialogService.open(TruckNumberDetailComponent, {
        width: '70%',
        contentStyle: {"overflow": "auto"},
        baseZIndex: 10000,
    });    
    this.paramsService.TruckServprovGid= this.params.data.servprovGid;
    this.paramsService.TruckEquipmentGroupGid= this.params.data.equipmentGroupGid;
    this.ref.onClose.subscribe((res: any) =>{
          if (res) {
            this.valueChanged(res.carNumber)
            let row=this.params.api.getDisplayedRowAtIndex(this.params.rowIndex)
            this.params.api.redrawRows({ rowNodes: row })
          }
      });
    }else if(e==2){
      this.ref=this.dialogService.open(DriverDetailComponent, {
        width: '70%',
        contentStyle: {"overflow": "auto"},
        baseZIndex: 10000,
    });
    this.paramsService.DriverServprovGid = this.params.data.servprovGid;
      this.ref.onClose.subscribe((res: any) =>{
          if(res.name=='DriverDetailComponent' )return 
          if (res) {
            console.debug(res,'---res')
            this.primeFocus.nativeElement.focus()
            this.params.data.driverNumber=res.phone;
            this.params.data.email=res.email;
            this.params.data.equipmentNumber=res.carNumber;
            this.params.data.actualEquipmentGroupGid=res.equipmentGroupGid;
            this.params.data.driverGid = res.id
            this.valueChanged(res.name)
            let row=this.params.api.getDisplayedRowAtIndex(this.params.rowIndex)
            this.params.api.redrawRows({ rowNodes: row })
          }
      });
    }else{
      this.ref=this.dialogService.open(TruckTypeDetailComponent, {
        width: '70%',
        contentStyle: {"overflow": "auto"},
        baseZIndex: 10000,
    });
    this.ref.onClose.subscribe((res: any) =>{
      if(res.name=='TruckTypeDetailComponent' )return 
      if (res) {
        console.debug(res,'---res')
        this.primeFocus.nativeElement.focus()
        this.params.data.driverNumber=res.phone;
        this.params.data.email=res.email;
        this.params.data.equipmentNumber=res.carNumber;
        this.params.data.driverGid = res.id
        this.params.data.actualEquipmentGroupGid=res.equipmentGroupGid;
        // this.valueChanged(res.name)
        let row=this.params.api.getDisplayedRowAtIndex(this.params.rowIndex)
        this.params.api.redrawRows({ rowNodes: row })
      }
  });
    }
  
  }
  // ag-Grid Specific Methods -->>

  public clickImage(){
    window.open(this.params.data.fileUrl, '_blank')
  }

  public agInit(params: any): void {

    this.params = params;    
    this.propertyName = params.cellMetaData.propertyName;
    this.tableEditor = params.context.componentParent.tableEditor;
    this.keyEventHandler = params.context.componentParent.keyEventHandler;
    this.currentRow = params.context.componentParent.pageRows[params.rowIndex];
    switch (params.cellMetaData.type) {
      case 'TEXTFIELD': {
        // if (params.cellMetaData.displayType === 'EL' || params.cellMetaData.displayType === 'L' ||
        //     params.cellMetaData.displayType === 'EA') {
        //   this.templateId = 2;
        // } else {
        //   this.templateId = 1;
        // }
        if (params.cellMetaData.displayType === 'EL'){
          if(params.cellMetaData.propertyName=='driverName'){
            this.templateId = 1;
          }else if(params.cellMetaData.propertyName=='driverName'){
          this.templateId = 1.2;
          }else{
          this.templateId = 1.1;

          }
        }else if(params.cellMetaData.displayType === 'T'){
          this.templateId = -1;
        }else{
          if(params.cellMetaData.propertyName == 'planDeliveryDate'|| params.cellMetaData.propertyName == 'planStartShippingDate'){
            this.params.value = transToLocationTime(this.params.value)
          }
          // if(params.cellMetaData.propertyName == 'driverNumber'){
          //   console.debug('driverNumber')
          //   this.templateId=0.1
          // }else{
          //   this.templateId = 0;
          // }
          this.templateId = 0;
        }
        break;
      }
      case 'DROPDOWN':
      case 'EDITABLEDROPDOWN': {
        /*  const indexFromLastRow: number =
            this.params.context.componentParent.pageDefinition.pageSize - this.params.rowIndex;
          this.isDropup = (indexFromLastRow <= 5);
          */
        const pageDef = this.params.context.componentParent.pageDefinition;
        const isLastPage: boolean = pageDef.pageNumber * pageDef.pageSize >= pageDef.recordCount;
        const rowsInLastPage = pageDef.recordCount % pageDef.pageSize === 0 ? pageDef.pageSize : pageDef.recordCount % pageDef.pageSize;
        const rowsInCurrentPage = isLastPage ? rowsInLastPage : pageDef.pageSize;
        const indexFromLastRow: number = rowsInCurrentPage - this.params.rowIndex;
        // const maxVisibleNoOfRowsInScroll = 10;
        // set dropup for last 5rows if records displayed more than 5. else from 6th row.
        this.isDropup = this.params.rowIndex >= 5 && (indexFromLastRow <= 5);
        if (params.cellMetaData.type == 'DROPDOWN') {
          this.templateId = 3;
        } else if (params.cellMetaData.type === 'EDITABLEDROPDOWN') {
          // [LFWM-2114] - adding one more templaeId for editable dropdown
          this.templateId = 12;
        }
        break;
      }
      case 'DATE':
      case 'DATETIME': {
        this.dateFormat = this.dateConfigService.defaultDateDisplayFormat;
        this.dateTimeFormat = this.dateConfigService.defaultDateDisplayFormat + ' ' + this.dateConfigService.defaultTimeDisplayFormat;
        /* const indexFromLastRow: number =
          this.params.context.componentParent.pageDefinition.pageSize - this.params.rowIndex;
         console.debug()
        if (indexFromLastRow <= 2) {
          this.isDropup = true;
        } else if (indexFromLastRow <= 8) {
          this.offset = -40 - ((8 - indexFromLastRow) * 30);
          this.isDropup = undefined;
        } */
        const maxVisibleNoOfRowsInScroll = 10;
        if (this.params.rowIndex > this.params.context.componentParent.pageDefinition.pageSize - 2) {
          this.isDropup = true; // dropup for last row
        } else if (this.params.rowIndex >= 1) {
          // calculate offset to show sideways for other rows except row#1
          // indexAfterModulo will have 0-9 (maxVisibleNoOfRowsInScroll=10)
          const indexAfterModulo =
            this.params.rowIndex < maxVisibleNoOfRowsInScroll ?
              this.params.rowIndex : (this.params.rowIndex % maxVisibleNoOfRowsInScroll);
          this.offset = 10 - (indexAfterModulo * 30);
          this.isDropup = undefined;
        }
        if (this.isDropup) {
          this.offset = -280;
        }
        if (params.cellMetaData.type === 'DATE') {
          this.templateId = 4;
        } else if (params.cellMetaData.type === 'DATETIME') {
          this.templateId = 10;
        }
        break;
      }
      case 'CHECKBOX': {
        this.templateId = 5;
        break;
      }
      case 'CHECKBOX_CHR': {
        this.templateId = 5;
        break;
      }
      case 'DROPDOWN_MC': {
        this.templateId = 8;
        break;
      }
      case 'TIME24': {
        this.templateId = 9;
        break;
      }
      case 'DROPDOWN_MS': {
        this.templateId = 11;
        break;
      }
      default: {
        this.templateId = 0;
        break;
      }
    }
    /* params.eGridCell offers the cell editor template as a HTML DOM Element.
       Focus Event Listener is attached to this DOM Element, in order to set focus
       on input element with primeFocus template ID */
    // this.params.eGridCell.addEventListener('focus', () => this.setPrimeFocus());
    this.isLoading = false;
  }

  public afterGuiAttached() {
    if (this.params.cellStartedEdit) {
      this.setPrimeFocus();
    }
  }

  public refresh(): boolean {
    return true;
  }

  public isPopup(): boolean {
    return false;
  }

  public getValue() {
    /* Note :: params.value is passed as an Object Reference.
      Hence, value getter should return the same and NOT just params.value.cVal */
    return this.params.value;
  }

  public focusIn() {
    // Below change is done to avoid partial focus on multiple cells
    this.params.eGridCell.focus();
  }

  public ngOnDestroy() {
    this.params.eGridCell.removeEventListener('focus', () => { });
  }

  // <<-- ag-Grid Specific Methods


  public valueChanged(sourceEvent?: any) {

    // let newVal = null;
    // let hasValueChanged: boolean;
    // if (sourceEvent !== null && sourceEvent !== undefined) {
    //   if ('string' === typeof sourceEvent) {
    //     newVal = sourceEvent;
    //   } else if ('object' === typeof sourceEvent) {
    //     newVal = sourceEvent['newVal'];
    //   }
    // }

    // if (newVal === null || newVal === undefined) {
    //   newVal = this.params.value;
    // }
  
    this.params.data[this.propertyName] = sourceEvent.newVal || sourceEvent
    this.params.value= sourceEvent.newVal || sourceEvent
    this.params.data.saveFlag = true
    // this.params.data.value = sourceEvent.newVal

    
    // this.tableEditor.refreshCells(this.params);
   // return
    // this.params.data.pickupStatus = 
    // // check if the original value is modified
    // this.params.value.isEdited = (this.params.value !== this.params.value.cValOrig);
    // if (sourceEvent && sourceEvent.type && sourceEvent.type === 'dropdown') {
    //   hasValueChanged = (newVal !== sourceEvent.prevVal);
    // } else {
    //   hasValueChanged = (newVal !== this.params.value);
    // }


    // if (this.params.value.isEdited || hasValueChanged) {
    //   // if the value has changed, send an event and check if the change can be accepted
    //   const event: PropertyChangeEvent = new PropertyChangeEvent();
    //   event.source = 'table-editor';
    //   event.type = MANUAL_EDIT;
    //   event.property = this.params.column.colId;
    //   event.value = newVal;
    //   event.previousValue = this.params.value.cValPrev;
    //   event.origValue = this.params.value.cValOrig;
    //   event.row = this.params.context.componentParent.pageRows[this.params.rowIndex];
    //   const acceptChange: boolean = this.tableEditor.propertyValueChanged(event);

    //   // if the property change is to be rejected, do not set the is edited flag
    //   if (acceptChange) {
    //     // Status flags should be updated in searchService.pagedData.pageRows directly
    //     this.params.context.componentParent.pageRows[this.params.rowIndex].isEdited = true;
    //     // if the event is from dropdown the current value is already set in this.cellDataMap[key]
    //     if (sourceEvent && sourceEvent.type && sourceEvent.type === 'dropdown') {
    //       this.params.value.cValPrev = sourceEvent.prevVal;
    //       this.params.value = newVal;
    //     } else {
    //       this.params.value.cValPrev = this.params.value;
    //       this.params.value = newVal;
    //     }
    //     // else if (sourceEvent && sourceEvent.type && sourceEvent.type === 'dropdown') {
    //     //   // Code changes to fix checkbox selection issue LFWM-1718
    //     //   this.params.value.cValPrev = sourceEvent.prevVal;
    //     //   this.params.value.cVal = newVal;
    //     // }
    //     // [JIRA LFWM-1096 fixes]- Ordered/Received qty should be updated upon UOM change
    //     // if this is a UOM property, autopopulate the dependant properties
    //     if (sourceEvent && sourceEvent.type === 'dropdown' && this.params.cellMetaData.displayType === 'EA') {
    //       this.params.value.cValOldUOM = this.params.value.cValPrev;
    //       this.tableEditor.autoPopulate(event.row, event.property);
    //     }
    //   }
    //   // moving the refresh Cells method to generic editor service
       this.tableEditor.refreshCells(this.params);
        this.navService.preventNavigation = true;
    //   console.debug(this.params)
    // }
  }

  // JIRA LFWM-927 Fixes - when user tab over from UOM, quantity should be automatically highlighted for user to overwrite the data
  public onTextFocus($event: any) {
    setTimeout(() => {
      $event.target.select();
    }, 5);

  }

  public toggleCheckbox() {
    // Code changes to fix checkbox selection issue LFWM-1718
    const sourceEvent = { type: 'checkbox' };
    sourceEvent['prevVal'] = this.params.value.cVal;
    if (this.params.cellMetaData.dataType === 'String') {
      sourceEvent['newVal'] = (this.params.value.cVal === 'N') ? 'Y' : 'N';
    } else if (this.params.cellMetaData.dataType === 'Integer') {
      sourceEvent['newVal'] = (this.params.value.cVal === 0) ? 1 : 0;
    }
    this.valueChanged(sourceEvent);
  }

  public openLookup(row: any, property: string) {
    this.params.context.componentParent.tableEditor.openLookup(row, property);
  }

  public openMultiColumnPopup(row: any, property: string) {
    this.params.context.componentParent.tableEditor.openMultiColumnPopup(row, property, this.primeFocus);
  }

  public setFocusedCell(row: any, property: any) {
    this.params.context.componentParent.searchService.getPagedData().focusedRow = row;
    this.params.context.componentParent.searchService.getPagedData().focusedCellId = property;
    this.params.context.componentParent.keyEventHandler.setFocusedCell(row, property);
  }

  // method used for displaying multicolumn dropdown
  public showMultiColumnDropdown(row: any, colProp: any) {
    row.cells[colProp].showMultiColumn = !row.cells[colProp].showMultiColumn;
  }

  // method  used to handle multi column click
  public handleMultiColumnClick(row: any, colProp: any, key: any, value: any) {
    const currentVal = row.cells[colProp].cVal;
    if (key === row.cells[colProp].cName || key === 'key') {
      row.cells[colProp].cVal = value;
    } else {
      row.cells[colProp].cVal = currentVal;
    }
    row.cells[colProp]['isEdited'] = true;
    row['isEdited'] = true;
    this.showMultiColumnDropdown(row, colProp);
    this.tableEditor.autoPopulate(row, colProp);
  }

  public onValueChangeInTypeaheadTextBox(event) {
    this.valueChanged(event);
  }
  public toSetFocusFromTypeaheadTextBox(event) {
    this.setFocusedCell(event.row, event.property);
  }
  public editKeyPressListnerInTypeaheadTextBox(event) {
    this.keyEventHandler.editKeyPressListner(event.event, event.currentRow, this.params.cellMetaData);
  }

  public onValueChangeOfTimeComponent(event) {
    this.valueChanged(event);
  }
}
