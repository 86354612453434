<!-- <div class="wrapper">
    <div class="row margin-style">
      <div class="col-md-3">
        <span class="cell-title">{{getLabel('Application Code')}}</span>
          <select class="form-control"  name="flag"  [(ngModel)]="" 
          >
              <option  [value]="">                    
              </option>
          </select>
      </div>
      <div class="col-md-3">
        <span class="cell-title">{{getLabel('Country/Region')}}</span>
        <select class="form-control"  name="SubRule" [(ngModel)]="">        
    
        <option  [value]=""

        [selected]="">                  
        </option>
    </select>
      </div>
      <div class="col-md-3">
        <span class="cell-title">{{getLabel('Event Code')}}</span>
        <select class="form-control"  name="SubRule" [(ngModel)]="">        
    
        <option   [value]=""
             >                   
        </option>
    </select>
      </div>
    </div>
  </div> -->
<div class="form-row" *ngIf="showtagForms">
  <div class="col-md-3">
    <label class="cell-title">
      {{getLabel('Application Code')}}</label>
    <select class="form-control" name="appcode" [(ngModel)]="appCode"
    (ngModelChange)="valueChanged($event)">
      <option [ngValue]="undefined" disabled selected hidden>Select Application Code</option>
      <option *ngFor="let type of inputOptionsForAppcode" [value]="type.value">{{type.value}}
      </option>
    </select>
  </div>
  <div class="col-md-3">
    <label class="cell-title">
      {{getLabel('Country/Region')}}</label>
    <select class="form-control" name="country" [(ngModel)]="country"
    (ngModelChange)="valueChanged($event)">
      <option [ngValue]="undefined" disabled selected hidden>Select Country/Region</option>
      <option *ngFor="let type of inputOptionsForCountry" [value]="type.value">{{type.value}}
      </option>
    </select>
  </div>
  <div class="col-md-3">
    <label class="cell-title">
      {{getLabel('EventCode')}}</label>
    <select class="form-control" name="eventcode" [(ngModel)]="eventCode"
    (ngModelChange)="valueChanged($event)">
      <option [ngValue]="undefined" disabled selected hidden>Select Event Code</option>
      <option *ngFor="let type of EventCode" [value]="type.name">{{type.name}}
      </option>
    </select>
  </div>
</div>
<ng-template [ngIf]="!isLoading">
  <div 
    [ngClass]="{'emailTemplate': showTemplateRightItems || showSmsRightItems, 'view-transactions-popup-container':true}">
    <lfwms-result-data-table [showSelectOption]="this.showSelectOptions" [searchService]="searchService"
      [tableMode]="searchService.displayMode">
    </lfwms-result-data-table>

    <!-- for EMAIL -->
    <div  *ngIf="showTemplateRightItems">
      <div style="margin: 0;margin-right: 10px;margin-bottom: 10px;
    width: 100%;">
        <div style="display:flex;justify-content: space-between;">
          <h5 style="margin-top: 3px;">HTML Body</h5>
          <div (click)="copyText()"
            style="background-image: url('/assets/images/svg/icon-copy.svg');height: 21px;background-repeat: no-repeat;cursor: pointer;">
            <h5 style="margin-left: 27px;margin-top: 3px;">Copy HTML</h5>
          </div>
        </div>
        <div style="pointer-events: none;">
        <angular-editor [(ngModel)]="searchService['htmlContent']" [config]="config">
        </angular-editor>
      </div>
      </div>
      <div style="
    width: 100%;height:50%;margin-bottom: 10px;">
        <h5>Source Code</h5>
        <div style="height: 100%; " class="html">
          <textarea style="width: 100%;
    height: 100%;font-size: 13px;" [(ngModel)]="searchService['htmlContent']"></textarea>
        </div>
      </div>
    </div>


    <!-- for SMS -->


    <div *ngIf="showSmsRightItems" style="margin-left: 20px;
    margin-right: 20px;">

      <div>
        <div style="margin: 0;margin-right: 10px;margin-bottom: 10px;">
          <div style="display:flex; justify-content: space-between;margin-bottom: 7px;">
            <h5 style="margin-top: 3px;margin-right: 10px; "> Messages</h5>
            <div (click)="copyText()"
              style="background-image: url('/assets/images/svg/icon-copy.svg');height: 21px;background-repeat: no-repeat;cursor: pointer;">
              <h5 style="margin-left: 27px;margin-top: 3px;">Copy Text</h5>
            </div>
          </div>

          <textarea [(ngModel)]="searchService['htmlContent']" style="height: 240px;
                margin-top: -7px;
                width: 887px;
                border: none;
                border-radius: 6px;
                font-size: 13px;
                margin-left: -11px;pointer-events: none;" rows="4" maxlength="200" cols="50"></textarea>

        </div>
        <div style="
width: 76%;
    margin-bottom: 10px;
    margin-left: -9px;">
          <div style="display:flex;justify-content: space-between;">
            <h5 style="margin-top: 3px;"> Preview Messages</h5>

          </div>

          <div style="height: 105%;
          border: 1px solid #dddd;
          padding: 71px;
          border-radius: 25px;
    background: white;
    margin-left: 191px;">

            <div style="font-weight:bolder;margin-left: 126px;
            font-weight: bolder;
            font-size: 21px;
            margin-top: -47px;
            margin-bottom: 30px;">SMS</div>
            <div class="chat">
              <div class="yours messages">
                <div class="message last">
                  {{searchService['htmlContent']}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</ng-template>

<button *ngIf="showtagForms" style="padding: 6px 17px;" class="btn-populatepo" (click)="handlePopulateActionForTag()">
  {{resourceService.get('Save')}} </button>
<button *ngIf="!showtagForms && !showTemplateRightItems && !showSmsRightItems" style="padding: 6px 17px;"
  class="btn-populatepo" (click)="handlePopulateAction()"> {{resourceService.get('Add')}} </button>