import { LFDynamicWidgetConfig } from './lf-dynamic-widget-config';
import { SearchableEntityDataService } from '../services/searchable-entity/searchable-entity-data.service';
import { SearchableEntityDataEditorService } from '../services/searchable-entity/searchable-entity-data-editor.service';
import { DetailViewKeyEventHandlerService } from '../services/detail-view-key-event-handler.service';

export class AccordionConfig {

    public name: string;
    public isOpen: boolean = false;
    public columnGroup: string;
    public activeIndex: number;
    public propertyList: any;
    public isPrimaryAccordion: boolean;
    public cellDataMap: any;
    public metaDataMap: any;
    public dataService: SearchableEntityDataService;
    public dataEditor: SearchableEntityDataEditorService;
    public keyEventHandler: DetailViewKeyEventHandlerService;
}
