export const MANUAL_EDIT: number = 0;

export class PropertyChangeEvent {

    public source: string;

    public type: number;

    public property: string;

    public value: any;

    public previousValue: any;

    public origValue: any;

    public row: any;
}
