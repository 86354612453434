import { Component, OnInit, EventEmitter, Input, Output, HostListener, OnDestroy} from '@angular/core';
import { AccordionConfig } from '../../models/accordion-config';
import { NavigationService, ResourceService, UserService } from 'sce-core';
import { DetailViewKeyEventHandlerService } from '../../services/detail-view-key-event-handler.service';
import { SearchableEntityDataService } from '../../services/searchable-entity/searchable-entity-data.service';
import { SearchableEntityDataEditorService } from '../../services/searchable-entity/searchable-entity-data-editor.service';
import { LeftPanelService } from '../../../layout/home/services/left-panel.service';
import { AlertService } from 'sce-core';
import { PropertyChangeEvent, MANUAL_EDIT } from '../../models/property-change-event';
import { Subscription } from 'rxjs';
import { DatePickerConfigService } from '../datepicker/datepicker-config.service';
import { trigger, state, transition, animate, style} from '@angular/animations';

@Component({
  selector: 'lfwms-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css'],
  animations: [
    trigger('tabContent', [
      state('hidden', style({
        height: '0'
      })),
      state('visible', style({
        height: '*'
      })),
      transition('visible <=> hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class AccordionComponent implements OnInit, OnDestroy {

  @Input() accordionConfig: AccordionConfig;
  @Output() reorder: EventEmitter<any> = new EventEmitter();

  public metadataMap: any;
  public filteredMetaData: any;
  public cellDataMap: any;
  public propertyList: any;
  public accordionTitle: string;
  public isLoading = false;
  public isPrimaryDataLoaded = false;
  public panel: any;
  public default: any;
  public dataService: SearchableEntityDataService;
  public dataEditor: SearchableEntityDataEditorService;
  public keyEventHandler: DetailViewKeyEventHandlerService;
  public windowWidth: any;
  public sidePanelWidth: number;
  public searchValue: any;
  public eventSubscription: Subscription;
  public dateFormat: string;
  public dateTimeFormat: string;

  public isAccordionLoading: boolean = true;
  public isAccordionAnimating: boolean= false;

  // Method to recalculate cell width and align Property Grid on Window Resize event
  @HostListener('window:resize', ['$event'])
  public onResize($event: any) {
    this.windowWidth = $event.target.innerWidth;
    this.realignPropertyGrid();
  }

  constructor(public resourceService: ResourceService,
    public navService: NavigationService,
    public leftPanelService: LeftPanelService,
    public alertService: AlertService,
    public userService: UserService,
    public dateConfigService: DatePickerConfigService) {
  }

  public ngOnInit() {
    this.metadataMap = this.accordionConfig.metaDataMap;
    this.filteredMetaData = JSON.parse(JSON.stringify(this.metadataMap));
    this.cellDataMap = this.accordionConfig.cellDataMap;
    this.initializeDropDownValuesByStorer();
    /* set initial value before first call to realignPropertyGrid()
    or  any events are received via subscription.edge case. */
    this.sidePanelWidth = this.leftPanelService.isOpened() ? this.leftPanelService.getLeftPanelWidthInPixels() : 0;
    this.realignPropertyGrid();
    this.leftPanelService.getLeftPanelOpenEvent().subscribe((isOpened) => {
      if (isOpened) {
        this.sidePanelWidth = this.leftPanelService.getLeftPanelWidthInPixels();
      } else {
        this.sidePanelWidth = 0;
      }
      this.realignPropertyGrid();
    });
    this.accordionTitle = this.accordionConfig.name;
    if (this.accordionConfig.isPrimaryAccordion) {
      this.accordionConfig.isOpen = true;
    }
    this.dataService = this.accordionConfig.dataService;
    this.dataEditor = this.accordionConfig.dataEditor;
    this.keyEventHandler = this.accordionConfig.keyEventHandler;
    this.dataService.originalDataMap = JSON.parse(JSON.stringify(this.accordionConfig.cellDataMap));
    this.dateFormat = this.dateConfigService.defaultDateDisplayFormat;
    this.dateTimeFormat = this.dateConfigService.defaultDateDisplayFormat + ' ' + this.dateConfigService.defaultTimeDisplayFormat;
  }


  public realignPropertyGrid() {
    let cellsPerRow: number = 0;
    this.windowWidth = window.innerWidth - this.sidePanelWidth;

    switch (true) {
      case (this.windowWidth > 1250): {
        cellsPerRow = 5;
        break;
      }
      case (this.windowWidth <= 1250 && this.windowWidth > 1030): {
        cellsPerRow = 4;
        break;
      }
      case (this.windowWidth <= 1030 && this.windowWidth >= 800): {
        cellsPerRow = 3;
        break;
      }
      case (this.windowWidth <= 800 && this.windowWidth >= 560): {
        cellsPerRow = 2;
        break;
      }
      case (this.windowWidth < 560): {
        cellsPerRow = 1;
        break;
      }
      default: {
        cellsPerRow = 5;
        break;
      }
    }
    const newPropertyList = this.removeHiddenFiedls();
    this.propertyList = this.preparePropertyGrid(newPropertyList, cellsPerRow);
  }

  public removeHiddenFiedls() {
    const propertyListArray = this.accordionConfig.propertyList;
    const newPropertyListArray = [];
    for (let i = 0; i < propertyListArray.length; i++) {
      if (this.metadataMap[propertyListArray[i]]['displayType'] !== 'H') {
        newPropertyListArray.push(propertyListArray[i]);
      }
    }
    return newPropertyListArray;
  }
  // Method to define grid layout for each accordion.
  public preparePropertyGrid(propertyList: Array<any>, cellsPerRow) {
    let accordionRow;
    const accordionDefinition = [];
    for (let x = 0; x < propertyList.length; x++) {
      if (x % cellsPerRow === 0) {
        if (accordionRow && accordionRow.length === cellsPerRow) {
          accordionDefinition.push(accordionRow);
        }
        accordionRow = [];
      }
      accordionRow.push(propertyList[x]);
    }
    if (accordionRow && accordionRow.length !== 0) {
      for (let y = accordionRow.length; y < cellsPerRow; y++) {
        accordionRow.push('dummy');
      }
      accordionDefinition.push(accordionRow);
    }
    return accordionDefinition;
  }

  // method used to translate the labels
  public getLabel(key: any) {
    return this.resourceService.get(key);
  }

  // Method to return Cell Title corresponding to each property
  public getCellTitle(property: any): string {
    return this.resourceService.get(this.metadataMap[property].elementId);
  }

  // Method  to override default display format for each cell value (in VIEW Mode ONLY)
  public getCellDisplayValue(property: any): any {
    if (!this.isLoading) {
      let cellDisplayValue: any;
      const cellDefinition: any = this.cellDataMap[property];
      const cellMetadata: any = this.metadataMap[property];
      if (cellDefinition && !cellDefinition.cVal) {
        if (property === 'qty' && cellMetadata.defaultVal !== '') {
          cellDisplayValue = cellMetadata.defaultVal;
        } else {
          cellDisplayValue = '';
        }
      } else {
        if (cellMetadata.type === 'DROPDOWN' || cellMetadata.type === 'EDITABLEDROPDOWN') {
          const filteredValueObject = cellMetadata.values.filter((valueObject) => {
            // if(property === 'COUNTRY_ID'){
            //   return (valueObject.key === Number(cellDefinition.cVal));
            // } else {
              return (valueObject.key === cellDefinition.cVal);
           // }
          });
          if (filteredValueObject.length > 0) {
            if (property === 'facility') {
              cellDisplayValue = filteredValueObject[0].key;
            } else {
              cellDisplayValue = filteredValueObject[0].value;
            }
          } else if (cellMetadata.type === 'EDITABLEDROPDOWN') {
            /* [LFWM-2114] -if type - EDITABLEDROPDOWN - In View mode the input value of dropdown is not present in dropdown list then the input value
            from loadbypage is assigned to cellDisplayValue */
            cellDisplayValue = cellDefinition.cVal;
          } else {
            cellDisplayValue = '';
          }
        } else if (cellMetadata.type === 'DATE' || cellMetadata.type === 'DATETIME') {
          if (cellDefinition.cVal) {
            //  cellDisplayValue = moment(cellDefinition.cVal).format('MM/DD/YYYY');
            if (cellMetadata.type === 'DATE') {
              cellDisplayValue = this.dateConfigService.getFormattedDisplayDate(cellDefinition.cVal);
            } else if (cellMetadata.type === 'DATETIME') {
              cellDisplayValue = this.dateConfigService.getFormattedDisplayDateTime(cellDefinition.cVal);
            }
          } else {
            cellDisplayValue = '';
          }
        } else {
          if (cellDefinition && cellDefinition.cVal) {
            cellDisplayValue = cellDefinition.cVal;
          }
        }
      }
      return cellDisplayValue;
    } else {
      return '';
    }
  }

  public toggleAccordionExpand() {
    this.accordionConfig.isOpen = !this.accordionConfig.isOpen;
  }

  public emitReorderEvent(isDrop: any, columnGroup: string) {
    this.reorder.emit({ isDrop: isDrop, columnGroup: columnGroup });
  }

  public valueChanged(key: any, sourceEvent?: any) {
    let newVal = null;
    if (sourceEvent !== null && sourceEvent !== undefined) {
      if ('string' === typeof sourceEvent) {
        newVal = sourceEvent;
      } else if ('object' === typeof sourceEvent) {
        newVal = sourceEvent['newVal'];
      }
    }

    if (newVal === null || newVal === undefined) {
      newVal = this.cellDataMap[key].cVal;
    }

    // if the value has changed, send an event and check if the change can be accepted
    const event: PropertyChangeEvent = new PropertyChangeEvent();
    event.source = 'table-editor';
    event.type = MANUAL_EDIT;
    event.property = this.cellDataMap[key].cName;
    event.value = newVal;
    // JIRA LFWM-1474 Fixes - current value and previous value of a dropdown element should not be the same value
    if (sourceEvent.type && sourceEvent.type === 'dropdown') {
      event.previousValue = sourceEvent.prevVal;
    } else {
      event.previousValue = this.cellDataMap[key].cVal;
    }
    const acceptChange: boolean = this.dataEditor.propertyValueChanged(event);
    // if the property change is to be rejected, do not set the is edited flag
    if (acceptChange) {
      const hasValueChanged = (newVal !== this.dataService.originalDataMap[key].cVal);
      this.cellDataMap[key].isEdited = hasValueChanged;
      this.navService.preventNavigation = hasValueChanged;
      // if the event is from dropdown the current value is already set in this.cellDataMap[key].cVal
      if (sourceEvent.type && sourceEvent.type === 'dropdown') {
        this.cellDataMap[key].cValPrev = sourceEvent.prevVal;
      } else {
        this.cellDataMap[key].cValPrev = this.cellDataMap[key].cVal;
        this.cellDataMap[key].cVal = newVal;
      }

      if (key === 'storerKey') {
        this.initializeDropDownValuesByStorer();
      }
    }
  }

  public onValueChangeInTypeaheadTextBox(event) {
    this.valueChanged(event.propertyName, event);
  }

  // Method for checkbox starts
  public toggleSelect(key) {
    const event: PropertyChangeEvent = new PropertyChangeEvent();
    event.source = 'table-editor';
    event.type = MANUAL_EDIT;
    event.property = this.cellDataMap[key].cName;
    event.value = this.cellDataMap[key].cVal;
    // const acceptChange: boolean = this.dataEditor.propertyValueChanged(event);
    this.cellDataMap[key].isEdited = true;
    if (this.cellDataMap[key].cVal === 'Y' || this.cellDataMap[key].cVal === '1') {
      // If datatype of checkbox value is string
      if (this.metadataMap[key].dataType === 'String') {
        this.cellDataMap[key].cVal = 'N';
      } else if (this.metadataMap[key].dataType === 'Integer') { // If datatype of checkbox value is string
        this.cellDataMap[key].cVal = '0';
      }
    } else if (this.cellDataMap[key].cVal === 'N' || this.cellDataMap[key].cVal === '0' || this.cellDataMap[key].cVal === null) {
      if (this.metadataMap[key].dataType === 'String') {
        this.cellDataMap[key].cVal = 'Y';
      } else if (this.metadataMap[key].dataType === 'Integer') {
        this.cellDataMap[key].cVal = '1';
      }
    }
  }

  // Method for checkbox ends

  public filterMetaData(key: any) {
    return this.filteredMetaData[key].values;
  }

  public initializeDropDownValuesByStorer() {
    const value = 1;
    for (const data in this.filteredMetaData) {
      if (this.filteredMetaData[data].type === 'DROPDOWN' || this.filteredMetaData[data].type === 'EDITABLEDROPDOWN') {
        this.filteredMetaData[data].values = [];
        this.metadataMap[data].values.forEach(element => {
          // In scenarios where there is storerkey
          /* [LFWM-2051] - Filter of storerkey for Adjustment Type in codelkup is case-sentitive -
            changing the filtered storerkey value and keyin storer value to uppercase for comparision */
        //  if (element.storerkey && this.cellDataMap['storerKey']) {
          //  if (this.cellDataMap['storerKey'].cVal &&
           // (element.storerkey.toUpperCase() === this.cellDataMap['storerKey'].cVal.trim().toUpperCase())) {
              this.filteredMetaData[data].values.push(element);
            //}
        //  }
        });
        // if (this.filteredMetaData[data].values.length === 0) {
        //   this.metadataMap[data].values.forEach(element => {
        //     if (element.storerkey === '' || element.storerkey === null) {
        //       this.filteredMetaData[data].values.push(element);
        //     }
        //   });
        // }
      }
    }

    // return this.filteredMetaData[key].values;
  }

  public subscriptionFunction() {
    this.eventSubscription = this.dataService.valueChangedReceiveEvent().subscribe((event) => {
      if (event.property === 'storerKey') {
        this.initializeDropDownValuesByStorer();
      }
    });
  }

  public ngOnDestroy() {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  public toggleAccordion() {
    this.isAccordionAnimating = true;
    this.accordionConfig.isOpen = !this.accordionConfig.isOpen;
  }

}

