export const SORT_ORDER_ASC: number = 0;
export const SORT_ORDER_DESC: number = 1;

// default page size
export const SEARCH_DEFAULT_PAGE_SIZE: number = 10;
// default column count in case of a partial fetch
export const SEARCH_DEFAULT_COLUMN_COUNT: number = 10;

export const NEW_SEARCH_EVENT: number = 1;
export const ITEM_CLICK_EVENT: number = 2;
// this is the event that gets propogated when a new row is added to the table
export const NEW_ROW_EVENT: number = 3;
// this is the event that gets propogated when a row is deleted from the table
export const DELETE_ROW_EVENT: number = 4;
export const ITEM_AUTO_POPULATE: number = 5;
export const NEW_PAGE_EVENT: number = 6;
// this is the event that triggers a new row to be added to the table
export const AUTO_ADD_ROW_EVENT: number = 7;
export const PAGE_OVERFLOW_EVENT: number = 8;
export const REFRESH_SEARCH_EVENT: number = 9;
export const PAGINATE_EVENT: number = 10;
export const INSERT_ROW_EVENT: number = 11;
export const DUPLICATE_ROW_EVENT: number = 12;
export const NEW_POP_UP_EVENT: number = 13;
export const SHOW_TAB_EVENT: number = 14;

