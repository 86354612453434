<div [ngClass]="[viewLottablesPopup ? 'button-main-container-view-lottables' : 'button-main-container']">
  <div id="button_panel">
    <!--<span class="sidepanel-trigger"><b >&#8942;</b></span>-->
    <ng-container *ngFor="let  buttonItem of getVisiblebuttons('non-ellipsis');let i = index;">
      <ul [hidden]="buttonItem.isHidden" class="" [ngClass]="{'menu-level1-disabled':!buttonItem.isEnabled,'menu-level1':buttonItem.isEnabled ,'menu-level1-submenu-isopened': false}"
        (mouseout)="buttonItem.hovered=false">
        <div class="menu-name-container menu-name-container-prop">
          <div class="centre">
            <button *ngIf="buttonItem.isEnabled" (click)="handleButtonClick(buttonItem);" [disabled]="!buttonItem.isEnabled" [ngClass]="{'btn-txt-style-disabled': !buttonItem.isEnabled}"
              class="btn-txt-style">
        
            <span [ngSwitch]="buttonItem.specialButtonConfig?.buttonType">
              <ng-template [ngSwitchCase]="'UploadImport'">
              <!-- currently used for file uplaod and import screens - emits with import id instead of action-->
                        <lfwms-import (onFileImport)="onFileImport($event)" [buttonName]="buttonItem.elementId"
                          [returnType]="buttonItem.elementId==='Upload'? 'file':'data'" [imgPath]="buttonItem.imageUrl"
                          [importId]="buttonItem.elementId"></lfwms-import>

               </ng-template>
              <ng-template [ngSwitchCase]="'multi-file-upload'">
              <!-- currently used for multi-image upload in asn and sku pages for uplaoding sku images -->
                    <lfwms-import (onFileImport)="onFileImport(buttonItem.action)" [buttonName]="buttonItem.elementId"
                      [returnType]="buttonItem.specialButtonConfig.returnType?buttonItem.specialButtonConfig.returnType:'file'"
                      [imgPath]="buttonItem.imageUrl" [multiSelectEnabled]="true"
                      [importId]="buttonItem.elementId"></lfwms-import>

              </ng-template>
              <ng-template ngSwitchDefault>
                <!-- All other standard buttons -->
                      <span [hidden]="buttonItem.hovered">
                        <img *ngIf="buttonItem.imageUrl" src="{{'assets/images/svg/'+buttonItem.imageUrl}}" width="20px">
                      </span>
                      <span class="title-button-bar" title='{{buttonItem.elementId }}'>{{buttonItem.elementId}}
                        <span class="dropdown-icon" *ngIf="(buttonItem.subItems.length  > 0)">
                          <i class="fa fa-caret-down"></i>
                        </span>
                      </span>
              </ng-template>
            </span>
             
            </button>
          </div>
        </div>
        <ul *ngIf="(buttonItem.subItems && buttonItem.subItems.length > 0) && buttonItem.isEnabled" class="dropdown-menu menu-level2">
          <ng-container *ngFor="let subButtonItem of buttonItem.subItems">
            <li [hidden]="subButtonItem.isHidden">
              <button (click)="handleButtonClick(subButtonItem);" [class.disabled]='!subButtonItem.isVisible' [ngClass]="{'menu-level2-expanded': false}">
                <img *ngIf="subButtonItem.imageUrl" class="pull-left ellipse-icon-pos" src="{{'assets/images/svg/'+subButtonItem.imageUrl}}"
                  width="20px">
                <span class="ellipse-lbl-pos">{{subButtonItem.elementId}}</span>
              </button>
            </li>
          </ng-container>
        </ul>
      </ul>
      <div *ngIf="buttonItem.isVisible && buttonItem.isEnabled" class="menu-short-line"></div>
    </ng-container>
    <ng-container *ngIf="getVisiblebuttons('ellipsis').length > 0">
      <ul [ngClass]="{'menu-level1':true , 'ellipsis-prop':true}">
        <div class="menu-name-container btn-ellipsis">
          <div class="btn-ellipsis-prop">
            ...
          </div>
        </div>
        <ul class="ellipsis-dropdown-menu menu-level2">
          <ng-container *ngFor="let  buttonItem of getVisiblebuttons('ellipsis');let i = index;">
            <li class="dot-submenu-hover" [hidden]="buttonItem.isHidden" 
              [ngClass]="{'action-menu-disabled':!buttonItem.isEnabled ,'menu-level2-expanded':false,'ellipsis-list':true }"
              (click)="handleButtonClick(buttonItem);">
              <button [class.disabled]='!buttonItem.isEnabled'>
                <img *ngIf="buttonItem.imageUrl" class="pull-left ellipse-icon-pos" src="{{'assets/images/svg/'+buttonItem.imageUrl}}" width="20px">
                <span class="ellipse-lbl-pos">{{buttonItem.elementId}}</span>
              </button>
              <span>
                <i *ngIf="(buttonItem.subItems.length  > 0)" class="arrow-ellipsis">&#x25BA;</i>
              </span>
              <ul *ngIf="(buttonItem.subItems && buttonItem.subItems.length > 0)" class="ellipsis-dropdown-menu menu-level3">
                <ng-container *ngFor="let subButtonItem of buttonItem.subItems">
                  <li [hidden]="subButtonItem.isHidden">
                    <button (click)="handleButtonClick(subButtonItem);" [class.disabled]='!subButtonItem.isVisible' [ngClass]="{'menu-level3-expanded': false}">
                      <img *ngIf="subButtonItem.imageUrl" class="pull-left ellipse-icon-pos" src="{{'assets/images/svg/'+subButtonItem.imageUrl}}"
                        width="20px">
                      <span class="ellipse-lbl-pos">{{subButtonItem.elementId}}</span>
                    </button>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ng-container>
        </ul>
      </ul>
    </ng-container>
    <div class="dummy-btn-styling"></div>
  </div>
</div>