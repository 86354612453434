import { Injectable } from '@angular/core';
import { DataTableEditorService } from '../../search/results/data-table-editor.service';
import { SearchableEntityDataService } from './searchable-entity-data.service';
import { SearchableEntityBackendService } from './searchable-entity-backend.service';
import { SharedServiceRegistry } from '../../shared-service-registry';
import { CoreServiceRegistry } from '../../../core/core-service-registry';
import { PropertyChangeEvent } from '../../models/property-change-event';

@Injectable()
export class SearchableEntityDataEditorService extends DataTableEditorService {

  public backendService: SearchableEntityBackendService;
  public dataService: SearchableEntityDataService;

  constructor(public coreServiceRegistry: CoreServiceRegistry,
    public sharedServiceRegistry: SharedServiceRegistry) {
    super(coreServiceRegistry, sharedServiceRegistry);
  }

  public setDependentServices(dataService, backendService) {
    this.dataService = dataService;
    this.backendService = backendService;
  }

  /**
   * This method handles the changes to the property value
   * @param event - Property Change Event
   */
  public propertyValueChanged(event: PropertyChangeEvent): boolean {
    return true;
  }
  /**
   * This method is used to refresh the cells of the table
   * @param params - cell params
   */
  public refreshCells(params: any) {
    params.api.refreshCells({ force: true });
  }
}
