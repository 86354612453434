import { Directive, ElementRef, Inject, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[overflow-tooltip]'
})
export class OverflowTooltipDirective implements AfterViewInit {

  constructor(@Inject(ElementRef) public element: ElementRef) { }

   ngAfterViewInit() {
     setTimeout(() => {
      if (this.element.nativeElement.offsetWidth < this.element.nativeElement.scrollWidth ) {
        this.element.nativeElement.title = this.element.nativeElement.textContent;
    }
     }, 1000);
     /* When using tabbed and pane and so on content init called but,
      view wont be shown at the same time.So put small delay to inject title */
   }

}
