import { DataTableEditorService } from '../results/data-table-editor.service';
import { SearchableEntityButtonService } from '../../services/searchable-entity/searchable-entity-button.service';
import { ButtonItem } from '../../button/button.model';
import { AlertService } from 'sce-core';
import { DataTableConfig } from './data-table-config';
import { SEARCH_DEFAULT_PAGE_SIZE } from '../search-constants';

export class SearchParams {

    public tableId: string = '';
    public idProperty: string;
    public isFullFetch: boolean;
    public paginateLocally: boolean = false;
    public defaultPageSize: number = SEARCH_DEFAULT_PAGE_SIZE;
    public columnCount: number;
    public defaultSearchColumn: string;
    public dataTableConfig: DataTableConfig;
    public autoGenerateIdProperty: boolean = true;
    public tableEditor: DataTableEditorService;
    public alertService: AlertService;
    public actionButtons: Array<ButtonItem>;
    public additionalParamMap: any;
    public isAutoSearch: boolean;
    public storerKey: string;
     // array of feilds needs to be hidden in the search filter
    public hiddenPropertiesForFilter: any = [];
    public isSingleSelect: boolean = false;
    public fetchArchiveRecords: boolean = false;
    public restrictUserCountry: boolean = true;
    public restrictUserClient: boolean = true;
    public restrictUserForwarder: boolean = true;
    public restrictUserVendor: boolean = true;
    public restrictCountryColumnName: string = 'COUNTRY';
    public restrictClientColumnName: string = 'GC_FORWARDER_ID';
    public defaultSortOrder: number = 0;
    public defaultSortColumn: string = '';
    public enableOperandForMandatoryClauses = true;
    public allCountry =  false;
    public allClient = false;
    public allForwarder = false;
    public allVendor = false;
    public url = `/recipient/recipgroup/search`;
    public exportUrl = '';
    public transportMode: string;
    public clientID: string;
    public VendorID: string;

}
