
import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { LeftPanelService } from '../services/left-panel.service';
import { NavigationService, ResourceService, UserService } from 'sce-core';
import { ConfirmDialogService } from 'sce-core';
import { environment } from '../../../../environments/environment.sit';
import { Router } from '@angular/router';

@Component({
  selector: 'lfwms-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  animations: [
    trigger('openClose', [
      state('open', style({
        right: '350px',
      })),
      state('closed', style({
        right: '0px'
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ])
  ]
})
export class ProfileComponent implements OnInit {

  public displayName: string;
  public displayCountry: any = {};
  public displayLanguage: any = {};
  public show: boolean = false;
  public cntryDropDownShow: boolean;
  public langDropDownShow: boolean;
  public image_user = 'assets/images/icon-person.png';
  public displayPhotoBool: boolean = false;

  public countries: any[];
  public  languages: any[];
  public storers: any[];
  public selectedCountry: number;
  public selectedLanguage: number;
  public selectedStorer: string;

  constructor(public userService: UserService,
    public navService: NavigationService,
    public confirmDialogService: ConfirmDialogService,
    public resourceService: ResourceService,
    public router: Router,
    public leftPanelService: LeftPanelService) {
  }

  // method used to translate the labels
  public getLabel(key: any) {
    return this.resourceService.get(key);
  }

  public ngOnInit() {
    this.retrieveCountryLangList();
    this.displayName = this.userService.userInfo.displayName;
    if (this.userService.userInfo.photo) {
    this.image_user = this.userService.userInfo.photo;
    this.displayPhotoBool = true;
    }
  }

  // Logs the user out
  public logout() {
    if (this.navService.preventNavigation) {
      const dialogMsg = [`<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`, this.resourceService.get('mn_nav_confirm_msg')];
      this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg)).subscribe((isConfirmed) => {
        if (!isConfirmed) {
          return false;
        }
        this.navService.preventNavigation = false;
        this.userService.logout();

       });
      return;
    }
    // else
    this.userService.logout();
  }

 /* Save the selected language to backend.
    this.saveCntryLang() reloads homepage */
    public saveLang(language: any) {
      if (this.navService.preventNavigation) {
        const dialogMsg = [`<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`, this.resourceService.get('mn_nav_confirm_msg')];
        this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg)).subscribe((isConfirmed) => {
          if (!isConfirmed) {
            return false;
          }
          this.navService.preventNavigation = false;
          this.selectedLanguage = language.languageId;
          this.displayLanguage = language;
          // this.saveCntryLang();
  
         });
        return;
      }
      // else
      this.selectedLanguage = language.languageId;
      this.displayLanguage = language;
  
      // this.userService.saveLang(this.displayLanguage).subscribe((response: any) => {
      //   this.userService.resetUserInfo();
      //   location.reload();
      // });
      this.saveCntryLang();
    }

  /* Save the selected country to backend.
    this.saveCntryLang() reloads homepage */
  public saveCntry(country: any) {
    if (this.navService.preventNavigation) {
      const dialogMsg = [`<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`, this.resourceService.get('mn_nav_confirm_msg')];
      this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg)).subscribe((isConfirmed) => {
        if (!isConfirmed) {
          return false;
        }
        this.navService.preventNavigation = false;

          this.selectedCountry = country.countryId;
          this.displayCountry = country;
          this.saveCntryLang();

       });
      return;
    }
    // else
    this.selectedCountry = country.countryId;
    this.displayCountry = country;
    this.saveCntryLang();
  }

  /* This methods reads currently selected countryId and languageId
  and calls update preferences method for the user.
  Once save call is successfull it reloads homepage.*/
  public saveCntryLang() {
   // this.selectedLanguage = Number(this.selectedLanguage);
    this.userService.updatePreferences(
      this.selectedCountry,
      this.selectedLanguage)
      .subscribe((data: any) => {
        // this.navService.initAppDataAndReload('/login');
        // window.location.href = '/wms';
        window.location.href = environment.baseUrlUI; // SIT URL changed
      }); // navigateByUrl('/login'); // window.location.href = '/home');
  }

  /* This function fetches and sets Country list and Language list
    for use in Cntry/Lang change dropdowns */
  public retrieveCountryLangList() {    
    this.userService.getEnabledCountries()
      .subscribe((data: any) => {
        this.countries = data;
        this.countries = this.countries.filter((cntry: any) => {
          return cntry.countryId !== this.userService.userInfo.defaultCountry;
        });
        for (const country of data) {
          if (country.countryId === this.userService.userInfo.defaultCountry) {
            this.displayCountry = country;
            break;
          }
        }
      });
    this.userService.getAllLanguages()
      .subscribe((data: any) => {
        this.languages = data;
        this.languages = this.languages.filter((lang: any) => {
          return lang.languageId !== this.userService.userInfo.defaultLanguage;
        });
        for (const language of data) {
          if (language.languageId === this.userService.userInfo.defaultLanguage) {
            this.displayLanguage = language;
            break;
          }
        }
      });
    this.selectedCountry = this.userService.userInfo.defaultCountry;
    this.selectedLanguage = this.userService.userInfo.defaultLanguage;
    this.storers = ['NIKE', 'nike1'];
    this.selectedStorer = 'NIKE';
  }

  openProfile() {
    //this.navService.currentMenuIdSubject.next('my-profile');
    this.navService.resetBreadcrumb('home');
    this.navService.addBreadCrumbItem('My-profile');
    this.router.navigate(['/home/my-profile']);
  }

}
