import {
  Component, OnInit, OnChanges, OnDestroy,
  Input, SimpleChanges, Output, EventEmitter,
  ViewChild, ElementRef, HostListener
} from '@angular/core';
import { Subscription } from 'rxjs';
// import { ColDef, GridReadyEvent, IRowDragItem, GridOptions, RowNode } from 'ag-grid-community';
import { GridOptions, RowNode } from '@ag-grid-community/all-modules';
import {
  NEW_SEARCH_EVENT, NEW_ROW_EVENT, DELETE_ROW_EVENT,
  REFRESH_SEARCH_EVENT, PAGE_OVERFLOW_EVENT
} from '../../search-constants';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { SearchEvent } from '../../models/search-event';
import { SearchService } from '../../search.service';
import { NavigationService, ResourceService } from 'sce-core';
import { BasicCellHeaderComponent } from './renderers/basic-cell-header/basic-cell-header.component';
import { BasicCellRendererComponent } from './renderers/basic-cell-renderer/basic-cell-renderer.component';
import { BasicCellEditorComponent } from './editors/basic-cell-editor/basic-cell-editor.component';
import { GridViewKeyEventHandlerService } from '../../../services/grid-view-key-event-handler.service';
import { DataTableEditorService } from '../data-table-editor.service';
import { ButtonItem } from '../../../button/button.model';
import { AlertService } from 'sce-core';
import { ConfirmDialogService } from 'sce-core';
import { PageDefinition } from '../../models/page-definition.model';
import { PaginationEvent } from '../../../events/pagination-event';
import { DataTableConfig } from '../../models/data-table-config';
import { SearchParams } from '../../models/search-params.model';
import { TableRow } from './../../models/table-row.model';
import { TablePreference } from '../../models/table-preference.model';
import { UserPreferenceService } from '../../../../layout/home/services/user-preference.service';
import { PinnedCellRendererComponent } from './renderers/pinned-cell-renderer/pinned-cell-renderer.component';
import { Module } from '@ag-grid-community/core';

import { tmsConfig } from 'src/app/core/tms-config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { tmsConstant } from 'src/app/features/basic-data/tms-constant';
import { DashboardCrrSearchService } from 'src/app/features/reserve/dashboard/dashboard-crr-home/services/dashboard-crr-search.service';
import { ParamsService } from 'src/app/features/reserve/params.service';
import { transToLocationTime } from 'src/assets/utils/transToLocationTime';
@Component({
  selector: 'lfwms-result-data-table',
  templateUrl: './result-data-table.component.html',
  styleUrls: ['./result-data-table.component.css']
})
export class ResultDataTableComponent implements OnInit, OnChanges, OnDestroy {

  // ag-Grid Element Reference (DOM Access)
  @ViewChild('agGrid', { read: ElementRef }) public agGridRef: ElementRef;

  modules: Module[] = [ClientSideRowModelModule];
  // MetaData Reference Object
  public metaData: any;
  // MetaData Map : { property => metaData Definition }
  public metaDataMap: any;
  // Id Property to uniquely identify the record
  public isBackdropSet: boolean = false;
  public eventSubscription: Subscription;
  public resizedColumn: string = '';
  public tableEditor: DataTableEditorService;
  public keyEventHandler: GridViewKeyEventHandlerService;
  public tablePreference: TablePreference;
  public addNewRowOnPageReload: boolean = false;
  public isPageDefModified: boolean = false;
  public isSortDefModified: boolean = false;
  public isTemplateScreen: boolean = false
  public rowSelection: 'single' | 'multiple' = 'multiple';
  @Input() public tableMode: string = 'V';
  @Input() public searchService: SearchService;
  @Input() public showSelectOption = true;
  @Input() public searchCondition : any;
  @Input() public gid : any;
  
  @Output() public buttonActionHandler: EventEmitter<string> = new EventEmitter<string>();
  @Output() public onSelectCheckbox: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onSelectData: EventEmitter<any> = new EventEmitter<any>();
  @Output() public selectData: EventEmitter<any> = new EventEmitter<any>();

  public codeValueMap: Map<String, any[]> = new Map<String, any[]>();
  public isGridReady: boolean = false;
  public gridHeight: number = 372;
  /*tableHeightAsNumberOfRows - used inside setBackDrop as well as in initial table setup for setting gridheight */
  public tableHeightAsNumberOfRows = 15;
  public tableConfig: DataTableConfig;
  public gridOptions: GridOptions;
  // Search Params Reference in Search Service
  public searchParams: SearchParams;
  public isSelectedAll: boolean = false;
  public selectedRowCount: number = 0;
  public isLoading: boolean = false;
  public columnDefinitions: Array<any>;
  public rowsData: Array<any> = [];
  @Input()
  public displayCustomizer: boolean = true;
  public showCustomizer: boolean = false;
  public customizerConfig: any;
  public filterModel: any = {};
  public pageDefinition: PageDefinition;
  public pageRows: Array<any>=[];
  public actionButtons: Array<ButtonItem>;
  public isDataLoadedToGrid: boolean = false;
  public isColumnDefinitionLoaded: boolean = false;
  public isFullWidthRow: boolean = false;
  public targetCell: any;
  public newPageNum : any = 1
  public newPageSize : any = 10
  public newRecordCount : any = 10
  @Input() public putRowData:any;
  @Input() public crrData:any;
 
  /* When the value is 0 ->top paginator and buttons only, value = 1->bottom paginator only,
   anyother value->no paginator and buttons */
  @Input() public showPaginatorAndButtons: number = 0;
  markList: any[]=[];
  statusList: any[]=[];
  truckTypes: { key: string; value: string; }[]=[];
  public selectRows : any = []

  constructor(public resourceService: ResourceService,
    public alertService: AlertService,
    public http:HttpClient,
    public confirmDialogService: ConfirmDialogService,
    public navService: NavigationService,
    public userPreferenceService: UserPreferenceService,
    public paramsService :ParamsService
    ) { }

  public async  ngOnInit() {   
    this.initializeTableProperties();
    this.subscribeForSearchEvents();
    this.gridOptions = this.createGridOptions();
    if (this.paramsService.metadata.length!=0) {
      this.metaData=this.paramsService.metadata
    } else {
      this.searchService.fetchMetadata().subscribe(res=>{
        this.metaData=res

      })
    }
    this.actionButtons = this.searchService.searchParams.actionButtons;
    this.pageDefinition = this.searchService.getPagedData().pageDefinition;
  }
  ngAfterViewInit(){
    // this.rowData=this.putRowData
    // console.debug(this.rowData)
  }
  onClick(e){
    this.paramsService.clickData=e.data;
  }
  onSelectionChanged(e){ 
    let res = e.api.getSelectedRows()
    this.paramsService.viewData= res;
    this.selectData.emit(res)
    if(res.length) {
      this.onSelectData.emit(res[res.length-1])
      this.paramsService.id = res[res.length-1].xid;
    } else{
      this.selectData.emit([])
      this.onSelectData.emit([])
      this.paramsService.id = ''
    }
  }
  // ngDoCheck(){
  //   let eq=this.paramsService.cellEdit?.equipmentNumber||'';
  //   let index=this.paramsService?.cellIndex||0
  //   let _eq;
  //   if(this.crrData.length!=0){
  //      _eq=this.crrData[index].equipmentNumber;
  //      if(eq!=_eq){
  //       eq=_eq
  //       this.crrData[index].equipmentNumber=eq
  //       this.rowData=this.crrData
  //     }else{
  //       this.rowData=[...this.crrData]
  //     }
  //   }
   
  //   // console.debug(eq)
  // }
  public ngOnChanges(changes: SimpleChanges) { 
    
    if(this.crrData){  
      this.newPageNum = this.crrData.number + 1
      this.newPageSize = this.crrData.size
      this.newRecordCount = this.crrData.totalElements  
      this.rowData = this.crrData.content;
      this.pageRows = this.crrData.content;
    }
 
    //}
  
    // this.loadData()
    
   
    // if (!this.isGridReady || !this.searchService.getMetadata()) {
    //   return;
    // }

    
    // if (changes['tableMode']) {
    //   if (['C', 'E', 'V', 'P'].indexOf(this.tableMode) === -1) {
    //     const errorMsg = 'Invalid Table Mode Input';
    //     throw new Error(this.getLabel(errorMsg));
    //   }
    //   this.loadGridIfReady(true);
    //   if (this.tableMode !== 'V') {
    //     if (this.metaDataMap) {
    //       Object.values(this.metaDataMap)
    //         .forEach((value: any, index: any) => {
    //           if ((value.displayType === 'EL') || (value.displayType === 'EA')) {
    //             this.tableEditor.populateRestrictedValues(this.pageRows, value.propertyName);
    //           }
    //         });
    //     }
    //   }
    // }
  }

  // Subscribe for the required events from the SearchService
  public subscribeForSearchEvents() {


    this.eventSubscription = this.searchService.receiveEvent()
    .subscribe(($event: SearchEvent) => {
      switch ($event.type) {
        case NEW_SEARCH_EVENT: {
          if (!this.addNewRowOnPageReload) {
            if (!this.isDataLoadedToGrid) {
              this.loadGridIfReady();
            } else {
              this.loadData();
            }
          } else {
            this.searchService.addNewRowToModel();
            this.addNewRowOnPageReload = false;
          }
          break;
        }
        case NEW_ROW_EVENT: {
          this.loadData();
          break;
        }
        case DELETE_ROW_EVENT: {
          this.loadData();
          break;
        }
        case REFRESH_SEARCH_EVENT: {
          if (this.gridOptions) {
            this.gridOptions.api.refreshCells();
            this.loadData();
          }
          break;
        }
      
    }});
  }

  // public createGridOptions() {
  //   return <GridOptions>{
  //     onGridReady: ($event: any) => {
  //       this.isGridReady = true;
  //      // this.gridOptions.defaultColDef.filter = true;
  //       this.loadGridIfReady();
  //       this.paramsService.aggridApi=this.gridOptions.api;
  //       this.searchService.getPagedData().aggridApi = this.gridOptions.api;
  //     },
  //     onColumnResized: ($event: any) => {
  //       /* if condition on event.finished is removed because some event capture is lost at times
  //        * so here the width wil be captured every time when the user resize the column
  //        * after completing his actions when user tends to navigate to other component
  //        * the ngDestroy() is called for current component where we save the column width preference
  //        * call will be give once
  //        * on refresh or kill of application current state is not save or lost
  //        */
  //       this.resizedColumn = $event.column.colId;
  //       const filteredIndex: number = this.gridOptions.columnApi.getColumnState()
  //         .findIndex((column: any) => {
  //           return (column.colId === this.resizedColumn);
  //         });
  //       this.tablePreference.columnConfig.configuration.columnWidthsObject[this.resizedColumn]
  //         = this.gridOptions.columnApi.getColumnState()[filteredIndex].width;
  //     },
  //     getRowStyle: this.tableConfig.rowStyle ? this.tableConfig.rowStyle : this.getRowStyle,
  //     context: {
  //       // Below Reference is to enable parent component's method access for custom cell renderers/editors
  //       componentParent: this
  //     },
  //     overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
  //     overlayNoRowsTemplate: '<span class="ag-overlay-loading-center">No records to display</span>',
  //     animateRows: true,
  //     enableColResize: this.tableConfig.enableColResize,
  //     singleClickEdit: true,
  //     suppressClickEdit: this.tableConfig.suppressClickEdit,
  //     suppressAutoSize: true,
  //     suppressColumnMoveAnimation: false,
  //     suppressDragLeaveHidesColumns: true,
  //     suppressMovableColumns: true,
  //     suppressFieldDotNotation: false,
  //     editType: 'fullRow',
  //     enableFilter: false,
  //     headerHeight: 60,
  //     rowHeight: this.tableConfig.rowHeight || 30, // default 40
  //     isExternalFilterPresent: this.isExternalFilterPresent,
  //     doesExternalFilterPass: this.doesExternalFilterPass,
  //     defaultColDef: {
  //       resizable:true
  //     }
  //   };
  // }
  public createGridOptions() {
    return <GridOptions>{
      onGridReady: ($event: any) => {
        this.isGridReady = true;
       // this.gridOptions.defaultColDef.filter = true;
        this.loadGridIfReady();
        // this.searchService.getPagedData().aggridApi = this.gridOptions.api;
      },
      onColumnResized: ($event: any) => {
        /* if condition on event.finished is removed because some event capture is lost at times
         * so here the width wil be captured every time when the user resize the column
         * after completing his actions when user tends to navigate to other component
         * the ngDestroy() is called for current component where we save the column width preference
         * call will be give once
         * on refresh or kill of application current state is not save or lost
         */
        this.resizedColumn = $event.column.colId;
        const filteredIndex: number = this.gridOptions.columnApi.getColumnState()
          .findIndex((column: any) => {
            return (column.colId === this.resizedColumn);
          });
        this.tablePreference.columnConfig.configuration.columnWidthsObject[this.resizedColumn]
          = this.gridOptions.columnApi.getColumnState()[filteredIndex].width;
      },
      getRowStyle: this.tableConfig.rowStyle ? this.tableConfig.rowStyle : this.getRowStyle,
      context: {
        // Below Reference is to enable parent component's method access for custom cell renderers/editors
        componentParent: this
      },
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
      overlayNoRowsTemplate: '<span class="ag-overlay-loading-center">No records to display</span>',
      animateRows: true,
      enableColResize: this.tableConfig.enableColResize,
      singleClickEdit: true,
      suppressClickEdit: this.tableConfig.suppressClickEdit,
      suppressAutoSize: true,
      suppressColumnMoveAnimation: false,
      suppressDragLeaveHidesColumns: true,
      suppressMovableColumns: true,
      suppressFieldDotNotation: false,
      editType: 'fullRow',
      enableFilter: false,
      headerHeight: 60,
      rowHeight: this.tableConfig.rowHeight || 30, // default 40
      isExternalFilterPresent: this.isExternalFilterPresent,
      doesExternalFilterPass: this.doesExternalFilterPass,
      defaultColDef: {
        resizable:true
      }
    };
  }
  onCellClicked(e){
    this.paramsService.params=e
    // console.debug(e,'----------------------eeeeee')
  }
  public isExternalFilterPresent() {
    return true;
  }

  public doesExternalFilterPass(node: any) {
    return true;
  }

  public getRowStyle(params: any) {

    if (params.data && params.data['isNewItem'] && params.data['isNewItem'].cVal) {
      return { 'background-color': '#FCBC05', 'color': '#212121' };
    } else {
      if (params.node.rowIndex % 2 === 0) {
        return { 'background-color': '#FFFFFF', 'color': '#212121' };
      } else {
        return { 'background-color': '#E5E5E5', 'color': '#212121' };
      }
    }

  }

  public initializeTableProperties() {
    console.debug("window.innerHeight", window.innerHeight);
    /*jira 1894
    1920px x 1080px
    1680px x 1050px
    Surface Pro3: 1440px x 960px
    Surface Pro3/4/5: 1368px x 912p
    */
    if (window.innerHeight >= 1440) {
      this.tableHeightAsNumberOfRows = 40;
      /* 40 row calculation --
          1272X to be hardcoded for larger screens as per jira 1894.
          1272 - 72(header height)  = 1200
          1200/30px(30 is 1 row height) = 40 ~ 40rows.
          Height needed as rows since variable used to setbackdrop also when noofrows are less.
        */
    } else if (window.innerHeight >= 1080) {
      this.tableHeightAsNumberOfRows = 26;
      /* 26 row calculation --
          850PX to be hardcoded for larger screens as per jira 1894.
          850 - 72(header height)  = 778
          778/30px(30 is 1 row height) = 25.9 ~ 26rows.
          Height needed as rows since variable used to setbackdrop also when noofrows are less.
        */
    } else if (window.innerHeight >= 1050) {
      this.tableHeightAsNumberOfRows = 20;
      /* 20 row calculation --
         675PX to be hardcoded for larger screens as per jira 1894.
         675 - 72(header height)  = 603
         603/30px(30 is 1 row height) = 20.1 ~ 20rows.
         Height needed as rows since variable used to setbackdrop also when noofrows are less.
       */
    } else if (window.innerHeight >= 1024) {

      this.tableHeightAsNumberOfRows = 16;
      /* 16 row calculation --
        550PX to be hardcoded for larger screens as per jira 1894.
        550 - 72(header height)  = 468
        468/30px(30 is 1 row height) = 15.6 ~ 16rows.
        Height needed as rows since variable used to setbackdrop also when noofrows are less.
    */
    } else if (window.innerHeight >= 900) {

      this.tableHeightAsNumberOfRows = 15;
      /* 15 row calculation --
        525PX to be hardcoded for larger screens as per jira 1894.
        525 - 72(header height)  = 453
        453/30px(30 is 1 row height) = 15.1 ~ 15rows.
        Height needed as rows since variable used to setbackdrop also when noofrows are less.
    */
    } else {
      this.tableHeightAsNumberOfRows = 10;
    }


    this.searchParams = this.searchService.searchParams;
    // Initialize table config from search params
    this.tableConfig = this.searchParams.dataTableConfig;
    if (!this.tableConfig) {
      this.tableConfig = new DataTableConfig();
    }
    this.tableEditor = this.searchService.searchParams.tableEditor;
    if(this.searchService.getPagedData().pageRows.length > 0 && this.searchService.displayMode !== 'C'
     || (this.searchService.displayMode === 'C')) {
      this.actionButtons = this.searchService.searchParams.actionButtons;
    }
    this.keyEventHandler = new GridViewKeyEventHandlerService(this.tableEditor);
    if (this.tableConfig.keyEventHandler) {
      this.keyEventHandler = this.tableConfig.keyEventHandler;
    } else {
      this.keyEventHandler = new GridViewKeyEventHandlerService(this.tableEditor);
    }
    // Load user preferences
    this.tablePreference = this.searchService.getTablePreference();
    if (this.tablePreference && this.tablePreference.columnConfig) {
      this.loadGridIfReady();
    }
  }

  public loadGridIfReady(manualRefresh?: boolean) {
    if (this.isGridReady) {
      if ((manualRefresh || !this.isColumnDefinitionLoaded) && this.metaData) {
        this.buildColumnDefinitions();
        this.isColumnDefinitionLoaded = true;
      }
      if ((manualRefresh || !this.isDataLoadedToGrid)
        && this.isColumnDefinitionLoaded && this.searchService.searchStatus.hasResults) {

          this.loadData();
        this.isDataLoadedToGrid = true;
      }
      if (this.tableMode === 'C') {

        this.loadData();
        this.isDataLoadedToGrid = true;
      }
    }
  }
  public defaultColDef = {
    editable: true,
  };

  private buildColumnDefinitions() {
    this.columnDefinitions = [];
    const defaultCellRenderer: any
      = this.tableConfig.defaultCellRenderer ?
        this.tableConfig.defaultCellRenderer : BasicCellRendererComponent;
    const defaultCellEditor: any
      = this.tableConfig.defaultCellEditor ?
        this.tableConfig.defaultCellEditor : BasicCellEditorComponent;
    const defaultHeaderComponent: any
      = this.tableConfig.defaultHeaderComponent ?
        this.tableConfig.defaultHeaderComponent : BasicCellHeaderComponent;
    // const defaultPinnedCellRenderer: any
    //   = this.tableConfig.defaultPinnedCellRenderer ?
    //     this.tableConfig.defaultPinnedCellRenderer : PinnedCellRendererComponent;
    if (this.tableConfig.indexColumnVisible) {
      this.columnDefinitions.push({
        isRestricted: true,
        headerName: '',
        colId: 'isSelected',
        field: 'isSelected',
        suppressResize: true,
        suppressSizeToFit: true,
        width: this.tableConfig.isSelectedCellWidth,
        pinned: 'left',
        headerComponentFramework: defaultHeaderComponent,
        cellRendererFramework: defaultCellRenderer,
       // pinnedRowCellRendererFramework: defaultPinnedCellRenderer,
        index: -1,
        activeIndex: -1,
        suppressFilter: true, // Disabling server side filter
        checkboxSelection : true,
        showDisabledCheckboxes : false,
        headerCheckboxSelection : true
      });
    }
    let columnCount = this.metaData['columnList'].length;
    if (columnCount > 10 && !this.searchParams.isFullFetch
      && (this.searchParams.columnCount > 0)) {
      columnCount = this.searchParams.columnCount; 
    }
    this.metaDataMap = {};
    this.metaDataMap['isSelected'] = { displayType: 'R' }; // Display Type :: 'Restricted'
    // Populating ColumnDefinitions for Ag-grid
    for (let x: number = 0; x < columnCount; x++) {
      const key: string = this.metaData['columnList'][x].propertyName;
      this.metaDataMap[key] = this.metaData['columnList'][x];
      if (this.tableConfig.hiddenPropertiesForTable.indexOf(key) !== -1) {
        continue;
      }
      const columnDefinition: any = new Object();
      // Note:: columnDefinition[isRestricted] = true, will not be available in User Preferences
      columnDefinition.isRestricted = (this.metaDataMap[key].displayType === 'H');
      columnDefinition.headerName = this.resourceService.get(this.metaDataMap[key].elementId);
      columnDefinition.field = columnDefinition.colId = key;
      if (this.metaDataMap[key].type === 'DROPDOWN' || this.metaDataMap[key] === 'EDITABLEDROPDOWN') {
        if(this.metaDataMap[key].values){
          this.metaDataMap[key].values = JSON.parse(JSON.stringify(this.metaDataMap[key].values));
        }
        /* LFWM-295 Inventory adjustment Screen Changes
     	   Customizing the metadata for display purpose */
        this.customizeMetadataMap(key);
      }
      columnDefinition.index = this.metaDataMap[key].index;
      const width: number = this.tableConfig.columnWidth ? this.tableConfig.columnWidth : 250;
      columnDefinition.width = (this.metaDataMap[key].type === 'DATE' || this.metaDataMap[key].type === 'DATETIME') ? width : width; // Default Column Width
      // minWidth & maxWidth should be set only after setting width property
      const minWidth = this.tableConfig.minColumnWidth ? this.tableConfig.minColumnWidth : 150;
      columnDefinition.minWidth = (this.metaDataMap[key].type === 'DATE' || this.metaDataMap[key].type === 'DATETIME') ? minWidth : minWidth;
      columnDefinition.maxWidth = 450;
      columnDefinition.headerComponentFramework = (this.tableConfig && this.tableConfig.columnHeaderMapping[key])
        ? this.tableConfig.columnHeaderMapping[key] : defaultHeaderComponent;
      columnDefinition.cellRendererFramework = (this.tableConfig && this.tableConfig.columnRendererMapping[key])
        ? this.tableConfig.columnRendererMapping[key] : defaultCellEditor;
      columnDefinition.cellRendererParams = { cellMetaData: this.metaDataMap[key] };
      // if this table is not editable, set the editable property as false
      // if the table is editable, set the editable property as a dynamic callback function
      columnDefinition.editable = !this.tableConfig.editable ? false :
        (this.tableConfig.isColumnEditable ? this.tableConfig.isColumnEditable : this.isColumnEditable);
      columnDefinition.cellStyle = { 'overflow': 'visible' };
      columnDefinition.cellEditorFramework = (this.tableConfig && this.tableConfig.columnEditorMapping[key])
        ? this.tableConfig.columnEditorMapping[key] : defaultCellEditor;
      columnDefinition.cellEditorParams = { cellMetaData: this.metaDataMap[key] };
      // columnDefinition.pinnedRowCellRendererFramework = (this.tableConfig && this.tableConfig.columnPinnedRendererMapping[key])
      //   ? this.tableConfig.columnPinnedRendererMapping[key] : defaultPinnedCellRenderer;
      columnDefinition.suppressKeyboardEvent = (params: any) => {
        // Note :: While Editing Navigation via Arrow Keys will be disabled
        const KEY_LEFT: number = 37;
        const KEY_UP: number = 38;
        const KEY_RIGHT: number = 39;
        const KEY_DOWN: number = 40;
        const KEY_PAGE_UP: number = 33;
        const KEY_PAGE_DOWN: number = 34;
        const KEY_PAGE_HOME: number = 36;
        const KEY_PAGE_END: number = 35;
        return (params.editing && ((params.event.keyCode === KEY_LEFT)
          || (params.event.keyCode === KEY_UP)
          || (params.event.keyCode === KEY_RIGHT)
          || (params.event.keyCode === KEY_DOWN)
          || (params.event.keyCode === KEY_PAGE_UP)
          || (params.event.keyCode === KEY_PAGE_DOWN)
          || (params.event.keyCode === KEY_PAGE_HOME)
          || (params.event.keyCode === KEY_PAGE_END)));
      };
      this.columnDefinitions.push(columnDefinition);
    }
    this.applyUserPreferences();
    this.searchService.getPagedData().aggridApi = this.gridOptions.api;
    this.gridOptions.onSelectionChanged =()=>{
      var ids = []
      var selectRows = this.gridOptions.api.getSelectedRows()
      this.paramsService.setData(selectRows)
      console.debug(this.paramsService.data,selectRows)
      this.selectRows = selectRows
    }
    this.gridOptions.api.setColumnDefs(this.columnDefinitions);
  }

  // public buildColumnDefinitions() {
  //   this.columnDefinitions = [];
  //   const defaultCellRenderer: any
  //     = this.tableConfig.defaultCellRenderer ?
  //       this.tableConfig.defaultCellRenderer : BasicCellRendererComponent;
  //   const defaultCellEditor: any
  //     = this.tableConfig.defaultCellEditor ?
  //       this.tableConfig.defaultCellEditor : BasicCellEditorComponent;
  //   const defaultHeaderComponent: any
  //     = this.tableConfig.defaultHeaderComponent ?
  //       this.tableConfig.defaultHeaderComponent : BasicCellHeaderComponent;
  //   if (this.tableConfig.indexColumnVisible) {
  //     this.columnDefinitions.push({
  //       isRestricted: true,
  //       headerName: '',
  //       colId: 'isSelected',
  //       field: 'isSelected',
  //       suppressResize: true,
  //       suppressSizeToFit: true, 
  //       width: this.tableConfig.isSelectedCellWidth,
  //       pinned: 'left',
  //       headerComponentFramework: defaultHeaderComponent,
  //       cellRendererFramework: defaultCellRenderer,
  //      // pinnedRowCellRendererFramework: defaultPinnedCellRenderer,
  //       index: -1,
  //       activeIndex: -1,
  //       suppressFilter: true, // Disabling server side filter
  //       checkboxSelection : true,
  //       showDisabledCheckboxes : false,
  //       headerCheckboxSelection : true
  //     });
  //   }
  //   let columnCount = this.metaData['columnList'].length;
  //   if (columnCount > 10 && !this.searchParams.isFullFetch
  //     && (this.searchParams.columnCount > 0)) {
  //     columnCount = this.searchParams.columnCount;
  //   }
  //   this.metaDataMap = {};
  //   this.metaDataMap['isSelected'] = { displayType: 'R' }; // Display Type :: 'Restricted'
  //   // Populating ColumnDefinitions for Ag-grid
  //   for (let x: number = 0; x < columnCount; x++) {
  //     const key: string = this.metaData['columnList'][x].propertyName;
  //     this.metaDataMap[key] = this.metaData['columnList'][x];
  //     if (this.tableConfig.hiddenPropertiesForTable.indexOf(key) !== -1) {
  //       continue;
  //     }
  //     const columnDefinition: any = new Object();
  //     // Note:: columnDefinition[isRestricted] = true, will not be available in User Preferences
  //     columnDefinition.isRestricted = (this.metaDataMap[key].displayType === 'H');
  //     columnDefinition.headerName = this.resourceService.get(this.metaDataMap[key].elementId);
  //     columnDefinition.field = columnDefinition.colId = key;
      
  //     if (this.metaDataMap[key].type === 'DROPDOWN' || this.metaDataMap[key] === 'EDITABLEDROPDOWN') {
  //       this.metaDataMap[key].values = JSON.parse(JSON.stringify(this.metaDataMap[key].values));
  //       /* LFWM-295 Inventory adjustment Screen Changes
  //    	   Customizing the metadata for display purpose */
  //       this.customizeMetadataMap(key);
  //     }
  //     columnDefinition.index = this.metaDataMap[key].index;
  //     const width: number = this.tableConfig.columnWidth ? this.tableConfig.columnWidth : 250;
  //     columnDefinition.width = (this.metaDataMap[key].type === 'DATE') ? 267 : width; // Default Column Width
  //     // minWidth & maxWidth should be set only after setting width property
  //     const minWidth = this.tableConfig.minColumnWidth ? this.tableConfig.minColumnWidth : 150;
  //     columnDefinition.minWidth = (this.metaDataMap[key].type === 'DATE') ? 267 : minWidth;
  //     columnDefinition.maxWidth = 450;
  //     columnDefinition.headerComponentFramework = (this.tableConfig && this.tableConfig.columnHeaderMapping[key])
  //       ? this.tableConfig.columnHeaderMapping[key] : defaultHeaderComponent;
  //     columnDefinition.cellRendererFramework = (this.tableConfig && this.tableConfig.columnRendererMapping[key])
  //       ? this.tableConfig.columnRendererMapping[key] : defaultCellEditor;
  //     columnDefinition.cellRendererParams = { cellMetaData: this.metaDataMap[key] };
  //     // if this table is not editable, set the editable property as false
  //     // if the table is editable, set the editable property as a dynamic callback function
  //     columnDefinition.editable = !this.tableConfig.editable ? false :
  //       (this.tableConfig.isColumnEditable ? this.tableConfig.isColumnEditable : this.isColumnEditable);
  //     columnDefinition.cellStyle = { 'overflow': 'visible' };
  //     columnDefinition.cellEditorFramework = (this.tableConfig && this.tableConfig.columnEditorMapping[key])
  //       ? this.tableConfig.columnEditorMapping[key] : defaultCellEditor;
  //     columnDefinition.cellEditorParams = { cellMetaData: this.metaDataMap[key] };
  //     // columnDefinition.pinnedRowCellRendererFramework = (this.tableConfig && this.tableConfig.columnPinnedRendererMapping[key])
  //     //   ? this.tableConfig.columnPinnedRendererMapping[key] : defaultPinnedCellRenderer;
  //     columnDefinition.suppressKeyboardEvent = (params: any) => {
  //       // Note :: While Editing Navigation via Arrow Keys will be disabled
  //       const KEY_LEFT: number = 37;
  //       const KEY_UP: number = 38;
  //       const KEY_RIGHT: number = 39;
  //       const KEY_DOWN: number = 40;
  //       const KEY_PAGE_UP: number = 33;
  //       const KEY_PAGE_DOWN: number = 34;
  //       const KEY_PAGE_HOME: number = 36;
  //       const KEY_PAGE_END: number = 35;
  //       return (params.editing && ((params.event.keyCode === KEY_LEFT)
  //         || (params.event.keyCode === KEY_UP)
  //         || (params.event.keyCode === KEY_RIGHT)
  //         || (params.event.keyCode === KEY_DOWN)
  //         || (params.event.keyCode === KEY_PAGE_UP)
  //         || (params.event.keyCode === KEY_PAGE_DOWN)
  //         || (params.event.keyCode === KEY_PAGE_HOME)
  //         || (params.event.keyCode === KEY_PAGE_END)));
  //     };
  //     this.columnDefinitions.push(columnDefinition);
  //   }
  //   this.applyUserPreferences();
  //   this.gridOptions.onSelectionChanged =()=>{
  //     var ids = []
      
  //     var selectRows = this.gridOptions.api.getSelectedRows()
  //     console.debug('selectRows', selectRows);


  //     this.paramsService.setData(selectRows)
  //   }
  //   // this.searchService.getPagedData().aggridApi = this.gridOptions.api;
  //   this.gridOptions.api.setColumnDefs(this.columnDefinitions);
  // }
  public columnDefs: any[] = [
    { field: 'name' },
    // Using dot notation to access nested property
    { field: 'medals.gold', headerName: 'Gold' },
    // Show default header name
    { field: 'person.age' },
  ];

  public rowData: any[] | null = [];


  /**
  * If the table is in View Mode make all columns as non editable
  * In the Edit Mode, make columns editable based on its metadata
  * In Create Mode, make all columns as editable
  * @paramdisplayType : The column displayType as in metadata
  */
  public isColumnEditable(params: any) {
    const rowData = params.data;
    const componentRef = params.context.componentParent;
    // if the table is in view mode, do not make any column as editable
    if (['V', 'P'].indexOf(componentRef.tableMode) !== -1) {
      return false;
    }

    const isNewRow: boolean = rowData && rowData['isNewItem'] && rowData['isNewItem'].cVal;
    // if this is a new row and if its id is not auto generated, then enable all columns
    if (isNewRow && !componentRef.searchParams.autoGenerateIdProperty) {
      return true;
    } else {
      // enable the column only if it is an editable field in the metadata
      return ([ 'EL', 'L', 'EA', 'ET'].indexOf(componentRef.metaDataMap[params.colDef.colId].displayType) > -1);
    }
  }

  // Method to apply/refresh user preferences
  public applyUserPreferences() {
    if (this.tableConfig.tablePreferencDisable) {
      return;
    }
    this.tablePreference = this.searchService.tablePreference;
    this.columnDefinitions
      .forEach((columnDefinition: any) => {
        const key = columnDefinition.colId;
        columnDefinition.hide =
          ((this.tablePreference.columnConfig?.configuration.hiddenPropertiesList.indexOf(key) > -1)
            || (this.metaDataMap[key].displayType === 'H'));
        columnDefinition.activeIndex =
          this.tablePreference.columnConfig?.configuration.propertyIndexMap[key] ?
            this.tablePreference.columnConfig?.configuration.propertyIndexMap[key] : columnDefinition.index;
        if (!columnDefinition.isRestricted) {
          columnDefinition.pinned =
            (this.tablePreference.columnConfig?.configuration.pinnedPropertiesList.indexOf(key) > -1) ? 'left' : null;
          if (this.tablePreference.columnConfig?.configuration.columnWidthsObject[key]) {
            columnDefinition.width = this.tablePreference.columnConfig?.configuration.columnWidthsObject[key];
          } else {
            const width: number = this.tableConfig.columnWidth ? this.tableConfig.columnWidth : 250;
            columnDefinition.width = (this.metaDataMap[key].type === 'DATE') ? 267 : width;
            /**
             * The below code is added here from line no: 352
             * This added because if a column has special width then that has to be applied over default width
             * but this width should not override the user preference width for the same column
             * you can refer wave release 'editicon' column for implements special width feature
             */
            const propertyName = this.metaDataMap[key].propertyName;
            const customColumnWidth = this.tableConfig.specialWidthDetails[propertyName];
            if (customColumnWidth) {
              columnDefinition.minWidth = customColumnWidth;
              columnDefinition.width = customColumnWidth;
            }
          }
        }
      });
    // this.columnDefinitions
    //   .sort((a: any, b: any) => {
    //     return ((a.activeIndex < b.activeIndex) ? -1 : ((a.activeIndex > b.activeIndex) ? 1 : 0));
    //   });
  }

  // Method to Customize Metadata for Display Only Changes
  public customizeMetadataMap(key: any) {
    // Overriding Metadata for finalized flag to modify the display type while in Create mode
    // Below, Deep Clone has to be performed to avoid directly updating Metadata Object in Service
    /*LFWM-295 adding this check as the finalized Flag dropdown should be
         disabled while creating new adjustment detail*/
    if (this.tableMode === 'C') {
      if (this.searchParams.tableId
        && this.searchParams.tableId === 'adjdetailtd' && key === 'finalizedflag') {
        const finalizedFlagMetadata = JSON.parse(JSON.stringify(this.metaDataMap[key]));
        finalizedFlagMetadata.displayType = 'V';
        this.metaDataMap[key] = finalizedFlagMetadata;
      }
    }
  }

  public loadData() {
    // this.pageRows=this.crrData
    if (!this.columnDefinitions) {
      // column definitions are not built yet. Skip this event. Data will be loaded once columns are built
      return;
    }
    if (typeof (this.tablePreference.columnConfig?.configuration) === 'string') {
      this.tablePreference.columnConfig.configuration = JSON.parse(this.tablePreference.columnConfig.configuration);
    }
    if (this.isPageDefModified || this.isSortDefModified) {
      if (this.tablePreference.columnConfig?.configuration.pageDefinitionObject) {
        this.tablePreference.columnConfig.configuration.pageDefinitionObject = {};
      }
      this.tablePreference.columnConfig.configuration
        .pageDefinitionObject['pageSize'] = this.pageDefinition.pageSize;
      this.tablePreference.columnConfig.configuration
        .pageDefinitionObject['sortColumn'] = this.pageDefinition.sortColumn;
      this.tablePreference.columnConfig.configuration
        .pageDefinitionObject['sortOrder'] = this.pageDefinition.sortOrder;
      this.saveUserPreferences();
    }
    // this.pageRows = this.searchService.getPagedData().pageRows;
    // this.pageRows=this.crrData
    // console.debug(this.pageRows,'----this.pageRows')
    this.gridHeight = this.tableConfig.gridHeight ? this.tableConfig.gridHeight : (72 + (this.gridOptions.rowHeight * this.tableHeightAsNumberOfRows));
    this.rowsData = this.rowData
    this.selectedRowCount
      = (this.showSelectOption && !this.searchParams.isSingleSelect) ? 0 : -1;
    this.pageRows
      .forEach((pageRow: TableRow) => {
        if (this.selectedRowCount !== -1 && pageRow.isSelected && !pageRow.isNewRow) {
          this.selectedRowCount++;
        }
        const rowDefinition: any = { isSelected: pageRow.isSelected };
        Object.keys(pageRow)
          .forEach((key: any) => {
            rowDefinition[key] = pageRow.cells[key];
          });
        if (pageRow['showAdditionalSelectionIcon']) {
          rowDefinition['showAdditionalSelectionIcon'] = true;
        }
        this.rowsData.push(rowDefinition);
      });
    this.isSelectedAll = ((this.pageRows.length > 0)
      && (this.selectedRowCount === this.pageRows.length));
    this.gridOptions.api.setRowData(this.rowsData);
    if (this.searchService.getPagedData().pinnedPageRows.length > 0) {
      this.gridHeight += (this.searchService.getPagedData().pinnedPageRows.length * 30);
      this.gridOptions.api.setPinnedBottomRowData(this
        .searchService.getPagedData().pinnedPageRows);
    }
    if ((this.tableMode !== 'V') && this.pageRows.length > 0) {
      this.gridOptions.api.setFocusedCell(0, 'isSelected');
      if (this.tableConfig.autoTriggerEdit) {
        this.gridOptions.api.startEditingCell({ rowIndex: 0, colKey: 'isSelected' });
      }
    }
    this.setLoader(false);
    if (this.targetCell) {
      if (this.tableMode === 'V') {
        this.gridOptions.api
          .setFocusedCell(this.targetCell.rowIndex, this.targetCell.colKey);
      } else {
        this.gridOptions.api.startEditingCell(this.targetCell);
      }
      this.targetCell = null;
    }
  }
  public setLoader(isLoading: boolean) {    
    if (isLoading) {
      this.isLoading = isLoading;
      this.gridOptions.api.showLoadingOverlay();
    } else {
      // const gridWidth: number
      //   = this.agGridRef.nativeElement.querySelector('.ag-body-viewport').clientWidth;
      // const totalColumnWidth: number
      //   = this.agGridRef.nativeElement
      //     .querySelector('.ag-row').clientWidth
      //   + this.agGridRef.nativeElement
      //     .querySelector('.ag-pinned-left-cols-container').clientWidth;
      // this.isFullWidthRow = (totalColumnWidth < gridWidth);
      if (!this.isBackdropSet) {
        this.setBackdrop();
      }
      if (this.rowsData.length === 0) {
        this.gridOptions.api.showNoRowsOverlay();
      } else {
        this.gridOptions.api.hideOverlay();
      }
      this.isLoading = isLoading;
    }
  }

  public setBackdrop() {
    let boxShadowCSS: string = '';
    const rowHeight: number = this.gridOptions.rowHeight - 1;
    let shadowHeight: number = 0;
    const rowsToDummyFillBg = this.tableHeightAsNumberOfRows > this.pageDefinition.pageSize ? this.tableHeightAsNumberOfRows : this.pageDefinition.pageSize;
    for (let i = 1; i <= rowsToDummyFillBg; i++) {
      if (i !== 1) {
        boxShadowCSS += ', ';
      }
      shadowHeight += rowHeight;
      boxShadowCSS += 'inset 0px ' + shadowHeight + 'px 0px 0px #' + ((i % 2 === 0) ? 'E5E5E5' : 'FFFFFF');
      shadowHeight++;
      boxShadowCSS += ', inset 0px ' + shadowHeight + 'px 0px 0px #D9DCDE';
    }
    this.agGridRef.nativeElement.querySelector('.ag-body-viewport').style.boxShadow = boxShadowCSS;
    this.isBackdropSet = true;
  }

  public toggleSelectAll(selectAll?: boolean) {
    if (this.selectedRowCount !== -1) {
      // Note: Select All will work only when atleast 1 saved row is present
      const savedRecord: TableRow = this.pageRows
        .find((pageRow: TableRow) => {
          return !pageRow.isNewRow;
        });
      if (!savedRecord) {
        return;
      }
      this.gridOptions.api.stopEditing();
      if (selectAll === undefined) {
        this.isSelectedAll = !this.isSelectedAll;
      } else {
        this.isSelectedAll = selectAll;
      }
      this.pageRows
        .forEach((pageRow: TableRow, index: number) => {
          if (!pageRow.isNewRow && !pageRow.isSelectionFreezed) {
            pageRow.isSelected = this.rowsData[index].isSelected = this.isSelectedAll;
          }
        });
      this.selectedRowCount = this.isSelectedAll ? this.pageRows.length : 0;
      this.gridOptions.api.refreshCells({ columns: ['isSelected'] });
      // event is emitted on enabling or disabling the checkbox at header level
      this.onSelectCheckbox.emit();
    }
  }

  public toggleSelect(rowIndex: number, rowNode: RowNode) {
    if ((this.selectedRowCount !== -1 && !this.pageRows[rowIndex].isSelectionFreezed)
      || (this.showSelectOption && this.searchParams.isSingleSelect)) {
      this.rowsData[rowIndex].isSelected
        = this.pageRows[rowIndex].isSelected = !this.rowsData[rowIndex].isSelected;
      if (this.selectedRowCount !== -1 && !this.pageRows[rowIndex].isSelectionFreezed) {
        this.rowsData[rowIndex].isSelected ? this.selectedRowCount++ : this.selectedRowCount--;
        this.isSelectedAll = (this.selectedRowCount === this.rowsData.length);
        this.gridOptions.api.refreshCells({ columns: ['isSelected'], rowNodes: [rowNode] });
      }
      if (this.showSelectOption && this.searchParams.isSingleSelect) {
        if (this.rowsData[rowIndex].isSelected) {
          this.rowsData
            .forEach((rowData: TableRow, index: number) => {
              rowData.isSelected = this.pageRows[index].isSelected = (index === rowIndex);
            });
          this.gridOptions.api.refreshCells({ columns: ['isSelected'] });
        } else {
          this.gridOptions.api.refreshCells({ columns: ['isSelected'], rowNodes: [rowNode] });
        }
      }
      this.gridOptions.api.stopEditing();
      // event is emitted on enabling or disabling the checkbox at row level
      this.onSelectCheckbox.emit(rowIndex);
    }
  }

  public toggleColumnSort(colId: string) {
    if (colId === this.pageDefinition.sortColumn) {
      this.pageDefinition.sortOrder
        = (this.pageDefinition.sortOrder === 1) ? 0 : 1;
    } else {
      this.pageDefinition.sortColumn = colId;
      this.pageDefinition.sortOrder = 1;
    }
    this.isSortDefModified = true;
    this.setLoader(true);
    this.searchService.performSearch();
  }

  
  public onPageChanged(paginationEvent: PaginationEvent) {
    // if (!this.searchParams.paginateLocally && this.isDetailsEdited()) {
    //   // if there are any edited cells, show a confirmation dialog
    //   this.confirmDialogService.confirmDialog(this.resourceService.get('msg_save_rcrd'))
    //     .subscribe((isConfirm: boolean) => {
    //       if (isConfirm) {
    //         this.toggleSelectAll(false);
    //         const event: SearchEvent = new SearchEvent(PAGE_OVERFLOW_EVENT);
    //         this.searchService.publishEvent(event);
    //         return;
    //       }
    //     });
    // } else {
    //   this.pageDefinition.pageNumber = paginationEvent.pageNumber;
    //   this.isPageDefModified = (this.pageDefinition.pageSize !== paginationEvent.pageSize);
    //   this.pageDefinition.pageSize = paginationEvent.pageSize;
    //   this.isBackdropSet = (this.pageDefinition.pageSize !== paginationEvent.pageSize);
    //   this.setLoader(true);
    //   this.searchService.setIsFromPageChange(true);
    //   this.searchService.performSearch();
    // }
    this.newRecordCount=this.pageDefinition.recordCount
    this.newPageNum = paginationEvent.pageNumber - 1 
    this.newPageSize = paginationEvent.pageSize
    // this.queryOrder([],this.newPageNum,this.newPageSize).subscribe((newdata)=>{
    //   this.rowData = newdata.content
    //   this.loadData()
    // })
    let payload = {
      businessUnitGid : null
    }
    if(this.searchCondition){
      payload = {
        ...this.searchCondition
      }
    }
    if(this.gid){
      payload.businessUnitGid = this.gid
    }
    var permission =  JSON.parse(localStorage.getItem('tms_button_permission'))
    var tenantId = permission['userRestrictions'][0]?.restrictions.filter(item=>item.restrictionTypeName == 'Tenant ID')[0]?.restrictionValue || 0
    var permissionAddUrl = `&tenantID=${tenantId}`

    var matchFlag = (window.location.pathname == '/home/reserve/shipment-match/search')

    this.http.post((`${matchFlag?tmsConfig.tms.newshipmentQueryUri:tmsConfig.tms.shipmentQueryUri}?page=${ this.newPageNum}&size=${this.newPageSize}${permissionAddUrl}`), payload).subscribe((res:any)=>{
      this.newPageNum = res.number + 1
      this.newPageSize = res.size
      this.newRecordCount = res.totalElements
      this.rowData = res.content
    })
  }

  public getLabel(key: any) {
    return this.resourceService.get(key);
  }

  public openLookup(row: any, property: string) {
    this.tableEditor.openLookup(row, property);
  }

  public addNewRow() {
    if (this.pageRows.length < this.pageDefinition.pageSize) {
      this.searchService.addNewRowToModel();
    } else {
      if (!this.searchParams.paginateLocally && this.isDetailsEdited()) {
        // if there are any edited cells, show a confirmation dialog
        this.confirmDialogService.confirmDialog(this.resourceService.get('msg_save_rcrd'))
          .subscribe((isConfirmed: boolean) => {
            if (isConfirmed) {
              this.toggleSelectAll(false);
              const event: SearchEvent = new SearchEvent(PAGE_OVERFLOW_EVENT);
              this.searchService.publishEvent(event);
              return;
            }
          });
      } else {
        // if nothing is edited, navigate to the last page and add a new row
        this.navigateToLastPageAndAddRow();
      }
    }
  }

  // Function to delete newly added row before saving
  public deleteNewRow(rowIndexToDelete: number) {
    this.searchService.deleteNewRowFromModel(rowIndexToDelete);
  }

  public isDetailsEdited() {
    return this.pageRows
      .findIndex((row: any) => {
        return row.isEdited;
      }) >= 0;
  }

  public navigateToLastPageAndAddRow() {
    if (this.pageDefinition.recordCount >= this.pageDefinition.pageSize) {
      let lastPage = Math.ceil(this.pageDefinition.recordCount / this.pageDefinition.pageSize);
      const rowCountOnLastPage = this.pageDefinition.recordCount % this.pageDefinition.pageSize;
      if (rowCountOnLastPage === 0) {
        // if the last page is full, go to the next page
        lastPage++;
      }
      if (lastPage !== this.pageDefinition.pageNumber) {
        const pageEvent = new PaginationEvent(PaginationEvent.PAGE_CHANGE);
        pageEvent.pageNumber = lastPage;
        pageEvent.pageSize = this.pageDefinition.pageSize;
        this.onPageChanged(pageEvent);
        this.addNewRowOnPageReload = true;
      } else {
        this.searchService.addNewRowToModel();
      }
    }
  }

  public handleButtonPanelActions(action) {
    if (action === 'Add Row') {
      this.addNewRow();
    } else {
      this.buttonActionHandler.emit(action);
    }
  }

  public ngOnDestroy() {
    /* call to save user column width preference */
    this.saveUserPreferences();
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  public fetchSearchResults(key: string, value: string, displayType: string) {
    this.filterModel[key] = value;
    this.tableEditor.getAutoFilter(key, value, this.searchService, displayType);
  }

  public showCustomizerPanel() {
    if (!this.isLoading) {
      this.customizerConfig = {};
      this.customizerConfig.columnDefinitions = this.columnDefinitions;
      this.customizerConfig.pageDefinitionObjectBackup
        = Object.assign({}, this.tablePreference.columnConfig.configuration.pageDefinitionObject);
      this.customizerConfig.idProperty = this.searchParams.idProperty;
      this.customizerConfig.showIdPropertyAsHyperLink = this.tableConfig.showIdAsAHyperLink;
      this.customizerConfig.userPreferences = this.tablePreference.columnConfig;
      this.showCustomizer = true;
    }
  }

  public onCustomized() {
    this.showCustomizer = false;
    if (this.tablePreference.columnConfig.configuration) {
      this.applyUserPreferences();
      this.gridOptions.api.setColumnDefs(this.columnDefinitions);
      this.loadData();
      if ((this.tablePreference.columnConfig.configuration
        .pageDefinitionObject['pageSize'] !== this.pageDefinition.pageSize)
        || (this.tablePreference.columnConfig.configuration
          .pageDefinitionObject['sortColumn'] !== this.pageDefinition.sortColumn)
        || (this.tablePreference.columnConfig.configuration
          .pageDefinitionObject['sortOrder'] !== this.pageDefinition.sortOrder)) {
        this.pageDefinition.pageSize = this.tablePreference
          .columnConfig.configuration.pageDefinitionObject['pageSize'];
        this.pageDefinition.sortColumn = this.tablePreference
          .columnConfig.configuration.pageDefinitionObject['sortColumn'];
        this.pageDefinition.sortOrder = this.tablePreference
          .columnConfig.configuration.pageDefinitionObject['sortOrder'];
        this.searchService.performSearch();
      }
    }
  }

  public toggleColumnPinning(colId: string, pinned: string) {
    const filteredIndex: number = this.columnDefinitions
      .findIndex((column: any) => {
        return (column.colId === colId);
      });
    this.columnDefinitions[filteredIndex].pinned = (pinned ? null : 'left');
    this.gridOptions.columnApi.setColumnPinned(colId, this.columnDefinitions[filteredIndex].pinned);
    let retry: boolean = false;
    do {
      try {
        if (this.columnDefinitions[filteredIndex].pinned) {
          this.tablePreference.columnConfig.configuration.pinnedPropertiesList.push(colId);
        } else {
          this.tablePreference.columnConfig.configuration.pinnedPropertiesList
            .splice(this.tablePreference.columnConfig.configuration.pinnedPropertiesList.indexOf(colId), 1);
        }
        retry = false;
      } catch (error) {
        retry = true;
      }
    } while (retry);
    this.saveUserPreferences();
  }

  public saveUserPreferences() {
    this.userPreferenceService.saveTablePreferences(this.tablePreference.columnConfig)
      .subscribe((data: any) => {
        if (!this.tablePreference.columnConfig.preferenceId) {
          this.tablePreference.columnConfig.preferenceId = data.preferenceId;
        }
        this.isPageDefModified = false;
        this.isSortDefModified = false;
      }, (error: any) => {
          this.alertService.clearAll().error(this.resourceService.get('msg_pref_sav_fail'));
      });
  }

}
