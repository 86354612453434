import { Injectable } from '@angular/core';
import { UserService } from 'sce-core';
import { DatePickerConfigService } from '../components/datepicker/datepicker-config.service';

@Injectable()
export class DocumentUtilService {

  constructor(public userService: UserService,
    public dateConfigService: DatePickerConfigService) { }

  // Method to create new record based on Metadata
  public createNewDocumentDataMap(metaData: any, idProperty: string, documentId: string, docType: string) {
    const cellDataMap = {};
    metaData.columnList.forEach((column) => {
      const property = column.propertyName;
      if (property === 'dummy') {
        // Skip
      } else if (property === idProperty) {
        cellDataMap[idProperty] = {
          cName: idProperty,
          cVal: documentId
        };
      } else if (property === 'storerKey') {
        cellDataMap['storerKey'] = {
          cName: property,
          cVal: this.getDefaultStorerValueForUser(column.defaultValue)
        };
      } else if (property === 'facility') {
        const userFacilities = this.userService.getUserRestrictions('Facility');
        let userFacility: string;
        if (userFacilities === null || userFacilities.length === 0) {
          userFacility = column.values[0].key;
        } else {
          userFacility = userFacilities[0];
        }

        cellDataMap[property] = {
          cName: property,
          cVal: userFacility
        };
      } else if (column.type === 'DATE' || column.type === 'DATETIME') {
        cellDataMap[property] = {
          cName: property,
          cVal: null  // To set date value as empty initially in header part
        };
      } else if (column.type === 'DROPDOWN' || column.type === 'EDITABLEDROPDOWN') {
        let defaultOption = '';
         if (property === 'status') {
          defaultOption = '0';
        } else if (property === 'externalPOStatus') {
          defaultOption = '0';
        } else if (property === 'externasnStatus') {
          defaultOption = '0';
        } else if (property === 'recType') {
          if (docType === 'A') {
            defaultOption = 'NORMAL';
          } else if (docType === 'R') {
            defaultOption = 'GRN';
          } else if (docType === 'X') {
            defaultOption = 'XDOCK';
          }
        } else if (property === 'finalizedFlag') { // LFWM-295 adding this check to set Default finalized flag status as 'Open'
          defaultOption = 'N';
        }  else if (column.values[0] && column.values[0].key) {
          defaultOption = column.values[0].key;
        } else if (column.defaultVal && column.defaultVal !== '') {
          defaultOption = column.defaultVal;
        }
        cellDataMap[property] = {
          cName: property,
          cVal: defaultOption
        };
      } else if (column.type === 'CHECKBOX' || column.type === 'CHECKBOX_CHR') {
        // Adding CHECKBOX_CHR type denoting the saved value will be 'Y' or 'N'
        // CHECKBOX type denoting the saved value will be '1' or '0'
        // If the datatype is integer then initial value is set as 0
        let defaultCheckVal: any;
        if (column.dataType === 'Integer') {
          defaultCheckVal = '0';
        } else if (column.dataType === 'String') { // If the datatype is string then initial value is set as N
          defaultCheckVal = 'N';
        } else {
          defaultCheckVal = null;
        }
        cellDataMap[property] = {
          cName: property,
          cVal: defaultCheckVal
        };
      } else {
        let defaultValue: any;
        if (column.dataType === 'Integer' || column.dataType === 'Float') {
          if (column.defaultVal && column.defaultVal !== '') {
            defaultValue = column.defaultVal;
          } else {
            defaultValue = 0;
          }
        } else if (column.dataType === 'String' ) {
          if (column.defaultVal && column.defaultVal !== '') {
            defaultValue = column.defaultVal;
          } else {
            defaultValue = '';
          }
        } else {
          defaultValue = null;
        }

        cellDataMap[property] = {
          cName: property,
          cVal: defaultValue
        };
      }
    });
    return cellDataMap;
  }

  public getDefaultStorerValueForUser(defaultValue) {
    if (defaultValue === undefined) {
      defaultValue = '';
    }
    const userStorers = this.userService.getUserRestrictions('Storer');
    if (userStorers.length > 0) {
      if (userStorers.indexOf(defaultValue) !== -1) {
        return defaultValue;
      } else {
        return userStorers[0];
      }
    } else {
      return defaultValue;
    }
  }

  // Method  to override default display format for each cell value (in VIEW Mode ONLY)
  public getCellDisplayValue(property: any, isLoading, cellDataMap: any, metaDataMap: any): any {
    if (!isLoading) {
      let cellDisplayValue: any;
      const cellDefinition: any = cellDataMap[property];
      if ((cellDefinition.cVal === null) || (cellDefinition.cVal === undefined)) {
        cellDisplayValue = '';
      } else {
        const cellMetadata: any = metaDataMap[property];
        if (cellMetadata.type === 'DROPDOWN' || cellMetadata.type === 'EDITABLEDROPDOWN') {
          const filteredValueObject = cellMetadata.values.filter((valueObject) => {
            return (valueObject.key === cellDefinition.cVal);
          });
          if (filteredValueObject.length > 0) {
            if (property === 'facility') {
              cellDisplayValue = filteredValueObject[0].key;
            } else {
              cellDisplayValue = filteredValueObject[0].value;
            }
          } else {
            cellDisplayValue = '';
          }
        } else if (cellMetadata.type === 'DATE' || cellMetadata.type === 'DATETIME') {
          if (cellDefinition.cVal) {
            if (cellMetadata.type === 'DATE') {
              cellDisplayValue = this.dateConfigService.getFormattedDisplayDate(cellDefinition.cVal);
            } else if (cellMetadata.type === 'DATETIME') {
              cellDisplayValue = this.dateConfigService.getFormattedDisplayDateTime(cellDefinition.cVal);
            }
          } else {
            cellDisplayValue = '';
          }
        } else {
          cellDisplayValue = cellDefinition.cVal;
        }
      }
      return cellDisplayValue;
    } else {
      return '';
    }
  }
}
