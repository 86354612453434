import { AlertService, AppStorageService, KEYS_APPSTORAGE, LoaderEnabled, UserService } from 'sce-core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { ResourceService } from 'sce-core';
import { DatePickerConfigService } from 'sce-core';
import { Observable } from 'rxjs/Observable';
import { SafeResourceUrl } from '@angular/platform-browser';
import { SearchService } from 'src/app/shared/search/search.service';
import { SearchParams } from 'src/app/shared/search/models/search-params.model';
import { SearchStatus } from 'src/app/shared/search/models/search-status.model';
import { UserPreferenceService } from 'src/app/layout/home/services/user-preference.service';
import { PageDefinition } from 'src/app/shared/search/models/page-definition.model';
import { SearchEvent } from 'src/app/shared/search/models/search-event';
import { TablePreference } from 'src/app/shared/search/models/table-preference.model';
import { catchError, map } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { NEW_SEARCH_EVENT, DELETE_ROW_EVENT } from 'src/app/shared/search/search-constants';
import { metaDataGrp, metaDataGrpDetail } from '../../notification-recipient.config';
@Injectable()
export class NotificationRecipientSearchService extends SearchService {
  public metaDataFetchSubjectBill: ReplaySubject<any>;
  public selectedModule;
  public resultsAccordionTitle: string = '';
  public userRestrictions = [];
  public orderData: any = {};
  public pomsUserInfo;
  public userCountryCodes: Array<any> = [];
  public userCountries: Array<any> = [];
  public objectUrl: SafeResourceUrl;
  showObjectTag: boolean;
  maintainSearchCondtions: any;
  constructor(public http$: HttpClient,
    public searchParams: SearchParams,
    public searchStatus: SearchStatus,
    public resourceService: ResourceService,
    public userPreferenceService: UserPreferenceService,
    public alertService: AlertService,
    public dateConfigService: DatePickerConfigService,
    public userService: UserService) {
    // this.searchParams = this.
    super(http$, searchParams, searchStatus, resourceService, userPreferenceService, alertService, dateConfigService, userService);
   // this.pomsUserInfo = this.userService.getPomsUserInfo();
    // this.pomsUserInfo.userCountries[0].dateFormat = 'YYYY/MM/DD HH:mm:ss.SSS';
    // if (this.pomsUserInfo && this.pomsUserInfo.userRestrictions) {
    //   this.userRestrictions = this.pomsUserInfo.userRestrictions;
    // }
    // if (this.pomsUserInfo && this.pomsUserInfo.userCountries) {
    //   this.pomsUserInfo.userCountries.forEach(countries => {
    //     this.userCountries.push(countries.name.toUpperCase());
    //     this.userCountryCodes.push(countries.code);
    //   });
    // }
  }
  
  public performSearch() {
    // if (this.searchParams.tableId === 'recipgroupsearchtd' ) {
    //   this.saveLastSearchCriteria().subscribe((data: any) => {
    //     this.searchId = data.searchId;
    //     this.fetchLastSearch().subscribe((resp: any) => {
    //       if (resp['statusCode'] && resp['statusMessage']) {
    //           if (resp['statusCode'] === 200 && resp['statusMessage'] === 'SUCCESS') {
    //               if (resp.criteria !== 'No records available') {
    //                   this.searchId = resp.searchId;
    //               }
    //           }
    //       }
    //   });
    //   });
    // }
  
    this.fetchSearchResults()
      .subscribe((data: any) => {
        this.searchStatus.hasResults = true;
        this.searchStatus.recordCount = this.pagedData.pageDefinition.recordCount;
        const event = new SearchEvent(NEW_SEARCH_EVENT);
        event.isSearchSuccess = true;
        this.searchCriteria = this.maintainSearchCondtions;
        this.publishEvent(event);
      });
  }

  public performSearchForPopulate(): any {
    if (this.searchParams.tableId === 'sailingsearchtd') {
      this.instructionForLegSearch.next('pass');
    }
    return new Promise((resolve, reject) => {

    this.fetchSearchResults()
      .subscribe((data: any) => {
        this.searchStatus.hasResults = true;
        this.searchStatus.recordCount = this.pagedData.pageDefinition.recordCount;
        const event = new SearchEvent(NEW_SEARCH_EVENT);
        event.isSearchSuccess = true;
        this.publishEvent(event);
        resolve(this.populateLegData);
        });
      });
  }

  // @LoaderEnabled()
  // public fetchLastSearch(): Observable<any> {
  //   let searchType;
  //   if (this.searchParams.tableId === 'ordersearchtd') {
  //     searchType = 'order';
  //   } else if (this.searchParams.tableId === 'shpmtsearchtd') {
  //     searchType = 'shipment';
  //   } else if (this.searchParams.tableId === 'clientsearchtd') {
  //     searchType = 'client';
  //   } else if (this.searchParams.tableId === 'forwardersearchtd') {
  //     searchType = 'forwarder';
  //   } else if (this.searchParams.tableId === 'carriersearchtd') {
  //     searchType = 'carrier';
  //   } else if (this.searchParams.tableId === 'vendorsearchtd') {
  //     searchType = 'vendor';
  //   } else if (this.searchParams.tableId === 'shpprconsgnsearchtd') {
  //     searchType = 'shipperconsignee';
  //   } else if (this.searchParams.tableId === 'evtgroupsearchtd') {
  //     searchType = 'evtgroup';
  //   } else if (this.searchParams.tableId === 'evtmappingsearchtd') {
  //     searchType = 'evtmapping';
  //   } else if (this.searchParams.tableId === 'evtstatussearchtd') {
  //     searchType = 'evtstatus';
  //   } else if (this.searchParams.tableId === 'evtcodesearchtd') {
  //     searchType = 'evtcode';
  //   } else if (this.searchParams.tableId === 'evttriggersearchtd') {
  //     searchType = 'evttrigger';
  //   } else if (this.searchParams.tableId === 'mandfldsearchtd') {
  //     searchType = 'mandfld';
  //   } else if (this.searchParams.tableId === 'invoicessearchtd') {
  //     searchType = 'invoice';
  //   }
  //   const response$ = this.http$.get(`api/data/` + 7 + '/recipgroupsearchtd/' + `fetch/last/search`, { headers: this.hdr })
  //     catchError((error: any) => {
  //       return Observable.throw(error.message);
  //     });
  //   return response$;
  // }
  public saveLastSearchCriteria(): Observable<any> {
    let searchType;
    const lastSearchUrl = `api/data/save/last/search`;
    if (this.searchParams.tableId === 'recipgroupsearchtd') {
      searchType = 'recipientgroup';
    } 
    const ReqPageInfo = {
      tableId: this.searchParams.tableId,
      pageNo: this.pagedData.pageDefinition.pageNumber,
      pageSize: this.pagedData.pageDefinition.pageSize,
      fullFetch: true,
    };
    const ReqCriteria = {
      pageInfo: ReqPageInfo,
      searchCriteria: this.formatSaveSearchCriteria(this.searchCriteria, this.searchParams.additionalParamMap, this.filterCondition)
    };
    const reqPayload = {
      type: 'recipgroupsearchtd',
      appId: 7,
      searchId: this.searchId ? this.searchId : 0,
      criteria: JSON.stringify(ReqCriteria)
    };
    const dataUrl$: any
      = this.http$.post(lastSearchUrl, reqPayload, { headers: this.hdr });
    return dataUrl$.pipe(
      map((response: any) => {
       this.alertService.clearAll().success(response.statusMessage);
        return(response);
      }),
      catchError((error: any) => {
          return error;
        // if (errorObject.error && errorObject.error.errors && errorObject.error.errors[0]) {
        //   this.alertService.error(errorObject.error.errors[0].validationMessage);
        // }
      }));
      
  }

  @LoaderEnabled()
  public fetchSearchResults(): Observable<any> {
    this.pagedData.pageRows = [];
    let rawResponse;
    if (this.pagedData.pageDefinition.sortOrder === null) {
      this.pagedData.pageDefinition.sortOrder = 0;
    }
    const tableId = this.searchParams.tableId;
    let pageInfoReq = {
      pageNum: this.pagedData.pageDefinition.pageNumber,
      sortColumn: this.pagedData.pageDefinition.sortColumn,
      sortOrder: this.pagedData.pageDefinition.sortOrder,
      pageSize: this.pagedData.pageDefinition.pageSize,
      tableName: this.searchParams.tableId
    };
    if (tableId === 'sailinglegsearchtd' && this.isMultiLegSelection) {
      pageInfoReq = {
        pageNum: 0,
        sortColumn: this.pagedData.pageDefinition.sortColumn,
        sortOrder: this.pagedData.pageDefinition.sortOrder,
        pageSize: 0,
        tableName: this.searchParams.tableId
      };
      this.isMultiLegSelection = false;
    }
    const payload = {
      pageInfo: pageInfoReq,
      searchCriteria: this.localFormatingSearchCri(
        this.searchCriteria , this.filterCondition),
      appId: 7,
      hiddenProperties: null,
      pinnedProperties: null,
      propertyIndexMap: null,
      columnOrder: null
    };
    if (!payload.searchCriteria.conditions) {
      return of({});
    }
    // const dataUrl$: any
    //   = this.http$.post(`api/poms/shipment/search`, JSON.stringify(payload));
    // return dataUrl$
    //   .map((resp: any) => {
    //     const response = resp.result.data;
    //     this.resultsAccordionTitle = response.total + ' '
    //       .concat(this.resourceService.get('lbl_result_count'));
    //     const rows = [];
    //     response.data.forEach((pageRow, index) => {
    //       const cells = [];
    //       // cells = Object.keys(pageRow).map(key => ({'cName': key, 'cVal': pageRow[key]}));
    //       Object.keys(pageRow).forEach((key: any) => {
    //         this.metadata.columnList.forEach(meta => {
    //           if (meta.elementId === key) {
    //             cells.push({ 'cName': key, 'cVal': pageRow[key] });
    //           }
    //         });
    //       });
    //       const rowObj = { rIdx: index + 1, cells: cells };
    //       rows.push(rowObj);
    //     });
    //     const transformedRes = {
    //       statusCode: response.data.status, statusMessage: 'SUCCESS',
    //       tableId: this.searchParams.tableId, pageNo: response.pageNum, totalRecords: response.total,
    //       sortColumn: response.sortColumn, sortOrder: response.sortOrder, pageSize: response.pageSize,
    //       pageData: { rows: rows }, grandTotalProperties: null, aggregateRecords: null
    //     };
    //     return this.transformSearchResponse(transformedRes);

    //   })
  //   const dataUrl$: any
  //   = this.http$.post(`api/poms/shipment/search`, JSON.stringify(payload));
  // return dataUrl$
    const hdr = new HttpHeaders({ 'api': 'notification' });
  const dataUrl$: any
  = this.http$.post(this.searchParams.url, JSON.stringify(payload),{ headers: hdr });
return dataUrl$.pipe(
    map((response: any) => {
      if(this.searchParams.url === 'api/recipient/recipgroupdetails'){
        let success = false;
        for(let i = 0 ; i < response['pageData'].rows[0].cells.length ; i++){
          if(response['pageData'].rows[0].cells[i].cName === 'USER_ID') {
            success = true;
            break;
            } else {
              success = false;
            }
        }
      if(success === true){
        return  this.transformSearchResponse(response);
      } else {
        this.pagedData.pageDefinition.recordCount = 0;
        this.pagedData.pageRows.splice(0, 0);
        this.searchStatus.hasResults = true;
        this.searchStatus.recordCount = 0;
        const event = new SearchEvent(NEW_SEARCH_EVENT);
        event.isSearchSuccess = false;
        this.publishEvent(event);
      }
      } else {
        return this.transformSearchResponse(response);
      }
    }),
      catchError((error: any) => {
        // if (errorObject.error && errorObject.error.errors && errorObject.error.errors[0]) {
        //   this.alertService.error(errorObject.error.errors[0].validationMessage);
        // }else if(errorObject.error.statusCode === 403 && errorObject.error.statusMessage === 'Access is denied') {
        //   this.alertService.error(errorObject.error.statusMessage);
        // }
        // JIRA LFWM - 1716 When the user provides any invalid filter value then the previous result data should be cleared
        this.pagedData.pageDefinition.recordCount = 0;
        this.pagedData.pageRows.splice(0, 0);
        this.searchStatus.hasResults = true;
        this.searchStatus.recordCount = 0;
        const event = new SearchEvent(NEW_SEARCH_EVENT);
        event.isSearchSuccess = false;
        this.publishEvent(event);
        return error;
        // return Observable.throw('Failed to fetch  Search Results :: ' + errorObject.message);
      }));

  }
  
  localFormatingSearchCri(searchCriteria: any , filterCondition) {
     if (!searchCriteria) {
      return {};
    }
    this.maintainSearchCondtions = (JSON.parse(JSON.stringify(searchCriteria)));
    let allConditions = null;
    // Code added to merge filter conditions with master search criteria, if filter condition is there.
    if (filterCondition && filterCondition.clauses.length > 0) {
      allConditions = JSON.parse(JSON.stringify(searchCriteria.conditions));
      if (allConditions && allConditions.length > 0) {


        allConditions[allConditions.length - 1].operator = 'AND';
      }
      allConditions.push(filterCondition);
    } else {
      allConditions = searchCriteria.conditions;
    }
    // const conditionReqList = [];
    // allConditions.forEach((condition: any, index: any) => {
    //   const clauseReqlist = [];
    //   condition.clauses.forEach((clause: any) => {
    //     if (clause.value !== undefined && clause.value !== null) {
    //       clauseReqlist.push({
    //         column: clause.column,
    //         operation: clause.operation,
    //         value: clause.value,
    //         value1: clause.value1,
    //         logicalOperation: clause.logicalOperation,
    //         typeOfField: clause.typeOfField
    //       });
    //     }
    //   });
    //   if (clauseReqlist.length !== 0) {
    //     let logOp = null;
    //     if (index < allConditions.length - 1) {
    //       logOp = condition.operator;
    //     }
    //     conditionReqList.push({
    //       mandatory: condition.isMandatory,
    //       clauses: clauseReqlist,
    //       logicalOperation: logOp
    //     });
    //   }
    // });
    // if (additionalParamMap) {
    //   const additionalClauses = [];
    //   const additionalParams = additionalParamMap;
    //   const additionalProps = Object.keys(additionalParams);
    //   if (additionalProps.length > 0) {
    //     const lastCondition = conditionReqList.slice(conditionReqList.length - 1)[0];
    //     lastCondition.logicalOperation = 'AND';
    //     additionalProps.forEach((property: string, index: number) => {
    //       additionalClauses.push({
    //         column: property,
    //         operation: '=',
    //         value: additionalParams[property],
    //         logicalOperation: index === 0 ? '' : 'AND'
    //       });
    //     });
    //     conditionReqList.push({
    //       mandatory: false,
    //       clauses: additionalClauses,
    //       logicalOperation: ''
    //     });
    //   }
    // }
     searchCriteria.conditions = allConditions;
    searchCriteria['conditions'].forEach((condition: any, index: any) => {
      condition.clauses.forEach((clause: any) => {
        clause['columnName'] = clause.column;
     })});
    return searchCriteria;
  }

  
  @LoaderEnabled()
  public fetchMetadataNotifDtl(): Observable<any> {
    if (!this.metaDataFetchSubjectBill) {
      this.metaDataFetchSubjectBill = new ReplaySubject<any>(1);
       const tableId = this.searchParams.tableId;
       this.getTablePref(tableId).subscribe((tablePref) =>{
        this.tablePreference = tablePref;
      const pageDefinition: PageDefinition = this.pagedData.pageDefinition;
      const userPreference = this.tablePreference.columnConfig;
      if (this.searchParams.defaultSortColumn) {
        pageDefinition.sortColumn = this.searchParams.defaultSortColumn;
      } else {
        pageDefinition.sortColumn = userPreference.configuration.pageDefinitionObject['sortColumn'];
      }
      if (userPreference.configuration.pageDefinitionObject['sortOrder'] === null) {
        pageDefinition.sortOrder = 0;
      } else {
        pageDefinition.sortOrder = userPreference.configuration.pageDefinitionObject['sortOrder'];
      }
      if (this.searchParams.defaultPageSize) {
        pageDefinition.pageSize = this.searchParams.defaultPageSize;
      } else {
        pageDefinition.pageSize = userPreference.configuration.pageDefinitionObject['pageSize'];
      }
      this.metaDataFetchSubjectBill.next({});
        this.metaDataFetchSubjectBill.complete();
      });
   
         //this.metadata =  metaDataGrpDetail;
         //this.setMetadata(metaDataGrpDetail);
        
     // });
    }
    return this.metaDataFetchSubjectBill.asObservable();
  }
  @LoaderEnabled()
  public fetchMetadata(): Observable<any> {
    if (!this.metaDataFetchSubjectBill) {
      this.metaDataFetchSubjectBill = new ReplaySubject<any>(1);
       const tableId = this.searchParams.tableId;
       
    //   const lookupList = [];
    //   this.columnListOrder = [];
    //   this.entityConfigMetaData.columnList.forEach(element => {
    //     this.columnListOrder.push(element.propertyName);
    //     if ((element.type === 'DROPDOWN' ||  element.type === 'MULTIDROPDOWN') && element.dropDownId) {
    //       if (lookupList.indexOf(element.dropDownId) < 0) {
    //         lookupList.push(element.dropDownId);
    //       }
    //     }
    //   });
    //   const dropDownReq = {lookupList};
    //   const dropDowns = this.fetchDropdowns(dropDownReq);
        this.getTablePref(tableId).subscribe((tablePref) =>{
          this.tablePreference = tablePref;
        const pageDefinition: PageDefinition = this.pagedData.pageDefinition;
        const userPreference = this.tablePreference.columnConfig;
        if (this.searchParams.defaultSortColumn) {
          pageDefinition.sortColumn = this.searchParams.defaultSortColumn;
        } else {
          pageDefinition.sortColumn = userPreference.configuration.pageDefinitionObject['sortColumn'];
        }
        if (userPreference.configuration.pageDefinitionObject['sortOrder'] === null) {
          pageDefinition.sortOrder = 0;
        } else {
          pageDefinition.sortOrder = userPreference.configuration.pageDefinitionObject['sortOrder'];
        }
        if (this.searchParams.defaultPageSize) {
          pageDefinition.pageSize = this.searchParams.defaultPageSize;
        } else {
          pageDefinition.pageSize = userPreference.configuration.pageDefinitionObject['pageSize'];
        }
        this.metadata =  metaDataGrp;
        this.setMetadata(metaDataGrp);
        this.metaDataFetchSubjectBill.next(this.metadata);
        this.metaDataFetchSubjectBill.complete();
        });
    //   const clientDropdownValues = this.fetchClientDropdown();
    //   const forwarderDropdownValues = this.fetchForwarderDropdown();
    //   const vendorDropdownValues = this.fetchVendorDropdown();
    //   forkJoin([dropDowns, tablePref, clientDropdownValues, forwarderDropdownValues, vendorDropdownValues]).subscribe(resp => {
        
    //     let dropDownRes = [];
    //     let clientDropDownRes = [];
    //     let forwarderDownRes = [];
    //     let vendorDropDownRes = [];
    //     if (resp[0].response) {
    //       dropDownRes = resp[0].response;
    //     } else {
    //       this.alertService.clearAll().error('Failed to load dropdown values');
    //     }
    //     if (resp[2].typeList) {
    //       clientDropDownRes = resp[2].typeList;
    //     } else {
    //       this.alertService.clearAll().error('Failed to load client dropdown values');
    //     }
    //     if (resp[3].typeList) {
    //       forwarderDownRes = resp[3].typeList;
    //     } else {
    //       this.alertService.clearAll().error('Failed to load forwarder dropdown values');
    //     }
    //     if (resp[4].typeList) {
    //       vendorDropDownRes = resp[4].typeList;
    //     } else {
    //       this.alertService.clearAll().error('Failed to load vendor dropdown values');
    //     }
    //     // initialize the page definition based on the user preferene
        
    //     // pageDefinition.pageSize = userPreference.configuration.pageDefinitionObject['pageSize'];
    //     this.entityConfigMetaData.columnList.forEach(element => {
    //       if ((element.type === 'DROPDOWN' ||  element.type === 'MULTIDROPDOWN') && element.dropDownId) {
    //         // Object.keys(dropDownRes).forEach((key: any) => {
    //           const dropdownValues = [];
    //           const currentPropValues = dropDownRes[element.dropDownId];
    //           const propertyArray = ['client', 'forwarder', 'vendor'];
    //         if (!propertyArray.includes(element.dropDownId)) {
    //           currentPropValues.forEach(dropDownDetails => {
    //             dropdownValues.push({'key': dropDownDetails.code, 'value': dropDownDetails.description});
    //           });
    //         } else if (element.dropDownId === 'client') {
    //           clientDropDownRes.forEach(dropDownDetails => {
    //             dropdownValues.push({'key': dropDownDetails.id, 'value': dropDownDetails.name});
    //           });
    //         } else if (element.dropDownId === 'forwarder') {
    //           forwarderDownRes.forEach(dropDownDetails => {
    //             dropdownValues.push({'key': dropDownDetails.id, 'value': dropDownDetails.name});
    //           });
    //         } else if (element.dropDownId === 'vendor') {
    //           // if (tableId === 'shpprconsgnsearchtd' ||
    //           // tableId === 'shpprconsgnpopupsearchtd') {
    //           //   vendorDropDownRes.forEach(dropDownDetails => {
    //           //     dropdownValues.push({ 'key': dropDownDetails.vendorCode, 'value': dropDownDetails.name, 'code': dropDownDetails.vendorCode });
    //           //   });
    //           // } else {
    //             vendorDropDownRes.forEach(dropDownDetails => {
    //               dropdownValues.push({ 'key': dropDownDetails.id, 'value': dropDownDetails.name, 'code': dropDownDetails.vendorCode });
    //             });
    //           // }
    //         }
    //           // if (element.dropDownId === key) {
    //             element.values = this.formatDropdowns(dropdownValues, element.dropDownId);
    //             if (element.defaultDropdownValues && element.defaultDropdownValues.length > 0) {
    //               let ind = 1;
    //               element.defaultDropdownValues.forEach(defValues => {
    //                 element.values.splice(ind, 0, defValues);
    //                 ind += 1;
    //               });
    //             }
    //           // }
    //        // });
    //       }
    //     });
     
     // });
    }
    return this.metaDataFetchSubjectBill.asObservable();
  }

  @LoaderEnabled()
  public getTablePref(tableId): Observable<any> {
    return this.http$.get(`api/users/user/tablepreferences/` + tableId + '/', { headers: this.hdr }).pipe(
      map((response: any) => {
        return this.transformTablePreference(tableId, response);
      }),
      catchError((error: any) => {
        // Lets not stop navigation. Continue as if there is no preference set
        return of(new TablePreference());
      }));
  }

  @LoaderEnabled()
  public getItemDetailForId(idPropName: string, idPropVal: string, req?: any): Observable<Response> {
    const storerKey = this.searchParams.storerKey;
    if (idPropName === 'sku' && storerKey) {
      // LFWM-2045 Creating search criteria for sku loadbypage by adding storerkey
      const searchCriteria = {
        conditions: [{
          mandatory: true,
          clauses: [{
            column: idPropName,
            operation: '=',
            value: idPropVal,
            logicalOperation: ''
          }],
          logicalOperation: 'AND'
        },
        {
          mandatory: true,
          clauses: [{
            column: 'storerKey',
            operation: '=',
            value: storerKey,
            logicalOperation: ''
          }],
          logicalOperation: 'AND'
        }]
      };
      return this.getFirstItemForSearchCriteria(searchCriteria);
    } else {
      const searchCriteria = {
        conditions: [{

          mandatory: true,
          clauses: [{
            columnName: idPropName,
            operation: '=',
            value: idPropVal,
            logicalOperation: '',
            typeOfField:"TEXTFIELD"
          }],
          logicalOperation: 'AND'
        }]
      };
      return this.getFirstItemForSearchCriteria(searchCriteria);
    }
  }

  public getFirstItemForSearchCriteria(criteria: any): Observable<Response> {
      const pageInfoReq = {
      // tableId: this.searchParams.tableId,
      pageNum: 1,
      sortColumn: this.pagedData.pageDefinition.sortColumn,
      sortOrder: this.pagedData.pageDefinition.sortOrder,
      pageSize: this.pagedData.pageDefinition.pageSize,
    };
    const payload = {
      pageInfo: pageInfoReq,
      searchCriteria: criteria,
      appId:7,
      hiddenProperties:null,
      pinnedProperties:null,
      propertyIndexMap:null,
      columnOrder:null
    };
    const hdr = new HttpHeaders({ 'api': 'notification' });
    const dataUrl$ = this.http$.post(`api/recipient/recipgroupdetails`, JSON.stringify(payload) ,{ headers: hdr }).pipe(
      map((response: any) => {
        if (response['pageData'] === null || response['pageData'].rows === null
          || response['pageData'].rows.length <= 0) {
          return [];
        }
        return response['pageData'].rows[0];
      }),
      catchError((error: any) => {
        return Observable.throw('Failed to fetch  Item details :: ' + error.message);
      }));
    return dataUrl$;
  }


  @LoaderEnabled()
  public fetchDropdowns(dropDownReq): Observable<any> {
    // tslint:disable-next-line:max-line-length
    // return Observable.of({'result': {'data': {'data': {'CO_ISO_COUNTRY': 'CHN,HKG,IDN,MYS,PHL,SGP,THA,TWN', 'CO_COUNTRY': 'CHINA,HONG KONG,INDONESIA,MALAYSIA,PHILIPPINES,SINGAPORE,TAIWAN,THAILAND'}, 'message': 'Call successful', 'status': 200}, 'errorCode': null, 'errorMessage': null, 'status': 'success'}});
    return this.http$.post('api/poms/lookup', dropDownReq).pipe(
      map((response: any) => {
        return response;
      }));
    // return Observable.of(res);
  }

  @LoaderEnabled()
  public fetchClientDropdown(): Observable<any> {
    // tslint:disable-next-line:max-line-length
    // return Observable.of({'result': {'data': {'data': {'CO_ISO_COUNTRY': 'CHN,HKG,IDN,MYS,PHL,SGP,THA,TWN', 'CO_COUNTRY': 'CHINA,HONG KONG,INDONESIA,MALAYSIA,PHILIPPINES,SINGAPORE,TAIWAN,THAILAND'}, 'message': 'Call successful', 'status': 200}, 'errorCode': null, 'errorMessage': null, 'status': 'success'}});
    return this.http$.get('api/poms/client/list').pipe(
      map((response: any) => {
        return response;
      }));
    // return Observable.of(res);
  }
 @LoaderEnabled()
  public fetchForwarderDropdown(): Observable<any> {
    // tslint:disable-next-line:max-line-length
    // return Observable.of({'result': {'data': {'data': {'CO_ISO_COUNTRY': 'CHN,HKG,IDN,MYS,PHL,SGP,THA,TWN', 'CO_COUNTRY': 'CHINA,HONG KONG,INDONESIA,MALAYSIA,PHILIPPINES,SINGAPORE,TAIWAN,THAILAND'}, 'message': 'Call successful', 'status': 200}, 'errorCode': null, 'errorMessage': null, 'status': 'success'}});
    return this.http$.get('api/poms/forwarder/list').pipe(
      map((response: any) => {
        return response;
      }));
    // return Observable.of(res);
  }
  @LoaderEnabled()
  public fetchVendorDropdown(): Observable<any> {
    // tslint:disable-next-line:max-line-length
    // return Observable.of({'result': {'data': {'data': {'CO_ISO_COUNTRY': 'CHN,HKG,IDN,MYS,PHL,SGP,THA,TWN', 'CO_COUNTRY': 'CHINA,HONG KONG,INDONESIA,MALAYSIA,PHILIPPINES,SINGAPORE,TAIWAN,THAILAND'}, 'message': 'Call successful', 'status': 200}, 'errorCode': null, 'errorMessage': null, 'status': 'success'}});
    return this.http$.get('api/poms/vendor/list').pipe(
      map((response: any) => {
        return response;
      }));
    // return Observable.of(res);
  }
  public formatDropdowns(dropdowns, property) {
    const transformedDropdowns = [];
    let userRestriction = [];
    transformedDropdowns.push({ key: '', value: '-Select-' });
    if (property === 'client') {
      userRestriction = this.getUserRestrictions('Client');
    } else if (property === 'forwarder') {
      userRestriction = this.getUserRestrictions('Forwarder');
    } else if (property === 'vendor') {
      userRestriction = this.getUserRestrictions('Vendor');
    }
    dropdowns.forEach(element => {
      if (userRestriction.length > 0 && (this.searchParams.tableId !== 'shpprconsgnsearchtd' &&
      this.searchParams.tableId !== 'shpprconsgnpopupsearchtd')) {
        if (property !== 'client' && property !== 'vendor') {
          if (userRestriction.indexOf(element.value) > -1) {
            transformedDropdowns.push({ key: element.key, value: element.value });
          }
        } else if (property === 'client') {
          if (userRestriction.indexOf(element.key) > -1) {
            transformedDropdowns.push({ key: element.key, value: element.value });
          }
        } else if (property === 'vendor') {
          if (userRestriction.indexOf(element.code) > -1) {
            transformedDropdowns.push({ key: element.key, value: element.value, code: element.code });
          }
        }
      } else {
        // if (property === 'TRANSACTION_TYPE' || property === 'SCHEDULING') {
        //   transformedDropdowns.push({ key: element.charAt(0), value: element });
        // } else {
          transformedDropdowns.push({ key: element.key, value: element.value });
        // }
      }
    });
    return transformedDropdowns;
  }
  public formatSearchCriteria(searchCriteria, additionalParamMap, filterCondition?: any) {
    if (!searchCriteria) {
      return {};
    }
    let isClientFilter = false;
    let isForwarderFilter = false;
    let isVendorFilter = false;
    let allConditions = null;
    let userRestrictionMismatch = false;
    // Code added to merge filter conditions with master search criteria, if filter condition is there.
    if (filterCondition && filterCondition.clauses.length > 0) {
      allConditions = JSON.parse(JSON.stringify(searchCriteria.conditions));
      if (allConditions && allConditions.length > 0) {

        allConditions[allConditions.length - 1].operator = 'AND';
      }
      allConditions.push(filterCondition);
    } else {
      allConditions = searchCriteria.conditions;
    }
    const conditionReqList = [];
    const clauseReqlist = [];
    // if (this.searchParams.tableId === 'sailingsearchtd') {
    //   clauseReqlist.push({
    //     columnName: 'TRANSPORT_MODE',
    //     operation: '=',
    //     value: this.searchParams.transportMode.toUpperCase(),
    //     logicalOperation: 'AND',
    //     typeOfField: 'TEXTFIELD'
    //   });
    //   if (clauseReqlist.length !== 0) {
    //     let logOp = null;
    //     conditionReqList.push({
    //       mandatory: true,
    //       clauses: clauseReqlist,
    //       logicalOperation: logOp
    //     });
    //   }
    // }

    if (this.searchParams.tableId === 'populatepolinestd') {
      clauseReqlist.push({
        columnName: 'CLIENT_ID',
        operation: '=',
        value: this.searchParams.clientID.toUpperCase(),
        logicalOperation: 'AND',
        typeOfField: 'TEXTFIELD'
      });
      clauseReqlist.push({
        columnName: 'GC_VENDOR_ID',
        operation: '=',
        value: this.searchParams.VendorID.toUpperCase(),
        logicalOperation: 'AND',
        typeOfField: 'TEXTFIELD'
      });
      if (clauseReqlist.length !== 0) {
        let logOp = null;
        conditionReqList.push({
          mandatory: true,
          clauses: clauseReqlist,
          logicalOperation: logOp
        });
      }
    }

    if (this.searchParams.tableId === 'shpprconsgnpopupsearchtd') {
      clauseReqlist.push({
        columnName: 'ORG_CODE',
        operation: 'contains',
        value: '%',
        logicalOperation: 'AND',
        typeOfField: 'TEXTFIELD',
      });
      if (clauseReqlist.length !== 0) {
        let logOp = null;
        conditionReqList.push({
          mandatory: true,
          clauses: clauseReqlist,
          logicalOperation: logOp
        });
      }
    }

    // if (this.searchParams.restrictUserCountry) {
    //   const userCountryClauseReqlist = [{
    //     columnName: this.searchParams.restrictCountryColumnName,
    //     operation: 'IN',
    //     value: this.userCountryCodes.map(x => "'" + x + "'").join(','),
    //     logicalOperation: null
    //   }];
    //   if (this.searchParams.allCountry) {
    //     userCountryClauseReqlist.push({
    //       columnName: this.searchParams.restrictCountryColumnName,
    //       operation: '=',
    //       value: '*',
    //       logicalOperation: 'OR'
    //     });
    //   }
    //   conditionReqList.push({
    //     mandatory: false,
    //     clauses: userCountryClauseReqlist,
    //     logicalOperation: 'AND'
    //   });
    // }
    if (this.searchParams.tableId === 'clientpopupsearchtd' ||
    this.searchParams.tableId === 'clientsearchtd' ||
    this.searchParams.tableId === 'vendorsearchtd' ||
    this.searchParams.tableId === 'forwardersearchtd' ||
    this.searchParams.tableId === 'carriersearchtd' ||
    this.searchParams.tableId === 'shpprconsgnpopupsearchtd' ||
    this.searchParams.tableId === 'vendorpopupsearchtd' ||
    this.searchParams.tableId === 'shpprconsgnsearchtd' ||
    this.searchParams.tableId === 'evtstatussearchtd' ||
    this.searchParams.tableId === 'evtmappingsearchtd' ||
    this.searchParams.tableId === 'evttriggersearchtd' ||
    this.searchParams.tableId === 'evtcodesearchtd' ||
    this.searchParams.tableId === 'mandfldsearchtd' ||
    this.searchParams.tableId === 'evtgroupsearchtd') {
      isClientFilter = true;
      isForwarderFilter = true;
      isVendorFilter = true;
    }
    allConditions.forEach((condition: any, index: any) => {
      // const clauseReqlist = [];
      let userRestrictionProperty = '';
      if (userRestrictionMismatch) {
        return {};
      }
      condition.clauses.forEach((clause: any) => {
        if (userRestrictionMismatch) {
          return {};
        }
        if ((this.searchParams.tableId === 'clientpopupsearchtd' ||
        this.searchParams.tableId === 'clientsearchtd' ||
        this.searchParams.tableId === 'evtstatussearchtd'  ||
        this.searchParams.tableId === 'evtmappingsearchtd' ||
        this.searchParams.tableId === 'shpprconsgnsearchtd' ||
        this.searchParams.tableId === 'shpprconsgnpopupsearchtd') && clause.column === 'CLIENT_ID') {
          userRestrictionProperty = 'Client';
          isClientFilter = true;
          clauseReqlist.push({
            columnName: 'CLIENT_ID',
            operation: clause.operation,
            value: clause.value,
            logicalOperation: 'AND',
            typeOfField: 'TEXTFIELD'
          });
          if (clauseReqlist.length !== 0) {
            let logOp = null;
            conditionReqList.push({
              mandatory: true,
              clauses: clauseReqlist,
              logicalOperation: logOp
            });
          }
          return;
        }
        if ((this.searchParams.tableId === 'shpprconsgnsearchtd' ||
        this.searchParams.tableId === 'shpprconsgnpopupsearchtd') &&
        clause.column === 'VENDOR_ID') {
          userRestrictionProperty = 'Vendor';
          isVendorFilter = true;
          clauseReqlist.push({
            columnName: 'VENDORID',
            operation: clause.operation,
            value: clause.value,
            logicalOperation: 'AND',
            typeOfField: 'TEXTFIELD'
          });
          if (clauseReqlist.length !== 0) {
            let logOp = null;
            conditionReqList.push({
              mandatory: true,
              clauses: clauseReqlist,
              logicalOperation: logOp
            });
          }
          return;
        }

        // if (this.searchParams.tableId === 'shpprconsgnpopupsearchtd' &&
        // this.entityConfigMetaData.screen && this.entityConfigMetaData.screen === 'vendor') {
        //   userRestrictionProperty = 'Vendor';
        //   isVendorFilter = true;
        // }
        // if (this.searchParams.tableId === 'shpprconsgnpopupsearchtd' &&
        // this.entityConfigMetaData.screen && this.entityConfigMetaData.screen === 'client') {
        //   userRestrictionProperty = 'Client';
        //   isVendorFilter = true;
        // }
        if (clause.value !== undefined && clause.value !== null) {
          // Adding user client restrictions in the search criteria
          if (clause.column === 'GC_FORWARDER_ID') {
            userRestrictionProperty = 'Forwarder';
            isForwarderFilter = true;
          } else if (clause.column === 'CLIENT_ID') {
            userRestrictionProperty = 'Client';
            isClientFilter = true;
          } else if (clause.column === 'GC_VENDOR_ID') {
            userRestrictionProperty = 'Vendor';
            isVendorFilter = true;
          }
          if ((clause.column === 'GC_FORWARDER_ID' || clause.column === 'CLIENT_ID' ||
            clause.column === 'GC_VENDOR_ID') && clause.value === '*') {
            // if (clause.column === 'GC_FORWARDER_ID') {
            //   isForwarderFilter = true;
            // } else if (clause.column === 'CLIENT_ID') {
            //   isClientFilter = true;
            // } else if (clause.column === 'GC_VENDOR_ID') {
            //   isVendorFilter = true;
            // }
            const userRestrictedValues = this.getUserRestrictions(userRestrictionProperty);
            const userRestrictedKeys = [];
            // const clause = [];
            const values = this.getMetadataForProperty(clause.column).values;
            userRestrictedValues.forEach((rest) => {
              values.forEach((element) => {
                if (clause.column === 'CLIENT_ID') {
                  if (element.key === rest) {
                    userRestrictedKeys.push(element.key);
                  }
                }  else if (clause.column === 'GC_VENDOR_ID') {
                  if (element.code === rest) {
                    userRestrictedKeys.push(element.key);
                  }
                } else if (element.value === rest) {
                  userRestrictedKeys.push(element.key);
                }
              });
            });
            if (userRestrictedValues && userRestrictedValues.length > 0 &&
              userRestrictedKeys && userRestrictedKeys.length > 0) {
              clauseReqlist.push({
                columnName: clause.column.toUpperCase(),
                operation: 'IN',
                value: userRestrictedKeys.map(x =>  x ).join(','),
                value1: clause.value1,
                logicalOperation: clause.logicalOperation,
                typeOfField: clause.typeOfField
              });

              // if (this.searchParams.allClient) {
              //   clauseReqlist.push({
              //     columnName: clause.column.toUpperCase(),
              //     operation: '=',
              //     value: '*',
              //     value1: clause.value1,
              //     logicalOperation: 'OR',
              //     typeOfField: clause.typeOfField
              //   });
              // }
            } else if (userRestrictedValues && userRestrictedValues.length > 0 &&
              userRestrictedKeys && userRestrictedKeys.length <= 0) {
                userRestrictionMismatch = true;
                return {};
            } else {
              clauseReqlist.push({
                columnName: clause.column.toUpperCase(),
                operation: clause.operation,
                value: clause.value,
                value1: clause.value1,
                logicalOperation: clause.logicalOperation,
                typeOfField: clause.typeOfField
              });
            }
          } else {
            let value: any;
            let value1: any;
            if (clause.typeOfField === 'MULTIDROPDOWN') {
              if (clause.value === 'Y') {
                value = 1;
              } else if (clause.value === 'N') {
                value = 0;
              } else if (clause.value === 'Y,N' || clause.value === 'N,Y') {
                value = '1,0';
              } else {
                value = clause.value;
              }
              if (clause.value1 === 'Y') {
                value1 = 1;
              } else if (clause.value1 === 'N') {
                value1 = 0;
              } else if (clause.value1 === 'Y,N' || clause.value1 === 'N,Y') {
                value = '1,0';
              } else {
                value1 = clause.value1;
              }
            } else {
              value = clause.value;
              value1 = clause.value1;
            }
            clauseReqlist.push({
              columnName: clause.column,
              operation: clause.typeOfField === 'MULTIDROPDOWN' ? 'IN' : clause.operation,
              value: value,
              value1: value1,
              logicalOperation: clause.logicalOperation,
              typeOfField: clause.typeOfField
            });
          }
        }
      });
      if (clauseReqlist.length !== 0) {
        let logOp = null;
        if (index < allConditions.length - 1) {
          logOp = condition.operator;
        }
        conditionReqList.push({
          mandatory: condition.isMandatory,
          clauses: clauseReqlist,
          logicalOperation: logOp
        });
      }
    });
    if (userRestrictionMismatch) {
      return {};
    }
    if ((this.searchParams.restrictUserClient && !isClientFilter) ||
      (this.searchParams.restrictUserForwarder && !isForwarderFilter) ||
      (this.searchParams.restrictUserVendor && !isVendorFilter)) {
      const userRestrictionProperty = [];
      const metadataProperty = [];
      if (this.searchParams.restrictUserForwarder && !isForwarderFilter) {
        userRestrictionProperty.push('Forwarder');
        metadataProperty.push('GC_FORWARDER_ID');
      }
      if (this.searchParams.restrictUserClient && !isClientFilter) {
        userRestrictionProperty.push('Client');
        metadataProperty.push('CLIENT_ID');
      }
      if (this.searchParams.restrictUserVendor && !isVendorFilter) {
        userRestrictionProperty.push('Vendor');
        metadataProperty.push('GC_VENDOR_ID');
      }
      for (let i = 0; i < userRestrictionProperty.length; i++) {
        const userRestrictedValues = this.getUserRestrictions(userRestrictionProperty[i]);
        const userRestrictedKeys = [];
        const clause = [];
        if (this.getMetadataForProperty(metadataProperty[i])
        && this.getMetadataForProperty(metadataProperty[i]).values) {
        const values = this.getMetadataForProperty(metadataProperty[i]).values;
        userRestrictedValues.forEach((rest) => {
          values.forEach((element) => {
            if (metadataProperty[i] === 'CLIENT_ID') {
            if (element.key === rest) {
              userRestrictedKeys.push(element.key);
            }
          }  else if (metadataProperty[i] === 'GC_VENDOR_ID') {
            if (element.code === rest) {
              userRestrictedKeys.push(element.key);
            }
          } else if (element.value === rest) {
            userRestrictedKeys.push(element.key);
          }
          });
        });
        if (userRestrictedValues && userRestrictedValues.length > 0 &&
          userRestrictedKeys && userRestrictedKeys.length > 0) {
          clause.push({
            columnName: metadataProperty[i],
            operation: 'IN',
            value: userRestrictedKeys.map(x => x ).join(','),
            value1: '',
            logicalOperation: null
          });
          // if (this.searchParams.allClient) {
          //   clause.push({
          //     columnName: metadataProperty,
          //     operation: '=',
          //     value: '*',
          //     value1: '',
          //     logicalOperation: 'OR'
          //   });
          // }
        } else if (userRestrictedValues && userRestrictedValues.length > 0 &&
          userRestrictedKeys && userRestrictedKeys.length <= 0) {
            userRestrictionMismatch = true;
            return {};
        } else {
          clause.push({
            columnName: metadataProperty[i],
            operation: '=',
            value: '*',
            value1: '',
            logicalOperation: null
          });
        }
        conditionReqList.splice(0, 0, {
          mandatory: true,
          clauses: clause,
          logicalOperation: 'AND'
        });
      }
      }
    }
    if (additionalParamMap) {
      const additionalClauses = [];
      const additionalParams = additionalParamMap;
      const additionalProps = Object.keys(additionalParams);
      if (additionalProps.length > 0) {
        const lastCondition = conditionReqList.slice(conditionReqList.length - 1)[0];
        lastCondition.logicalOperation = 'AND';
        additionalProps.forEach((property: string, index: number) => {
          additionalClauses.push({
            column: property,
            operation: '=',
            value: additionalParams[property],
            logicalOperation: index === 0 ? '' : 'AND'
          });
        });
        conditionReqList.push({
          mandatory: false,
          clauses: additionalClauses,
          logicalOperation: ''
        });
      }
    }
    if (conditionReqList.length === 1) {
      conditionReqList[0].logicalOperation = '';
    }
    return { conditions: conditionReqList };
  }

  // public applyUserRestrictions(property: any): any {
  //   const userRestrictedClients = this.getUserRestrictions(property);
  //   if (userRestrictedClients && userRestrictedClients.length > 0) {
  //     clauseReqlist.push({
  //       columnName: clause.column.toUpperCase(),
  //       operation: 'IN',
  //       value: userRestrictedClients.map(x => "'" + x + "'").join(','),
  //       value1: clause.value1,
  //       logicalOperation: clause.logicalOperation,
  //       typeOfField: clause.typeOfField
  //     });

  //     if (this.searchParams.allClient) {
  //       clauseReqlist.push({
  //         columnName: clause.column.toUpperCase(),
  //         operation: '=',
  //         value: '*',
  //         value1: clause.value1,
  //         logicalOperation: 'OR',
  //         typeOfField: clause.typeOfField
  //       });
  //     }
  //   } else {
  //     clauseReqlist.push({
  //       columnName: clause.column.toUpperCase(),
  //       operation: clause.operation,
  //       value: clause.value,
  //       value1: clause.value1,
  //       logicalOperation: clause.logicalOperation,
  //       typeOfField: clause.typeOfField
  //     });
  //   }
  // }
  public formatSaveSearchCriteria(searchCriteria, additionalParamMap, filterCondition?: any) {
    if (!searchCriteria) {
      return {};
    }
    let allConditions = null;
    // Code added to merge filter conditions with master search criteria, if filter condition is there.
    // if (filterCondition && filterCondition.clauses.length > 0) {
    //   allConditions = JSON.parse(JSON.stringify(searchCriteria.conditions));
    //   if (allConditions && allConditions.length > 0) {
    //     allConditions[allConditions.length - 1].operator = 'AND';
    //   }
    //   allConditions.push(filterCondition);
    // } else {
    //   allConditions = searchCriteria.conditions;
    // }
    allConditions = searchCriteria.conditions;
    const conditionReqList = [];
    const clauseReqlist = [];
    allConditions.forEach((condition: any, index: any) => {
      const clauseReqlist = [];
      condition.clauses.forEach((clause: any) => {
        if (clause.value !== undefined && clause.value !== null) {
            let value: any;
            let value1: any;
            // if (clause.typeOfField === 'MULTIDROPDOWN') {
            //   if (clause.value === 'Y') {
            //     value = 1;
            //   } else if (clause.value === 'N') {
            //     value = 0;
            //   } else if (clause.value === 'Y,N' || clause.value === 'N,Y') {
            //     value = '1,0';
            //   } else {
            //     value = clause.value;
            //   }
            //   if (clause.value1 === 'Y') {
            //     value1 = 1;
            //   } else if (clause.value1 === 'N') {
            //     value1 = 0;
            //   } else if (clause.value1 === 'Y,N' || clause.value1 === 'N,Y') {
            //     value = '1,0';
            //   } else {
            //     value1 = clause.value1;
            //   }
            // } else {
              value = clause.value;
              value1 = clause.value1;
            // }
            clauseReqlist.push({
              column: clause.column,
              operation: clause.typeOfField === 'MULTIDROPDOWN' ? 'IN' : clause.operation,
              value: value,
              value1: value1,
              logicalOperation: clause.logicalOperation,
              typeOfField: clause.typeOfField
            });
        }
      });
      if (clauseReqlist.length !== 0) {
        let logOp = null;
        if (index < allConditions.length - 1) {
          logOp = condition.operator;
        }
        conditionReqList.push({
          mandatory: condition.isMandatory,
          clauses: clauseReqlist,
          logicalOperation: logOp
        });
      }
    });
    if (additionalParamMap) {
      const additionalClauses = [];
      const additionalParams = additionalParamMap;
      const additionalProps = Object.keys(additionalParams);
      if (additionalProps.length > 0) {
        const lastCondition = conditionReqList.slice(conditionReqList.length - 1)[0];
        lastCondition.logicalOperation = 'AND';
        additionalProps.forEach((property: string, index: number) => {
          additionalClauses.push({
            column: property,
            operation: '=',
            value: additionalParams[property],
            logicalOperation: index === 0 ? '' : 'AND'
          });
        });
        conditionReqList.push({
          mandatory: false,
          clauses: additionalClauses,
          logicalOperation: ''
        });
      }
    }
    if (conditionReqList.length === 1) {
      conditionReqList[0].logicalOperation = '';
    }
    return { conditions: conditionReqList };
  }
  public getUserRestrictions(restrictionType) {
    let userRestrictions = [];
    let allRestrictionValues = '';
    this.userRestrictions.forEach((restriction) => {
      restriction.restrictions.forEach((rest) => {
        if (rest.restrictionTypeName === restrictionType && rest.restrictionValue) {
          allRestrictionValues += rest.restrictionValue;
        }
      });
    });
    if (allRestrictionValues) {
      userRestrictions = allRestrictionValues.split(',');
    }
    return userRestrictions;
  }
  /**
   * Deletes the selected row locally (client side only)
   *
   * @param idProperty - Id property
   * @param rowsToDelete - Value
   */
  public deleteRowsFromModel(idProperty: any, rowsToDelete: any, deleteByRowId?: boolean) {
    if (this.metadata === null || this.metadata['columnList'] === null) {
      return 'meta data not loaded';
    }
    if (this.pagedData.pageRows === undefined || this.pagedData.pageRows === null) {
      return 'page data not loaded';
    }
    if (idProperty !== null && idProperty !== undefined) {
      const startIndex = ((this.pagedData.pageDefinition.pageNumber - 1) *
        this.pagedData.pageDefinition.pageSize) + idProperty;
      this.fullData.splice(startIndex, 1);
      this.pagedData.pageRows.splice(idProperty, 1);
    }
  
  }

  public exportData(format: string, tableId?: string) {
    const tableIdFromSearchParams = this.searchParams.tableId;
    if (tableId == null) {
      tableId = tableIdFromSearchParams;
    }
    const pageInfoReq = {
      tableId: tableId,
      sortColumn: this.pagedData.pageDefinition.sortColumn,
      sortOrder: this.pagedData.pageDefinition.sortOrder,
      fullFetch: this.searchParams.isFullFetch
    };
    let hiddenProperties = [];

    if (format === 'Selected') {
      hiddenProperties = this.tablePreference.columnConfig.configuration.hiddenPropertiesList;
    }
    const payload = {
      pageInfo: pageInfoReq,
      searchCriteria: this.formatSearchCriteria(this.searchCriteria, this.searchParams.additionalParamMap, this.filterCondition),
      hiddenProperties: hiddenProperties,
      pinnedProperties: this.tablePreference.columnConfig.configuration.pinnedPropertiesList,
      propertyIndexMap: this.tablePreference.columnConfig.configuration.propertyIndexMap,
      columnOrder: this.columnListOrder
    };
    if (!payload.searchCriteria.conditions) {
      return of({});
    }
    const response$ = this.http$.post(this.searchParams.exportUrl, payload).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        return Observable.throw(error);
      }));
    return response$;
  }

  public downloadExcelTemplateForUpload (config) {
    let request = {};
    let url = '';
    request = { 'cellDetails': config };
    url = 'api/poms/excel/template/download';
    const response$ = this.http$.post(url, request).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        return Observable.throw(error);
      }));
    return response$;
  }

  @LoaderEnabled()
  public getVersionList(versionType,bookingId): Observable<any> {
    return this.http$.get(`api/poms/shipment/versiondropdown/` + versionType + '/'+ bookingId).pipe(
      map((response: any) => {
        return response;
      }, (error) => {
        if (error.error.errors[0].validationMessage) {
          return Observable.throw(error.error.errors[0].validationMessage);
      }
      }));
  }

  @LoaderEnabled()
  public getComparedVersionDetails(versionType,bookingId,versionList): Observable<any> {
    return this.http$.post(`api/poms/shipment/` + versionType + '/'+ bookingId+'/compare',versionList).pipe(
      map((response: any) => {
        return response;
      }));
  }

  @LoaderEnabled()
  public exportVersion(data): Observable<any> {
    return this.http$.post('api/poms/shipment/booking/versionCompare/excel',data).pipe(
      map((response: any) => {
        return response;
      }));
  }

  public exportPO(request) {
    let url = '';
    url = 'api/poms/excel/aggrid/export';
    const response$ = this.http$.post(url, request).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        return Observable.throw(error);
      }));
    return response$;
  }

  @LoaderEnabled()
  public exportCommonVersion(request, versionType, bookingId): Observable<any> {
    return this.http$.post('api/poms/shipment/' + versionType + '/' + bookingId + '/compare/export', request).pipe(
      map((response: any) => {
        return response;
      }, (error) => {
        if (error.error.errors[0].validationMessage) {
          return Observable.throw(error.error.errors[0].validationMessage);
        }
      }));
  }

  public downloadSinglePdf(params) {
    const file_path = params.data.INV_URL.cVal;
    const a = document.createElement('a');
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  public toPreviewInNewTabApi(invId: any) {
    const response$ = this.http$.get('api/poms/invoice/preview/pdf?invId=' + invId, {
        headers: new HttpHeaders({
            'Accept': '*/*',
        }), responseType: 'blob'
        }).pipe(
        map((response: any) => {
            return response;
        }),
        catchError((error: any) => {
            return Observable.throw(error);
        }));
    return response$;
}
}

