// import { Injectable } from '@angular/core';
import { UserService } from 'sce-core';
import { Injectable } from '@angular/core';
import * as coordtransform from 'coordtransform';
import { ResourceService } from 'sce-core';
import { tmsConfig } from 'src/app/core/tms-config';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
// import { lchmodSync } from 'fs';
// import { el } from '@angular/platform-browser/testing/src/browser_util';
import * as elementResizeDetectorMaker from '../../../../../../assets/js/element-resize-detector.min';

declare var BMap: any;
declare var BMapLib: any;
declare var H: any;
declare var $: any;

// 交货状态以及页面
interface DeliveredState {
  Page: string;
  Status: string;
  earlyPickupDate: string;
  requestedDeliveryDate: string;
  shipmentPlannedStartDate: string;
  shipmentPlannedEndDate: string;
  sourceCity: string;
  destCity: string;
  diffDistance: number;
  time: string;
  orderStatus: string;
  eventStatusCode: string;
  shipmentXid: string;
  pickupDateTime: string;
  timeOfArrival: string;
  refuseTime: string;
  gpsLat: string;
  gpsLon: string;
  correctFlag: string;
  sourceLon: string;
  sourceLat: string;
  destLon: string;
  destLat: string;
  url: string
}

@Injectable() export class DefaultMapService {
  private map: any;
  public isBaidu: boolean = false;
  public mapLat: Number;
  public mapLng: Number;
  public rowData: any;
  public mapData: any;
  public markersArray: any;
  private countryCode: string;
  private platform: any;
  private searchService: any;
  ui: any;
  defaultLayers: any;
  getChangeList: any;
  logContainer: HTMLElement;

  constructor(
    public userService: UserService,
    public resourceService: ResourceService,
    public http$: HttpClient,
  ) {
  }

  /**
   * 普通地图初始化方法
   * @param mapId map element id
   * @param mapType baidu/here
   * @param zoomendCallback 鼠标滚动回调函数
   */
  initMap(mapId: any, rowData?:any, mapData?:any, mapType?: any, zoomendCallback?: Function) {
    // mapType = 'here';
    this.rowData = rowData
    this.mapData = mapData
    console.log(mapData, 'mapDatamapDatamapData')
    if (!mapType) {
      // if (!this.countryCode) {
      //   var userinfo = JSON.parse(localStorage.getItem('user_info'))    
      //   console.debug('userinfo', userinfo);        
      //   var countryCode =  userinfo['userRestrictions'][0]['countryCode'];
      //   this.countryCode = countryCode
      // }
      mapType = 'here';
      // if (this.countryCode === 'CHN') {
      //   mapType = 'baidu';
      // } else {
      // }
    }
    if (!['baidu', 'here'].includes(mapType)) {
      throw Error('Unknown map type!');
    }
    if ('baidu' === mapType) {
      this.initMapForBaidu(mapId);
      this.isBaidu = true;
    } else if ('here' === mapType) {
      this.initMapForHere(mapId);
    }
  }

  /**
   * 浮标地图初始化方法
   * @param mapId map element id
   * @param mapType baidu/here
   * @param zoomendCallback 鼠标滚动回调函数
   */
  async initMapMarker(mapId: any, mapData: any,  mapType?: string, zoomendCallback?: Function) {
    // mapType = 'here';
    if (!mapType) {
      if (!this.countryCode) {
        const result = await this.userService.getEnabledCountries().toPromise();
        this.countryCode = result[0].code;

      }
      if (this.countryCode === 'CHN') {
        mapType = 'baidu';
      } else {
        mapType = 'here';
      }
    }
    if (!['baidu', 'here'].includes(mapType)) {
      throw Error('Unknown map type!');
    }
    if ('baidu' === mapType) {
      this.initMapForBaidu(mapId, true, zoomendCallback);
      this.isBaidu = true;
    } else if ('here' === mapType) {
      this.initMapForHere(mapId,  true, zoomendCallback);
    }
  }

  private initMapForBaidu(mapId, isMarker?: boolean, zoomendCallback?: Function) {
    this.map = new BMap.Map(mapId);
    this.map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
    if (zoomendCallback) {
      this.map.addEventListener('zoomend', (e) => {
        zoomendCallback(this.map, e);
      });
    }
  }

  private initMapForHere(mapId, isMarker?: boolean, zoomendCallback?: Function) {
    // Step 1: initialize communication with the platform
    // In your own code, replace variable window.apikey with your own apikey
    // let app_id = '6oIzBTM9e52Q3JjCx9F7';
    let app_id = 'hJUucGrj24JGSks3eOOa';
    let app_code = 'IRLOlY26vxDjdEecraV0Ug';
    if (this.countryCode !== 'CHN') {
      app_id = '0llBksHdu9QwrVCsv6fZ';
      app_code = '1XVD3gSvC5l9IWaPrfraSg';
    }
    console.log('这里是初始化地图')
    this.platform = new H.service.Platform({
      'app_id': app_id,
      'app_code': app_code,
      'apiKey': 'BgEr8-hf2JLj3lJQ78fVj_Aft6cLHAB0wC2Q2UX_Ld8'
    });
    this.defaultLayers = this.platform.createDefaultLayers();
    let defaultLng = 121.01216330000612
    let defaultLat = 14.626782928240136
    if(this.rowData?.length > 0 && this.rowData?.[0].lng) {  
      defaultLng = this.rowData[0]?.lng;  
      defaultLat = this.rowData[0]?.lat;  
    }
    console.log(this.defaultLayers, 'this.defaultLayers=======>')
    // Step 2: initialize a map - this map is centered over Europe
    try {
      this.map = new H.Map(
        document.getElementById('fleetMap'),
        (this.defaultLayers.vector.normal.map),
        {
          zoom: this.mapData.currentZoom ? this.mapData.currentZoom : 6,
          pixelRatio: window.devicePixelRatio || 1,
          center: { lng: this.mapData?.lng ? this.mapData?.lng:  defaultLng, lat: this.mapData?.lat ? this.mapData?.lat:  defaultLat },
        });

      // add a resize listener to make sure that the map occupies the whole container
      var ui = H.ui.UI.createDefault(this.map, this.defaultLayers);
      this.ui = ui
      window.addEventListener('resize', () => this.map.getViewPort().resize());

    } catch (e) {
      console.error(e);
    }

    //  Step 3: make the map interactive
    // MapEvents enables the event system
    // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
    // var ui = H.ui.UI.createDefault(this.map, this.defaultLayers);
    //Add marker points
    this.addMarkersToMap(this.map);
    // draw a circle
    // this.addCircleToMap(this.map, mapData);

    // Create the default UI components
    // const ui = H.ui.UI.createDefault(this.map, defaultLayers);

    // add zoom changed envent
    if (zoomendCallback) {
      this.map.addEventListener('mapviewchangeend', (e) => {
        zoomendCallback(this.map);
      });
    }
  }

  // addMarkersToMap(map) {
  //   this.markersArray = []
  //   // 创建一个自定义图标  
  //   var customOnIcon = new H.map.Icon(  
  //     '../../../../../../../../assets/images/svg/fleets/icon-gps-on.svg', // 图标路径  
  //     {  
  //         // 图标大小（可选）  
  //         size: new H.math.Size(18, 18),  
  //         // // 图标锚点（可选），默认为图标的左下角  
  //         // anchor: new H.math.Point(16, 16)  
  //     }  
  //   );  
  //   var customOffIcon = new H.map.Icon(  
  //     '../../../../../../../../assets/images/svg/fleets/icon-gps-off.svg', // 图标路径  
  //     {  
  //         // 图标大小（可选）  
  //         size: new H.math.Size(18, 18),  
  //         // // 图标锚点（可选），默认为图标的左下角  
  //         // anchor: new H.math.Point(16, 16)  
  //     }  
  //   );  
  //   let UI = H.ui.UI.createDefault(this.map, this.defaultLayers);
  //   var that = this
  //   this.rowData.forEach((location: any) => { 
  //     // 创建一个标记  
  //     if(location.lat && location.lng) {
  //       let marker = new H.map.Marker({ 
  //         lat: location.lat, lng: location.lng 
  //       }, {  
  //         icon: location.gpsStatus === '1' ? customOnIcon : customOffIcon // 应用自定义图标  
  //       }
  //       );  
  //       // 将标记添加到地图上  
  //       this.markersArray.push(marker)
  //       map.addObject(marker);  
  //     }
  // })
  // console.log(this.markersArray, 'this.markersArray')
  // }
  addMarkersToMap(map) {
    var that = this
    let UI = H.ui.UI.createDefault(this.map, this.defaultLayers);
    var dataPoints = (this.rowData ?? []).map(function (item) {
      if(item && item.hasOwnProperty('lat') && item.hasOwnProperty('lng')) {
         return new H.clustering.DataPoint(item.lat, item.lng, null, item);
      }
      return null;  
    });
    dataPoints = dataPoints.filter(function(dataPoint) {  
      return dataPoint !== null;
    });

    function getCustomStyle(item: any){  
      var blueCar = `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 74" width="70" height="50">
      <defs>
        <style>
          .cls-1 {
            fill: none;
          }
          .cls-2 {
            fill: #007fff;
          }
          .cls-3 {
            fill: #fff;
          }
        </style>
      </defs>
      <g id="Group_14881" data-name="Group 14881">
        <rect id="Rectangle_3062" data-name="Rectangle 3062" class="cls-1" x="13" y="13" width="55" height="55"/>
        <path id="Path_12767" data-name="Path 12767" fill='blue' d="M22.33,38.07c-1.64,0-2.96,1.33-2.96,2.96s1.33,2.96,2.96,2.96,2.96-1.33,2.96-2.96h0c0-1.64-1.33-2.96-2.96-2.96"/>
        <path id="Path_12768" data-name="Path 12768" fill='blue' d="M38.7,38.07c-1.64,0-2.96,1.33-2.96,2.97s1.33,2.96,2.97,2.96c1.64,0,2.96-1.33,2.96-2.96,0-1.64-1.33-2.96-2.96-2.96"/>
        <path id="Line_2505" data-name="Line 2505" fill='blue' d="M42.51,35.83h-7.64c-.55,0-1-.45-1-1s.45-1,1-1h7.64c.55,0,1,.45,1,1s-.45,1-1,1Z"/>
        <path id="Path_12769" data-name="Path 12769" fill='blue' d="M44.04,41.94H16.53c-.55,0-1-.45-1-1V22.6c0-.55,.45-1,1-1h1.53c.55,0,1,.45,1,1s-.45,1-1,1h-.53v16.34h25.51v-6.41l-2.67-5.35h-5.5c-.55,0-1-.45-1-1s.45-1,1-1h6.11c.38,0,.72,.21,.89,.55l3.06,6.11c.07,.14,.11,.29,.11,.45v7.64c0,.55-.45,1-1,1Z"/>
        <path id="Path_12770" data-name="Path 12770" fill='blue' d="M20.34,19.53c0,2.34,3.37,7.81,4.99,10.4,.17,.3,.55,.4,.85,.24,.1-.06,.18-.14,.24-.24,1.62-2.59,4.93-8.06,4.93-10.4,0-3.04-2.46-5.5-5.5-5.5s-5.5,2.46-5.5,5.5m3.61-.06h0c0-1.06,.86-1.92,1.92-1.92s1.92,.86,1.92,1.92-.86,1.92-1.92,1.92c0,0,0,0,0,0-1.06,0-1.92-.86-1.92-1.92,0,0,0,0,0,0"/>
        <path id="Path_12771" data-name="Path 12771" fill='blue' d="M34.87,40.55c-.55,0-1-.45-1-1v-15.95h-.53c-.55,0-1-.45-1-1s.45-1,1-1h1.53c.55,0,1,.45,1,1v16.95c0,.55-.45,1-1,1Z"/>
        <rect id="Rectangle_3070" data-name="Rectangle 3070" class="cls-1" x="15" y="14" width="40.57" height="40"/>
        <text id="dynamicText" x="0" y="70" font-size="16" fill="red" font-weight="bold">${item.truckNumber}</text>
        </g>
    </svg>`  
    var greenCar = `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 74" width="70" height="50">
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
        .cls-2 {
          fill: #007fff;
        }
        .cls-3 {
          fill: #fff;
        }
      </style>
    </defs>
    <g id="Group_14881" data-name="Group 14881">
      <rect id="Rectangle_3062" data-name="Rectangle 3062" class="cls-1" x="13" y="13" width="55" height="55"/>
      <path id="Path_12767" data-name="Path 12767" fill='green' d="M22.33,38.07c-1.64,0-2.96,1.33-2.96,2.96s1.33,2.96,2.96,2.96,2.96-1.33,2.96-2.96h0c0-1.64-1.33-2.96-2.96-2.96"/>
      <path id="Path_12768" data-name="Path 12768" fill='green' d="M38.7,38.07c-1.64,0-2.96,1.33-2.96,2.97s1.33,2.96,2.97,2.96c1.64,0,2.96-1.33,2.96-2.96,0-1.64-1.33-2.96-2.96-2.96"/>
      <path id="Line_2505" data-name="Line 2505" fill='green' d="M42.51,35.83h-7.64c-.55,0-1-.45-1-1s.45-1,1-1h7.64c.55,0,1,.45,1,1s-.45,1-1,1Z"/>
      <path id="Path_12769" data-name="Path 12769" fill='green' d="M44.04,41.94H16.53c-.55,0-1-.45-1-1V22.6c0-.55,.45-1,1-1h1.53c.55,0,1,.45,1,1s-.45,1-1,1h-.53v16.34h25.51v-6.41l-2.67-5.35h-5.5c-.55,0-1-.45-1-1s.45-1,1-1h6.11c.38,0,.72,.21,.89,.55l3.06,6.11c.07,.14,.11,.29,.11,.45v7.64c0,.55-.45,1-1,1Z"/>
      <path id="Path_12770" data-name="Path 12770" fill='green' d="M20.34,19.53c0,2.34,3.37,7.81,4.99,10.4,.17,.3,.55,.4,.85,.24,.1-.06,.18-.14,.24-.24,1.62-2.59,4.93-8.06,4.93-10.4,0-3.04-2.46-5.5-5.5-5.5s-5.5,2.46-5.5,5.5m3.61-.06h0c0-1.06,.86-1.92,1.92-1.92s1.92,.86,1.92,1.92-.86,1.92-1.92,1.92c0,0,0,0,0,0-1.06,0-1.92-.86-1.92-1.92,0,0,0,0,0,0"/>
      <path id="Path_12771" data-name="Path 12771" fill='green' d="M34.87,40.55c-.55,0-1-.45-1-1v-15.95h-.53c-.55,0-1-.45-1-1s.45-1,1-1h1.53c.55,0,1,.45,1,1v16.95c0,.55-.45,1-1,1Z"/>
      <rect id="Rectangle_3070" data-name="Rectangle 3070" class="cls-1" x="15" y="14" width="40.57" height="40"/>
      <text id="dynamicText" x="0" y="70" font-size="16" fill="red" font-weight="bold">${item.truckNumber}</text>
      </g>
  </svg>`
  var redCar = `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 74" width="70" height="50">
  <defs>
    <style>
      .cls-1 {
        fill: none;
      }
      .cls-2 {
        fill: #007fff;
      }
      .cls-3 {
        fill: #fff;
      }
    </style>
  </defs>
  <g id="Group_14881" data-name="Group 14881">
    <rect id="Rectangle_3062" data-name="Rectangle 3062" class="cls-1" x="13" y="13" width="75" height="75"/>
    <path id="Path_12767" data-name="Path 12767" fill='red' d="M22.33,38.07c-1.64,0-2.96,1.33-2.96,2.96s1.33,2.96,2.96,2.96,2.96-1.33,2.96-2.96h0c0-1.64-1.33-2.96-2.96-2.96"/>
    <path id="Path_12768" data-name="Path 12768" fill='red' d="M38.7,38.07c-1.64,0-2.96,1.33-2.96,2.97s1.33,2.96,2.97,2.96c1.64,0,2.96-1.33,2.96-2.96,0-1.64-1.33-2.96-2.96-2.96"/>
    <path id="Line_2505" data-name="Line 2505" fill='red' d="M42.51,35.83h-7.64c-.55,0-1-.45-1-1s.45-1,1-1h7.64c.55,0,1,.45,1,1s-.45,1-1,1Z"/>
    <path id="Path_12769" data-name="Path 12769" fill='red' d="M44.04,41.94H16.53c-.55,0-1-.45-1-1V22.6c0-.55,.45-1,1-1h1.53c.55,0,1,.45,1,1s-.45,1-1,1h-.53v16.34h25.51v-6.41l-2.67-5.35h-5.5c-.55,0-1-.45-1-1s.45-1,1-1h6.11c.38,0,.72,.21,.89,.55l3.06,6.11c.07,.14,.11,.29,.11,.45v7.64c0,.55-.45,1-1,1Z"/>
    <path id="Path_12770" data-name="Path 12770" fill='red' d="M20.34,19.53c0,2.34,3.37,7.81,4.99,10.4,.17,.3,.55,.4,.85,.24,.1-.06,.18-.14,.24-.24,1.62-2.59,4.93-8.06,4.93-10.4,0-3.04-2.46-5.5-5.5-5.5s-5.5,2.46-5.5,5.5m3.61-.06h0c0-1.06,.86-1.92,1.92-1.92s1.92,.86,1.92,1.92-.86,1.92-1.92,1.92c0,0,0,0,0,0-1.06,0-1.92-.86-1.92-1.92,0,0,0,0,0,0"/>
    <path id="Path_12771" data-name="Path 12771" fill='red' d="M34.87,40.55c-.55,0-1-.45-1-1v-15.95h-.53c-.55,0-1-.45-1-1s.45-1,1-1h1.53c.55,0,1,.45,1,1v16.95c0,.55-.45,1-1,1Z"/>
    <rect id="Rectangle_3070" data-name="Rectangle 3070" class="cls-1" x="15" y="14" width="60.57" height="60"/>
    <text id="dynamicText" x="0" y="70" font-size="16" fill="red" font-weight="bold">${item.truckNumber}</text>
    </g>
   </svg>`
     var Car = item.gpsStatus === '0' ? redCar : (item.speed < 30 ? blueCar : greenCar) 

      return Car  
    }

    function getRandomDataPoint(cluster) {
      var dataPoints = [];
      cluster.forEachDataPoint(dataPoints.push.bind(dataPoints));
      return dataPoints[Math.random() * dataPoints.length | 0];
    }

    let CUSTOM_THEME ={
      getClusterPresentation: function(cluster) {
        var randomDataPoint = getRandomDataPoint(cluster),
        data = randomDataPoint.getData();
    
        var customIcon = document.createElement('div')
        customIcon.style.width = '18px';
        customIcon.style.height = '18px';
        customIcon.style.background = 'rgb(0,36,61)';
        customIcon.style.textAlign = 'center';
        customIcon.style.lineHeight = '18px';
        customIcon.style.borderRadius = '5px';
        customIcon.style.color = '#FFF';
        customIcon.innerHTML = cluster.getWeight() ;
        var clusterMarker = new H.map.DomMarker(cluster.getPosition(), {
          icon: new H.map.DomIcon(customIcon),
          min: cluster.getMinZoom(),
          max: cluster.getMaxZoom()
        });
        clusterMarker.setData(data);
        return clusterMarker;
      },
      getNoisePresentation: function (noisePoint) {
        var data = noisePoint.getData(),
        noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
          min: noisePoint.getMinZoom(),
          icon: new H.map.Icon(getCustomStyle(data))
        });
        noiseMarker.setData(data);
        noiseMarker.addEventListener('tap', function (evt) {
          var bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
            content: evt.target.getData()
          });
          
          data.location && that.ui.addBubble(bubble);
        }, false);
        let html = `<div style="width: 150px;">${data.location || ''}</div>`;
        noiseMarker.setData(html);
        return noiseMarker;
      }
    }

    var clusteredDataProvider = new H.clustering.Provider(dataPoints, {
      clusteringOptions: {
        eps: 40,
        minWeight: 2,
      },
      theme: CUSTOM_THEME
    });
  
    var clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);
    map.addLayer(clusteringLayer);
  }
  // displayInfoBubble(position, item) {  
  //       var bubble = new H.ui.InfoBubble({  
  //           lat: position.lat,  
  //           lng: position.lng  
  //       }, {  
  //           content:` <div>${item.location}</div>`
  //       });  
  //       this.map.addObject(bubble);  
  //       bubble.open();  
  // } 
  addNowPoint(lat, lng, shipmentStopList, course) {
    var vehicleIcon = document.createElement('div')
    vehicleIcon.innerHTML = `<img src="assets/images/svg/fleets/icon_map_navigator.svg">`;
    vehicleIcon.style.width = '18px';
    vehicleIcon.style.height = '18px';
    vehicleIcon.style.top = '-10px';
    vehicleIcon.style.left = '-10px';
    document.getElementById('fleetMap').appendChild(vehicleIcon);
    const domIcon = new H.map.DomIcon(vehicleIcon,{
      onAttach: function(clonedElement, domIcon, domMarker) {
        var clonedContent = clonedElement.getElementsByTagName('img')[0];
        clonedContent.style.transform = 'rotate(' + (course.toFixed(2)) + 'deg)';
    }}); 
    // var customOnIcon = new H.map.Icon(  
    //   '../../../../../../../../assets/images/svg/fleets/icon-location.svg', // 图标路径  
    //   {  
    //       size: new H.math.Size(18, 18),  
    //   }  
    // );
    let marker = new H.map.DomMarker({   
      lat: lat, lng: lng   
    }, {    
        icon: domIcon // 应用自定义的DOM图标    
    }) 
    //起点
    var startCustomOnIcon = new H.map.Icon(  
      '<svg  width="24" height="24" xmlns="http://www.w3.org/2000/svg">' +
      '<rect stroke="black" fill="blue" x="1" y="1" width="22" height="22" />' +
      '<text x="12" y="18" font-size="12pt" font-family="Arial" font-weight="bold" ' +
      'text-anchor="middle" fill="white" >P</text></svg>', // 图标路径  
      {  
          size: new H.math.Size(18, 18),  
      }  
    );
    let startMarker = new H.map.Marker({ 
      lat: shipmentStopList[0].stopLat, lng: shipmentStopList[0].stopLon
    }, {  
      icon: startCustomOnIcon // 应用自定义图标  
    }
    );
     //终点点
    var endCustomOnIcon = new H.map.Icon(  
      '<svg  width="24" height="24" xmlns="http://www.w3.org/2000/svg">' +
      '<rect stroke="black" fill="green" x="1" y="1" width="22" height="22" />' +
      '<text x="12" y="18" font-size="12pt" font-family="Arial" font-weight="bold" ' +
      'text-anchor="middle" fill="white" >D</text></svg>', // 图标路径  
      {  
          size: new H.math.Size(18, 18),  
      }  
    );
    let endMarker = new H.map.Marker({ 
      lat: shipmentStopList[1].stopLat, lng: shipmentStopList[1].stopLon
    }, {  
      icon: endCustomOnIcon // 应用自定义图标  
    }
    );
      console.log(marker,startMarker, endMarker, '=====================>')
      this.map.setCenter({lat: lat, lng: lng});  
      this.map.addObject(marker);  
      this.map.addObject(startMarker,); 
      this.map.addObject(endMarker); 
  }
  addHeartbeatPoint(arr) {
      arr.forEach((item: any) => { 
      // 创建一个标记  
      var svgMarkup = '<svg  width="10" height="10" xmlns="http://www.w3.org/2000/svg">' +
      '<circle stroke="black" fill="red" cx="5" cy="5" r="4" />  ' +
      '</svg>'
      if(item.lat && item.lng) {
        let marker = new H.map.Marker({ 
          lat: item.lat, lng: item.lng 
        }, {  
          icon: new H.map.Icon(svgMarkup)
        }
        );  
        // 将标记添加到地图上  
        this.map.addObject(marker);  
      }
    })
  }
  addRouteLine(polylineList) {
    polylineList.forEach((ele, index) => {
      const linestring = H.geo.LineString.fromFlexiblePolyline(ele);
      const routeLine = new H.map.Polyline(linestring, {
        style: { strokeColor: '#3956d2', lineWidth: 3 }
      })
        this.map.addObject(routeLine);

    });
  }
  addNewRouteLine(polylineList) {
    console.log('这里是当前位置到终点的划线')
    console.log(polylineList)
    polylineList.forEach((ele, index) => {
      const linestring = H.geo.LineString.fromFlexiblePolyline(ele);
      const routeLine = new H.map.Polyline(linestring, {
        style: { strokeColor: '#ff6a00', lineWidth: 8 }
      })
        this.map.addObject(routeLine);
    });
  }
  getCurrentCenter() {
    var center = this.map.getCenter();  
    console.log("当前地图中心点经纬度：" + center.lat + ", " + center.lng);
    var currentZoom = this.map.getZoom();  
    console.log("当前地图缩放级别：" + currentZoom);
    return {center, currentZoom}

  }
  decodeFlexiblePolyline(encoded) {
    var poly = [];  
    var index = 0, lat = 0, lng = 0, result = 0;  
    var len = encoded.length;  
    var latlng;  
  
    while (index < len) {  
        var b, shift = 0, result = 0;  
        do {  
            b = encoded.charCodeAt(index++) - 63;  
            result |= (b & 0x1f) << shift;  
            shift += 5;  
        } while (b >= 0x20);  
  
        var dlat = ((result & 1) ? ~(result >> 1) : (result >> 1));  
        lat += dlat;  
  
        shift = 0;  
        result = 0;  
        do {  
            b = encoded.charCodeAt(index++) - 63;  
            result |= (b & 0x1f) << shift;  
            shift += 5;  
        } while (b >= 0x20);  
  
        var dlng = ((result & 1) ? ~(result >> 1) : (result >> 1));  
        lng += dlng;  
  
        latlng = [lng * 1e-5, lat * 1e-5]; // 将结果转换为度数（乘以1e-5，因为polyline编码的精度是5位小数）  
        poly.push(latlng);  
    }  
    console.log(poly, '========>poly')
    return poly; 
  }
  // removeMarkersFromMap() {  
  //   console.log(this.markersArray, 'this.markersArray')

  //   // 遍历并删除所有存储的标记  
  //   this.markersArray.forEach(marker => {  
  //       this.map.removeObject(marker); // 假设这是从地图中删除标记的方法  
  //   });  
  //   // 清空标记数组以便后续使用  
  //   this.markersArray = [];  
  
  //   // 如果需要，刷新或重绘地图（这取决于API）  
  //   // this.map.refresh(); // 或类似的方法  
  // };


  private refreshBaiduMap(points: Array<Array<number>>) {

    const newPoints: any[] = [];
    points.forEach((point: Array<number>) => {
      newPoints.push(new BMap.Point(point[0], point[1]));
    });

    this.map.centerAndZoom(newPoints[0], 10); // 初始化地图，设置中心点坐标和地图级别
    this.map.clearOverlays(); // 清除地图上所有覆盖物

    const map = this.map;
    const content = '';
    let lushu;
    // 实例化一个驾车导航用来生成路线
    const drv = new BMap.DrivingRoute('---', {
      onSearchComplete: function (res) {
        if (drv.getStatus() === 0) {
          const plan = res.getPlan(0);
          let arrPois = [];
          for (let j = 0; j < plan.getNumRoutes(); j++) {
            const route = plan.getRoute(j);
            arrPois = arrPois.concat(route.getPath());
          }
          map.addOverlay(new BMap.Polyline(arrPois, { strokeColor: 'blue' }));
          map.setViewport(arrPois);

          lushu = new BMapLib.LuShu(map, arrPois, {
            defaultContent: content, // "从天安门到百度大厦"
            autoView: true, // 是否开启自动视野调整，如果开启那么路书在运动过程中会根据视野自动调整
            icon: new BMap.Icon('/jsdemo/img/car.png', new BMap.Size(52, 26), { anchor: new BMap.Size(27, 13) }),
            speed: 4500,
            enableRotation: true, // 是否设置marker随着道路的走向进行旋转
            landmarkPois: []
          });
        }
      }
    });
    drv.search(newPoints[0], newPoints[newPoints.length - 1]);

    // 创建点标记
    const markers = [];
    points.forEach(point => {
      markers.push(new BMap.Marker(new BMap.Point(point[0], point[1])));
    });
    // 在地图上添加点标记
    markers.forEach(marker => {
      this.map.addOverlay(marker);
    });
  }

  private refreshHereMap(points: Array<Array<number>>, DeliveredState?: DeliveredState) {
    if (!this.map) {
      return;
    }
    let times = 0;
    let length = 0;
    this.Ajax.get(DeliveredState.url, (res) => {
      res = JSON.parse(res)
      if (res && res.routes) {
        res.routes[0].sections.forEach(ele => {
          times += ele.summary.duration
          length += ele.summary.length
        });
      }
    })
    let routingParameters = {
      'routingMode': 'fast',
      'transportMode': 'car',
      // The start point of the route:
      'origin': points[0][1] + ',' + points[0][0],
      // The end point of the route:
      'destination': points[1][1] + ',' + points[1][0],
      // Include the route shape in the response
      'return': 'polyline'
    };
    // 判断小车画线
    let orderStatus = ["DELIVERED", "IN-TRANSIT", 'PODRETURNED']
    if (DeliveredState.shipmentXid) {
      if (orderStatus.includes(DeliveredState.orderStatus)) {
        if (DeliveredState.eventStatusCode == 'AF') {
          // Create a marker for the end point:运输中
          routingParameters = {
            'routingMode': 'fast',
            'transportMode': 'car',
            // The start point of the route:
            'origin': DeliveredState.gpsLat + ',' + DeliveredState.gpsLon,
            // The end point of the route:
            'destination': points[1][1] + ',' + points[1][0],
            // Include the route shape in the response
            'return': 'polyline'
          };
        }
      }
    }

    const onResult = (result?) => {
      var Destination = `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 74"  width="50" height="50">
      <defs>
        <style>
          .cls-1 {
            fill: #fff;
          }
          .cls-2 {
            fill: #ff9300;
          }
        </style>
      </defs>
      <g id="Group_14877" data-name="Group 14877">
        <g id="Path_12765" data-name="Path 12765">
          <path class="cls-2" d="M30,59.5c-3.98,0-7.85-.78-11.48-2.32-3.51-1.49-6.67-3.61-9.38-6.32-2.71-2.71-4.84-5.86-6.32-9.38-1.54-3.64-2.32-7.5-2.32-11.48s.78-7.85,2.32-11.48c1.49-3.51,3.61-6.67,6.32-9.38,2.71-2.71,5.86-4.84,9.38-6.32,3.64-1.54,7.5-2.32,11.48-2.32s7.85,.78,11.48,2.32c3.51,1.49,6.67,3.61,9.38,6.32,2.71,2.71,4.84,5.86,6.32,9.38,1.54,3.64,2.32,7.5,2.32,11.48s-.78,7.85-2.32,11.48c-1.49,3.51-3.61,6.67-6.32,9.38s-5.86,4.84-9.38,6.32c-3.64,1.54-7.5,2.32-11.48,2.32Z"/>
          <path class="cls-1" d="M30,1c-3.92,0-7.71,.77-11.29,2.28-3.45,1.46-6.55,3.55-9.22,6.22-2.66,2.66-4.75,5.77-6.22,9.22-1.51,3.57-2.28,7.37-2.28,11.29s.77,7.71,2.28,11.29c1.46,3.45,3.55,6.55,6.22,9.22,2.66,2.66,5.77,4.75,9.22,6.22,3.57,1.51,7.37,2.28,11.29,2.28s7.71-.77,11.29-2.28c3.45-1.46,6.55-3.55,9.22-6.22,2.66-2.66,4.75-5.77,6.22-9.22,1.51-3.57,2.28-7.37,2.28-11.29s-.77-7.71-2.28-11.29c-1.46-3.45-3.55-6.55-6.22-9.22-2.66-2.66-5.77-4.75-9.22-6.22-3.57-1.51-7.37-2.28-11.29-2.28m0-1c16.57,0,30,13.43,30,30s-13.43,30-30,30S0,46.57,0,30,13.43,0,30,0Z"/>
        </g>
        <g id="Path_12766" data-name="Path 12766">
          <path class="cls-2" d="M30.5,73.5c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5Z"/>
          <path class="cls-1" d="M30.5,64c-2.48,0-4.5,2.02-4.5,4.5s2.02,4.5,4.5,4.5,4.5-2.02,4.5-4.5-2.02-4.5-4.5-4.5m0-1c3.04,0,5.5,2.46,5.5,5.5s-2.46,5.5-5.5,5.5-5.5-2.46-5.5-5.5,2.46-5.5,5.5-5.5Z"/>
        </g>
        <path id="Path_835" data-name="Path 835" class="cls-1" d="M30.23,41.03c-3.95,.05-7.9-.37-11.75-1.26-1.1-.27-2.17-.66-3.19-1.16-.37-.2-1.49-.8-1.49-1.77,0-.87,.9-1.7,2.67-2.47,2.34-.93,4.79-1.52,7.29-1.78,.49-.06,.94,.28,1,.77s-.28,.94-.77,1c-2.17,.23-4.31,.72-6.36,1.46-.67,.23-1.3,.57-1.86,.99,1.18,.68,2.47,1.15,3.81,1.4,3.5,.75,7.08,1.11,10.66,1.05,3.59,.05,7.17-.3,10.67-1.06,1.37-.24,2.69-.73,3.88-1.44-.51-.58-2.7-2.12-8.22-2.6-.49-.04-.86-.48-.81-.97,.04-.49,.48-.86,.97-.81,2.53,.14,5.01,.73,7.33,1.74,1.69,.82,2.63,1.78,2.63,2.71s-1.12,1.57-1.49,1.77c-1.02,.51-2.09,.9-3.19,1.16-3.85,.92-7.8,1.35-11.76,1.27Z"/>
        <path id="Path_836" data-name="Path 836" class="cls-1" d="M21.91,20.04c0,3.55,5.1,11.85,7.56,15.76,.09,.14,.2,.26,.35,.35,.45,.25,1.02,.1,1.27-.35,2.46-3.92,7.49-12.22,7.49-15.76,.05-4.61-3.65-8.39-8.25-8.44-4.61-.05-8.39,3.65-8.44,8.25,0,.06,0,.12,0,.18h.02Zm5.47-.09h0c0-1.61,1.31-2.92,2.92-2.92,1.61,0,2.92,1.31,2.92,2.92,0,1.61-1.31,2.92-2.92,2.92h0c-1.61-.02-2.9-1.32-2.92-2.92Z"/>
      </g>
    </svg>`
      var Car = `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 74" width="50" height="50">
      <defs>
        <style>
          .cls-1 {
            fill: none;
          }
          .cls-2 {
            fill: #007fff;
          }
          .cls-3 {
            fill: #fff;
          }
        </style>
      </defs>
      <g id="Group_14881" data-name="Group 14881">
        <g id="Path_12765" data-name="Path 12765">
          <path class="cls-2" d="M30,59.5c-3.98,0-7.85-.78-11.48-2.32-3.51-1.49-6.67-3.61-9.38-6.32-2.71-2.71-4.84-5.86-6.32-9.38-1.54-3.64-2.32-7.5-2.32-11.48s.78-7.85,2.32-11.48c1.49-3.51,3.61-6.67,6.32-9.38,2.71-2.71,5.86-4.84,9.38-6.32,3.64-1.54,7.5-2.32,11.48-2.32s7.85,.78,11.48,2.32c3.51,1.49,6.67,3.61,9.38,6.32,2.71,2.71,4.84,5.86,6.32,9.38,1.54,3.64,2.32,7.5,2.32,11.48s-.78,7.85-2.32,11.48c-1.49,3.51-3.61,6.67-6.32,9.38s-5.86,4.84-9.38,6.32c-3.64,1.54-7.5,2.32-11.48,2.32Z"/>
          <path class="cls-3" d="M30,1c-3.92,0-7.71,.77-11.29,2.28-3.45,1.46-6.55,3.55-9.22,6.22-2.66,2.66-4.75,5.77-6.22,9.22-1.51,3.57-2.28,7.37-2.28,11.29s.77,7.71,2.28,11.29c1.46,3.45,3.55,6.55,6.22,9.22,2.66,2.66,5.77,4.75,9.22,6.22,3.57,1.51,7.37,2.28,11.29,2.28s7.71-.77,11.29-2.28c3.45-1.46,6.55-3.55,9.22-6.22,2.66-2.66,4.75-5.77,6.22-9.22,1.51-3.57,2.28-7.37,2.28-11.29s-.77-7.71-2.28-11.29c-1.46-3.45-3.55-6.55-6.22-9.22-2.66-2.66-5.77-4.75-9.22-6.22-3.57-1.51-7.37-2.28-11.29-2.28m0-1c16.57,0,30,13.43,30,30s-13.43,30-30,30S0,46.57,0,30,13.43,0,30,0Z"/>
        </g>
        <g id="Path_12766" data-name="Path 12766">
          <path class="cls-2" d="M30.5,73.5c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5Z"/>
          <path class="cls-3" d="M30.5,64c-2.48,0-4.5,2.02-4.5,4.5s2.02,4.5,4.5,4.5,4.5-2.02,4.5-4.5-2.02-4.5-4.5-4.5m0-1c3.04,0,5.5,2.46,5.5,5.5s-2.46,5.5-5.5,5.5-5.5-2.46-5.5-5.5,2.46-5.5,5.5-5.5Z"/>
        </g>
        <rect id="Rectangle_3062" data-name="Rectangle 3062" class="cls-1" x="13" y="13" width="35" height="35"/>
        <path id="Path_12767" data-name="Path 12767" class="cls-3" d="M22.33,38.07c-1.64,0-2.96,1.33-2.96,2.96s1.33,2.96,2.96,2.96,2.96-1.33,2.96-2.96h0c0-1.64-1.33-2.96-2.96-2.96"/>
        <path id="Path_12768" data-name="Path 12768" class="cls-3" d="M38.7,38.07c-1.64,0-2.96,1.33-2.96,2.97s1.33,2.96,2.97,2.96c1.64,0,2.96-1.33,2.96-2.96,0-1.64-1.33-2.96-2.96-2.96"/>
        <path id="Line_2505" data-name="Line 2505" class="cls-3" d="M42.51,35.83h-7.64c-.55,0-1-.45-1-1s.45-1,1-1h7.64c.55,0,1,.45,1,1s-.45,1-1,1Z"/>
        <path id="Path_12769" data-name="Path 12769" class="cls-3" d="M44.04,41.94H16.53c-.55,0-1-.45-1-1V22.6c0-.55,.45-1,1-1h1.53c.55,0,1,.45,1,1s-.45,1-1,1h-.53v16.34h25.51v-6.41l-2.67-5.35h-5.5c-.55,0-1-.45-1-1s.45-1,1-1h6.11c.38,0,.72,.21,.89,.55l3.06,6.11c.07,.14,.11,.29,.11,.45v7.64c0,.55-.45,1-1,1Z"/>
        <path id="Path_12770" data-name="Path 12770" class="cls-3" d="M20.34,19.53c0,2.34,3.37,7.81,4.99,10.4,.17,.3,.55,.4,.85,.24,.1-.06,.18-.14,.24-.24,1.62-2.59,4.93-8.06,4.93-10.4,0-3.04-2.46-5.5-5.5-5.5s-5.5,2.46-5.5,5.5m3.61-.06h0c0-1.06,.86-1.92,1.92-1.92s1.92,.86,1.92,1.92-.86,1.92-1.92,1.92c0,0,0,0,0,0-1.06,0-1.92-.86-1.92-1.92,0,0,0,0,0,0"/>
        <path id="Path_12771" data-name="Path 12771" class="cls-3" d="M34.87,40.55c-.55,0-1-.45-1-1v-15.95h-.53c-.55,0-1-.45-1-1s.45-1,1-1h1.53c.55,0,1,.45,1,1v16.95c0,.55-.45,1-1,1Z"/>
        <rect id="Rectangle_3070" data-name="Rectangle 3070" class="cls-1" x="15" y="14" width="30.57" height="30"/>
      </g>
    </svg>`
      var starts = `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 74" width="50" height="50">
    <defs>
      <style>
        .cls-1 {
          fill: #fff;
        }
        .cls-2 {
          fill: #ff00f5;
        }
  
        .cls-3 {
          fill: #ff00ce;
        }
      </style>
    </defs>
    <g id="Group_14880" data-name="Group 14880">
      <g id="Path_12772" data-name="Path 12772">
        <path class="cls-2" d="M30,59.5c-3.98,0-7.85-.78-11.48-2.32-3.51-1.49-6.67-3.61-9.38-6.32-2.71-2.71-4.84-5.86-6.32-9.38-1.54-3.64-2.32-7.5-2.32-11.48s.78-7.85,2.32-11.48c1.49-3.51,3.61-6.67,6.32-9.38,2.71-2.71,5.86-4.84,9.38-6.32,3.64-1.54,7.5-2.32,11.48-2.32s7.85,.78,11.48,2.32c3.51,1.49,6.67,3.61,9.38,6.32,2.71,2.71,4.84,5.86,6.32,9.38,1.54,3.64,2.32,7.5,2.32,11.48s-.78,7.85-2.32,11.48c-1.49,3.51-3.61,6.67-6.32,9.38s-5.86,4.84-9.38,6.32c-3.64,1.54-7.5,2.32-11.48,2.32Z"/>
        <path class="cls-1" d="M30,1c-3.92,0-7.71,.77-11.29,2.28-3.45,1.46-6.55,3.55-9.22,6.22-2.66,2.66-4.75,5.77-6.22,9.22-1.51,3.57-2.28,7.37-2.28,11.29s.77,7.71,2.28,11.29c1.46,3.45,3.55,6.55,6.22,9.22,2.66,2.66,5.77,4.75,9.22,6.22,3.57,1.51,7.37,2.28,11.29,2.28s7.71-.77,11.29-2.28c3.45-1.46,6.55-3.55,9.22-6.22,2.66-2.66,4.75-5.77,6.22-9.22,1.51-3.57,2.28-7.37,2.28-11.29s-.77-7.71-2.28-11.29c-1.46-3.45-3.55-6.55-6.22-9.22-2.66-2.66-5.77-4.75-9.22-6.22-3.57-1.51-7.37-2.28-11.29-2.28m0-1c16.57,0,30,13.43,30,30s-13.43,30-30,30S0,46.57,0,30,13.43,0,30,0Z"/>
      </g>
      <g id="Path_12773" data-name="Path 12773">
        <path class="cls-3" d="M30.5,73.5c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5Z"/>
        <path class="cls-1" d="M30.5,64c-2.48,0-4.5,2.02-4.5,4.5s2.02,4.5,4.5,4.5,4.5-2.02,4.5-4.5-2.02-4.5-4.5-4.5m0-1c3.04,0,5.5,2.46,5.5,5.5s-2.46,5.5-5.5,5.5-5.5-2.46-5.5-5.5,2.46-5.5,5.5-5.5Z"/>
      </g>
      <path id="Path_836" data-name="Path 836" class="cls-1" d="M33.16,34.15c0,2.88,4.14,9.61,6.13,12.79,.07,.12,.17,.21,.28,.28,.36,.21,.83,.08,1.03-.28,1.99-3.18,6.07-9.91,6.07-12.79,0-3.74-3.03-6.77-6.77-6.77-3.74,0-6.77,3.03-6.77,6.77h0s.02,0,.02,0Zm4.44-.07h0c0-1.31,1.06-2.37,2.37-2.37s2.37,1.06,2.37,2.37-1.06,2.37-2.37,2.37h0c-1.3-.02-2.35-1.07-2.37-2.37Z"/>
      <path id="Path_12760" data-name="Path 12760" class="cls-1" d="M28.93,26.14c-.14,0-.27-.03-.39-.08l-12.34-5.29c-.51-.22-.74-.81-.52-1.31,.1-.24,.29-.42,.52-.52l12.34-5.29c.25-.11,.54-.11,.79,0l12.34,5.29c.22,.1,.41,.27,.51,.49h0s.03,.06,.04,.09h0c.04,.11,.06,.24,.06,.36v3.95c0,.55-.45,1-1,1s-1-.45-1-1v-2.46l-10.95,4.69c-.12,.05-.26,.08-.39,.08Zm-9.8-6.29l9.8,4.2,9.8-4.2-9.8-4.2-9.8,4.2Z"/>
      <path id="Path_12761" data-name="Path 12761" class="cls-1" d="M28.92,42c-.14,0-.27-.03-.39-.08l-12.34-5.29c-.37-.16-.61-.52-.61-.92v-15.87c0-.55,.45-1,1-1,.55,0,1,.45,1,1v15.21l11.73,5.03c.51,.22,.74,.81,.52,1.31-.16,.37-.52,.61-.92,.61h0Z"/>
      <path id="Line_2501" data-name="Line 2501" class="cls-1" d="M20.11,31.06c-.21,0-.38-.17-.38-.38v-3.79c0-.21,.17-.38,.38-.38s.38,.17,.38,.38v3.79c0,.21-.17,.38-.38,.38Z"/>
      <path id="Line_2502" data-name="Line 2502" class="cls-1" d="M23.64,32.56c-.21,0-.38-.17-.38-.38v-3.53c0-.21,.17-.38,.38-.38s.38,.17,.38,.38v3.53c0,.21-.17,.37-.38,.37Z"/>
      <path id="Line_2503" data-name="Line 2503" class="cls-1" d="M21.87,31.75c-.21,0-.38-.17-.38-.38v-2.24c0-.21,.17-.38,.38-.38s.38,.17,.38,.38v2.24c0,.21-.17,.38-.38,.38Z"/>
      <path id="Line_2504" data-name="Line 2504" class="cls-1" d="M28.92,42c-.55,0-1-.45-1-1v-15.87c0-.55,.45-1,1-1s1,.45,1,1v15.87c0,.55-.45,1-1,1h0Z"/>
    </g>
  </svg>`
      var startsIcon = new H.map.Icon(starts)
      var DestinationIcon = new H.map.Icon(Destination)
      var CarIcon = new H.map.Icon(Car)
      let startMarker;
      let endMarker;

      // ensure that at least one route was found
      if (result && result.routes.length) {
        result.routes[0].sections.forEach((section) => {
          // Create a linestring to use as a point source for the route line
          const linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);

          // Create a polyline to display the route:
          const routeLine = new H.map.Polyline(linestring, {
            style: { strokeColor: 'blue', lineWidth: 5 }
          });
          console.log(this.logContainer);
          console.log(this.logContainer?.innerHTML);
          if (this.logContainer?.innerHTML) {
            this.logContainer.innerHTML = ''
          }
          // 方框内容展示判断
          if (DeliveredState && DeliveredState.Page == "OrderTrack" && DeliveredState.correctFlag == 'T') {
            let shipmentStatus = ["DELIVERED", "IN-TRANSIT", 'PODRETURNED']
            this.logContainer = document.createElement('text');
            this.logContainer.className = 'log';
            console.log(this.logContainer);
            console.log(this.logContainer?.innerHTML);
            // this.map.getElement().parentNode
            // .removeChild(logContainer);
            startMarker = new H.map.Marker(section.departure.place.location, { icon: startsIcon });
            if (DeliveredState.shipmentXid) {
              if (shipmentStatus.includes(DeliveredState.orderStatus)) {
                if (DeliveredState.eventStatusCode == 'AF') {
                  // Create a marker for the end point:运输中
                  let time = this.getDuration(times)
                  let location = { lat: DeliveredState.gpsLat, lng: DeliveredState.gpsLon }
                  startMarker = new H.map.Marker(location, { icon: CarIcon });
                  this.logContainer.innerHTML =
                    `<p>${this.getLabel('lbl_remaining_distance')}: ${length / 1000}&nbsp;Km</p>
                  <p>${this.getLabel('lbl_est_travel_time')}: ${time}</p>
                  `;
                  this.map.getElement().appendChild(this.logContainer);
                } else if (DeliveredState.eventStatusCode == 'D1') {
                  // 实际时间
                  this.logContainer.innerHTML =
                    `<p>${this.getLabel('lbl_actual_pickup_date')}: ${DeliveredState.pickupDateTime || ''}</p>
                  <p>${this.getLabel('lbl_actual_delivery_date')}: ${DeliveredState.timeOfArrival || ''}</p>
                  `;
                  this.map.getElement().appendChild(this.logContainer);
                  // <p>Source City: ${DeliveredState.sourceCity || ''}</p>
                  // <p>Dest City: ${DeliveredState.destCity || ''}</p>
                } else if (DeliveredState.eventStatusCode == 'A7') {
                  this.logContainer.innerHTML =
                    `<p>${this.getLabel('lbl_actual_pickup_date')}: ${DeliveredState.pickupDateTime || ''}</p>
                  <p>${this.getLabel('lbl_actual_delivery_date')}: ${DeliveredState.refuseTime || ''}</p>
                  `;
                  this.map.getElement().appendChild(this.logContainer);
                }
              } else {
                this.logContainer.innerHTML =
                  `<p>${this.getLabel('lbl_planned_pickup_date')}: ${DeliveredState.shipmentPlannedStartDate || ''}</p>
                <p>${this.getLabel('lbl_planned_delivery_date')}: ${DeliveredState.shipmentPlannedEndDate || ''}</p>
                `;
                this.map.getElement().appendChild(this.logContainer);
              }
            }
            // Create a marker for the start point:
            endMarker = new H.map.Marker(section.arrival.place.location, { icon: DestinationIcon });
          } else {
            // Create a marker for the start point:
            startMarker = new H.map.Marker(section.departure.place.location);
            // Create a marker for the end point:
            endMarker = new H.map.Marker(section.arrival.place.location);
          }


          // remove old objects
          const objs = this.map.getObjects();
          this.map.removeObjects(objs);
          var group = new H.map.Group();
          var ui = H.ui.UI.createDefault(this.map, this.defaultLayers);
          // var marker = new H.map.Marker(address,{icon});
          // 地图调用
          //   var url = "https://wps.hereapi.com/v8/findsequence2";
          //   var data = {
          //     apiKey:'T6FD5iqHZVAJccjo8CFvY7BTEeq3s_Tnry0xVhub2EI',
          //     start:{'WiesbadenCentralStation':[50.0715,8.2434]},
          //     destination0:{'FranfurtCentralStation':[50.1073,8.6647]},
          //     end:{'MainzCentralStation':[50.0021,8.259]},
          //     mode:'car',
          //     improveFor:'time'
          //   }
          //  this.JqueryService.getAsync(url,data).then(res=>{

          //  })

          // 左上角显示框
          // addInfoBubble(this.map)
          // Add the route polyline and the two markers to the map:
          this.map.addObjects([routeLine, startMarker, endMarker]);
          var m1 = routingParameters.origin.split(",");
          var m2 = routingParameters.destination.split(",");
          var distance = this.GetDistance(m1[0], m1[1], m2[0], m2[1])
          let zooms = 8
          if (distance > 2000000) {
            zooms = 5;
          } else if (distance < 500000 && distance > 200000) {
            zooms = 9;
          } else if (distance < 200000 && distance > 100000) {
            zooms = 10;
          } else if (distance < 100000 && distance > 50000) {
            zooms = 11;
          } else if (distance < 50000 && distance > 20000) {
            zooms = 12;
          } else if (distance < 20000 && distance > 10000) {
            zooms = 13;
          } else if (distance < 10000 && distance > 5000) {
            zooms = 14;
          } else if (distance < 5000) {
            zooms = 15;
          }

          // Set the map's viewport to make the whole route visible:
          this.map.getViewModel().setLookAtData({ bounds: routeLine.getBoundingBox(), zoom: zooms });
        });
      } else {
        let end = { lat: DeliveredState.destLat, lng: DeliveredState.destLon }
        let start = { lat: DeliveredState.sourceLat, lng: DeliveredState.sourceLon }
        if (DeliveredState && DeliveredState.Page == "OrderTrack" && DeliveredState.correctFlag == 'T') {
          let shipmentStatus = ["DELIVERED", "INTRANSIT", 'PODRETURNED']
          var logContainer = document.createElement('text');
          logContainer.className = 'log';
          startMarker = new H.map.Marker(start, { icon: startsIcon });
          if (DeliveredState.shipmentXid) {
            if (shipmentStatus.includes(DeliveredState.orderStatus)) {
              if (DeliveredState.eventStatusCode == 'AF') {
                // Create a marker for the end point:运输中
                let time = this.getDuration(times)
                let location
                if (DeliveredState.gpsLat && DeliveredState.gpsLon) {
                  location = { lat: DeliveredState.gpsLat, lng: DeliveredState.gpsLon }
                } else {
                  location = end
                }
                startMarker = new H.map.Marker(location, { icon: CarIcon });
                logContainer.innerHTML =
                  `<p>${this.getLabel('lbl_remaining_distance')}: ${length / 1000}&nbsp;Km</p>
                <p>${this.getLabel('lbl_est_travel_time')}: ${time}</p>
                `;
                if (DeliveredState.diffDistance && DeliveredState.time) {
                  this.map.getElement().appendChild(logContainer);
                }
              } else if (DeliveredState.eventStatusCode == 'D1') {
                // 实际时间
                logContainer.innerHTML =
                  `<p>${this.getLabel('lbl_actual_pickup_date')}: ${DeliveredState.pickupDateTime || ''}</p>
                <p>${this.getLabel('lbl_actual_delivery_date')}: ${DeliveredState.timeOfArrival || ''}</p>
                `;
                this.map.getElement().appendChild(logContainer);
                // <p>Source City: ${DeliveredState.sourceCity || ''}</p>
                // <p>Dest City: ${DeliveredState.destCity || ''}</p>
              } else if (DeliveredState.eventStatusCode == 'A7') {
                logContainer.innerHTML =
                  `<p>${this.getLabel('lbl_actual_pickup_date')}: ${DeliveredState.pickupDateTime || ''}</p>
                <p>${this.getLabel('lbl_actual_delivery_date')}: ${DeliveredState.refuseTime || ''}</p>
                `;
                this.map.getElement().appendChild(logContainer);
              }
            } else {
              logContainer.innerHTML =
                `<p>${this.getLabel('lbl_planned_pickup_date')}: ${DeliveredState.shipmentPlannedStartDate || ''}</p>
              <p>${this.getLabel('lbl_planned_delivery_date')}: ${DeliveredState.shipmentPlannedEndDate || ''}</p>
              `;
              this.map.getElement().appendChild(logContainer);
            }
          }
          // Create a marker for the start point:

          startMarker = new H.map.Marker(start, { icon: startsIcon });
          endMarker = new H.map.Marker(end, { icon: DestinationIcon });
        } else {
          // Create a marker for the start point:
          startMarker = new H.map.Marker(start);
          // Create a marker for the end point:
          endMarker = new H.map.Marker(end);
        }
        // remove old objects
        const objs = this.map.getObjects();
        this.map.removeObjects(objs);
        var group = new H.map.Group();
        var ui = H.ui.UI.createDefault(this.map, this.defaultLayers);
        // var marker = new H.map.Marker(address,{icon});
        // 地图调用
        //   var url = "https://wps.hereapi.com/v8/findsequence2";
        //   var data = {
        //     apiKey:'T6FD5iqHZVAJccjo8CFvY7BTEeq3s_Tnry0xVhub2EI',
        //     start:{'WiesbadenCentralStation':[50.0715,8.2434]},
        //     destination0:{'FranfurtCentralStation':[50.1073,8.6647]},
        //     end:{'MainzCentralStation':[50.0021,8.259]},
        //     mode:'car',
        //     improveFor:'time'
        //   }
        //  this.JqueryService.getAsync(url,data).then(res=>{

        //  })

        // 左上角显示框
        // addInfoBubble(this.map)
        // Add the route polyline and the two markers to the map:
        this.map.addObject(startMarker);
        this.map.addObject(endMarker);
        var distance = this.GetDistance(DeliveredState.sourceLat, DeliveredState.sourceLon, DeliveredState.destLat, DeliveredState.destLon)
        let zooms = 8
        if (distance > 2000000) {
          zooms = 5;
        } else if (distance < 500000 && distance > 200000) {
          zooms = 9;
        } else if (distance < 200000 && distance > 100000) {
          zooms = 10;
        } else if (distance < 100000 && distance > 50000) {
          zooms = 11;
        } else if (distance < 50000 && distance > 20000) {
          zooms = 12;
        } else if (distance < 20000 && distance > 10000) {
          zooms = 13;
        } else if (distance < 10000 && distance > 5000) {
          zooms = 14;
        } else if (distance < 5000) {
          zooms = 15;
        }
        let coords;
        let coordlist = [[DeliveredState.sourceLat, DeliveredState.sourceLon], [DeliveredState.destLat, DeliveredState.destLon]]
        coords = this.getLatLngCenter(coordlist)
        this.map.setCenter(coords);
        this.map.setZoom(zooms);
        // Set the map's viewport to make the whole route visible:
        // this.map.getViewModel().setLookAtData({ zoom: zooms });
      }
    };

    // Get an instance of the routing service:
    // const router = this.platform.getRoutingService();
    const router = this.platform.getRoutingService(null, 8);

    // Call calculateRoute() with the routing parameters,
    // the callback and an error callback function (called if a
    // communication error occurs): 
    if (routingParameters.origin !== "undefined,undefined" && routingParameters.destination) {
      router.calculateRoute(routingParameters, onResult,
        function (error) {
          console.debug(error.message || error);
        });
    } else {
      onResult()
    }
  }
  getLabel(value) {
    if (this.getChangeList) {
      const data = this.getChangeList.find(item => item.promptCode === value);
      return data ? data.description : value;
    } else {
      return value
    }
  }
  // 原生Ajax
  Ajax = {
    get: function (url, callback) {
      // XMLHttpRequest对象用于在后台与服务器交换数据
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, false);
      xhr.onreadystatechange = function () {
        // readyState == 4说明请求已完成
        if (xhr.readyState == 4) {
          if (xhr.status == 200 || xhr.status == 304) {
            callback(xhr.responseText);
          }
        }
      }
      xhr.send();
    },

    // data应为'a=a1&b=b1'这种字符串格式，在jq里如果data为对象会自动将对象转成这种字符串格式
    post: function (url, data, callback) {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', url, false);
      // 添加http头，发送信息至服务器时内容编码类型
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200 || xhr.status == 304) {
            // console.debug(xhr.responseText);
            callback(xhr.responseText);
          }
        }
      }
      xhr.send(data);
    }
  }
  // 计算天小时分钟
  getDuration: (second: number) => string = (second) => {
    var days = Math.floor(second / 86400);
    var hours = Math.floor((second % 86400) / 3600);
    var minutes = Math.floor(((second % 86400) % 3600) / 60);
    // var seconds = Math.floor(((second % 86400) % 3600) % 60);
    // var duration = days + "day" + hours + "hours" + minutes + "minutes" + seconds + "秒";
    var duration;
    if (days == 0) {
      duration = hours + "&nbsp;H&nbsp;" + minutes + "&nbsp;Min";
      if (hours == 0) {
        duration = minutes + "&nbsp;Min";
      }
    } else {
      duration = days + "&nbsp;Day&nbsp;" + hours + "&nbsp;H&nbsp;" + minutes + "&nbsp;Min";
    }
    return duration;
  }

  //计算经纬度距离
  Rad(d) {
    return d * Math.PI / 180.0;//经纬度转换成三角函数中度分表形式。
  }
  //lat 纬度
  //lng 经度
  GetDistance(lat1, lng1, lat2, lng2) {
    var radLat1 = this.Rad(lat1);
    var radLat2 = this.Rad(lat2);
    var a = radLat1 - radLat2;
    var b = this.Rad(lng1) - this.Rad(lng2);
    var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
      Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
    s = s * 6378.137;// 地球半径;
    s = Math.round(s * 10000) / 10; //输出为米
    return s;
  }

  //计算中心点
  rad2degr(rad) { return rad * 180 / Math.PI; }
  degr2rad(degr) { return degr * Math.PI / 180; }

  /**
   * @param latLngInDeg array of arrays with latitude and longtitude
   *   pairs in degrees. e.g. [[latitude1, longtitude1], [latitude2
   *   [longtitude2] ...]
   *
   * @return array with the center latitude longtitude pairs in 
   *   degrees.
   */
  getLatLngCenter(latLngInDegr) {
    var LATIDX = 0;
    var LNGIDX = 1;
    var sumX = 0;
    var sumY = 0;
    var sumZ = 0;

    for (var i = 0; i < latLngInDegr.length; i++) {
      var lat = this.degr2rad(latLngInDegr[i][LATIDX]);
      var lng = this.degr2rad(latLngInDegr[i][LNGIDX]);
      // sum of cartesian coordinates        sumX += Math.cos(lat) * Math.cos(lng);
      sumY += Math.cos(lat) * Math.sin(lng);
      sumZ += Math.sin(lat);
    }

    var avgX = sumX / latLngInDegr.length;
    var avgY = sumY / latLngInDegr.length;
    var avgZ = sumZ / latLngInDegr.length;

    // convert average x, y, z coordinate to latitude and longtitude    var lng = Math.atan2(avgY, avgX);
    var hyp = Math.sqrt(avgX * avgX + avgY * avgY);
    var lat = Math.atan2(avgZ, hyp);

    return ({ lat: this.rad2degr(lat), lng: this.rad2degr(lng) });
  }
  /**
   * 刷新地图
   * @param points 坐标：[[lng, lat], [lng, lat], ...]
   */
  refreshMap(points: Array<Array<number>>, DeliveredState?: DeliveredState) {
    if (this.isBaidu) {
      this.refreshBaiduMap(points);
    } else {
      this.refreshHereMap(points, DeliveredState);
    }
  }

  /**
   * 刷新浮标地图
   * @param points 坐标：[[lng, lat], [lng, lat], ...]
   * @param notTransformPoint 是否不需要转换坐标：BD09<-->WGS84
   */
  refreshMapMarker(points: number[][], notTransformPoint?: boolean) {
    if (this.isBaidu) {
      this.refreshBaiduMapMarker(points);
    } else {
      let points2 = null;
      if (notTransformPoint) {
        points2 = points;
      } else {
        points2 = this.bd09_to_wgs84(points);
      }
      this.refreshHereMapMarker(points2);
    }
  }

  private refreshBaiduMapMarker(points: number[][]) {
    this.map.clearOverlays(); // 清除地图上所有覆盖物
    this.map.centerAndZoom(new BMap.Point(points[0][0], points[0][1]), 10);

    // 创建点标记
    const markers = [];
    points.forEach(point => {
      markers.push(new BMap.Marker(new BMap.Point(point[0], point[1])));
    });
    // 在地图上添加点标记
    markers.forEach(marker => {
      this.map.addOverlay(marker);
    });
  }

  private refreshHereMapMarker(points: number[][]) {
    this.map.setCenter({
      lng: points[0][0],
      lat: points[0][1]
    });

    const objs = this.map.getObjects();
    this.map.removeObjects(objs);

    // Add a marker for each location found
    for (let i = 0; i < points.length; i++) {
      const position = {
        lng: points[i][0],
        lat: points[i][1]
      };
      const marker = new H.map.Marker(position);
      this.map.addObject(marker);
    }
  }


  public clearOverlays() {
    if (this.isBaidu) {
      this.map.clearOverlays(); // 清除地图上所有覆盖物
    } else {
      const objs = this.map.getObjects();
      this.map.removeObjects(objs);
    }
  }


  // [{
  //   point: [lon, lot],
  //   text: 'text',
  //   style: {},
  // }]
  refreshMapCircleLable(labels: any[], clearOverlays?: boolean) {
    try {
      if (this.isBaidu) {
        this.refreshBaiduMapCircleLable(labels, clearOverlays);
      } else {
        this.refreshHereMapCircleLable(labels, clearOverlays);
      }
    } catch (error) {
      // console.error(error);
    }
  }

  private refreshBaiduMapCircleLable(labels: any[], clearOverlays?: boolean) {
    if (clearOverlays) {
      this.map.clearOverlays(); // 清除地图上所有覆盖物
    }
    this.map.centerAndZoom(new BMap.Point(labels[0].point[0], labels[0].point[1]), labels[0].zoom || 5);
    labels.forEach(lbl => {
      const opts = {
        position: new BMap.Point(lbl.point[0], lbl.point[1]),    // 指定文本标注所在的地理位置
        // offset: new BMap.Size(30, -30),    //设置文本偏移量
      }

      var label = new BMap.Label(lbl.text, opts);  // 创建文本标注对象
      label.setStyle(lbl.style);

      label.addEventListener('mouseover', (evt) => { evt.domEvent.target.style.opacity = 0.6; });
      label.addEventListener('mouseout', (evt) => { evt.domEvent.target.style.opacity = 1; })

      this.map.addOverlay(label);
    });
  }

  private refreshHereMapCircleLable(labels: any[], clearOverlays?: boolean) {
    const objs = this.map.getObjects();
    this.map.removeObjects(objs);

    this.map.setCenter({
      lng: labels[0].point[0],
      lat: labels[0].point[1]
    });
    // Add a marker for each location found
    for (let i = 0; i < labels.length; i++) {
      const point: number[] = labels[i].point;
      const position = {
        lng: point[0],
        lat: point[1]
      };
      const marker = this.createDomMarker(position, labels[i]);
      this.map.addObject(marker);
    }
  }

  public createDomMarker(position, option: any): any {
    const outerElement = document.createElement('div');
    const innerElement = document.createElement('label');

    outerElement.style.userSelect = 'none';
    outerElement.style.cursor = 'default';

    innerElement.style.color = 'red';

    innerElement.style.paddingTop = '2px';
    innerElement.style.paddingLeft = '4px';



    // add negative margin to inner element
    // to move the anchor to center of the div
    innerElement.style.marginTop = '-10px';
    innerElement.style.marginLeft = '-10px';

    var style = option.style;

    Object.keys(style).forEach(function (k) {
      innerElement.style[k] = style[k];
    })
    outerElement.appendChild(innerElement);

    // Add text to the DOM element
    innerElement.innerHTML = option.text;

    function changeOpacity(evt) {
      evt.target.style.opacity = 0.6;
    };

    function changeOpacityToOne(evt) {
      evt.target.style.opacity = 1;
    };

    //create dom icon and add/remove opacity listeners
    const domIcon = new H.map.DomIcon(outerElement, {
      // the function is called every time marker enters the viewport
      onAttach: function (clonedElement, domIcon, domMarker) {
        clonedElement.addEventListener('mouseover', changeOpacity);
        clonedElement.addEventListener('mouseout', changeOpacityToOne);
      },
      // the function is called every time marker leaves the viewport
      onDetach: function (clonedElement, domIcon, domMarker) {
        clonedElement.removeEventListener('mouseover', changeOpacity);
        clonedElement.removeEventListener('mouseout', changeOpacityToOne);
      }
    });

    // Marker for Chicago Bears home
    return new H.map.DomMarker(position, { icon: domIcon });
  }



  // for here map
  public bd09_to_wgs84(bd09Points: Array<any[]>): Array<any[]> {
    const wgs84Points: Array<any[]> = [];
    if (bd09Points && bd09Points.length) {
      bd09Points.forEach((p) => {
        let p2: number[] = coordtransform.bd09togcj02(p[0], p[1]);
        p2 = coordtransform.gcj02towgs84(p2[0], p2[1]);
        wgs84Points.push(p2);
      });
    }
    return wgs84Points;
  }



  public showNavigationControl() {
    if (this.isBaidu) {
      this.map.addControl(new BMap.NavigationControl());
    }
  }


  public enableLocalSearch(onSearchComplete): any {
    if (this.isBaidu) {
      this.searchService = new BMap.LocalSearch(this.map, {
        renderOptions: { map: this.map },
        onSearchComplete: onSearchComplete
      });
      this.searchService
    } else {
      this.searchService = this.platform.getSearchService();
    }

    this.searchService;
    return this.searchService;
  }


  public searchByText(text, onSearchComplete) {
    if (this.isBaidu) {
      this.searchService.search(text);
    } else {
      this.searchService.geocode({
        q: text
      }, (result) => {
        onSearchComplete(result);
      }, (e) => {
        console.debug(e);
      });
    }

  }

  public addEventListener(eventName: string, fn: Function) {
    this.map.addEventListener(eventName, fn);
  }

  public getGeocoder() {
    if (this.isBaidu) {
      return new BMap.Geocoder();
    } else {
      return {};
    }
  }

  // 监听地图盒子大小变化
  boxSize(mapId) {
    const erd = elementResizeDetectorMaker();
    erd.listenTo(document.getElementById(mapId), element => {
      const width = element.offsetWidth;
      console.debug(width);

      this.map.getViewPort().resize()
    });
    window.addEventListener('resize', () => this.map.getViewPort().resize());
  }

}
