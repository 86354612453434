import { Component } from '@angular/core';
import { LeftPanelService } from '../services/left-panel.service';

@Component({
  selector: 'lfwms-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
/*@author: abidhmuhsinp
*/
export class HeaderComponent {

  constructor(public leftPanelService: LeftPanelService) { }

}
