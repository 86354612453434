import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ColInfo } from 'xlsx';
import { catchError, map } from 'rxjs/operators';
import { AlertService , ResourceService } from 'sce-core'


@Injectable()
export class FileUtilityService {

    constructor(public http$: HttpClient, public alertService: AlertService, public resourceService:ResourceService) { }

    /**
     * This is a utility method to upload single file to a given upload path
     */
    public uploadFile(file: File, fileUploadPath: string) {
        // const headers = new Headers();
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' }) };
        const formData = new FormData();
        formData.append('file', file);
        const response$: any = this.http$.post(fileUploadPath, formData, options).pipe(
            map((response: any) => {
              return response;
          }),
          catchError((error: any) => {
            this.alertService.error(error.message);
            return this.resourceService.get(error.message);
      
          }),);
          return response$
    }
    /**
     * This is a utility method to upload multiple files to a given upload path
     */
    public uploadFiles(files: File[], fileUploadPath: string) {
        // const headers = new Headers();
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' }) };
        const formData = new FormData();
        for (const file of files) {
            formData.append('file', file);
        //    console.debug(file);
        }
        const response$: any = this.http$.post(fileUploadPath, formData, options).pipe(
            map((response: any) => {
              return response;
          }),
          catchError((error: any) => {
            this.alertService.error(error.message);
            return this.resourceService.get(error.message);
      
          }),);
          return response$
    }

    /**
     * This is a utility method to download file from the given path
     */
    public downloadFile(fileDownloadPath: string) {
        const response$: any = this.http$.get('file' + fileDownloadPath, { responseType: 'blob' }).pipe(
            map((response: any) => {
              return response;
          }),
          catchError((error: any) => {
            this.alertService.error(error.message);
            return this.resourceService.get(error.message);
      
          }),);
          return response$
    }

    /**
     * This method saves the given file to the local drive. It currently wraps the file saver library
    */
    public saveFileLocally(file: any, fileName?: string) {
        FileSaver.saveAs(file, fileName);
    }

    /**
     * This method saves the given file to the local drive. It currently wraps the file saver library
    */
    public saveAsFile(items: any[], fileName: string) {
        const exportItem = items;
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportItem);
        const colInfos: ColInfo[] = [];
        Object.keys(exportItem[0]).forEach(col => colInfos.push({wpx: 200}));
        worksheet['!cols'] = colInfos;
        const workbook: XLSX.WorkBook = { Sheets: { 'Sheet0': worksheet }, SheetNames: ['Sheet0'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const file = new File([excelBuffer], fileName, { 
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
         });
        FileSaver.saveAs(file, fileName);
    }
}
