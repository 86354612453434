import { SORT_ORDER_ASC, SEARCH_DEFAULT_PAGE_SIZE } from '../search-constants';

export class PageDefinition {

  public tableId: string = '';
  public pageNumber: number;
  public recordCount: number;
  public sortColumn: string;
  public sortOrder: number;
  public pageSize: number;
  /* [LFWM-1956] - for Inventory Screen - fetching aggregateRecords from loadbyPage response to show aggregate of some columns
    for records which is present in server side [this aggregateRecords used only in Inventory Screen ] */
  public aggregateRecords: any = {}; //

  public constructor() {
    this.reset();
  }

  public reset() {
    this.pageNumber = 1;
    this.recordCount = 0;
    this.pageSize = SEARCH_DEFAULT_PAGE_SIZE;
    this.sortOrder = 0;
    this.sortColumn = null;
  }
}
