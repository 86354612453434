import { Component, OnDestroy } from '@angular/core';
import { SharedServiceRegistry } from './../../../shared-service-registry';
import { CoreServiceRegistry } from './../../../../core/core-service-registry';
import { ResourceService, AlertService, ConfirmDialogService } from 'sce-core';
import { SearchService } from './../../../search/search.service';
import { SearchParams } from './../../../search/models/search-params.model';
import { SearchStatus } from './../../../search/models/search-status.model';
import { Subject ,  Observable } from 'rxjs';
import { ButtonItem } from '../../../button/button.model';
import { DataTableEditorService } from '../../../search/results/data-table-editor.service';
import { DataTableConfig } from '../../../search/models/data-table-config';
import { ValidatorService } from '../../../services/validator.service';
import { SearchableEntityDataEditorService } from '../../../services/searchable-entity/searchable-entity-data-editor.service';

@Component({
  selector: 'lfwms-view-transactions-popup',
  templateUrl: './show-task-popup.component.html',
  styleUrls: ['./show-task-popup.component.css']
})
export class ShowTaskPopupComponent implements OnDestroy {

  public lookupAlertService: AlertService;
  // Two searchServices are used one for asntd table and other for asndetailtd table
  public lookupSearchService: SearchService;
  public eventSubject = new Subject<any>();
  public parentDataService: any;
  public dataEditor: any;
  public isLoading: boolean = true;
  public searchService: SearchService;
  public resourceService: ResourceService;
  public confirmDialogService: ConfirmDialogService;
  public buttonArray: Array<ButtonItem>;
  public tableId: string;
  public callbackFunction: any;
  public parentRef: any;
  public validatorService: ValidatorService;

  constructor(public coreServiceRegistry: CoreServiceRegistry,
    public sharedServiceRegistry: SharedServiceRegistry) {
    this.lookupAlertService = this.sharedServiceRegistry.alertService;
    this.confirmDialogService = this.sharedServiceRegistry.confirmDialogService;
    this.eventSubject = new Subject<any>();
    this.resourceService = this.coreServiceRegistry.resourceService;
    this.validatorService = new ValidatorService(this.lookupAlertService, this.resourceService);
    this.buttonArray = [
      new ButtonItem('Edit', this.resourceService.get('btn_edit'), 'jm_task_edit', 'icon_edit.svg', []),
      new ButtonItem('Save', this.resourceService.get('btn_save'), 'jm_task_save', 'icon_save.svg', []),
    ];
  }

  public initialize(dataService: string, searchCriteria: any) {
    /* Data editor is needed  for searching in tableheader filter */
    this.parentDataService = dataService;
    this.lookupSearchService = this.createSearchService(this.tableId);
    const tableEditor: SearchableEntityDataEditorService = new SearchableEntityDataEditorService(this.coreServiceRegistry,
      this.sharedServiceRegistry);
    this.dataEditor = tableEditor;
    this.lookupSearchService.searchParams.tableEditor = tableEditor;
    this.lookupSearchService.fetchMetadata().subscribe(() => {
      this.lookupSearchService.setSearchCriteria(searchCriteria);
      this.lookupSearchService.performSearch();
    });
    this.enableButton('Edit');
  }

  public createSearchService(tableId: string): SearchService {
    const searchParams = new SearchParams();
    searchParams.tableId = tableId;
    searchParams.isFullFetch = true;
    searchParams.columnCount = 10;
    searchParams.defaultSearchColumn = '';
    const tableConfig: DataTableConfig = new DataTableConfig();
    tableConfig.editable = true;
    tableConfig.supportFiltering = true;
    tableConfig.supportFiltering = true;
    searchParams.dataTableConfig = tableConfig;
    searchParams.alertService = this.lookupAlertService;
    searchParams.actionButtons = this.buttonArray;
    searchParams.tableEditor = this.dataEditor;
    const searchStatus = new SearchStatus();
    const searchService = this.sharedServiceRegistry.createSearchService(searchParams, searchStatus);
    return searchService;
  }

  public registerCallback(callback: any, parentRef: any) {
    this.callbackFunction = callback;
    this.parentRef = parentRef;
  }

  public ngOnDestroy() {
    // Memory Cleanup
    this.searchService = null;
  }

  public handleButtonAction(action: string) {
    if (action === 'Edit') {
      this.lookupSearchService.setDisplayMode('E');
      this.enableButton('Save');
      // this.lookupSearchService.searchParams.actionButtons = this.buttonArrayForEditMode;
    } else if (action === 'Save') {
      const lineItems = this.lookupSearchService.getEditedRows();
      let requestItems: Array<any>;
      // adding the warning so as to avoid saving when no modifications are done to the entity and trying to save.
      if (lineItems.length === 0) {
        const errorMsg: string = 'msg_no_mod';
        this.lookupAlertService.clearAll().warn(this.resourceService.get(errorMsg));
        this.lookupSearchService.clearMarkerFlags();
        this.lookupSearchService.setDisplayMode('V');
        this.enableButton('Edit');
        return;
      }
      // format the modified line items in the required request format
      requestItems = this.getCellValuesList(lineItems);
      // added to validate mandatory fields
      if (this.validateUserEnteredData()) {
        const saveObj: Observable<any> = this.callbackFunction(this.parentRef, requestItems);
        if (saveObj) {
          saveObj.subscribe((response) => {
            if (response.statusCode === 200) {
              // If there are line items in details page
              if (this.lookupSearchService.pagedData.pageRows.length > 0) {
                this.lookupSearchService.clearMarkerFlags();

              }
              const successMsg = 'msg_save_succ';
              this.lookupAlertService.clearAll().success(this.resourceService.get(successMsg));
              this.lookupSearchService.setDisplayMode('V');
              this.enableButton('Edit');
            } else {
              this.lookupAlertService.clearAll();
              this.lookupAlertService.error(this.resourceService.get(response.statusMessage));
            }
          }, (error) => {
            this.lookupAlertService.clearAll().error(this.resourceService.get(error));
          });
        }
      }
    }
  }

  public enableButton(buttonId: string) {
    const showEdit = buttonId === 'Edit' ? true : false;
    this.buttonArray[0].isEnabled = showEdit;
    this.buttonArray[0].isVisible = showEdit;
    this.buttonArray[1].isEnabled = !showEdit;
    this.buttonArray[1].isVisible = !showEdit;
  }

  public getCellValuesList(modifiedLineItems) {
    // const primaryIdProperty = this.lookupSearchService.searchParams.idProperty;
    const requestList: Array<any> = [];
    let selectedLineItem: any;
    modifiedLineItems.forEach((row: any, index: any) => {
      selectedLineItem = [];
      const isNewItem: boolean = (row.cells['isNewItem'] && row.cells['isNewItem'].cVal) ? true : false;
      let typeName = '';
      if (!isNewItem) {
        typeName = 'original_';
      }
      Object.keys(row.cells).forEach((property: any) => {
        const cell = row.cells[property];
        if ((cell.cValOrig !== undefined) && (cell.cValOrig !== cell.cVal)) {
          selectedLineItem.push({ cName: typeName + property, cVal: cell.cValOrig });
          selectedLineItem.push({ cName: property, cVal: cell.cVal });
        } else {
          selectedLineItem.push({ cName: typeName + property, cVal: cell.cVal });
        }
      });
      const isNewItemObj: any = {
        'cName': 'isNewItem',
        'cVal': false
      };
      selectedLineItem.push(isNewItemObj);
      const request = {
        'moduleProperties': selectedLineItem,
        'screenType': this.lookupSearchService.searchParams.tableId
      };
      requestList.push(request);
    });


    return requestList;
  }

  public validateUserEnteredData() {
    // added to validate the fields of line items
    const validateLineItemsErr = this.validatorService.validateMultipleRows(this.lookupSearchService.
      getMetadata(), this.lookupSearchService.getPagedData());
    if (validateLineItemsErr) {
      return false;
    } else {
      return true;
    }
  }
}
