import { sceconfig } from 'sce-core';

export const environment = {
  production: false,
  logger: 'console',
  baseUrlUI : sceconfig.wms.baseUrlUI,
 // wmsApiBaseUrl: 'https://ofsce-dev.lflogistics.net/scecommon/',  // localhost envrionment
   wmsApiBaseUrl: 'https://ofsce-sit.lflogistics.net/scecommon/', // sit envrionment
  // wmsApiBaseUrl: 'https://ofsce-uat.lflogistics.net/scecommon/', // uat envrionment

//  notificationApiBaseUrl : 'https://ofscetms-uat.lflogistics.net/api' // uat envrionment
  notificationApiBaseUrl : 'https://ofscetms-sit.lflogistics.net/api' // sit envrionment
 // notificationApiBaseUrl: 'https://ofscetms-dev.lflogistics.net/api' // localhost envrionment
};
