import { Injectable } from '@angular/core';
import { ButtonFactoryService } from '../../button/button-factory.service';
import { NavigationService, ResourceService } from 'sce-core';
import { ButtonItem } from '../../button/button.model';
import { ConfirmDialogService } from 'sce-core';
import { AlertService } from 'sce-core';
import { SearchableEntityActionService } from './searchable-entity-action.service';
import { SearchableEntityDataService } from './searchable-entity-data.service';
import { PrinterService } from '../printer.service';
import { ModalService } from '../modal.service';
import { CoreServiceRegistry } from '../../../core/core-service-registry';
import { SharedServiceRegistry } from '../../shared-service-registry';

@Injectable()
export class SearchableEntityButtonService {

  public buttonFactoryService: ButtonFactoryService;
  public allButtonsMap: Map<string, Array<ButtonItem>>;
  public userButtonMap: Map<string, Array<ButtonItem>>;
  public dataService: SearchableEntityDataService;
  public actionService: SearchableEntityActionService;
  public resourceService: ResourceService;
  public confirmDialogService: ConfirmDialogService;
  public modalService: ModalService;
  public alertService: AlertService;
  public navService: NavigationService;
  public printerService: PrinterService;

  public searchMainButtons: Array<ButtonItem>;
  public searchTableButtons: Array<ButtonItem>;

  constructor(public coreServiceRegistry: CoreServiceRegistry,
    public sharedServiceRegistry: SharedServiceRegistry) {
    this.buttonFactoryService = sharedServiceRegistry.buttonFactoryService;
    this.resourceService = coreServiceRegistry.resourceService;
    this.navService = coreServiceRegistry.navigationService;
    this.confirmDialogService = sharedServiceRegistry.confirmDialogService;
    this.alertService = sharedServiceRegistry.alertService;
    this.printerService = sharedServiceRegistry.printerService;
    this.modalService = sharedServiceRegistry.sceModalService;
    this.allButtonsMap = this.getAllButtons();
    this.loadButtonsForUser();

    // initialize button panels
    this.searchMainButtons = this.userButtonMap['search-main'];
    this.searchTableButtons = this.userButtonMap['search-table'];
  }

  public setDependentServices(dataService, actionService) {
    this.dataService = dataService;
    this.actionService = actionService;
  }

  public getButtonsForPanel(panelId: string) {
    return this.userButtonMap[panelId];
  }

  /**
   * Default implementation
   */
  public loadButtonsForUser() {
    this.userButtonMap = new Map<string, Array<ButtonItem>>();
    if (this.allButtonsMap['search-main']) {
      this.userButtonMap['search-main'] = this.buttonFactoryService.loadButtonsByPrivilege(this.allButtonsMap['search-main']);
    } else {
      this.userButtonMap['search-main'] = [];
    }
    if (this.allButtonsMap['search-table']) {
      this.userButtonMap['search-table'] = this.buttonFactoryService.loadButtonsByPrivilege(this.allButtonsMap['search-table']);
    } else {
      this.userButtonMap['search-table'] = [];
    }
  }

  /**
   * Default implementation
   */
  public getAllButtons(): any {
    return {};
  }

  /**
   * Default implementation
   */
  public performButtonActions(action: any) {
  }

  /**
   * Default implementation
   */
  public printReport() {
  }

  /**
   * Default implementation
   */
  public initializeButtons(cellDataMap?: any) {
  }

  public initializeSearchPageButtons(cellDataMap?: any) {
  }

  /**
   * Default implementation
   */
  public rebuildButtonArray(buttons: Array<ButtonItem>, displayedActions: Array<string>,
    disabledActions?: Array<string>) {
   this.buttonFactoryService.rebuildButtonArray(buttons, displayedActions);
  }

  /**
   * Default implementation
   */
  public enableAllButtons(buttons: Array<ButtonItem>) {
    this.buttonFactoryService.enableAllButtons(buttons);
  }
}
