
import {throwError as observableThrowError,  Observable ,  Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { SharedServiceRegistry } from './../../../shared-service-registry';
import { CoreServiceRegistry } from './../../../../core/core-service-registry';
import { ResourceService } from 'sce-core';
import { SearchService } from './../../../search/search.service';
import { SearchParams } from './../../../search/models/search-params.model';
import { SearchStatus } from './../../../search/models/search-status.model';
import { ViewBalancesCellRendererComponent } from './view-balances-cell-renderer/view-balances-cell-renderer.component';
import { LotBalanceData } from '../../../models/lot-balance.model';
import { LotLocMovableUnitData } from '../../../models/lotlocmovableunit-data.model';
import { CommodityLocData } from '../../../models/commodityloc-data.model';
import { DataTableConfig } from '../../../search/models/data-table-config';
import { NEW_SEARCH_EVENT } from '../../../search/search-constants';
import { REFRESH_SEARCH_EVENT } from './../../../search/search-constants';
import { SearchEvent } from './../../../search/models/search-event';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'lfwms-view-balances-popup',
  templateUrl: './view-balances-popup.component.html',
  styleUrls: ['./view-balances-popup.component.css']
})
export class ViewBalancesPopupComponent implements OnInit, OnDestroy {

  public http$: any;
  public searchParams: SearchParams;
  public searchStatus: SearchStatus;
  public searchCriteria: any;
  public eventSubscription: Subscription;
  public defaultClauses: any;
  public isLoading: boolean = true;
  public searchService: SearchService;
  public resourceService: ResourceService;
  public showZeroBalances: boolean;
  public disableZeroBalanceToggle: boolean = false;

  // Dynamic Inputs (set programatically, not via template)
  public balanceType: string = 'C';
  public storerKey: string;
  public sku: string;
  /* Dynamic Inputs (set via template , not programatically)
  * passed from parent component instead of assigning values
  * using modelRef from the parent component Refer - Pickmanagement Popup Component */
  @Input() inputConfig: any;

  constructor(coreServiceRegistry: CoreServiceRegistry,
      public sharedServiceRegistry: SharedServiceRegistry) {
    this.showZeroBalances = false;
    this.disableZeroBalanceToggle = false;
    this.http$ = coreServiceRegistry.http$;
    this.resourceService = coreServiceRegistry.resourceService;
  }

  public getLabel(key: any) {
    return this.resourceService.get(key);
  }

  public ngOnInit() {
    // if the inputConfig exist then assign a values to storerKey, sku, balanceType
    if (this.inputConfig) {
      this.storerKey = this.inputConfig.storerKey;
      this.sku = this.inputConfig.sku;
      this.balanceType = this.inputConfig.balanceType;
      // need's to call initialize method in ngOnInit when using inputConfig
      this.initialize();
    }
    this.eventSubscription = this.searchService.receiveEvent()
      .subscribe(($event: SearchEvent) => {
        if ($event.type === NEW_SEARCH_EVENT) {
          this.disableZeroBalanceToggle = false;
          const pinnedPayload: any = this.getPinnedPayLoad(this.balanceType);
          const enrichPayload: any = (data: any) => {
            if (this.balanceType === 'C') {
              pinnedPayload['qtyLocationLimit']['cVal']
                = data['totalCapacityUnits'] ? +data['totalCapacityUnits'] : '0';
              pinnedPayload['qtyLocationMinimum']['cVal']
                = data['totalCapacityMinimum'] ? +data['totalCapacityMinimum'] : '0';
              pinnedPayload['qty']['cVal']
                = data['qty'] ? +data['totalQty'] : '0';
              pinnedPayload['qtyAllocated']['cVal']
                = data['totalQtyAllocated'] ? +data['totalQtyAllocated'] : '0';
              pinnedPayload['qtyPicked']['cVal']
                = data['totalQtyPicked'] ? +data['totalQtyPicked'] : '0';
              pinnedPayload['qtyExpected']['cVal']
                = data['totalQtyExpected'] ? +data['totalQtyExpected'] : '0';
              pinnedPayload['qtyPickInProcess']['cVal']
                = data['totalQtyPickInProgress'] ? +data['totalQtyPickInProgress'] : '0';
            } else if (this.balanceType === 'L') {
              pinnedPayload['caseCnt']['cVal']
                = data['totalCaseCount'] ? +data['totalCaseCount'] : '0';
              pinnedPayload['cube']['cVal']
                = data['totalCube'] ? +data['totalCube'] : '0';
              pinnedPayload['grossWgt']['cVal']
                = data['totalGrossWeight'] ? +data['totalGrossWeight'] : '0';
              pinnedPayload['innerPack']['cVal']
                = data['totalInnerPack'] ? +data['totalInnerPack'] : '0';
              pinnedPayload['netWgt']['cVal']
                = data['totalNetWeight'] ? +data['totalNetWeight'] : '0';
              pinnedPayload['otherUnit1']['cVal']
                = data['totalOtherUnit1'] ? +data['totalOtherUnit1'] : '0';
              pinnedPayload['otherUnit2']['cVal']
                = data['totalOtherUnit2'] ? +data['totalOtherUnit2'] : '0';
              pinnedPayload['pallet']['cVal']
                = data['totalPallet'] ? +data['totalPallet'] : '0';
              pinnedPayload['qty']['cVal']
                = data['totalQty'] ? +data['totalQty'] : '0';
              pinnedPayload['qtyAllocated']['cVal']
                = data['totalQtyAllocated'] ? +data['totalQtyAllocated'] : '0';
              pinnedPayload['qtyPreAllocated']['cVal']
                = data['totalQtyPreAllocated'] ? +data['totalQtyPreAllocated'] : '0';
            } else {
              pinnedPayload['qty']['cVal']
                = data['qty'] ? +data['totalQty'] : '0';
              pinnedPayload['qtyAllocated']['cVal']
                = data['totalQtyAllocated'] ? +data['totalQtyAllocated'] : '0';
              pinnedPayload['qtyPicked']['cVal']
                = data['totalQtyPicked'] ? +data['totalQtyPicked'] : '0';
              pinnedPayload['qtyExpected']['cVal']
                = data['totalQtyExpected'] ? +data['totalQtyExpected'] : '0';
              pinnedPayload['qtyPickInProcess']['cVal']
                = data['totalQtyPickInProgress'] ? +data['totalQtyPickInProgress'] : '0';
            }
          };
          if (this.balanceType === 'C') {
            this.getAllCommodityLocData()
              .subscribe((responseDatas: CommodityLocData) => {
                enrichPayload(responseDatas);
                this.searchService.getPagedData().pinnedPageRows = [pinnedPayload];
                this.searchService.publishEvent(new SearchEvent(REFRESH_SEARCH_EVENT));
              });
          } else if (this.balanceType === 'L') {
            this.getAllLotBalance()
              .subscribe((responseData: LotBalanceData) => {
                enrichPayload(responseData);
                this.searchService.getPagedData().pinnedPageRows = [pinnedPayload];
                this.searchService.publishEvent(new SearchEvent(REFRESH_SEARCH_EVENT));
              });
          } else {
            this.getAllLotLocMUBalance()
              .subscribe((respData: LotLocMovableUnitData) => {
                enrichPayload(respData);
                this.searchService.getPagedData().pinnedPageRows = [pinnedPayload];
                this.searchService.publishEvent(new SearchEvent(REFRESH_SEARCH_EVENT));
              });
          }
        }
      });
  }

  public getPinnedPayLoad(balanceType) {
    let pinnedPayLoad = {};
    if (balanceType === 'C') {
      pinnedPayLoad = {
        isSelected: false,
        locationType: {
          cName: 'locationType',
          cVal: ''
        },
        loc: {
          cName: 'loc',
          cVal: ''
        },
        qtyLocationLimit: {
          cName: 'qtyLocationLimit',
          cVal: '0'
        },
        qtyLocationMinimum: {
          cName: 'qtyLocationMinimum',
          cVal: '0'
        },
        qtyReplenishmentOverride: {
          cName: 'qtyReplenishmentOverride',
          cVal: ''
        },
        qty: {
          cName: 'qty',
          cVal: '0'
        },
        qtyAllocated: {
          cName: 'qtyAllocated',
          cVal: '0'
        },
        qtyPicked: {
          cName: 'qtyPicked',
          cVal: '0'
        },
        qtyExpected: {
          cName: 'qtyExpected',
          cVal: '0'
        },
        qtyPickInProcess: {
          cName: 'qtyPickInProcess',
          cVal: '0'
        },
        replenishmentPriority: {
          cName: 'replenishmentPriority',
          cVal: ''
        },
        lot: {
          cName: 'lot',
          cVal: ''
        },
        qtyPreAllocated: {
          cName: 'qtyPreAllocated',
          cVal: ''
        },
        id: {
          cName: 'id',
          cVal: ''
        }
      };
    } else if (balanceType === 'L') {
      pinnedPayLoad = {
        isSelected: false,
        lot: {
          cName: 'lot',
          cVal: ''
        },
        qty: {
          cName: 'qty',
          cVal: '0'
        },
        qtyPreAllocated: {
          cName: 'qtyPreAllocated',
          cVal: ''
        },
        qtyAllocated: {
          cName: 'qtyAllocated',
          cVal: '0'
        },
        qtyPicked: {
          cName: 'qtyPicked',
          cVal: '0'
        },
        caseCnt: {
          cName: 'caseCnt',
          cVal: '0'
        },
        innerPack: {
          cName: 'innerPack',
          cVal: '0'
        },
        pallet: {
          cName: 'pallet',
          cVal: '0'
        },
        cube: {
          cName: 'cube',
          cVal: '0'
        },
        grossWgt: {
          cName: 'grossWgt',
          cVal: '0'
        },
        netWgt: {
          cName: 'netWgt',
          cVal: '0'
        },
        otherUnit1: {
          cName: 'otherUnit1',
          cVal: '0'
        },
        otherUnit2: {
          cName: 'otherUnit2',
          cVal: '0'
        }
      };
    } else {
      pinnedPayLoad = {
        isSelected: false,
        lot: {
          cName: 'lot',
          cVal: ''
        },
        loc: {
          cName: 'loc',
          cVal: ''
        },
        id: {
          cName: 'id',
          cVal: ''
        },
        qty: {
          cName: 'qty',
          cVal: '0'
        },
        qtyAllocated: {
          cName: 'qtyAllocated',
          cVal: '0'
        },
        qtyPicked: {
          cName: 'qtyPicked',
          cVal: '0'
        },
        qtyExpected: {
          cName: 'qtyPqtyExpectedicked',
          cVal: '0'
        },
        qtyPickInProcess: {
          cName: 'qtyPickInProcess',
          cVal: '0'
        },
        lottable01: {
          cName: 'lottable01',
          cVal: ''
        },
        lottable02: {
          cName: 'lottable02',
          cVal: ''
        },
        lottable03: {
          cName: 'lottable03',
          cVal: ''
        },
        lottable04: {
          cName: 'lottable04',
          cVal: ''
        },
        lottable05: {
          cName: 'lottable05',
          cVal: ''
        },
        lottable06: {
          cName: 'lottable06',
          cVal: ''
        },
        lottable07: {
          cName: 'lottable07',
          cVal: ''
        },
        lottable08: {
          cName: 'lottable08',
          cVal: ''
        },
        lottable09: {
          cName: 'lottable09',
          cVal: ''
        },
        lottable10: {
          cName: 'lottable10',
          cVal: ''
        },
        lottable11: {
          cName: 'lottable11',
          cVal: ''
        },
        lottable12: {
          cName: 'lottable12',
          cVal: ''
        },
        lottable13: {
          cName: 'lottable13',
          cVal: ''
        },
        lottable14: {
          cName: 'lottable14',
          cVal: ''
        },
        lottable15: {
          cName: 'lottable15',
          cVal: ''
        },
      };
    }
    return pinnedPayLoad;
  }

  public initialize() {
    this.searchParams = new SearchParams();
    this.searchParams.dataTableConfig = new DataTableConfig();
    this.searchStatus = new SearchStatus();
    const pinnedColumnStart = this.balanceType === 'C' ? 'locationType' : 'lot';
    this.searchParams.dataTableConfig.columnPinnedRendererMapping[pinnedColumnStart]
      = ViewBalancesCellRendererComponent;
    this.defaultClauses = [{
      column: 'storerKey',
      operation: '=',
      value: this.storerKey,
      logicalOperation: null
    }, {
      column: 'sku',
      operation: '=',
      value: this.sku,
      logicalOperation: 'AND'
    }];
    if (this.balanceType === 'C') {
      this.searchParams.tableId = 'locbaltd';
    } else if (this.balanceType === 'L') {
      this.searchParams.tableId = 'lotbaltd';
    } else if (this.balanceType === 'M') {
      this.searchParams.tableId = 'movbaltd';
    }
    this.searchService = this.sharedServiceRegistry
      .createSearchService(this.searchParams, this.searchStatus);
    this.searchCriteria = {
      conditions: [
        {
          mandatory: false,
          clauses: null,
          logicalOperation: null
        }
      ]
    };
    this.searchService.setSearchCriteria(this.searchCriteria);
    this.searchService.fetchMetadata()
      .subscribe(() => {
        this.isLoading = false;
        this.toggleZeroBalances(true);
      });
  }

  public toggleZeroBalances(skipToggle?: boolean) {
    this.disableZeroBalanceToggle = true;
    if (!skipToggle) {
      this.showZeroBalances = !this.showZeroBalances;
    }
    this.searchCriteria.conditions[0].clauses = [];
    this.searchCriteria.conditions[0].clauses.push(...this.defaultClauses);
    if (!this.showZeroBalances) {
      this.searchCriteria.conditions[0].clauses.push({
        column: 'qty',
        operation: '>',
        value: 0,
        logicalOperation: 'AND'
      });
      if ((this.balanceType !== 'L')) {
        this.searchCriteria.conditions[0].clauses.push({
          column: 'qtyExpected',
          operation: '>',
          value: 0,
          logicalOperation: 'AND'
        });
      }
    }
    this.searchService.performSearch();
  }

  public getAllCommodityLocData(): Observable<CommodityLocData> {
    const response$: any = this.http$
      .get(`api/asn/` + this.storerKey + `/` + this.sku + `/loc/balance`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
              return observableThrowError(error.error.statusMessage);
          }
        }
      }));
    return response$;
  }

  public getAllLotBalance(): Observable<LotBalanceData> {
    const response$: any = this.http$
      .get(`api/asn/`  + this.storerKey  + `/` + this.sku + `/lot/balance`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((errors: any) => {
        if (errors.error.length !== 0) {
          if (errors.error.statusCode !== 200) {
            return observableThrowError(errors.error.statusMessage);
          }
        }
      }));
    return response$;
  }

  public getAllLotLocMUBalance(): Observable<LotLocMovableUnitData> {
    const response$: any = this.http$
      .get(`api/asn/` + this.storerKey + `/` + this.sku + `/LotLocMovable/balance`).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        if (err.error.length !== 0) {
            if (err.error.statusCode !== 200) {
                return observableThrowError(err.error.statusMessage);
            }
        }
      }));
    return response$;
  }

  public ngOnDestroy() {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
    // Memory cleanup
    this.searchService = null;
    this.showZeroBalances = false;
  }

}
