export const INITIALBREADEVENT = [
    { id: 'home', displayValue: 'Home', route: '/home' },
    { id: 'tms', displayValue: 'TMS', route: undefined },
    { id: 'manual_update', displayValue: 'Manual Update', route: undefined },
    { id: 'order_event', displayValue: 'Order Event', route: '/home/order/order-event' }
]

export const FETCH_DASHBOARD_DETAILS = {
    'RG': {code: 'RG', id: 2223, notificationId: '8', moduleId: 'Recipient Group', pageTitle: 'Receipient Group', tableId: 'recipgroupsearchtd'},
    'OD': {code: 'OD', id: 2225, notificationId: '8', moduleId: 'NotificationRecipient', pageTitle: 'mn_operation_notification', tableId: 'recipgroupsearchtd'}
};

export const metaDataOrderGrpTable = {
    'elementId': 'RECIPIENT_GROUP_TB',
    'columnList': [
    {
        'id': '1001',
        'elementId': 'lbl_tms_principal_id',
        'propertyName': 'Principal',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 1,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Principal',
    },
    {
        'id': '1001',
        'elementId': 'lbl_tms_origin_location_id',
        'propertyName': 'Source Location ID',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 1,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Source Location ID',
    },
    {
        'id': '1002',
        'elementId': 'lbl_tms_source_order_id',
        'propertyName': 'Order Source ID',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 2,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Order Source ID',
    },
    {
        'id': '1003',
        'elementId': 'lbl_tms_shipment_xid',
        'propertyName': 'Shipment ID',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 3,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Shipment ID',
    },
    {
        'id': '1004',
        'elementId': 'lbl_tms_customer_ref_no',
        'propertyName': 'Customer Ref No',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 4,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Customer Ref No',
    },
    {
        'id': '1005',
        'elementId': 'lbl_tms_invoice_no',
        'propertyName': 'Invoice No',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 5,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Invoice No',
    },
    {
        'id': '1006',
        'elementId': 'lbl_tms_tracking_no',
        'propertyName': 'Tracking No',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 6,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Tracking No',
    },
    {
        'id': '1007',
        'elementId': 'lbl_tms_customer_po_no',
        'propertyName': 'Customer Po No',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 7,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Customer Po No',
    },
    {
        'id': '1008',
        'elementId': 'lbl_tms_destination_state',
        'propertyName': 'Destination State',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 8,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Destination State',
    },
    {
        'id': '1009',
        'elementId': 'lbl_tms_destination_city',
        'propertyName': 'Destination City',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 9,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Destination City',
    },
    {
        'id': '1010',
        'elementId': 'lbl_tms_planned_start_date',
        'propertyName': 'Planned Start Date',
        'type': 'DATETIME',
        'defaultVal': '',
        'defaultOp': 'between',
        'isMandatory': true,
        'maxLength': 30,
        'index': 10,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Planned Start Date',
    },
    {
        'id': '1011',
        'elementId': 'lbl_tms_planned_delivery_date',
        'propertyName': 'Planned Delivery Date',
        'type': 'DATETIME',
        'defaultVal': '',
        'defaultOp': 'between',
        'isMandatory': true,
        'maxLength': 30,
        'index': 11,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Planned Delivery Date',
    },
    {
        'id': '1012',
        'elementId': 'lbl_tms_status',
        'propertyName': 'Status',
        'type': 'DROPDOWN',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 12,
        'priority': 'LOW',
        'toolTip': null,
        'values': [
            { 'key': '', 'value': '-Select-' }, 
            { 'key': 'Open', 'value': 'Open' }, 
            // { 'key': 'Planned', 'value': 'Planned' },
            // { 'key': 'In Transit', 'value': 'In Transit' },
            // { 'key': 'Deliveried', 'value': 'Deliveried' },
            // { 'key': 'Cancelled', 'value': 'Cancelled' },
            // { 'key': 'Pickup', 'value': 'Planned' },
            // { 'key': 'Failure', 'value': 'Failure' },
            // { 'key': 'Delivery Failure', 'value': 'Delivery Failure' },
            // { 'key': 'DC Handover', 'value': 'DC Handover' },
            // { 'key': 'Suspended', 'value': 'Suspended' },
            
        ],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Status',
    },
    {
        'id': '1013',
        'elementId': 'lbl_tms_order_movement_id',
        'propertyName': 'Order Movement ID',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 13,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Order Movement ID',
    },
    {
        'id': '1014',
        'elementId': 'lbl_tms_driver_name',
        'propertyName': 'Driver Name',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 14,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Driver Name',
    },
    {
        'id': '1015',
        'elementId': 'lbl_tms_carrier_id',
        'propertyName': 'Carrier ID',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 15,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Carrier ID',
    },
    {
        'id': '1016',
        'elementId': 'lbl_tms_destination_location_id',
        'propertyName': 'Location ID',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 16,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Location ID',
    },
    {
        'id': '1017',
        'elementId': 'lbl_tms_destination_location_segment',
        'propertyName': 'Segment',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 17,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Segment',
    },
    {
        'id': '1012',
        'elementId': 'lbl_tms_epod',
        'propertyName': 'ePOD',
        'type': 'DROPDOWN',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': false,
        'maxLength': 20,
        'index': 45,
        'priority': 'LOW',
        'toolTip': null,
        'values': [
            { 'key': '', 'value': '-Select-' }, 
            { 'key': 'IS NULL', 'value': 'Is Null' },
            { 'key': 'NOT NULL', 'value': 'Not Null' }, 
        ],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'ePOD'
    },
]
}

export const metaDataGrp = {
    'elementId': 'RECIPIENT_GROUP_TB',
    'columnList': [
        {
            'id': '1000',
            'elementId': 'Event Date',
            'propertyName': 'EventDate',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dataType': 'String',
        },
        {
            'id': '1001',
            'elementId': 'Events',
            'propertyName': 'Event',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
        },
        {
            'id': '1002',
            'elementId': 'Event Remark',
            'propertyName': 'EventRemark',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
        },
    ]
}

export const metaDataOrderEventGrpTable = {
    'elementId': 'TAG_TB',
    'columnList': [
        {
            'id': '1001',
            'elementId': 'lbl_tms_shipment_xid',
            'propertyName': 'shipmentId',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 1,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'shipmentId',
            'moduleId': 'GroupNameTd'
        },
        {
            'id': '1002',
            'elementId': 'lbl_tms_order_movement_id',
            'propertyName': 'orderMovementId',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 2,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'orderMovementId',
        },
        {
            'id': '1003',
            'elementId': 'lbl_tms_source_order_id',
            'propertyName': 'orderSourceId',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 3,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
        },
        {
            'id': '1004',
            'elementId': 'lbl_tms_principal_id',
            'propertyName': 'principal',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 4,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
        },
        {
            'id': '1005',
            'elementId': 'lbl_tms_customer_ref_no',
            'propertyName': 'customerRefNo',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 5,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
        },
        
        {
            'id': '1006',
            'elementId': 'lbl_tms_invoice_no',
            'propertyName': 'invoiceNo',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 7,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
        },
        {
            'id': '1007',
            'elementId': 'lbl_tms_tracking_no',
            'propertyName': 'trackingNo',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 8,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
        },
        {
            'id': '1008',
            'elementId': 'lbl_tms_customer_po_no',
            'propertyName': 'customerPONo',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 8,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
        },
        {
            'id': '1009',
            'elementId': 'lbl_tms_pickup_status',
            'propertyName': 'pickupStatus',
            'type': 'DROPDOWN',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 9,
            'priority': 'LOW',
            'toolTip': null,
            'values': [
                { 'key': '', 'value': '-Select-' }, 
                { 'key': 'CANC-Order Cancellation', 'value': 'CANC-Order Cancellation' }, 
                { 'key': 'AA-Attempt Pickup', 'value': 'AA-Attempt Pickup' },
                { 'key': 'AF-Picking up at LF DC', 'value': 'AF-Picking up at LF DC' },
                { 'key': 'XX-Failed Pickup', 'value': 'XX-Failed Pickup' },
                { 'key': 'TP-Transfer Pickup', 'value': 'TP-Transfer Pickup' },
                { 'key': 'RF-Transfer Out', 'value': 'RF-Transfer Out' },
                { 'key': 'TA-Tender Accepted', 'value': 'TA-Tender Accepted' },
            ],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
            'cellDataName': 'pickupStatus',
        },
        {
            'id': '1010',
            'elementId': 'lbl_tms_pickup_reason',
            'propertyName': 'pickupReason',
            'type': 'DROPDOWN',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 10,
            'priority': 'LOW',
            'toolTip': null,
            'values': [
                { 'key': '', 'value': '-Select-' }, 
                { 'key': 'Normal Status', 'value': 'Normal Status' }, 
                { 'key': 'Wrong Product', 'value': 'Wrong Product' },
                { 'key': 'Other', 'value': 'Other' },
                { 'key': 'Wrong Quantity', 'value': 'Wrong Quantity' },
            ],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
        },
        {
            'id': '1011',
            'elementId': 'lbl_tms_pickup_time',
            'propertyName': 'pickupTime',
            'type': 'DATETIME',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 11,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
        },
        {
            'id': '1012',
            'elementId': 'lbl_tms_delivery_status',
            'propertyName': 'deliveryStatus',
            'type': 'DROPDOWN',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 12,
            'priority': 'LOW',
            'toolTip': null,
            'values': [
                { 'key': '', 'value': '-Select-' }, 
                { 'key': 'D1-Delivered', 'value': 'D1-Delivered' }, 
                { 'key': 'A7-Rejected', 'value': 'A7-Rejected' },
                { 'key': 'CANC-Order Cancellation', 'value': 'CANC-Order Cancellation' },
                { 'key': 'TP-Transfer Pickup', 'value': 'TP-Transfer Pickup' },
                { 'key': 'TP-Transfer Out', 'value': 'TP-Transfer Out' },
                { 'key': 'TA-Tender Accepted', 'value': 'TA-Tender Accepted' },
            ],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
        },
        {
            'id': '1013',
            'elementId': 'lbl_tms_delivery_reason',
            'propertyName': 'deliveryReason',
            'type': 'DROPDOWN',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 13,
            'priority': 'LOW',
            'toolTip': null,
            'values': [
                { 'key': '', 'value': '-Select-' }, 
                { 'key': 'Normal Status', 'value': 'Normal Status' }, 
                { 'key': 'Partial Rejection', 'value': 'Partial Rejection' },
            ],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
        },
        {
            'id': '1014',
            'elementId': 'lbl_tms_delivery_time',
            'propertyName': 'deliveryTime',
            'type': 'DATETIME',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 14,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
        },
        // {
        //     'id': '1015',
        //     'elementId': 'ePOD image',
        //     'propertyName': 'ePOD image',
        //     'type': 'TEXTFIELD',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': true,
        //     'maxLength': 30,
        //     'index': 14,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [],
        //     'columnGroup': 'Primary',
        //     'displayType': 'U',
        //     'dataType': 'String',
        // },
    ]
    }
