<div class="right-align" [@openClose]="leftPanelService.isOpened() ? 'open' : 'closed'">
  <!-- User -->
  <div class="right-nav">
    <ul>

      <!-- User's Display Name -->
      <li class="display-name">{{displayName}}</li>

      <!-- User Profile Section Trigger Icon - user's photo-->
      <li class="profile-trigger" [class.overlay-open]="show" (clickOutside)="show=false;">
        <span (click)="show = !show;cntryDropDownShow=false">
          <span *ngIf="displayPhotoBool">
            <div class="profile-img-bg trigger-user-pic" [ngStyle]="{'background-image': 'url(data:image/JPEG;base64,'+image_user+')'}"></div>
          </span>
          <span *ngIf="!displayPhotoBool">
            <i class="fa fa-user-circle fa-2x" [ngClass]="{'fa-user-circle-clicked':show}" aria-hidden="true">
            </i>
          </span>
        </span>


        <span class="profile-overlay"
         [ngClass]="{'profile-trigger-content-show':show, 'profile-trigger-content-hide':!show }"
          (mouseover)="show=true">
          <div class="profile-summary">
            <span class="profile-summary-user-image">
              <div *ngIf="displayPhotoBool" class="profile-img-bg display-user-pic" [ngStyle]="{'background-image': 'url(data:image/JPEG;base64,'+image_user+')'}"></div>
              <div *ngIf="!displayPhotoBool" class="profile-img-bg display-user-pic" [ngStyle]="{'background-image': 'url(image_user)'}"></div>
            </span>
            <span class="profile-summary-text">

              <span class="user-name">{{displayName}}</span>
              <br>
              <span class="company-name">LF Logistics</span>
              <br>
              <span class="country-name">{{displayCountry.name}}</span>
              <br>
            </span>
          </div>
          <div class="profile-actions">
          <div class="display-name-profile" (click)="openProfile()">
              <img id="my_image" src="assets/images/icon_login_username.png" alt="image error"> My Profile
          </div>
            <div class="cntry-dropdown" [ngClass]="{'opened':cntryDropDownShow}">
              <div class="toggle-btn" (click)="cntryDropDownShow=!cntryDropDownShow;langDropDownShow=false">
                <img *ngIf="displayCountry.code" src="{{'assets/images/svg/flags/flag_'+displayCountry.code+'.svg'}}"
                  class="flags" /> {{displayCountry.name}}
                <span class="caret"></span>
              </div>
              <ul class="dropdown-items" (mouseover)="cntryDropDownShow=true">
                <li class="item" *ngFor="let country of countries;" (click)="cntryDropDownShow=!cntryDropDownShow;saveCntry(country)">
                  <img src="{{'assets/images/svg/flags/flag_'+country.code+'.svg'}}" class="flags" /> {{country.name}}
                </li>
              </ul>
            </div>

            <div class="cntry-dropdown" [ngClass]="{'opened':langDropDownShow}">
              <div class="toggle-btn" (click)="langDropDownShow=!langDropDownShow;cntryDropDownShow=false">
                  <img src="{{'assets/images/svg/'+(langDropDownShow?'icon-language':'icon-language-white')+'.svg'}}"
                  class="lf-svg" />  {{displayLanguage.name}}
                <span class="caret"></span>
              </div>
              <ul class="dropdown-items" (mouseover)="langDropDownShow=true">
                <li class="item" *ngFor="let language of languages;" (click)="langDropDownShow=!langDropDownShow;saveLang(language)">
                  {{language.name}}
                </li>
              </ul>
            </div>

            <div class="">
              <button class="logout" (click)="logout()">
                  <img src="{{'assets/images/svg/icon-logout.svg'}}" class="lf-svg" /> 
               {{getLabel('Logout')}}
                </button>
            </div>



            <!-- <span class="arrow">&#9660;</span> -->
            <!-- <span class="storer-dropdown">

    <div class="language-dropdown"
    [ngClass]="{'opened':storerDropDownShow}">

      <button class="toggle"
      (click)="storerDropDownShow=!storerDropDownShow">
        <img src="{{'assets/images/svg/flags/flag_'+displayCountry.code+'.svg'}}" style="width:24px"/>
           {{selectedStorer}}
       <span class="caret"></span>
      </button>
      <ul class="menu"  (mouseout)="storerDropDownShow=false" (mouseover)="storerDropDownShow=true">
        <li class="item" *ngFor="let storer of storers;"
        (click)="storerDropDownShow=!storerDropDownShow;saveStorer(storer)" >
         {{storer}}
        </li>
      </ul>
    </div>



  </span> -->
          </div>
        </span>




      </li>
    </ul>
  </div>

</div>