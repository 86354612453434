<div *ngIf="!isLoading" class="editor-container" [ngSwitch]="templateId">
  <ng-template [ngSwitchCase]="0">
    <!-- Default renderer template -->
    <span class="renderer-default" [title]="params.value">{{ params.value }}</span>
  </ng-template>
 
  <ng-template [ngSwitchCase]="1">
    <div  class="input-group basic-input-group">
      <input #primeFocus class=" basic-input" (ngModelChange)="valueChanged($event)"
      type="text" [(ngModel)]="params.value">
      <!-- <img width="20px" height="20px" disabled src="assets/images/search.png" (click)="openTruck(1)"> -->
      <div class="input-group-append">
        <div class="btn-outline-secondary basic-input-grp-btn"
        (click)="openTruck(1)">
            <i class="fa fa-search" aria-hidden="true"></i>
        </div>
    </div>
    </div>
  </ng-template>
  <ng-template [ngSwitchCase]="1.1">
    <div class="input-group basic-input-group" [title]="params.value||''" (keydown)="keyEventHandler.editKeyPressListner($event, currentRow, params.cellMetaData);">
      <input #primeFocus class=" basic-input" 
      type="text" [(ngModel)]="params.value" (ngModelChange)="valueChanged($event)">
      <div class="input-group-append">
        <div class="btn-outline-secondary basic-input-grp-btn"
        (click)="openTruck(2)">
            <i class="fa fa-search" aria-hidden="true"></i>
        </div>
    </div>
    </div>
  </ng-template>
  <ng-template [ngSwitchCase]="2">
    <div [ngClass]="{ 'cell-error': params.value?.isValueInvalid ||'' }" class="input-group basic-input-group" (keydown)="keyEventHandler.editKeyPressListner($event, currentRow, params.cellMetaData);">
      <!-- 
        -- reuse of lfwms-lookup-input for auto suggestion- the mandatory inputs are
        -- @input [isDetailOrAcn]="'detail'"
        -- @input [tableEditor]="tableEditor"
        -- @input [dataModel]="params.value"
        -- @input [propertyName]="propertyName"
        -- @input [currentRow]="currentRow"
        -- @input [keyEventHandler]="keyEventHandler"
      -->
      <lfwms-lookup-input #primeFocus class="lkp-inpt-sugg-prop" [isDetailOrAcn]="'detail'" [tableEditor]="tableEditor" [dataModel]="params.value"
        [propertyName]="propertyName" [currentRow]="currentRow" [keyEventHandler]="keyEventHandler" (setFocus)="toSetFocusFromTypeaheadTextBox($event)"
        (onValueChange)="onValueChangeInTypeaheadTextBox($event)" (setFocus)="toSetFocusFromTypeaheadTextBox($event)">
      </lfwms-lookup-input>
    </div>
  </ng-template>
  <ng-template [ngSwitchCase]="3">
    <lfwms-dropdown #primeFocus [inputValue]="params.value" 
      [isDropup]="isDropup" [inputOptions]="params.cellMetaData.values" [scrollHeight]="scrollHeight"
      (valueChanged)="valueChanged($event);"></lfwms-dropdown>
  </ng-template>
  <ng-template [ngSwitchCase]="4">
    <!-- <lfwms-datepicker-grid #primeFocus [inputDate]="params.value" [isDropup]="isDropup" [offset]="offset" (valueChanged)="valueChanged($event);"></lfwms-datepicker-grid> -->
    <lfwms-datepicker #primeFocus [(ngModel)]="params.value" (ngModelChange)="valueChanged($event)" title={{dateFormat}}>
    </lfwms-datepicker>
  </ng-template>
  <ng-template [ngSwitchCase]="5">
    <span [ngSwitch]="params.value.cVal" (click)="toggleCheckbox();">
      <ng-template [ngSwitchCase]="true">
        <i class="fa fa-check-square-o lfwms-checkbox"></i>
      </ng-template>
      <ng-template [ngSwitchCase]="false">
        <i class="fa fa-square-o lfwms-checkbox"></i>
      </ng-template>
      <ng-template [ngSwitchCase]="'Y'">
        <i class="fa fa-check-square-o lfwms-checkbox"></i>
      </ng-template>
      <ng-template [ngSwitchCase]="'N'">
        <i class="fa fa-square-o lfwms-checkbox"></i>
      </ng-template>
      <ng-template [ngSwitchCase]=null>
        <i class="fa fa-square-o lfwms-checkbox"></i>
      </ng-template>
      <ng-template ngSwitchDefault>
        <i class="fa fa-square-o lfwms-checkbox"></i>
      </ng-template>
    </span>
  </ng-template>
  <!--Displaying drowdown having multi columns-->
  <ng-template [ngSwitchCase]="8">

    <div *ngIf="params.cellMetaData.displayType === 'EA'" class="mc-dropdown">
      <button [set-focus]="keyEventHandler.isFocused(currentRow, propertyName)" class="mc-button" (change)="valueChanged($event)"
        (click)="showMultiColumnDropdown(currentRow, propertyName);">
        {{ currentRow.cells[propertyName].cVal }}
        <span class="caret"></span>
      </button>

      <div *ngIf="currentRow.cells[propertyName].showMultiColumn">

        <div *ngIf="!currentRow.cells[propertyName].mcOptions ||
        (currentRow.cells[propertyName].mcOptions && currentRow.cells[propertyName].mcOptions.length < 1)"
         class="no-options-multi-dropdown-display">
         No Options
       </div>
       <div  class="multicolumn-display">
        <div *ngIf="(currentRow.cells[propertyName].mcOptions &&
        currentRow.cells[propertyName].mcOptions.length > 0)" class="mc-table">

          <span *ngFor="let mcOption of currentRow.cells[propertyName].mcOptions; let i=index;">
            <div class="mc-table-body">

              <span *ngFor="let key of currentRow.cells[propertyName].keyArray; let j=index">

                <div class="mc-table-row" *ngIf="i === 0">
                  <div *ngIf="propertyName === 'orderLineNo'" class="mc-table-header">{{ key }}</div>
                  <div *ngIf="propertyName !== 'orderLineNo' && j === 0" class="mc-table-header">
                    {{propertyName}}
                  </div>
                  <div *ngIf="propertyName !== 'orderLineNo' && j === 1" class="mc-table-header">
                    Quantity Available
                  </div>
                </div>

                <div class="mc-table-row" (click)="handleMultiColumnClick(currentRow, propertyName, key, mcOption[key]);">
                  <div class="mc-table-column" title="{{mcOption[key]}}">
                    {{mcOption[key]}}</div>
                </div>

              </span>
            </div>
          </span>
        </div>
      </div>


      </div>





    </div>

  </ng-template>
  <!-- <ng-template [ngSwitchCase]="9">
    <lfwms-shipment-time-mask #primeFocus [params]="params" [currentRow]="currentRow" [keyEventHandler]="keyEventHandler" (onValueChanged)="onValueChangeOfTimeComponent($event)"></lfwms-shipment-time-mask>
  </ng-template>
  <ng-template [ngSwitchCase]="10">
    <lfwms-datepicker #primeFocus [(ngModel)]="params.value.cVal" (ngModelChange)="valueChanged($event)" title={{dateTimeFormat}}
      [showTimePicker]="true">
    </lfwms-datepicker>
  </ng-template> -->
  <ng-template [ngSwitchCase]="11">
    <div *ngIf="params.cellMetaData.displayType === 'EA'" class="mc-dropdown">
      <div class="mc-wrapper">
        <input #primeFocus type="text" class="form-control multi-column-basic-input" [set-focus]="keyEventHandler.isFocused(currentRow, propertyName)"
          [ngModel]="params.value.cVal" (ngModelChange)="valueChanged($event)" (focus)="onTextFocus($event)">
        <div class="input-group-append">
          <div class="btn-outline-secondary multi-column-basic-input-grp-btn" (click)="setFocusedCell(currentRow, propertyName);
                  openMultiColumnPopup(currentRow, propertyName)">
            <i class="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- Editable Dropdown -->
  <ng-template [ngSwitchCase]="12">
    <lfwms-dropdown #primeFocus [inputValue]="params.value" [setFirstValuesAsDefault]="params.context.componentParent.searchParams.dataTableConfig.setFirstValuesAsDefault"
      [isDropup]="isDropup" [inputOptions]="params.value.cOptions || params.cellMetaData.values" [scrollHeight]="scrollHeight"
      (valueChanged)="valueChanged($event);" [isEditable]="true"></lfwms-dropdown>
  </ng-template>

</div>