import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef, AfterViewInit } from '@angular/core';
import { ButtonItem } from './button.model';
import { LeftPanelService } from '../../layout/home/services/left-panel.service';
import { SimpleEntityDataService } from '../services/searchable-entity/simple-entity/simple-entity-data.service';

@Component({
  selector: 'lfwms-button-panel',
  templateUrl: './button-panel.component.html',
  styleUrls: ['./button-panel.component.css']
})
export class ButtonPanelComponent implements OnInit, AfterViewInit {

  // Dynamic menu config
  public buttons: Array<ButtonItem>;
  public openedRootMenuId: string = '';
  public expandedLevel2id: string = '';
  // flag used to toggle between import and view import button
  public showViewImport: boolean = false;
  public dataService: SimpleEntityDataService;

  @Input() buttonArray: Array<ButtonItem>;
  @Input() viewLottablesPopup: boolean = false;


  @Output()
  buttonClickHandler: EventEmitter<string> = new EventEmitter();
  public countOfButtons: number;
  public windowWidth: number;
  public sidePanelWidth: number = 0;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
    this.recalculateWidthForFloatingMenu();
  }

  constructor(public leftPanelService: LeftPanelService, public elementRef: ElementRef) {
    // Initialise the menu from backend service.ElementRef
  }

  public ngAfterViewInit() {
    this.recalculateWidthForFloatingMenu();
  }

  public ngOnInit() {
    if (this.buttonArray) {
      this.buttons = this.buttonArray;
      /* set initial value on component load */
      this.sidePanelWidth = this.leftPanelService.isOpened() ? this.leftPanelService.getLeftPanelWidthInPixels() : 0;
      this.leftPanelService.getLeftPanelOpenEvent().subscribe((isOpened) => {
        if (isOpened) {
          this.sidePanelWidth = this.leftPanelService.getLeftPanelWidthInPixels();
        } else {
          this.sidePanelWidth = 0;
        }
        this.recalculateWidthForFloatingMenu();
      });
    }
  }

  public handleButtonClick(button: ButtonItem) {
    // Button action will perform only when button is enabled and not a specialButton
    // -removed -- if (button.isEnabled && button.action !== 'Import' && button.action !== 'Upload') {
    if (button.isEnabled && !button.specialButtonConfig) {
      this.buttonClickHandler.emit(button.action);
    }
  }

  // method gives visible button and also the button that goes into ellipsis based on window width
  public getVisiblebuttons(buttonDisplayType) {
    if (!this.buttonArray) {
      return [];
    }
    const visibleButtonList = this.buttonArray.filter(data => data.isVisible === true);
    if (!this.windowWidth) {
      this.windowWidth = window.innerWidth;
    }
    this.windowWidth = window.innerWidth - this.sidePanelWidth;
    let buttonPanelWidth = this.windowWidth;
    if (this.elementRef.nativeElement.previousElementSibling) {
      buttonPanelWidth = this.windowWidth - this.elementRef.nativeElement.previousElementSibling.offsetWidth;
    }
    // console.log(buttonPanelWidth)
    if (buttonPanelWidth > 1000) {
      this.countOfButtons = 5;
    } else if (buttonPanelWidth > 800) {
      this.countOfButtons = 4;
    } else if (buttonPanelWidth > 600) {
      this.countOfButtons = 2;
    } else if (buttonPanelWidth > 400) {
      this.countOfButtons = 1;
    } else {
      this.countOfButtons = 0;
    }
    return this.getButtonOnWindowSize(buttonDisplayType, visibleButtonList);
  }

  // this method returns the button that goes into ellipsis and not in ellipsis separately
  public getButtonOnWindowSize(buttonDisplayType, visibleButtonList): any | undefined {
    if (buttonDisplayType === 'non-ellipsis') {
      return visibleButtonList.length > this.countOfButtons ? visibleButtonList.slice(0, this.countOfButtons) : visibleButtonList;
    } else if (buttonDisplayType === 'ellipsis') {
      return visibleButtonList.length > this.countOfButtons ? visibleButtonList.slice(this.countOfButtons) : 0;
    }
  }

  public recalculateWidthForFloatingMenu() {
    if (this.elementRef.nativeElement.previousElementSibling) {
      // JIRA LFWM-1404 Fixes
      this.elementRef.nativeElement.style.width =
        (window.innerWidth - this.elementRef.nativeElement
          .previousElementSibling.offsetWidth - 60 - this.sidePanelWidth) + 'px';
    } else {
      // JIRA LFWM-1404 Fixes
      const width: number = (window.innerWidth - this.sidePanelWidth - 60);
      this.elementRef.nativeElement.style.width = width + 'px';
      // cases with no label .. 60 marginS
    }
  }

  // method call when import component emits event after reading and creating data from imported file
  public onFileImport(importId: string) {
    this.buttonClickHandler.emit(importId);
  }
}
