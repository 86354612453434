
import {throwError as observableThrowError,  Observable ,  Subject } from 'rxjs';

import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';



import { UserPreferenceEvent } from '../../../shared/models/user-favourite-event';

@Injectable()
export class UserPreferenceService {

  public preferenceEventSubject: Subject<UserPreferenceEvent>;

  constructor(public http$: HttpClient) {
    this.preferenceEventSubject = new Subject<UserPreferenceEvent>();
  }

  // Refresh left menu
  public receiveEvent(): Observable<UserPreferenceEvent> {
    return this.preferenceEventSubject.asObservable();
  }

  // setting menuItem to be updated
  public notifyEvent(event: UserPreferenceEvent) {
    this.preferenceEventSubject.next(event);
  }

  public getRecentSearchItemsList() {
    const response$ = this.http$.get('api/core/recentItems').pipe(
      catchError((error: any) => {
        return observableThrowError(error.message);
      }));
    return response$;
  }

  public getAllSavedSearchCriterias() {
    const response$ = this.http$.get('api/data/loadsearch/ALL').pipe(
      catchError((error: any) => {
        return observableThrowError(error.message);
      }));
    return response$;
  }

  public getAllFavorites() {
    const response$ = this.http$.get('api/core/favorites').pipe(
      catchError((error: any) => {
        if (error.error.errors.length !== 0) {
          if (error.error.errors[0].validationCode !== 200) {
            return observableThrowError(error.error.errors['0'].validationMessage);
          }
        }
      }));
    return response$;
  }

  public deleteFavorites(prefrenceId: number) {
    const response$ = this.http$.post(`api/core/delete/` + prefrenceId, {}).pipe(
      catchError((errors: any) => {
        if (errors.error.errors.length !== 0) {
          if (errors.error.errors[0].validationCode !== 200) {
            return observableThrowError(errors.error.errors['0'].validationMessage);
          }
        }
      }));
    return response$;
  }

  public fetchTablePreferences(dataTableId: string, prefType: string): Observable<Response> {
    const hdr = new HttpHeaders({ 'api': 'admin' });
    const dataUrl$ = this.http$
      .get(`api/core/user/tablepreferences/` + dataTableId + '/' + prefType , {headers: hdr}).pipe(
      map((response: any) => {
        if (response['prefType'] === 'FAVOURITECONFIG') {
          if (!response['configuration']) {
            response['configuration'] = { 'favourite': false };
            response['dataTableId'] = dataTableId;
            response['prefType'] = 'FAVOURITECONFIG';
            response['preferenceId'] = null;
          } else { response['configuration'] = JSON.parse(response['configuration']); }
        } else if (response['prefType'] === 'COLUMNCONFIG') {
          if (!response['configuration']) {
            response['dataTableId'] = dataTableId;
            response['prefType'] = 'COLUMNCONFIG';
            response['preferenceId'] = null;
            response['configuration'] = {
              hiddenPropertiesList: [],
              pinnedPropertiesList: [],
              columnWidthsObject: {},
              propertyIndexMap: {},
              pageDefinitionObject: {
                'sortColumn': null,
                'sortOrder': 0,
                'pageSize': 10
              }  // Page Definition Default Properties
            };
          } else {
            response['configuration'] = JSON.parse(response['configuration']);
          }
        } else if (response['prefType'] === 'COLUMNGROUPCONFIG') {
          if (!response['configuration']) {
            response['configuration'] = {};
            response['dataTableId'] = dataTableId;
            response['prefType'] = 'COLUMNGROUPCONFIG';
            response['preferenceId'] = null;
          } else {
            response['configuration'] = JSON.parse(response['configuration']);
          }
        }
        return response;
      }),catchError((error: any) => {
        return observableThrowError('Failed to fetch User Preferences for '
          + dataTableId + ' :: ' + error.message);
      }),);
    return dataUrl$;
  }

  public saveTablePreferences(userPreferences: any) {
    // [LFWM-1897] Fix - converting userPreferences.configuration to string is needed only when the type of userPreferences.configuration is an object
    if (typeof (userPreferences.configuration) === 'object') {
      userPreferences.configuration = JSON.stringify(userPreferences.configuration);
    }
    const parseToJson = function () {
      if (typeof (userPreferences.configuration) === 'string') {
        userPreferences.configuration = JSON.parse(userPreferences.configuration);
      }
    };
    const hdr = new HttpHeaders({ 'api': 'admin' });
    const response$ = this.http$.post(`api/core/user/tablepreferences/`, userPreferences , {headers: hdr}).pipe(
      map((response: any) => {
        parseToJson();
        return response;
      }),catchError((error: any) => {
        parseToJson();
        return observableThrowError(error);
      }),);
    return response$;
  }

  public saveSidePanelStatus(sidePanelStatus: boolean) {
    const response$ = this.http$.post(`api/core/sidepanel/update/` + sidePanelStatus, {}).pipe(
      catchError((err: any) => {
        if (err.error.errors.length !== 0) {
          if (err.error.errors[0].validationCode !== 200) {
            return observableThrowError(err.error.errors['0'].validationMessage);
          }
        }
      }));
    return response$;
  }

}
