export class SearchEvent {

    public selectedItemId: string;
    public isSearchSuccess: boolean;
    public message: string;
    public selectedProperty: string;
    public selectedRow: any;
    public pageSize: number;
    public pageNumber: number;
    public createdRowIndex: number;

    constructor(public type: number) { }

}

