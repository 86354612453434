import { Injectable } from '@angular/core';
import { SearchService } from './search/search.service';
import { SearchParams } from './search/models/search-params.model';
import { SearchStatus } from './search/models/search-status.model';
import { UserPreferenceService } from '../layout/home/services/user-preference.service';
import { ConfirmDialogService, UserService } from 'sce-core';
import { AlertService } from 'sce-core';
import { CoreServiceRegistry } from '../core/core-service-registry';
import { PrinterService } from './services/printer.service';
import { DocumentUtilService } from './services/document-util.service';
import { SavedSearchService } from './search/saved-search.service';
import { ButtonFactoryService } from './button/button-factory.service';
import { ValidatorService } from './services/validator.service';
import { LocalPagedSearchService } from './search/localpaged-search.service';
import { CrossFunctionalityNavigationService } from './services/cross-functionality-navigation.service';
import { LocalPagedDataManager } from './search/local-paged-data-manager.service';
import { FileUtilityService } from './services/file-utility.service';
import { ModalService } from './services/modal.service';
import { DatePickerConfigService } from './components/datepicker/datepicker-config.service';
import { NotificationRecipientSearchService } from '../features/notification/notification-recipient/notification-recipient/services/notification-recipient-search.service';
import { NotificationTagSearchService } from '../features/notification/notification-tag/notification-tag/services/notification-tag-search.service';
import { NotificationSchedulerSearchService } from '../features/notification/notification-scheduler/notification-scheduler/services/notification-scheduler-search.service';
import { NotificationTemplateSearchService } from '../features/notification/notification-template/notification-template/services/notification-template-search.service';

@Injectable()
export class SharedServiceRegistry {

    constructor(public coreServiceRegistry: CoreServiceRegistry,
        public savedSearchService: SavedSearchService, public userPreferencesService: UserPreferenceService,
        public confirmDialogService: ConfirmDialogService, public alertService: AlertService,
        public printerService: PrinterService, public documentUtilService: DocumentUtilService,
        public buttonFactoryService: ButtonFactoryService, public validatorService: ValidatorService,
        public crossFunctionalityNavigationService: CrossFunctionalityNavigationService,
        public localPagedDataManager: LocalPagedDataManager,
        public fileUtilityService: FileUtilityService,
        public sceModalService: ModalService,
        public dateConfigService: DatePickerConfigService,
        public userService: UserService) {

        console.debug('shared regisrtry', this.coreServiceRegistry);
        console.debug('shared regisrtry', this.userService);
        console.debug('shared regisrtry', this.alertService);
    }

    public createSearchService(searchParams: SearchParams,
        searchStatus: SearchStatus): SearchService {
        let searchService: SearchService = null;
        if (searchParams.paginateLocally) {
            // For local pagination scenario, use LocalPagedSearchService
            searchService
                = new LocalPagedSearchService(this.coreServiceRegistry.http$,
                    searchParams,
                    searchStatus,
                    this.coreServiceRegistry.resourceService,
                    this.userPreferencesService,
                    this.alertService,
                    this.dateConfigService, this.userService);
            searchService.setDataManager(this.localPagedDataManager);
        } else {
            searchService
                = new SearchService(this.coreServiceRegistry.http$,
                    searchParams,
                    searchStatus,
                    this.coreServiceRegistry.resourceService,
                    this.userPreferencesService,
                    this.alertService,
                    this.dateConfigService, this.userService);
        }
        return searchService;
    }

    public createPomsSearchService(searchParams: SearchParams,
        searchStatus: SearchStatus): NotificationRecipientSearchService {
        let searchService: NotificationRecipientSearchService = null;
        searchService
            = new NotificationRecipientSearchService(this.coreServiceRegistry.http$,
                searchParams,
                searchStatus,
                this.coreServiceRegistry.resourceService,
                this.userPreferencesService,
                this.alertService,
                this.dateConfigService, this.userService);
        return searchService;
    }

    public createTagSearchService(searchParams: SearchParams,
        searchStatus: SearchStatus): NotificationTagSearchService {
        let searchService: NotificationTagSearchService = null;
        searchService
            = new NotificationTagSearchService(this.coreServiceRegistry.http$,
                searchParams,
                searchStatus,
                this.coreServiceRegistry.resourceService,
                this.userPreferencesService,
                this.alertService,
                this.dateConfigService, this.userService);
        return searchService;
    }

    public createSchedulerSearchService(searchParams: SearchParams,
        searchStatus: SearchStatus): NotificationSchedulerSearchService {
        let searchService: NotificationSchedulerSearchService = null;
        searchService
            = new NotificationSchedulerSearchService(this.coreServiceRegistry.http$,
                searchParams,
                searchStatus,
                this.coreServiceRegistry.resourceService,
                this.userPreferencesService,
                this.alertService,
                this.dateConfigService, this.userService);
        return searchService;
    }

    public createTemplateSearchService(searchParams: SearchParams,
        searchStatus: SearchStatus): NotificationTemplateSearchService {
        let searchService: NotificationTemplateSearchService = null;
        searchService
            = new NotificationTemplateSearchService(this.coreServiceRegistry.http$,
                searchParams,
                searchStatus,
                this.coreServiceRegistry.resourceService,
                this.userPreferencesService,
                this.alertService,
                this.dateConfigService, this.userService);
        return searchService;
    }

}




