export const FETCH_DASHBOARD_DETAILS = {
    'RG': {code: 'RG', id: 2223, notificationId: '8', moduleId: 'template Group', pageTitle: 'Receipient Group', tableId: 'templatetd'},
    'OD': {code: 'OD', id: 2225, notificationId: '8', moduleId: 'NotificationTemplate', pageTitle: 'mn_operation_notification', tableId: 'templatetd'}
};

export const INITIALBREADCRUMRG = [
    { id: 'home', displayValue: 'Home', route: '/home' },
    { id: 'setup', displayValue: 'Setup', route: undefined },
    { id: 'notification', displayValue: 'Notification', route: undefined },
    { id: 'template-group', displayValue: 'template Group', route: '/home/notification/notification-template' }
]

export const INITIALBREADCRUMTEMPLATE = [
    { id: 'home', displayValue: 'Home', route: '/home' },
    { id: 'setup', displayValue: 'Setup', route: undefined },
    { id: 'notification', displayValue: 'Notification', route: undefined },
    { id: 'template', displayValue: 'Template', route: '/home/notification/notification-template' }

]

export const INITIALBREADCRUMPOD = [
    { id: 'home', displayValue: 'Home', route: '/home' },
    { id: 'global_control', displayValue: 'Global Control', route: undefined },
    { id: 'perf_mgmt', displayValue: 'Performance template', route: undefined },
    { id: 'operation_notification', displayValue: 'Operation NotificationTemplate', route: '/home/global-control/operation-notification' }
]

export const metaDataGrpDetail = {
    'elementId': 'TEMPLATE_TB',
    'columnList': [
        {
            'id': '998',
            'elementId': 'Template Name',
            'propertyName': 'TEMPLATE_NAME',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'templateName',
            'moduleId': 'templateId'
        },
        {
            'id': '999',
            'elementId': 'Template Profile',
            'propertyName': 'TEMPLATE_PROFILE',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'templateProfile'
        },
        {
            'id': '1000',
            'elementId': 'App name',
            'propertyName': 'APP_NAME',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'H',
            'dataType': 'String',
            'cellDataName': 'appName',
        },
        {
            'id': '1001',
            'elementId': 'Country Name',
            'propertyName': 'COUNTRY_NAME',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
            'cellDataName': 'countryName',
        },
        {
            'id': '1002',
            'elementId': 'Template ID',
            'propertyName': 'TEMPLATE_ID',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 60,
            'index': 4,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'templateId'
        },
        {
            'id': '1003',
            'elementId': 'App ID',
            'propertyName': 'APP_ID',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 100,
            'index': 6,
            'priority': 'LOW',
            'toolTip': null,
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
            'cellDataName': 'appId'
        },
        {
            'id': '1004',
            'elementId': 'Country ID',
            'propertyName': 'COUNTRY_ID',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'countryId'
        },
        // {
        //     'id': '1005',
        //     'elementId': 'REAL_TIME',
        //     'propertyName': 'REAL_TIME',
        //     'type': 'MULTIDROPDOWN',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': false,
        //     'maxLength': 20,
        //     'index': 45,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [{ 'key': '', 'value': '-Select-' }, { 'key': 'Y', 'value': 'Yes' }, { 'key': 'N', 'value': 'No' }],
        //     'columnGroup': 'Primary',
        //     'displayType': 'V',
        //     'dataType': 'String',
        //     'cellDataName': 'realTime'
        // },
        {
            'id': '1009',
            'elementId': 'Created By',
            'propertyName': 'CREATED_BY',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'createdBy'
        },
        {
            'id': '1010',
            'elementId': 'Created Date',
            'propertyName': 'CREATED_DATE',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'createdDate'
        },
        {
            'id': '1011',
            'elementId': 'Last Updated By',
            'propertyName': 'LAST_UPDATED_BY',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'lastUpdatedBy'
        },
        {
            'id': '1012',
            'elementId': 'Last Updated Date',
            'propertyName': 'LAST_UPDATED_DATE',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'lastUpdatedDate'
        },
      
    ]
    }

export const metaDataGrp = {
    'elementId': 'TEMPLATE_TB',
    'columnList': [
        {
            'id': '998',
            'elementId': 'Template Name',
            'propertyName': 'TEMPLATE_NAME',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'templateName',
            'moduleId': 'templateId'
        },
        {
            'id': '999',
            'elementId': 'Template Profile',
            'propertyName': 'TEMPLATE_PROFILE',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'templateProfile'
        },
        {
            'id': '1000',
            'elementId': 'App Name',
            'propertyName': 'APP_NAME',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'H',
            'dataType': 'String',
            'cellDataName': 'appName',
        },
        {
            'id': '1001',
            'elementId': 'Country Name',
            'propertyName': 'COUNTRY_NAME',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
            'cellDataName': 'countryName',
        },
        {
            'id': '1002',
            'elementId': 'Template ID',
            'propertyName': 'TEMPLATE_ID',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 60,
            'index': 4,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'templateId'
        },
        {
            'id': '1003',
            'elementId': 'App ID',
            'propertyName': 'APP_ID',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 100,
            'index': 6,
            'priority': 'LOW',
            'toolTip': null,
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
            'cellDataName': 'appId'
        },
        {
            'id': '1004',
            'elementId': 'Country ID',
            'propertyName': 'COUNTRY_ID',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'countryId'
        },
        // {
        //     'id': '1005',
        //     'elementId': 'REAL_TIME',
        //     'propertyName': 'REAL_TIME',
        //     'type': 'MULTIDROPDOWN',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': false,
        //     'maxLength': 20,
        //     'index': 45,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [{ 'key': '', 'value': '-Select-' }, { 'key': 'Y', 'value': 'Yes' }, { 'key': 'N', 'value': 'No' }],
        //     'columnGroup': 'Primary',
        //     'displayType': 'V',
        //     'dataType': 'String',
        //     'cellDataName': 'realTime'
        // },
        {
            'id': '1009',
            'elementId': 'Created By',
            'propertyName': 'CREATED_BY',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'createdBy'
        },
        {
            'id': '1010',
            'elementId': 'Created Date',
            'propertyName': 'CREATED_DATE',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'createdDate'
        },
        {
            'id': '1011',
            'elementId': 'Last Updated By',
            'propertyName': 'LAST_UPDATED_BY',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'lastUpdatedBy'
        },
        {
            'id': '1012',
            'elementId': 'Last Updated Date',
            'propertyName': 'LAST_UPDATED_DATE',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'lastUpdatedDate'
        },
      
    ]
}

export const metaDataGrpForEmail = {
    'elementId': 'TEMPLATE_TB',
    'columnList': [
        {
            'id': '998',
            'elementId': 'Template Name',
            'propertyName': 'TEMPLATE_NAME',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'templateName',
            'moduleId': 'emailTemplateId'
        },
        {
            'id': '1002',
            'elementId': 'Template ID',
            'propertyName': 'TEMPLATE_ID',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 60,
            'index': 4,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'templateId'
        },
        {
            'id': '1012',
            'elementId': 'Template',
            'propertyName': 'TEMPLATE',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'template'
        },
      
    ]
}

