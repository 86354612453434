import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { PropertyChangeEvent, MANUAL_EDIT } from '../../models/property-change-event';
import { SearchParams } from '../../search/models/search-params.model';
import { SearchService } from '../../search/search.service';
import { DataTableConfig } from '../../search/models/data-table-config';
import { SearchStatus } from '../../search/models/search-status.model';
import { SharedServiceRegistry } from '../../shared-service-registry';
import { AlertService, NavigationService } from 'sce-core';
import { Clause } from '../../search/models/search-filter-model';
import { SearchEvent } from '../../search/models/search-event';
import { ITEM_AUTO_POPULATE } from '../../search/search-constants';
import { CoreServiceRegistry } from '../../../core/core-service-registry';
@Component({
  selector: 'lfwms-lookup-input',
  templateUrl: './lookup-input.component.html',
  styleUrls: ['./lookup-input.component.css']
})
export class LookupInputComponent implements OnInit {

  @Input() public tableEditor: any;
  @Input() public dataModel: any;
  @Input() public propertyName: any;
  @Input() public currentRow: any;
  @Input() public keyEventHandler: any;
  @Input() public toolTip: string;
  @Input() public isDetailOrAcn: any;
  @Input() public disableField: boolean = false;

  // showLookupIcon: flag used to show the lens icon (which is used to open a lookup) or not
  @Input() public showLookupIcon: boolean = true;

  @Output() public onValueChange: EventEmitter<any> = new EventEmitter();
  @Output() public setFocus: EventEmitter<any> = new EventEmitter();
  @Output() public onLookUp: EventEmitter<any> = new EventEmitter();
  @Output() public onColumnFilter: EventEmitter<any> = new EventEmitter();

  public showSuggestion: boolean = false;
  public suggestionDataMap: any[] = [];
  public autoSuggestionList: any[] = [];
  public searchValue: string = '';
  public searchService: SearchService;
  public suggestionIdProperty: string = '';
  // public columnprop: string = '';
  public searchParams: SearchParams;
  // public suggestionrowIndex: any;
  // public isSuggestionInFilterPart: boolean = false;
  public alertService: AlertService;
  public navService: NavigationService;

  // [lfwms-923][keyboard navigation in Autosuggestion]
  // template referent for autosuggestion keyboard navigation
  @ViewChild('autoSuggestionTemplate') public autoSuggestionTemplate;
  @ViewChild('primeFocusInput') primeFocusInput: any;

  constructor(public coreServiceRegistry: CoreServiceRegistry,
    public sharedServiceRegistry: SharedServiceRegistry) {
    this.navService = coreServiceRegistry.navigationService;
    this.alertService = sharedServiceRegistry.alertService;
  }

  public ngOnInit() { }

  public getAutoSuggestion(dataMap, key, searchValue: string, isNextPage: boolean) {
    searchValue = searchValue.trim();
    if (searchValue.length >= 4) {
      if (!isNextPage) {
        this.searchValue = searchValue;
        this.autoSuggestionList = [];
        this.suggestionDataMap = [];
        if (!this.searchService) {
          this.searchParams = this.tableEditor.createLookupParamsForColumn(key);
          this.suggestionIdProperty = this.searchParams.idProperty;
          const tableConfig: DataTableConfig = new DataTableConfig();
          tableConfig.showIdAsAHyperLink = false;
          this.searchParams.dataTableConfig = tableConfig;
          this.searchParams.alertService = this.alertService;
          const autoSuggestSearchStatus = new SearchStatus();
          this.searchService = this.sharedServiceRegistry.createSearchService(this.searchParams,
            autoSuggestSearchStatus);
        }
        this.createSearchCriteriaForAutosuggestion(dataMap, searchValue, key);
        this.fetchSuggestionFromBackend(isNextPage);

      } else {
        this.fetchSuggestionFromBackend(isNextPage);
      }
    } else {
      this.autoSuggestionList = [];
      this.showSuggestion = false;
    }
  }

  public createSearchCriteriaForAutosuggestion(dataMap, searchValue, key) {
    const defaultSearchCriteria = this.tableEditor.createDefaultSearchCriteria(dataMap, searchValue, key);
    const suggestionClause = new Clause();
    suggestionClause.column = this.searchParams.idProperty;
    suggestionClause.value = searchValue;
    suggestionClause.operation = 'like';
    suggestionClause.logicalOperation = 'AND';
    if (defaultSearchCriteria.conditions.length > 0) {
      defaultSearchCriteria.conditions[0].clauses[0].logicalOperation = null;
      defaultSearchCriteria.conditions[0].clauses.push(suggestionClause);
    } else {
      defaultSearchCriteria.conditions = [];
      defaultSearchCriteria.loadDefaultCriteria = false;
      const defaultCondition = this.tableEditor.createSearchCondition(1);
      defaultCondition.isReadOnly = true;
      defaultCondition.isMandatory = true;
      suggestionClause.logicalOperation = null;
      defaultCondition.clauses.push(suggestionClause);
      defaultCondition.operator = 'AND';
      defaultSearchCriteria.conditions.push(defaultCondition);
    }
    this.searchService.setSearchCriteria(defaultSearchCriteria);
  }

  public handleSuggestionSelected(suggestioncellDataMap, key, selectedValue: any) {
    let dataMap: any;
    const event = new SearchEvent(ITEM_AUTO_POPULATE);
    event.selectedItemId = selectedValue;
    event.selectedProperty = key;
    event.selectedRow = this.filterSelectedMap(selectedValue);
    dataMap = suggestioncellDataMap['cells'] ? suggestioncellDataMap['cells'] : suggestioncellDataMap;
    if (this.isDetailOrAcn === 'column-filter') {
      const filterEvent = { propertyName: key,
        selectedValue: selectedValue };
        this.dataModel.cVal = selectedValue;
        this.onColumnFilter.emit(filterEvent);
    }else
    // LFWMS-2023 if the call is from viewReport Screen,no need to call handlelookupevent
     if (this.isDetailOrAcn === 'viewReportlookup') {
      // left empty intentionally
    }else {
      this.tableEditor.handleLookupEvent(event, key, selectedValue, dataMap, this.primeFocusInput);
      // JIRA LFWM-1664 - After reseting the lookup value Cursor control should be at the last updated/clicked text box
      this.primeFocusInput.nativeElement.focus();
    }
    if (dataMap && dataMap[key] && dataMap[key].isValueInvalid) {
      dataMap[key].isValueInvalid = false;
    }
    this.showSuggestion = false;
    // this.isSuggestionInFilterPart = false;
  }

  public filterSelectedMap(filterValue) {
    let value: any;
    this.suggestionDataMap.forEach(data => {
      if (data['cells'][this.suggestionIdProperty].cVal === filterValue) {
        value = data;
      }
    });
    return value;
  }

  public fetchSuggestionFromBackend(isNextPage) {
    this.searchService.fetchAutoSuggestionResults(isNextPage).subscribe(data => {
      // these code is added to avoid duplicate entries when consecutives keys are pressed fastly
      if (!isNextPage) {
        this.suggestionDataMap = [];
        this.autoSuggestionList = [];
      }
      const suggestionDataMap = this.searchService.getPagedData().pageRows;
      if (suggestionDataMap.length > 0) {
        suggestionDataMap.forEach(element => {
          this.suggestionDataMap.push(element);
          this.autoSuggestionList.push(element['cells'][this.suggestionIdProperty].cVal);
        });
        this.showSuggestion = true;
      } else {
        if (!(this.autoSuggestionList.length > 0) && isNextPage) {
          this.showSuggestion = false;
        } else if ((this.autoSuggestionList.length > 0) && !isNextPage) {
          this.showSuggestion = true;
        }
      }
    });
  }

  public onKeyDown(event: KeyboardEvent) {
    // keycode = 9 means Tab
    if (event.keyCode === 9) {
      this.showSuggestion = false;
      this.autoSuggestionList = [];
      return;
    }
    // keycode = 40 means Down Arrow key, keycode = 38 means Up Arrow key
    if (event.keyCode === 40 || event.keyCode === 38) {
      event.stopPropagation();
      event.preventDefault();
      event.stopImmediatePropagation();
      // [lfwms-923][keyboard navigation in Autosuggestion]
      // check wether template reference is undefined
      if (this.autoSuggestionTemplate) {
        const initialTemplateRefrence = this.autoSuggestionTemplate.nativeElement.getElementsByTagName('button');
        // check that the suggestion are not empty
        if (initialTemplateRefrence.length > 0) {
          // set focus manually to the first suggestion block
          this.autoSuggestionTemplate.nativeElement.getElementsByTagName('button')[0].focus();
        }
      }
    } else if (event.keyCode === 27) {
      // check for Esc key and close suggestion block and neglect other key press
      this.showSuggestion = false;
    }
  }


  // [lfwms-923][keyboard navigation in Autosuggestion]
  // this method is called for keyboard navigation
  public handleKeyboardNavigation(event, index, suggestioncellDataMap, key, selectedValue: any) {
    // check wether template reference is undefined
    if (this.autoSuggestionTemplate) {
      const AutosuggtionKeyNavigationTemplateVariable = this.autoSuggestionTemplate.nativeElement.getElementsByTagName('button');
      // check that the suggestion are not empty
      if (AutosuggtionKeyNavigationTemplateVariable.length > 0) {
        const indexToFocus = index;
        switch (event.keyCode) {
          // if uparrow key(uparrowkey = 38) need to set focus on previous sibling element
          case 38:
            // preventdefault,stopPropagation, stopPropagation
            // prevents the scroll of div block on uparrow or downarrow key sice we are manually setting focus and scroll based on that
            event.stopPropagation();
            event.preventDefault();
            event.stopPropagation();
            if (indexToFocus !== 0) {
              AutosuggtionKeyNavigationTemplateVariable[indexToFocus - 1].focus();
            }
            break;
          // if downarrow key(uparrowkey = 40) need to set focus on next sibling element
          case 40:
            event.stopPropagation();
            event.preventDefault();
            event.stopImmediatePropagation();
            if (AutosuggtionKeyNavigationTemplateVariable[indexToFocus + 1]) {
              AutosuggtionKeyNavigationTemplateVariable[indexToFocus + 1].focus();
            }
            break;
          // if ESC key(uparrowkey = 27) need to close the suggestion block
          case 27:
            this.showSuggestion = false;
            break;
          case 13:
            this.handleSuggestionSelected(suggestioncellDataMap, key, selectedValue);
            break;
        }
      }
    }
  }

  // Method called when auto suggestion scrolled
  public autoSuggestionScrolledToBottom(cellDataMap, key, value) {
    this.getAutoSuggestion(cellDataMap, key, value, true);
  }

  public valueChanged(event) {
/*  donot add the check for event.target.value becoz even if event.target,value will
 be empty(i.e when we delete the value) also the event shopuld be trigggered*/
    const customEvent = {
      propertyName: this.propertyName,
      prevVal: this.dataModel.cVal, newVal: event.target.value
    };
    if (this.isDetailOrAcn === 'detail') {
      this.onValueChange.emit(customEvent);
    } else if (this.isDetailOrAcn === 'accordion') {
      this.onValueChange.emit(customEvent);
    } else if (this.isDetailOrAcn === 'lookup') {
      this.onValueChange.emit(event);
    } else {
      this.onValueChange.emit(event);
    }

  }

  public setFocusedCell(row: any, property: any) {
    const setFocus = {
      'row': row,
      'property': property
    };
    this.setFocus.emit(setFocus);
  }

  public setFocusOnCell() {
    this.primeFocusInput.nativeElement.focus();
    }

  public onTextFocus($event: any) {
    $event.target.select();
  }

  public openLookup(row: any, property: string) {
    this.onLookUp.emit();
    const rowToLookUp = row.cells ? row : { cells: row };
    // JIRA LFWM-1664 - After reseting the lookup value Cursor control should be at the last updated/clicked text box
    this.tableEditor.openLookup(rowToLookUp, property, this.primeFocusInput);
  }

  public closeOnClickOutside(prop) {
    this.autoSuggestionList = [];
    this.showSuggestion = false;
  }


}
