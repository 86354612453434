import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParamsService {
  public data:any=[]
  id: any='';
  public viewData:any={}
  public searchService:any;
  public shipmentModified:any=''
  public parentComponent:any;
  showForm:any=true;
  showSearch:any=false;
  cancelHandler:any;
  confirmHandler2:any;
  servprovGid:any;
  confirmHandler:any;
  carrierData:any={};
  truckTypeDate:any={};
  truckNumDate:any={};
  shipment:any;
  metadata: any=[];
  businessUnitGid: any;
  viewDatas: any;
  clickData: any;
  equipmentGroupGid: any;
  confirmHandler3: (selectedRow: any) => void;
  params: any;
  cellEdit: any={};
  cellIndex: any=null;
  carrServprovGid: any;
  aggridApi: any;
  onlyShow: boolean;
  DriverServprovGid: any;
  TruckServprovGid: any;
  TruckEquipmentGroupGid: any;
  onlyShow2: boolean;
  shipmentPlanStatus:any;
  selectData:any;
  constructor() { 
    
  }
  setData(data){
    // if(data.length!=0){
    //   this.id=data[data.length-1].xid
    // }
    this.data=data
  }
}
