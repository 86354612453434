import { Injectable } from '@angular/core';
import { Router, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TokenService } from 'sce-core';
import { UserService } from 'sce-core';

@Injectable()
export class AlwaysAuthChildrenGuard implements CanActivateChild {

  constructor(public tokenService: TokenService,
    public userService: UserService,
    public router: Router) { }

  public canActivateChild(route: ActivatedRouteSnapshot) {
    if (this.tokenService.isAuthenticatedUser()) {
      return true;
    } else {
      // Clear any existing data by calling logout();
      this.userService.logout();
      return false;
    }
  }

}
