import { Injectable } from '@angular/core';
import { DetailedEntityDataService } from '../../shared/services/searchable-entity/detailed-entity/detailed-entity-data.service';
import { SearchStatus } from '../../shared/search/models/search-status.model';
import { FleetMonitoringSearchService } from './fleet-monitoring-search.service';
import { DatePickerConfigService, UserService } from 'sce-core';
import { UserPreferenceService } from 'src/app/layout/home/services/user-preference.service';
import { SearchParams } from 'src/app/shared/search/models/search-params.model';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class FleetMonitoringDataService extends DetailedEntityDataService {

    public originalColumnList = [];
    public lineItemProperties = [];
    public fromItemProperties = [];
    public toItemProperties = [];

    public columnsByDisplayName = {};

    public propertyNameToDisplayNameMap = {};

    public displayNameToPropertyNameMap = {};

    public sku: string;
    public storerKey: string;
    public loc: string;
    public lot: string;
    public id: string;
    public qty: string;
    public uccNo: string;
    public typeCoptions: any[];
    public reasonCoptions: any[];
    http$: HttpClient;
    searchParams: SearchParams;
    searchStatus: SearchStatus;
    dateConfigService: DatePickerConfigService;
    userService: UserService;
    userPreferenceService: UserPreferenceService;

    // Method to create new record based on Metadata
    public createNewRecord() {
        const cellDataMap = super.createNewRecord();
        // cellDataMap['fromStorerKey'].cVal = this.storerKey;
        // cellDataMap['toStorer'].cVal = this.storerKey;
        // cellDataMap['effectiveDate'].cVal = new Date();
        // cellDataMap['genHOCharges'].cVal = 1;
        // cellDataMap['genISHICharges'].cVal = '1';
        return cellDataMap;
    }

    

    public initializeDetailsSearchContext() {
        this.detailsSearchParams = this.entityConfig.getDetailSearchParams();
        this.detailsSearchParams.defaultSearchColumn = '';
        this.detailsSearchParams.tableEditor = this.dataEditor;
        this.detailsSearchParams.alertService = this.alertService;
        // if (this.buttonService) {
        //     this.buttonService.initializeDetailPageButtons(this.getPrimaryDataMap());
        //     this.detailsSearchParams.actionButtons = JSON.parse(JSON.stringify(this.buttonService.userButtonMap['search-table']));
        // }
        this.detailsSearchStatus = new SearchStatus();
        //    this.originalSearchService = this.sharedServiceRegistry.createSearchService(this.detailsSearchParams,
        //        this.detailsSearchStatus);

        // const transferSearchService = new FleetMonitoringSearchService(
        // this.http$, this.searchParams, this.searchStatus , this.resourceService , this.userPreferenceService, this.alertService
        //  , this.dateConfigService , this.userService);
       
        //this.detailsSearchService = transferSearchService;

        // this.detailsSearchService.fetchMetadata().subscribe(detailMetadata => {
        //     this.originalColumnList = detailMetadata.columnList;
        //     this.createMetadataForDisplay(detailMetadata.columnList);
        // });
    }

    public createMetadataForDisplay(originalColumnList) {
        this.columnsByDisplayName = {};
        this.propertyNameToDisplayNameMap = {};

        originalColumnList.forEach(column => {
            let rowType = null;
            if (column.columnGroup === 'fromDetail') {
                this.fromItemProperties.push(column.propertyName);
                rowType = 'from';
            } else if (column.columnGroup === 'toDetail') {
                this.toItemProperties.push(column.propertyName);
                rowType = 'to';
            } else if (column.columnGroup === 'lineItem') {
                this.lineItemProperties.push(column.propertyName);
                rowType = 'line';
            }
            this.propertyNameToDisplayNameMap[column.propertyName] = column.elementId;
            this.displayNameToPropertyNameMap[rowType + '_' + column.elementId] = column.propertyName;

            const clonedColumn = JSON.parse(JSON.stringify(column));
            clonedColumn.propertyName = column.elementId;
            this.columnsByDisplayName[column.elementId] = clonedColumn;
        });

        this.detailsSearchService.setMetadata({ columnList: Object.values(this.columnsByDisplayName) });
    }

    public getDisplayNameToPropertyNameMap() {
        const displayNameToPropertyNameMap = {};
        if (this.propertyNameToDisplayNameMap) {
            Object.keys(this.propertyNameToDisplayNameMap).forEach(prop =>
                displayNameToPropertyNameMap[this.propertyNameToDisplayNameMap[prop]] = prop);
        }
        return displayNameToPropertyNameMap;
    }

    public getOriginalPropertyForDisplayProperty(displayProperty: string, rowIndex: number) {
        let rowType = null;
        switch (rowIndex % 3) {
            case 0: { rowType = 'line'; break; }
            case 1: { rowType = 'from'; break; }
            case 2: { rowType = 'to'; break; }
        }

        return this.displayNameToPropertyNameMap[rowType + '_' + displayProperty];
    }

    public initializeValuesForNewLineItem(newRows) {
        let maxValue: string = '00000';
        const idProperty = this.detailsSearchParams.idProperty;
        const displayProperty = this.propertyNameToDisplayNameMap[idProperty];
        const pageRows = this.detailsSearchService.getPagedData().pageRows;
        // check only the line item rows
        for (let i = 0; i < pageRows.length; i = i + 3) {
            if (!maxValue || maxValue < pageRows[i].cells[displayProperty].cVal) {
                maxValue = pageRows[i].cells[displayProperty].cVal;
            }
        }

        if (this.maxIdValueForDetailTable && maxValue < this.maxIdValueForDetailTable) {
            maxValue = this.maxIdValueForDetailTable;
        }

        const newLineNumber = (+maxValue) + 1;
        let newLineNoStr = '' + newLineNumber;
        while (newLineNoStr.length < 5) {
            newLineNoStr = '0' + newLineNoStr;
        }

        // first row is the line item row
        newRows[0].cells[displayProperty].cVal = newLineNoStr;
    }

    // returns all the line items of the entity
    // public getAllLineItems(primaryProperties: any) {
    //     return this.getOriginalLineItemsFromTableRows(this.detailsSearchService.getPagedData().pageRows, primaryProperties);
    // }

    // returns all the line items of the entity
    public getOriginalLineItemsFromTableRows(tableRows, primaryProperties: any) {
        const primaryIdProperty = this.primarySearchParams.idProperty;
        if (!tableRows || tableRows.length === 0) {
            return tableRows;

        }
        const allLineItems = [];
        let isNewItemFlag = false;
        for (let i = 0; i + 2 < tableRows.length; i = i + 3) {
            const lineItem = [];
            Object.values(tableRows[i].cells).forEach((lineItemCell: any) => {
                if (tableRows[i].cells['isNewItem'] === lineItemCell) {
                    //  lineItem.push({ cName: 'isNewItem', cVal: lineItemCell.cVal });
                    isNewItemFlag = lineItemCell.cVal;
                    return;
                }
                const originalProperty = this.displayNameToPropertyNameMap['line_' + lineItemCell.cName];
                lineItem.push({ cName: originalProperty, cVal: lineItemCell.cVal });
            });
            Object.values(tableRows[i + 1].cells).forEach((fromCell: any) => {
                if (tableRows[i + 1].cells['isNewItem'] === fromCell) {
                    return;
                }
                const originalProperty = this.displayNameToPropertyNameMap['from_' + fromCell.cName];
                lineItem.push({ cName: originalProperty, cVal: fromCell.cVal });
            });
            Object.values(tableRows[i + 2].cells).forEach((toCell: any) => {
                if (tableRows[i + 2].cells['isNewItem'] === toCell) {
                    return;
                }
                const originalProperty = this.displayNameToPropertyNameMap['to_' + toCell.cName];
                lineItem.push({ cName: originalProperty, cVal: toCell.cVal });
            });
            if (isNewItemFlag) {
                lineItem.push({cName: 'isNewItem', cVal: isNewItemFlag});
            }
            lineItem.push({ cName: primaryIdProperty, cVal: primaryProperties[primaryIdProperty].cVal });
            const index = Math.floor(i / 3);
            allLineItems.push({ rIdx: index, cells: lineItem });
        }
        return allLineItems;
    }

    // Returns modified line items from the details table
    // public getModifiedLineItems(primaryProperties: any): any[] {
    //     return this.getModifiedLineItemsFromTableRows(this.detailsSearchService.getEditedRows(), primaryProperties);
    // }

    // Returns modified line items from the details table
    public getModifiedLineItemsFromTableRows(tableRows, primaryProperties: any) {
        const primaryIdProperty = this.primarySearchParams.idProperty;
        if (!tableRows || tableRows.length === 0) {
            return tableRows;
        }
        const modifiedLineItems = [];
        let isNewItemFlag = false;
        for (let i = 0; i + 2 < tableRows.length; i = i + 3) {
            const modifiedLineItem = [];
            Object.values(tableRows[i].cells).forEach((lineItemCell: any) => {
                if (tableRows[i].cells['isNewItem'] === lineItemCell) {
                    //  lineItem.push({ cName: 'isNewItem', cVal: lineItemCell.cVal });
                    isNewItemFlag = lineItemCell.cVal;
                    return;
                }
                const originalProperty = this.displayNameToPropertyNameMap['line_' + lineItemCell.cName];
                modifiedLineItem.push({ cName: originalProperty, cVal: lineItemCell.cVal, cValOrig: lineItemCell.cValOrig, cValPrev: lineItemCell.cValPrev});
            });
            Object.values(tableRows[i + 1].cells).forEach((fromCell: any) => {
                if (tableRows[i + 1].cells['isNewItem'] === fromCell) {
                    return;
                }
                const originalProperty = this.displayNameToPropertyNameMap['from_' + fromCell.cName];
                modifiedLineItem.push({ cName: originalProperty, cVal: fromCell.cVal, cValOrig: fromCell.cValOrig, cValPrev: fromCell.cValPrev});
            });
            Object.values(tableRows[i + 2].cells).forEach((toCell: any) => {
                if (tableRows[i + 2].cells['isNewItem'] === toCell) {
                    return;
                }
                const originalProperty = this.displayNameToPropertyNameMap['to_' + toCell.cName];
                modifiedLineItem.push({ cName: originalProperty, cVal: toCell.cVal, cValOrig: toCell.cValOrig, cValPrev: toCell.cValPrev});
            });
            if (isNewItemFlag) {
                modifiedLineItem.push({cName: 'isNewItem', cVal: isNewItemFlag});
            }
            modifiedLineItem.push({ cName: primaryIdProperty, cVal: primaryProperties[primaryIdProperty].cVal });
            const index = Math.floor(i / 3);
            modifiedLineItems.push({ rIdx: index, cells: modifiedLineItem });
        }
        return modifiedLineItems;
    }

    public overrideMetadataMap(displayMode: any) {
        // Overriding Metadata for facility to display it in ['Key - Value'] format
        // Below, Deep Clone has to be performed to avoid directly updating Metadata Object in Service
        if (this.primaryMetaDataMap['fromFacility']) {
            const facilityMetadata = JSON.parse(JSON.stringify(this.primaryMetaDataMap['fromFacility']));
            facilityMetadata.values.forEach((option: any) => {
                option.value = option.key + ' - ' + option.value;
            });
            facilityMetadata.values.sort((a: any, b: any) => {
                return (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0);
            });
            this.primaryMetaDataMap['fromFacility'] = facilityMetadata;
        }
        if (this.primaryMetaDataMap['toFacility']) {
            const facilityMetadata = JSON.parse(JSON.stringify(this.primaryMetaDataMap['toFacility']));
            facilityMetadata.values.forEach((option: any) => {
                option.value = option.key + ' - ' + option.value;
            });
            facilityMetadata.values.sort((a: any, b: any) => {
                return (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0);
            });
            this.primaryMetaDataMap['toFacility'] = facilityMetadata;
        }
    }
    // populate line items using UCC maintenance data
    public populateTransferLineItemFromUccMaintenance() {
        // to do
    }

    public requestFormation(entityProperties, lineItems){
        const obj = {
          'notifRecipUser' : []
        };
        // let requestItems = [];
        entityProperties.forEach(element => {
          if(element.cName === 'GROUP_ID'){
            obj['groupId']= element.cVal;
          }
          if(element.cName === 'GROUP_NAME'){
            obj['groupName']= element.cVal;
          }
          if(element.cName === 'COUNTRY_ID'){
            obj['country']= element.cVal;
          }
          if(element.cName === 'DESCRIPTION'){
            obj['description']= element.cVal;
          }
        });
        //requestItems.push(obj);
        let objForUser = {};
        lineItems.forEach((elementCells, indexparent ) => {
          elementCells.cells.forEach((element , index ) => {
            if(element.cName === 'USER_ID'){
              objForUser['userId']= element.cVal;
            }
            if(element.cName === 'isNewItem'){
              objForUser['isNew']= element.cVal;
            }
              objForUser['isDeleted']= false;
              objForUser['userLineNum']= indexparent+1;
          });
          obj['notifRecipUser'].push(objForUser);   
          objForUser = {}; 
        });
       return obj;
      }
}
