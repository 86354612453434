<div class="row">
  <div class="col-md-12 usrListPos">
    <div class="panel panel-default userListPanel" style="margin-bottom: 0px">
      <div class="panel-body">
        <div style="width:100%;height: 500px">

          <form class="form-horizontal" role="form" STYLE="margin: 0px 20px 20px 20px;">
            <div class="row">
              <label for="name" class="col-sm-2 control-label">{{resourceService.get('lbl_tms_driver_name')}}</label>
              <div class="col-xs-2 carrier-detail-form-col">
                <input type="text" class="form-control sr-box" id="name" name="name" [(ngModel)]="searchCondition.name"
                       (ngModelChange)='updateFilter()' autocomplete="off">
              </div>
              <label for="phone" class="col-sm-2 control-label">{{resourceService.get('lbl_tms_phone')}}</label>
              <div class="col-sm-2 carrier-detail-form-col">
                <input type="text" class="form-control sr-box" id="phone" name="phone" (ngModelChange)='updateFilter()'
                       [(ngModel)]="searchCondition.phone" autocomplete="off">
              </div>
            </div>
          </form>

          <div class="userlist" style="margin: 0px 20px">
            <!-- <p-dataTable [value]="pageInfo.content" rowHover="true" reorderableColumns="true"
                         resizableColumns="true" [(selection)]="selectedRow" (onRowClick)="onRowClick($event)"
                         columnResizeMode="expand" scrollable="true" scrollHeight="360px">
              <p-column [style]="{'padding':'8px','width':'36px'}" class="text-center"
                        selectionMode="single"></p-column>
              <p-column [style]="{'width':'190px'}" *ngFor="let column of columnDef" [field]="column.propertyName"
                        [header]="column.elementId">
                <ng-template pTemplate="header">
                  <span class="cell-header">{{column.elementId}}</span>
                </ng-template>
                <ng-template let-row="rowData" pTemplate="body">
                  <span class="cell-view">{{ row[column.propertyName] }}</span>
                </ng-template>
              </p-column>
            </p-dataTable> -->
            <p-table [columns]="columnDef" [value]="pageInfo.content" [(selection)]="selectedRow" rowHover="true" selectionMode="multiple"
            scrollHeight="240px" resizableColumns="true" columnResizeMode="expand" reorderableColumns="true" >
                   <ng-template pTemplate="header" let-columns>
                       <tr>
                        <td style="width: 3rem"></td>
                           <th *ngFor="let col of columns">
                               {{col.elementId}}
                           </th>
                       </tr>
                   </ng-template>
                   <ng-template pTemplate="body" let-rowData let-columns="columns">
                       <tr>
                        <td style="text-align: center">
                          <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
                        </td> 
                           <td *ngFor="let col of columns"  style="cursor: pointer">
                               <span class="cell-view">{{ rowData[col.propertyName] }}</span>
                           </td>
                       </tr>
                   </ng-template>
               </p-table>
            <div class="rdt-top-bar">
              <p-paginator #paginator [rows]="pageInfo.size" [totalRecords]="pageInfo.totalElements"
                           [rowsPerPageOptions]="[5,10,20,50]" (onPageChange)="onPageChange($event)"></p-paginator>
            </div>
          </div>

          <div class="text-center">
            <button class="lfwms-btn-default" (click)="onConfirm()">{{resourceService.get('btn_confirm')}}</button>
            <button class="lfwms-btn-default" (click)="onCancel()">{{resourceService.get('btn_cancel')}}</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
