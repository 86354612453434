import { Injectable } from '@angular/core';

@Injectable()
export class LocalPagedDataManager {


    /**
     *this method contains logic to filter records based on search criteria
     *
     */
    public filterDataForSearchCriteria(searchCriteria, fullData) {
        const filteredData = fullData.filter(rowData => {
            if (rowData && rowData.cells) {
                return this.doesRowSatisfyCriteria(searchCriteria, rowData);
            } else {
                return false;
            }
        });
        return filteredData;
    }

    public doesRowSatisfyCriteria(searchCriteria, rowData) {
        const filterConditions = searchCriteria.conditions;
        let patternMatch = true;
        filterConditions.forEach(condition => {
            if (!condition.clauses) {
                return;
            }

            condition.clauses.forEach(clause => {
                if (clause.value && clause.value !== '*') { // For handling value = * in search criteria
                    const columnName = clause.column;
                    const columnValue = clause.value;
                    const pattern = new RegExp('^' + columnValue, 'i');
                    if (rowData.cells[columnName] && rowData.cells[columnName].cVal) {
                        if (!pattern.test(rowData.cells[columnName].cVal)) {
                            patternMatch = false;
                            return;
                        }
                    }
                }
            });
            if (!patternMatch) {
                return;
            }
        });
        return patternMatch;
    }

    // method used to sort the column
    public sortColumn(rows: any[], sortColumnMetaData: any, sortOrder: number) {
        if (!sortColumnMetaData || ((sortOrder !== 1) && (sortOrder !== 0)) || !rows || rows.length === 0) {
            // if any of the inputs is invalid, do not sort
            return;
        }
        const reA = /[^a-zA-Z]/g;
        const reN = /[^0-9]/g;
        const columnName = sortColumnMetaData.propertyName;
        const sortColumnType = sortColumnMetaData.displayType;
        const sortColumnDatatype = sortColumnMetaData.dataType;
        /* LFWM 1466 - If the data type of column to be sorted is Integer then,
        its cVal is typecasted to Integer before comparison for proper sorting */
        if (sortOrder === 0) {
            rows.sort((a: any, b: any) => {
                if (sortColumnType !== 'DATE') {
                    if (sortColumnDatatype === 'Integer') {
                        if (+a.cells[columnName].cVal < +b.cells[columnName].cVal) {
                            return -1;
                        } else if (+a.cells[columnName].cVal > +b.cells[columnName].cVal) {
                            return 1;
                        } else {
                            return 0;
                        }
                    } else {

                        const value1 = a.cells[columnName].cVal;
                        const value2 = b.cells[columnName].cVal;
                        const replacedVAr1 = value1.replace(reA, '');
                        const replacedVAr2 = value2.replace(reA, '');
                        if (replacedVAr1 && replacedVAr2 && (replacedVAr1 === replacedVAr2)) {
                            const replaceVAr1 = parseInt(value1.replace(reN, ''), 10);
                            const replaceVAr2 = parseInt(value2.replace(reN, ''), 10);
                            return replaceVAr1 === replaceVAr2 ? 0 : replaceVAr1 < replaceVAr2 ? 1 : -1;
                        } else {
                            if (replacedVAr1 < replacedVAr2) {
                                return 1;
                            } else if (replacedVAr1 > replacedVAr2) {
                                return -1;
                            } else {
                                return 0;
                            }

                        }
                    }
                } else {
                    if (a.cells[columnName].cVal === null) {
                        return 1;
                    } else if (b.cells[columnName].cVal === null) {
                        return -1;
                    } else if (a.cells[columnName].cVal === b.cells[columnName].cVal) {
                        return 0;
                    } else if (a.cells[columnName].cVal !== null && b.cells[columnName].cVal !== null) {
                        let aDate: Date;
                        let bDate: Date;
                        aDate = new Date(a.cells[columnName].cVal);
                        bDate = new Date(b.cells[columnName].cVal);
                        return (aDate > bDate) ? -1 : ((bDate > aDate) ? 1 : 0);
                    }
                }
            });
        } else if (sortOrder === 1) {
            rows.sort((a: any, b: any) => {
                if (sortColumnType !== 'DATE') {
                    if (sortColumnDatatype === 'Integer') {
                        if (+a.cells[columnName].cVal < +b.cells[columnName].cVal) {
                            return 1;
                        } else if (+a.cells[columnName].cVal > +b.cells[columnName].cVal) {
                            return -1;
                        } else {
                            return 0;
                        }
                    } else {
                        const replacedVAr1 = a.cells[columnName].cVal.replace(reA, '');
                        const replacedVAr2 = b.cells[columnName].cVal.replace(reA, '');
                        if (replacedVAr1 === replacedVAr2) {
                            const replaceVAr1 = parseInt(a.cells[columnName].cVal.replace(reN, ''), 10);
                            const replaceVAr2 = parseInt(b.cells[columnName].cVal.replace(reN, ''), 10);
                            return replaceVAr1 === replaceVAr2 ? 0 : replaceVAr1 < replaceVAr2 ? -1 : 1;
                        } else {
                            if (replacedVAr1 < replacedVAr2) {
                                return -1;
                            } else if (replacedVAr1 > replacedVAr2) {
                                return 1;
                            } else {
                                return 0;
                            }
                        }
                    }
                } else {
                    if (a.cells[columnName].cVal === null) {
                        return 1;
                    } else if (b.cells[columnName].cVal === null) {
                        return -1;
                    } else if (a.cells[columnName].cVal === b.cells[columnName].cVal) {
                        return 0;
                    } else if (a.cells[columnName].cVal !== null && b.cells[columnName].cVal !== null) {
                        let aDate: Date;
                        let bDate: Date;
                        aDate = new Date(a.cells[columnName].cVal);
                        bDate = new Date(b[columnName.prop]);
                        return (aDate > bDate) ? 1 : ((bDate > aDate) ? -1 : 0);
                    }
                }
            });
        }
    }
}
