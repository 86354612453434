import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/all-modules';
// import { SearchEvent } from '../../../../../../../shared/search/models/search-event';
import { SearchEvent } from 'src/app/shared/search/models/search-event';
import { ITEM_CLICK_EVENT } from 'src/app/shared/search/search-constants';

@Component({
  selector: 'lfwms-order-Event-cell-renderer',
  templateUrl: './basic-cell-renderer.component.html',
  styleUrls: ['./basic-cell-renderer.component.css']
})
export class BasicCellRendererComponent implements ICellRendererAngularComp {

  public isLoading: boolean = true;
  public params: any;
  public templateId: number = 0;
  public cellValue: any;
  public cellDisplayValue: any;

  // Flag to display the 'x' mark / checkbox
  public isNewRow: boolean = false;

  constructor() {

  }

  public agInit(params: ICellRendererParams): void {
    
    this.params = params;
    this.cellValue = this.params.value;
    console.debug('cellValue',this.cellValue, params);
    
    /* For new rows, row selection checkbox will be
      replaced with row delete button 'x' button */
    // if (this.params.node.data['isNewItem']) {
    //   this.isNewRow = this.params.node.data['isNewItem'].cVal;
    // }
    // if (this.params.column.colDef.colId === 'isSelected') {
    //   // Row selection checkbox template
    //   this.templateId = 1;
    //   this.cellValue = this.params.value;
    // } else if (this.params.value) {
    //   this.cellValue = this.params.value.cVal;
    //   if (this.params.context.componentParent
    //     .tableConfig.showIdAsAHyperLink && (this.params.column.colDef.colId
    //       === this.params.context.componentParent
    //         .searchService.searchParams.idProperty)) {
    //     // Hyperlink template
    //     this.templateId = 2;
    //   } else if (this.params.cellMetaData
    //     && this.params.cellMetaData.dataType === 'Currency') {
    //     // Currency template
    //     this.templateId = 3;
    //   } else if (this.params.cellMetaData
    //     && this.params.cellMetaData.type === 'DROPDOWN' || this.params.cellMetaData.type === 'EDITABLEDROPDOWN') {
    //     const filteredOptions: Array<any> = this.params.cellMetaData.values
    //       .filter((option: any) => {
    //         return option.key === this.cellValue;
    //       });
    //     this.cellDisplayValue
    //       = ((filteredOptions.length > 0) ?
    //         filteredOptions[0].value : this.cellValue);
    //     if (this.cellDisplayValue === '- Select -') {
    //       this.cellDisplayValue = '';
    //     }
    //     // Dropdown template
    //     this.templateId = 4;
    //   } else if (this.params.cellMetaData
    //     && (this.params.cellMetaData.type === 'CHECKBOX' || this.params.cellMetaData.type === 'CHECKBOX_CHR')) {
    //     // Adding CHECKBOX_CHR type denoting the saved value will be 'Y' or 'N'
    //     // CHECKBOX type denoting the saved value will be '1' or '0'
    //     // Checkbox template
    //     this.templateId = 5;
    //     this.cellValue = this.params.value.cVal;
    //   } else if (this.params.cellMetaData &&
    //     (this.params.cellMetaData.type === 'DATE' || this.params.cellMetaData.type === 'DATETIME')) {
    //     // Date or Datetime Template
    //     this.templateId = 6;
    //     if (this.params.cellMetaData.type === 'DATE' && this.params.value.cVal) {
    //       this.cellValue = this.dateConfigService.getFormattedDisplayDate(this.params.value.cVal);
    //     } else if (this.params.cellMetaData.type === 'DATETIME' && this.params.value.cVal) {
    //       this.cellValue = this.dateConfigService.getFormattedDisplayDateTime(this.params.value.cVal);
    //     }
    //   } else {
    //     // Default renderer template
    //     this.templateId = 0;
    //   }
    // }

    // if (this.params.value.cName === 'REAL_TIME') {
    //   if (this.params.value.cVal === 'Y') {
    //     this.cellValue = 'ON';
    //   } else if (this.params.value.cVal === 'N') {
    //     this.cellValue = 'OFF';
    //   }
    // }

    // // if (this.params.context.componentParent.searchParams.tableId === 'evtmappingsearchtd') {
    // if (this.params.value.cName === 'SCHEDULER_ACTIVE') {
    //   this.templateId = 7;
    //   if (this.params.value.cVal === 'Y') {
    //     this.cellValue = '1';
    //   } else if (this.params.value.cVal === 'N') {
    //     this.cellValue = '0';
    //   }
    // }
    //} 
    this.isLoading = false;
  }

  public refresh(params: any): boolean {
    this.params = params;
    // if (this.params.column.colDef.colId === 'isSelected') {
    //   this.cellValue = this.params.value;
    // } else {
    //   if (this.params.value) {
    //     /* JIRA LFWM-1756 Fixes - Date format [country specific format ] of already existing date changes
    //    to default input format [YYYY-MM-DD HH:mm:ss] when choosing dates from calender. It should be displyed
    //    in the country specific format always */
    //     if (this.params.colDef.cellEditorParams.cellMetaData.type === 'DATE' && this.params.value.cVal) {
    //       this.cellValue = this.dateConfigService.getFormattedDisplayDate(this.params.value.cVal);
    //     } else if (this.params.colDef.cellEditorParams.cellMetaData.type === 'DATETIME' && this.params.value.cVal) {
    //       this.cellValue = this.dateConfigService.getFormattedDisplayDateTime(this.params.value.cVal);
    //     } else {
    //       this.cellValue = this.params.value.cVal;
    //     }
    //   }
    // }
    return true;
  }

  public navigateToHyperlink() {
    const searchEvent: SearchEvent = new SearchEvent(ITEM_CLICK_EVENT);
    searchEvent.selectedItemId = this.params.value.cVal;
    searchEvent.selectedProperty = this.params.colDef.field;
    searchEvent.selectedRow = this.params.context.componentParent.searchService
      .getPagedData().pageRows[this.params.rowIndex];
    if (this.params.cellMetaData.moduleId === 'GroupNameTd' && searchEvent.selectedRow.cells['GROUP_ID'] !== undefined) {
      if (searchEvent.selectedRow.cells['GROUP_ID'].cVal != null) {
        searchEvent.selectedItemId = searchEvent.selectedRow.cells['GROUP_ID'].cVal;
        searchEvent.selectedProperty = searchEvent.selectedRow.cells['GROUP_ID'].cName;
      }
    }

    // For Template(screen)  search page  
    if (this.params.cellMetaData.moduleId === 'templateId' && searchEvent.selectedRow.cells['TEMPLATE_ID'] !== undefined) {
      if (searchEvent.selectedRow.cells['TEMPLATE_ID'].cVal != null) {
        searchEvent.selectedItemId = searchEvent.selectedRow.cells['TEMPLATE_ID'].cVal;
        searchEvent.selectedProperty = searchEvent.selectedRow.cells['TEMPLATE_ID'].cName;
      }
    }

    // For Template view in load template popup
    if (this.params.cellMetaData.moduleId === 'emailTemplateId' && searchEvent.selectedRow.cells['TEMPLATE_ID'] !== undefined) {
      this.params.context.componentParent.searchService.passEmailTemplateValues(searchEvent.selectedRow.cells.TEMPLATE.cVal);
    }

      // For Scheduler(screen)  search page  
      if (this.params.cellMetaData.moduleId === 'schedulerId' && searchEvent.selectedRow.cells['SCHEDULER_ID'] !== undefined) {
        if (searchEvent.selectedRow.cells['SCHEDULER_ID'].cVal != null) {
          searchEvent.selectedItemId = searchEvent.selectedRow.cells['SCHEDULER_ID'].cVal;
          searchEvent.selectedProperty = searchEvent.selectedRow.cells['SCHEDULER_ID'].cName;
        }
      }


    this.params.context.componentParent.searchService.publishEvent(searchEvent);

  }

  public changeOnOff(event) {
    if (this.params.value.cName === 'IS_INTERFACE') {
      if (event.target.checked) {
        this.params.value.cVal = '1';
        this.cellValue = '1';
      } else {
        this.params.value.cVal = '0';
        this.cellValue = '0';
      }
    }
  }
}
