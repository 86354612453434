import { Injectable } from '@angular/core';
import { DetailedEntityButtonService } from '../../shared/services/searchable-entity/detailed-entity/detailed-entity-button.service';
import { CoreServiceRegistry } from '../../core/core-service-registry';
import { SharedServiceRegistry } from '../../shared/shared-service-registry';
import { ButtonItem } from '../../shared/button/button.model';
import { FleetMonitoringActionService } from './fleet-monitoring-action.service';
import { AUTO_ADD_ROW_EVENT, NEW_ROW_EVENT, NEW_SEARCH_EVENT } from '../../shared/search/search-constants';
import { SearchEvent } from '../../shared/search/models/search-event';
import { ViewTransactionsPopupComponent } from 'src/app/shared/components/common/view-transactions-popup/view-transactions-popup.component';
import { FleetMonitoringDataEditorService } from './fleet-monitoring-data-editor.service';

@Injectable()
export class FleetMonitoringButtonService extends DetailedEntityButtonService {

  public actionService: FleetMonitoringActionService;
  


  constructor(public coreServiceRegistry: CoreServiceRegistry,
    public sharedServiceRegistry: SharedServiceRegistry , public dataEditor: FleetMonitoringDataEditorService) {
    super(coreServiceRegistry, sharedServiceRegistry);
  }
  // public buttons: any = [{
  //   'action': 'Search',
  //   'elementId': 'Search',
  //   'accessPrivilege': '',
  //   'imageUrl': 'icon-search-action.svg',
  //   'subItems': [],
  //   'specialButtonConfig': '',
  //   'isEnabled': true,
  //   'isVisible': true,
  //   'hovered': false
  // }];
  public getAllButtons(): any {
    return {
      'search-main': [
        new ButtonItem('Create', this.resourceService.get('lbl_create_new_PG'), '', 'icon_new_doc.svg', [])
      ],
      'search-table': [
        new ButtonItem('Edit', this.resourceService.get('btn_edit'), '', 'icon_edit.svg', []),
        new ButtonItem('SaveTbl', this.resourceService.get('btn_save'), '', 'icon_save.svg', []),
        new ButtonItem('Delete', this.resourceService.get('btn_delete'), '', 'icon_delete.svg', []),
      ],
      'detail-main': [
        new ButtonItem('EditDtl', this.resourceService.get('btn_edit'), '', 'icon_edit.svg', []),
        new ButtonItem('Save', this.resourceService.get('btn_save'), '', 'icon_save.svg', []),
        // new ButtonItem('Delete', this.resourceService.get('btn_delete'), 'vas_trnsfr_del', 'icon_delete.svg', []),
        new ButtonItem('CancelDtl', this.resourceService.get('Cancel'), '', 'button-close.svg', []),
        // new ButtonItem('Allocate', this.resourceService.get('btn_allocate'), 'vas_trnsfr_alloc', 'icon_populate.svg', []),
        // new ButtonItem('View Transactions', this.resourceService.get('btn_view_transaction'), 'vas_trnsfr_vw_tr', 'icon_finalize.svg', []),
        // new ButtonItem('Finalize', this.resourceService.get('btn_finalize'), 'vas_trnsfr_finlz', 'icon_finalize.svg', []),
        // new ButtonItem('Print', this.resourceService.get('btn_print'), 'vas_trfr_prnt_reprt', 'icon-print.svg', [
        //   new ButtonItem('Report', this.resourceService.get('transfer_label'), 'vas_trfr_prnt_reprt', 'icon-print.svg', []),
        //   new ButtonItem('Labels', this.resourceService.get('transfer_ticket'), 'vas_trfr_prnt_lbl', 'icon-print.svg', [])
        // ]),
        // new ButtonItem('Export From Detail Header', this.resourceService.get('lbl_export'), 'vas_trans_exp', 'icon-export.svg',
        //   [
        //     new ButtonItem('Export as Excel From Detail Header',
        //       this.resourceService.get('btn_exportExcel'), 'vas_trans_exp', 'icon-export.svg', []),
        //     new ButtonItem('Export as CSV From Detail Header',
        //       this.resourceService.get('btn_exportCSV'), 'vas_trans_exp', 'icon-export.svg', [])
        //   ]),
        // // [LFWM-2159] - dynamic button creation
        // new ButtonItem('RCM01', 'RCM01', 'InvTr_rcm01', '', []),
        // new ButtonItem('RCM02', 'RCM02', 'InvTr_rcm02', '', []),
        // new ButtonItem('RCM03', 'RCM03', 'InvTr_rcm03', '', []),
        // new ButtonItem('RCM04', 'RCM04', 'InvTr_rcm04', '', []),
        // new ButtonItem('RCM05', 'RCM05', 'InvTr_rcm05', '', []),
      ],
      'detail-table': [
        new ButtonItem('Add', this.resourceService.get('Add'), '', 'icon-add-new-row.svg', []),
        // new ButtonItem('Add Recipients', this.resourceService.get('Add Recipients'), '', 'icon-add-new-row.svg', []),
        new ButtonItem('DeleteDtl', this.resourceService.get('btn_delete'), '', 'icon_delete.svg', []),
        // new ButtonItem('Delete_Main', this.resourceService.get('btn_delete'), 'vas_trnsfr_dtl_del', 'icon_delete.svg', [
        //   new ButtonItem('Delete Line Item(s)', this.resourceService.get('btn_delete_line'), 'vas_trnsfr_dtl_del', 'icon_delete.svg', []),
        //   new ButtonItem('Delete All Line Items', this.resourceService.get('btn_delete_all_lines'), 'vas_trnsfr_dtl_del', 'icon_delete.svg', []),
        // ]),
        // new ButtonItem('Copy Value To All Rows', this.resourceService.get('btn_copy_value_to'), 'vas_trnsfr_dtl_add', 'icon_copyvalue.svg', []),
        // new ButtonItem('Populate Transfer', this.resourceService.get('btn_populate_transfer'), 'vas_trnsfr_pop', 'icon_populate.svg', []),
        // new ButtonItem('View Line Transactions',
        //   this.resourceService.get('btn_view_line_transaction'), 'vas_trnsfr_dtl_tr', 'icon_finalize.svg', []),
        // // new ButtonItem('Populate Transfer by UCC', this.resourceService.get('Populate Transfer by UCC'), 'hold_add', 'icon_populate.svg', []),
        // new ButtonItem('Populate Candidate Stock on Hand', this.resourceService.get('Populate Candidate Stock on Hand'), 'hold_add', 'icon_populate.svg', []),
        // new ButtonItem('Export From Detail', this.resourceService.get('lbl_export'), 'vas_tran_det_exp', 'icon-export.svg',
        //   [
        //     new ButtonItem('Export as Excel From Detail',
        //       this.resourceService.get('btn_exportExcel'), 'vas_tran_det_exp', 'icon-export.svg', []),
        //     new ButtonItem('Export as CSV From Detail',
        //       this.resourceService.get('btn_exportCSV'), 'vas_tran_det_exp', 'icon-export.svg', [])
        //   ])
      ]
    };
  }

  public getButtonsForPanel(panelId: string) {
    return this.userButtonMap[panelId];
  }


  public initializeSearchPageButtons() {
   // this.fetchUserPrivileges().subscribe( resp=> {
     // resp.userPrivilegeList.forEach(element => {
      //   this.allPrivilegesForUser.push(element.privilegeCode)
      // });
      this.userButtonMap['search-table'] = this.loadButtonsByPrivilege(this.allButtonsMap['search-table']);
      if (this.dataService.displayMode !== 'V') {
        this.rebuildButtonArray(this.userButtonMap['search-table'],
          [
            'SaveTbl', 'Delete'
          ]);
      } else {
        this.rebuildButtonArray(this.userButtonMap['search-table'],
          [
            'Edit'
          ]);
      }
      this.dataService.detailsSearchParams.actionButtons = this.getButtonsForPanel('search-table');
      
    //  this.enableTable = true;
   // })
    
  }

  // this method sets the visible and enabled flags for the buttons based on the display mode
  public initializeDetailPageButtons(cellDataMap: any) {
    if (this.dataService.displayMode === 'C' || this.dataService.displayMode === 'E') {
      this.rebuildButtonArray(this.userButtonMap['detail-main'],
        [
           'Save',
           'CancelDtl'
        ]);
      this.rebuildButtonArray(this.userButtonMap['detail-table'],
        [
          'DeleteDtl',
          'Add'
        ]);
    } else {
      this.rebuildButtonArray(this.userButtonMap['detail-main'],
        [
         'EditDtl'
        ]);
      this.rebuildButtonArray(this.userButtonMap['detail-table'],
        [
          'DeleteDtl',
          'Add'
        ]);

        // if (!this.dataService.detailsSearchService ||
        //   !this.dataService.detailsSearchService.getPagedData()
        //   || !this.dataService.detailsSearchService.getPagedData().pageRows
        //   || this.dataService.detailsSearchService.getPagedData().pageRows.length <= 0) {
        //   if (this.dataService.displayMode === 'C' || this.dataService.displayMode === 'E') {
        //     this.rebuildButtonArray(this.userButtonMap['detail-table'],
        //       [ 'Delete',
        //       'Add']);
        //   } else {
        //     this.rebuildButtonArray(this.userButtonMap['detail-table'],
        //       []);
        //   }
        // }
      /* [LFWM-2159] - to fetch dynamic buttons when loading details page
      * dynamicButtonsExist boolean variable added to avoid multiple backend calls.
      * dynamicButtonsExist - false - on initial page load. initialized as false in details page component
      * dynamicButtonsExist - true - it will be true once we fetch the dynamic button list from backend */
    //   if (!this.dynamicButtonsExist) {
    //     // [LFWM-2159] - to fetch dynamic buttons when loading details page
    //     this.actionService.getDynamicButtons().subscribe(resp => {
    //       if (resp.statusCode === 200 && resp.statusMessage === 'SUCCESS') {
    //         this.dynamicButtons = resp.rcmConfigButtonInformation;
    //         this.dynamicButtonsExist = true;
    //         /* [LFWM-2159] - replacing the static [ex: RCM01] button name with dynamic button name which is already created
    //         after getting the dynamic buttons information from backend */
    //         this.replacingStaticButtonNameWithDynamicButtonName();
    //         // [LFWM-2159] - setting the detail page buttons in view mode after fetching the dynamic buttons from backend
    //         this.setupEnabledButtonsInDetailPage(cellDataMap);
    //       }
    //     }, (error: any) => {
    //       this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
    //       this.setupEnabledButtonsInDetailPage(cellDataMap);
    //     });
    //   } else {
    //     // [LFWM-2159] - setting the detail page buttons in edit mode. in this scenario we have dynamic buttons in hand already
    //     this.setupEnabledButtonsInDetailPage(cellDataMap);
    //   }
    // }
    }
    this.userButtonMap['detail-main'] = this.loadButtonsByPrivilege(this.userButtonMap['detail-main']);
    this.userButtonMap['search-table'] = this.loadButtonsByPrivilege(this.userButtonMap['search-table']);
    this.userButtonMap['detail-table'] = this.loadButtonsByPrivilege(this.userButtonMap['detail-table']);
  }

  public setupEnabledButtonsInDetailPage(cellDataMap: any) {
    // [LFWM-2159] - detailMainButtons - temp array of detail main buttons in view or edit mode
    let detailMainButtons = [];
    if (this.dataService.displayMode === 'V') {
      if (this.dataService.primaryDataMap['status'].cVal !== 'CANC' && this.dataService.primaryDataMap['status'].cVal !== '9') {
        detailMainButtons = [
          'Save',
          'CancelDtl'

        ];
        this.rebuildButtonArray(this.userButtonMap['detail-table'],
          [
            'DeleteDtl',
            'Add'
          ]);
      } else {
        detailMainButtons = [
          'Save',
          'CancelDtl'
        ];
        this.rebuildButtonArray(this.userButtonMap['detail-table'],
          [
            'DeleteDtl',
            'Add'
          ]);
      }
    } else {
      detailMainButtons = [
        'Save',
        'CancelDtl'
            ];
      this.enableAllButtons(this.userButtonMap['detail-table']);
    }
    // [LFWM-2159] - adding dynamic buttons to detail main buttons
    if (this.dynamicButtons.length > 0) {
      this.dynamicButtons.forEach((button: any) => {
        detailMainButtons.push(button.staticButtonName.toUpperCase());
      });
    }
    /* [LFWM-2159] - re-initializing the detail main button array based on dynamic buttons fetched from backend and status of po */
    //this.rebuildButtonArray(this.userButtonMap['detail-main'], detailMainButtons);
  }

  public handleButtonActionForCreate(action: any) {
    let needOkConfirmation = false;
    switch (action) {
      case 'Copy Value To All Rows':
        needOkConfirmation = true;
        break;
    }
    if (needOkConfirmation) {
      const dialogMsg = this.dataService.createAlertMessage(this.resourceService.get('err_msg_save_data'));
      this.confirmDialogService.okDialog(this.resourceService.get(dialogMsg));
    } else {
      this.performButtonActions(action);
    }
  }

  public handleButtonActionForEdit(action: any) {
    let needYesNoConfirmation = false;
    switch (action) {
      // if there are any actions which need confirmation, add it here.
      case 'Edit':
        needYesNoConfirmation = true;
        break;
      case 'Populate Transfer': {
        needYesNoConfirmation = true;
        break;
      }
    }
    if (needYesNoConfirmation) {
      const dialogMsg = this.dataService.createAlertMessage(this.resourceService.get('msg_conf_save_data'));
      this.confirmDialogService.yesnocancelDialog(this.resourceService.get(dialogMsg)).subscribe((isConfirmed: any) => {
        if (isConfirmed === true) {
          this.actionService.savePage().subscribe((response: any) => {
            if (response.statusCode && response.statusCode === 200) {
              const successMsg = 'msg_save_succ';
              this.alertService.clearAll().success(this.resourceService.get(successMsg));
              this.dataService.getDetailsSearchService().clearMarkerFlags();
              this.actionService.clearEditedFlags();
              this.navService.preventNavigation = false;
              this.performButtonActions(action);
            } else {
              this.alertService.clearAll().error(this.resourceService.translateServerResponse(response.statusMessage));
            }
          }, (error) => {
            this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
          });
        } else if (isConfirmed === false) {
          this.performButtonActions(action);
        }
      });
    } else {
      this.performButtonActions(action);
    }
  }

  public performButtonActions(action: any) {
    switch (action) {
      case 'Edit': {

        this.actionService.editPage();
        break;
      }

      case 'DeleteDtl': {

        if(this.dataService.displayMode !== 'V'){
          this.actionService.dialogMsg = this.dataService.createAlertMessage(this.resourceService.get('msg_conf_del'));
          this.actionService.successMsg = this.resourceService.get('msg_del_succ');
          this.actionService.errorMsg = this.resourceService.get('msg_sel_detail');
          this.actionService.delete();
          break;
        } else {
          
          this.alertService.warn('Please click edit button to delete the recipients')

        }
        break;
      }
      case 'Add': {
        if(this.dataService.displayMode !== 'V'){
          const searchCriteria = {
            conditions: [{
              mandatory: true,
              clauses: [{
                column: '',
                operation: '=',
                value: '',
                logicalOperation: '',
                typeOfField:''
              }],
              logicalOperation: 'AND'
            }]
          };
          this.alertService.clearAll();
          const modalRef: any = this.sharedServiceRegistry.sceModalService
            .showModal(ViewTransactionsPopupComponent,
              {
                overlayHeader: this.resourceService.get('Add User'),
                showHeader: true,
                overlayHeight: 480,
                overlayWidth: 1024
              });
          modalRef.content
            .initialize(this.dataService.primarySearchService, this.dataEditor, searchCriteria);
            modalRef.content.receiveEvent()
            .subscribe((event: any) => {
                if (event.type === 'cancel') {
                  let rowsData = this.dataService.primarySearchService.getPagedData().pageRows;
                  event.selectedItems.forEach(element => {
                    element.cells['isNewItem'] = { cVal: true };
                    element.cells['isSelected'] = false;
                   // element['isNewRow'] = true;
                   });  
                   event.selectedItems.forEach(element => {
                    rowsData.push(element);
                    });                   
                  this.dataService.primarySearchService.setPagedRows(rowsData);
                  const events = new SearchEvent(NEW_SEARCH_EVENT);
                  events.isSearchSuccess = true;
                  this.dataService.primarySearchService.publishEvent(events);
                  modalRef.hide();
                }
              });
            } else {
               this.alertService.warn('Please click edit button to add the recipients')
            }
            break;
        }

      case 'Cancel' :{
        break;
      }
      case 'SaveTbl': {
        this.actionService.successMsg = this.resourceService.get('msg_save_succ');
        const saveObs = this.actionService.savePageForTable();
        if (saveObs) {
          saveObs.subscribe((response) => {
            if (response.statusCode === 200) {
              // Clear edit flags
             // this.dataService.searchService.clearMarkerFlags();
              // this.actionService.clearEditedFlags();if (this.dataService.searchService.getNewPageEventInProgress()) {
              // this save has come while navigating to a new page. Hence continue the navigation
              this.alertService.clearAll().success(this.actionService.successMsg);
              if (this.dataService.displayMode === 'E') {
                this.dataService.setDisplayMode('V');
                this.initializeSearchPageButtons();
              }
              this.dataService.getSearchService().performSearch(); // Added to refresh the data
              this.navService.preventNavigation = false;
            } else {
              this.alertService.clearAll().error(this.resourceService.get(response.error));
            }
          }, (error) => {
            this.alertService.clearAll().error(this.resourceService.get(error));
          });
        }
        break;
      }
      case 'Delete': {
        this.actionService.dialogMsg = this.dataService.createAlertMessage(this.resourceService.get('msg_conf_del'));
        this.actionService.successMsg = this.resourceService.get('msg_del_succ');
        this.actionService.errorMsg = this.resourceService.get('msg_sel_detail');
        this.actionService.delete();
        // if (this.dataService.displayMode === 'E') {
        //   this.dataService.setDisplayMode('V');
        //   this.initializeSearchPageButtons();
        // }
        break;
      }
      case 'Create': {
        this.actionService.showDetailsPage();
        break;
      }
       case 'EditDtl': {
         this.actionService.editPageDtl();
         break;
       }
       case 'Save': {
          this.actionService.successMsg = 'msg_save_succ';
          const saveObs = this.actionService.savePage();
          if (saveObs) {
            saveObs.subscribe((response) => {
              if (response.statusCode === 200) {
                // If there are line items in details page
                if (this.dataService.entityConfig.getShowDetailLineItems()) {
                  if (!this.dataService.getPrimarySelectedDocument()) {
                    this.dataService.setPrimarySelectedDocument(response.groupId);
                  }
                  // Setting Search Criteria in Details Search Service which is the input for Data Table in Details Accordion
                  if (this.dataService.displayMode === 'C') {
                    this.dataService.getDetailsSearchService().setSearchCriteria({
                      conditions: [{
                        mandatory: false,
                        clauses: [{
                          column: this.dataService.primarySearchParams.idProperty,
                          operation: '=',
                          value: this.dataService.getPrimarySelectedDocument(),
                          logicalOperation: null
                        }],
                        logicalOperation: null
                      }]
                    });
                 }
                  this.dataService.getDetailsSearchService().clearMarkerFlags();
                  if (this.dataService.detailsSearchService.getNewPageEventInProgress()) {
                    // this save has come while navigating to a new page. Hence continue the navigation
                    this.dataService.detailsSearchService.setNewPageEventInProgress(false);
                    // if this was a create flow, set it to edit flow
                    if (this.dataService.displayMode === 'C') {
                      this.dataService.setDisplayMode('E');
                      this.initializeButtons(this.dataService.primaryDataMap);
                    }
                    const evt = new SearchEvent(AUTO_ADD_ROW_EVENT);
                    this.dataService.detailsSearchService.publishEvent(evt);
                    return;
                  }
                }
                this.actionService.clearEditedFlags();
                const successMsg = 'msg_save_succ';
                this.alertService.clearAll().success(this.resourceService.get(successMsg));
                this.navService.preventNavigation = false;
                if (this.dataService.displayMode === 'C') {
                  this.navService.removeLastBreadCrumbItem();
                  let id;
                  if (this.dataService.getPrimarySelectedDocument()) {
                    id = this.dataService.getPrimarySelectedDocument();
                  } else {
                    id = response.groupId;
                  }
                  this.dataService.setDisplayMode('V');
                  this.initializeButtons(this.dataService.getPrimaryDataMap());
                   // If there are line items in details page
                  //  Jira 1823 , as we have navigatewithRouteParms, it will do the same loadBypage, so an extra call leads to duplication of data
                  // if (this.dataService.entityConfig.getShowDetailLineItems()) {
                  //   this.dataService.getDetailsSearchService().performSearch();
                  // }
                  this.navService.retainAlerts().navigateWithRouteParams(this.dataService.getEntityConfiguration().getDetailPageRoute(), [id]);
                } else if (this.dataService.displayMode === 'E') {
                  this.dataService.setDisplayMode('V');
                  this.initializeButtons(this.dataService.getPrimaryDataMap());
                }
                this.dataService.detailsSearchService.performSearch();
              } else {
                 // If there are line items in details page
                if (this.dataService.entityConfig.getShowDetailLineItems()) {
                  if (this.dataService.detailsSearchService.getNewPageEventInProgress()) {
                    this.dataService.detailsSearchService.setNewPageEventInProgress(false);
                  }
              }
                this.alertService.clearAll();
                this.alertService.error(this.resourceService.get(response.statusMessage));
              }
            }, (error) => {
               // If there are line items in details page
              if (this.dataService.entityConfig.getShowDetailLineItems()) {
                if (this.dataService.detailsSearchService.getNewPageEventInProgress()) {
                  this.dataService.detailsSearchService.setNewPageEventInProgress(false);
                }
            }
              this.alertService.error(this.resourceService.get(error));
            });
          }
  
          break;
      }
       case 'CancelDtl': {
        this.actionService.dialogMsg = this.dataService.createAlertMessage(this.resourceService.get('Are you sure want to navigate'));
        // this.actionService.successMsg = this.resourceService.get('msg_del_succ');
        // this.actionService.errorMsg = this.resourceService.get('msg_sel_detail');
        this.actionService.cancelDtl();
        break;
       }
    
    }
  }

  public exportAsExcelFromDetailHeader() {
    this.actionService.exportData(this.dataService.detailsSearchService, 'excel', this.dataService.primarySearchParams.tableId);
  }

  public exportAsCSVFromDetailHeader() {
    this.actionService.exportData(this.dataService.detailsSearchService, 'csv', this.dataService.primarySearchParams.tableId);
  }

  public exportAsExcelFromDetail() {
    this.actionService.exportData(this.dataService.detailsSearchService, 'excel');
  }

  public exportAsCSVFromDetail() {
    this.actionService.exportData(this.dataService.detailsSearchService, 'csv');
  }

  public loadButtonsByPrivilege(fullButtonList: Array<ButtonItem>) {
    if (!fullButtonList) {
      return [];
    } else {
      fullButtonList.forEach((button: ButtonItem) => {
        this.enableButtonByPrivilege(button);
      });
      // return fullButtonList;
      return fullButtonList.filter(button => button.isEnabled);
    }
  }
  public enableButtonByPrivilege(button: ButtonItem) {
    if (button.accessPrivilege === '') {
      button.isEnabled = true;
    } else {
     // button.isEnabled = this.allPrivilegesForUser.indexOf(button.accessPrivilege) !== -1;
    }
    if (button.subItems) {
      button.subItems.forEach((subButton: any) => {
        this.enableButtonByPrivilege(subButton);
      });
    }
  }
}
