import { Injectable } from '@angular/core';
import { AlertService } from 'sce-core';
import { ResourceService } from 'sce-core';

@Injectable()
export class ValidatorService {

    constructor(public alertService: AlertService, public resourceService: ResourceService) {
    }

    public validateField(value, dataType, alertService, elementId) {
        switch (dataType) {
            case 'Integer': {
                let isNumeric = true;
                const pattern = /[0-9\+\-\ ]/;
                if (value) {
                    for (const charS of value.trim()) {
                        if (!pattern.test(charS)) {
                            isNumeric = false;
                            break;
                        }
                    }
                }
                if (isNumeric === false) {
                    alertService.clearAll();
                    if (elementId) {
                        alertService.error(this.resourceService.get(elementId).concat(': ').concat(this.resourceService.get('lbl_intValErrMsg')));
                    } else {
                        alertService.error(this.resourceService.get('lbl_intValErrMsg'));
                    }
                    return 'lbl_intValErrMsg';
                }
                return '';
            }

            case 'Float': {
                let isFloat = true;
                let dotCount = 0;
                const pattern = /[0-9\+\-\ ]/;
                if (value) {
                    for (const charS of value.trim()) {
                        if (!pattern.test(charS)) {
                            if (charS === '.') {
                                dotCount++;
                            } else {
                                isFloat = false;
                                break;
                            }
                        }
                    }
                }
                if (isFloat === false || dotCount > 1) {
                    alertService.clearAll();
                    if (elementId) {
                        alertService.error(this.resourceService.get(elementId).concat(': ').concat(this.resourceService.get('lbl_floatValErrMsg')));
                    } else {
                        alertService.error(this.resourceService.get('lbl_floatValErrMsg'));
                    }
                    return 'lbl_floatValErrMsg';
                }
                return '';
            }
            case 'String': {
                let isString = true;
                const pattern = /^[a-zA-Z0-9._*/+-\s]*$/;
                if (value) {
                    for (const charS of value.trim()) {
                        if (!pattern.test(charS)) {
                            isString = false;
                            break;
                        }
                    }
                }
                if (isString === false) {
                    alertService.clearAll();
                    if (elementId) {
                        alertService.error(this.resourceService.get(elementId).concat(': ').concat(this.resourceService.get('lbl_intValErrMsg')));
                    } else {
                        alertService.error(this.resourceService.get('lbl_stringValErrMsg'));
                    }
                    return 'lbl_stringValErrMsg';
                }
                return '';
            }
            default: {
                return '';
            }
        }
    }
    // method used to validate whether the a detail property(header detail) value is null or empty or undefined
    public validateProperty(detailValue: any) {
        if (!detailValue) {
            return 'err_msg_val_prop';
        }
        return '';
    }

    // it iterates through the paged rows and will send each row to validateRow method
    public validateMultipleRows(metaData: any, pagedData: any): any | undefined {
        for (let i = 0; i < pagedData.pageRows.length; i++) {
            const err = this.validateRow(metaData, pagedData.pageRows[i]);
            if (err) {
                return err;
            }
        }
    }

    // This method receives a row either from the paged row or from the primary data map and validates it
    public validateRow(metaData: any, row: any): any | undefined {
        let err;
        let enteredValue; // stores the value entered by the user
        for (let i = 0; i < metaData.columnList.length; i++) {
            if (metaData.columnList[i].dataType === 'Integer' || metaData.columnList[i].dataType === 'Float') {
                // For line items
                if (row.cells) {
                    // validation needs to be done for a new row or edited row only
                    if (row.isNewRow || row.cells[metaData.columnList[i].propertyName].isEdited) {
                        enteredValue = row.cells[metaData.columnList[i].propertyName].cVal;
                        if (['expectedQty', 'receivedQty', 'orderedQty', 'openQty', 'qty'].includes(metaData.columnList[i].propertyName)) {
                            if (row.cells[metaData.columnList[i].propertyName].cVal !== 0) { // initially for a new row the value will be 0
                            const index = row.cells[metaData.columnList[i].propertyName].cVal.indexOf('.');
                            if (index > -1) {
                            enteredValue = row.cells[metaData.columnList[i].propertyName].cVal.substring(0, index);
                            }
                        }
                        }
                        err = this.validateField(enteredValue, metaData.columnList[i].dataType,
                            this.alertService, metaData.columnList[i].elementId);
                        if (err) {
                            return err;
                        }
                    }
                } else { // for accordion details
                    if (row[metaData.columnList[i].propertyName]) {
                        err = this.validateField(row[metaData.columnList[i].propertyName].cVal, metaData.columnList[i].dataType,
                            this.alertService, metaData.columnList[i].elementId);
                        if (err) {
                            return err;
                        }
                    }
                }
            }
        }
    }
}
