
import { throwError as observableThrowError, Observable, ReplaySubject, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { SearchService } from '../../../../../shared/search/search.service';
import { SearchParams } from '../../../../../shared/search/models/search-params.model';
import { SearchStatus } from '../../../../../shared/search/models/search-status.model';
import { PagedData } from '../../../../../shared/search/models/paged-data.model';
import { TableRow } from '../../../../../shared/search/models/table-row.model';
import { PageDefinition } from '../../../../../shared/search/models/page-definition.model';
import { NotificationTagDataService } from './notification-tag-data.service';
import { CoreServiceRegistry } from '../../../../../core/core-service-registry';
import { SharedServiceRegistry } from '../../../../../shared/shared-service-registry';
import { DELETE_ROW_EVENT, NEW_SEARCH_EVENT } from '../../../../../shared/search/search-constants';
import { SearchEvent } from '../../../../../shared/search/models/search-event';
import { AlertService, DatePickerConfigService, LoaderEnabled, ResourceService, UserService } from 'sce-core';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { metaDataTag, metaDataTagForDetails } from '../../notification-tag.config';
import { UserPreferenceService } from 'src/app/layout/home/services/user-preference.service';
import { NotificationTagBackendService } from './notification-tag-backend.service';



@Injectable()
export class NotificationTagSearchService extends SearchService {

    public dataService: NotificationTagDataService;
    public backendService: NotificationTagBackendService;

    
    public metaDataFetchSubjectTag: ReplaySubject<any>;
    maintainSearchCondtions: any;

    constructor(public http$: HttpClient,
      public searchParams: SearchParams,
      public searchStatus: SearchStatus,
      public resourceService: ResourceService,
      public userPreferenceService: UserPreferenceService,
      public alertService: AlertService,
      public dateConfigService: DatePickerConfigService,
      public userService: UserService) {
        super(http$, searchParams, searchStatus, resourceService, userPreferenceService, alertService, dateConfigService, userService);
        this.pagedData = new PagedData<TableRow>();
    }

    public setDataService(dataService: NotificationTagDataService) {
        this.dataService = dataService;
    }

    @LoaderEnabled()
    public fetchLastSearch(): Observable<any> {
      let searchType;
       if(this.searchParams.tableId === 'tagsearchtd'){
        searchType = 'tag';
      }
      const response$ = this.http$.get(`api/data/7/tagsearchtd/fetch/last/search`, { headers: this.hdr })
        catchError((error: any) => {
          return Observable.throw(error.message);
        });
      return response$;
    }

    public saveLastSearchCriteria(): Observable<any> {
        let searchType;
        const lastSearchUrl = `api/data/save/last/search`;
        if(this.searchParams.tableId === 'tagsearchtd'){
          searchType = 'tag';
        }
        const ReqPageInfo = {
          tableId: this.searchParams.tableId,
          pageNo: this.pagedData.pageDefinition.pageNumber,
          pageSize: this.pagedData.pageDefinition.pageSize,
          fullFetch: true,
        };
        const ReqCriteria = {
          pageInfo: ReqPageInfo,
          searchCriteria: this.formatSaveSearchCriteria(this.searchCriteria, this.searchParams.additionalParamMap, this.filterCondition)
        };
        const reqPayload = {
          type: 'tagsearchtd',
          appId: 7,
          searchId: this.searchId ? this.searchId : 0,
          criteria: JSON.stringify(ReqCriteria)
        };
        const dataUrl$: any
          = this.http$.post(lastSearchUrl, reqPayload, { headers: this.hdr });
        return dataUrl$.pipe(
          map((response: any) => {
          //  this.alertService.clearAll().success(response.statusMessage);
            return(response);
          }),
          catchError((error: any) => {
              return error;
            // if (errorObject.error && errorObject.error.errors && errorObject.error.errors[0]) {
            //   this.alertService.error(errorObject.error.errors[0].validationMessage);
            // }
          }));
          
      }


      public formatSaveSearchCriteria(searchCriteria, additionalParamMap, filterCondition?: any) {
        if (!searchCriteria) {
          return {};
        }
        let allConditions = null;
        // Code added to merge filter conditions with master search criteria, if filter condition is there.
        // if (filterCondition && filterCondition.clauses.length > 0) {
        //   allConditions = JSON.parse(JSON.stringify(searchCriteria.conditions));
        //   if (allConditions && allConditions.length > 0) {
        //     allConditions[allConditions.length - 1].operator = 'AND';
        //   }
        //   allConditions.push(filterCondition);
        // } else {
        //   allConditions = searchCriteria.conditions;
        // }
        allConditions = searchCriteria.conditions;
        const conditionReqList = [];
        const clauseReqlist = [];
        allConditions.forEach((condition: any, index: any) => {
          const clauseReqlist = [];
          condition.clauses.forEach((clause: any) => {
            if (clause.value !== undefined && clause.value !== null) {
                let value: any;
                let value1: any;
                // if (clause.typeOfField === 'MULTIDROPDOWN') {
                //   if (clause.value === 'Y') {
                //     value = 1;
                //   } else if (clause.value === 'N') {
                //     value = 0;
                //   } else if (clause.value === 'Y,N' || clause.value === 'N,Y') {
                //     value = '1,0';
                //   } else {
                //     value = clause.value;
                //   }
                //   if (clause.value1 === 'Y') {
                //     value1 = 1;
                //   } else if (clause.value1 === 'N') {
                //     value1 = 0;
                //   } else if (clause.value1 === 'Y,N' || clause.value1 === 'N,Y') {
                //     value = '1,0';
                //   } else {
                //     value1 = clause.value1;
                //   }
                // } else {
                  value = clause.value;
                  value1 = clause.value1;
                // }
                clauseReqlist.push({
                  column: clause.column,
                  operation: clause.typeOfField === 'MULTIDROPDOWN' ? 'IN' : clause.operation,
                  value: value,
                  value1: value1,
                  logicalOperation: clause.logicalOperation,
                  typeOfField: clause.typeOfField
                });
            }
          });
          if (clauseReqlist.length !== 0) {
            let logOp = null;
            if (index < allConditions.length - 1) {
              logOp = condition.operator;
            }
            conditionReqList.push({
              mandatory: condition.isMandatory,
              clauses: clauseReqlist,
              logicalOperation: logOp
            });
          }
        });
        if (additionalParamMap) {
          const additionalClauses = [];
          const additionalParams = additionalParamMap;
          const additionalProps = Object.keys(additionalParams);
          if (additionalProps.length > 0) {
            const lastCondition = conditionReqList.slice(conditionReqList.length - 1)[0];
            lastCondition.logicalOperation = 'AND';
            additionalProps.forEach((property: string, index: number) => {
              additionalClauses.push({
                column: property,
                operation: '=',
                value: additionalParams[property],
                logicalOperation: index === 0 ? '' : 'AND'
              });
            });
            conditionReqList.push({
              mandatory: false,
              clauses: additionalClauses,
              logicalOperation: ''
            });
          }
        }
        if (conditionReqList.length === 1) {
          conditionReqList[0].logicalOperation = '';
        }
        return { conditions: conditionReqList };
      }
    


    public performSearch() {
      if (this.searchParams.tableId === 'tagsearchtd') {
      //   this.fetchLastSearch().subscribe((resp: any) => {
      //     if (resp['statusCode'] && resp['statusMessage']) {
      //         if (resp['statusCode'] === 200 && resp['statusMessage'] === 'SUCCESS') {
      //             if (resp.criteria !== 'No records available') {
      //                 this.searchId = resp.searchId;
      //             }
      //         }
      //     }
      // });
          this.saveLastSearchCriteria().subscribe((data: any) => {
            this.searchId = data.searchId;
            this.fetchLastSearch().subscribe((resp: any) => {
              if (resp['statusCode'] && resp['statusMessage']) {
                  if (resp['statusCode'] === 200 && resp['statusMessage'] === 'SUCCESS') {
                      if (resp.criteria !== 'No records available') {
                          this.searchId = resp.searchId;
                      }
                  }
              }
          });
          });
      }
        if (this.searchParams.tableId === 'sailingsearchtd') {
          this.instructionForLegSearch.next('pass');
        }
        this.fetchSearchResults()
          .subscribe((data: any) => {
            this.searchStatus.hasResults = true;
            this.searchStatus.recordCount = this.pagedData.pageDefinition.recordCount;
            const event = new SearchEvent(NEW_SEARCH_EVENT);
            event.isSearchSuccess = true;
            this.searchCriteria = this.maintainSearchCondtions;
            this.publishEvent(event);
          });
      }

    public fetchSearchResults(): Observable<any> {
        this.pagedData.pageRows = [];
        const tableId = this.searchParams.tableId;
        // sort column is the display name. This should be converted to the original property name
        // let sortColumn = this.pagedData.pageDefinition.sortColumn;
        // if (sortColumn) {
        //     // hardcoding the property as from row
        //     sortColumn = this.dataService.getOriginalPropertyForDisplayProperty(sortColumn, 1);
        // }
        if (this.pagedData.pageDefinition.sortOrder === null) {
          this.pagedData.pageDefinition.sortOrder = 0;
        }
        let pageInfoReq = {
          pageNum: this.pagedData.pageDefinition.pageNumber,
          sortColumn: 'APP_NAME,COUNTRY_NAME,TAG',
          sortOrder: this.pagedData.pageDefinition.sortOrder,
          pageSize: this.pagedData.pageDefinition.pageSize,
          tableName: this.searchParams.tableId
        };
        // const pageInfoReq = {
        //     tableId: tableId,
        //     pageNo: this.pagedData.pageDefinition.pageNumber,
        //     sortColumn: sortColumn,
        //     sortOrder: this.pagedData.pageDefinition.sortOrder,
        //     pageSize: this.pagedData.pageDefinition.pageSize,
        //     fullFetch: this.searchParams.isFullFetch,
        //     fetchAllrecords: this.searchParams.paginateLocally
        // };
        // For inventory balances screen, Search criteria will be formatted using different method a the fields can
        // take null and undefined values.
        const payload = {
            pageInfo: pageInfoReq,
            searchCriteria: this.localFormatingSearchCri(
              this.searchCriteria , this.filterCondition),
            appId: 7,
            hiddenProperties: null,
            pinnedProperties: null,
            propertyIndexMap: null,
            columnOrder: null
        };

        if (!payload.searchCriteria.conditions) {
          return of({});
        }


        const hdr = new HttpHeaders({ 'api': 'notification' });
        let dataUrl$: any
        if(this.searchParams.tableId === 'addTagtd'){
         // this.searchParams.url = ''
         var jFile = {
          "pageNo": "1",
          "pageSize": "50",
          "sortColumn": null,
          "sortOrder": "0",
          "statusCode": 200,
          "statusMessage": "SUCCESS",
          "tableId": null,
          "totalRecords": "50",
          "pageData": {
            "rows":[]}
      }; 
      for(let i=1;i<=50;i++){
        let tagval;
        if(i<10){
         tagval = "TAG0" + i
        }else{
          tagval = "TAG" + i
        }
         const newData = {
          "rIdx" : i,
          "cells" : [
            {"cName": "TAG", "cVal": tagval},
            {"cName": "TAG_LABEL", "cVal": ""},
            {"cName": "SAMPLE_VALUE", "cVal": ""},
            {"cName": "DESCRIPTION", "cVal": ""},
            {"cName": "APP_NAME", "cVal": ""},
            {"cName": "COUNTRY_NAME", "cVal": ""},
            {"cName": "EVENT_CODE", "cVal": ""},
            {"cName": "APP_ID", "cVal": 2},
            {"cName": "COUNTRY_ID", "cVal": 10},
            {"cName": "TAG_ID", "cVal": ""}
       ],
         }
       jFile.pageData.rows.push(newData);
      }
         dataUrl$ = of(jFile);
        //  = this.http$.post(this.searchParams.url, JSON.stringify(payload));
        }else{
          this.getTagValues();
        this.searchParams.url = 'api/tag/search'
         dataUrl$
        = this.http$.post(this.searchParams.url, JSON.stringify(payload),{ headers: hdr });
        }
      return dataUrl$.pipe(
          map((response: any) => {
                  return this.transformSearchResponse(response);
              }),
              catchError((error: any) => {
              // if (errorObject.error && errorObject.error.errors && errorObject.error.errors[0]) {
              //   this.alertService.error(errorObject.error.errors[0].validationMessage);
              // }else if(errorObject.error.statusCode === 403 && errorObject.error.statusMessage === 'Access is denied') {
              //   this.alertService.error(errorObject.error.statusMessage);
              // }
              // JIRA LFWM - 1716 When the user provides any invalid filter value then the previous result data should be cleared
              this.pagedData.pageDefinition.recordCount = 0;
              this.pagedData.pageRows.splice(0, 0);
              this.searchStatus.hasResults = true;
              this.searchStatus.recordCount = 0;
              const event = new SearchEvent(NEW_SEARCH_EVENT);
              event.isSearchSuccess = false;
              this.publishEvent(event);
              return error;
              // return Observable.throw('Failed to fetch  Search Results :: ' + errorObject.message);
            }));
        // const dataUrl$ = this.http$.post(`api/data/` + this.searchParams.tableId + `/loadByPage`, JSON.stringify(payload));
        // return dataUrl$.pipe(map((response: any) => {
        //     return this.transformSearchResponse(response);
        // }),
        //     catchError((error: any) => {
        //         return observableThrowError('Failed to fetch  Search Results :: ' + error.message);
        //     }));
    }

    /**
     * Transforms the search response to the client format
     *
     * @param response - Server response
     */
    // public transformSearchResponse(response: any) {
    //     const pDef: PageDefinition = this.pagedData.pageDefinition;
    //     const pData: Array<TableRow> = this.pagedData.pageRows;
    //     pDef.pageNumber = +response['pageNo'];
    //     pDef.sortColumn = response['sortColumn'];
    //     pDef.sortOrder = +response['sortOrder'];
    //     pDef.recordCount = +response['totalRecords'];
    //     pDef.pageSize = +response['pageSize'];
    //     let index = 0;
    //     for (let i = 0; i < response['pageData'].rows.length; i++) {
    //         // Adding code for DataTable View Structure Transformation
    //         const rowDefinition = response['pageData'].rows[i];
    //         const lineArray = [];
    //         const fromArray = [];
    //         const toArray = [];
    //         rowDefinition.cells.forEach((cell: any) => {
    //             if (this.dataService.lineItemProperties.indexOf(cell.cName) !== -1) {
    //                 lineArray.push(cell);
    //             } else if (this.dataService.fromItemProperties.indexOf(cell.cName) !== -1) {
    //                 fromArray.push(cell);
    //             } else if (this.dataService.toItemProperties.indexOf(cell.cName) !== -1) {
    //                 toArray.push(cell);
    //             }
    //             cell.cName = this.dataService.propertyNameToDisplayNameMap[cell.cName];
    //         });
    //         pData.push(this.formRowObject(lineArray, rowDefinition.rIdx + index, 'lineItem'));
    //         pData.push(this.formRowObject(fromArray, rowDefinition.rIdx + 1 + index, 'from'));
    //         pData.push(this.formRowObject(toArray, rowDefinition.rIdx + 2 + index, 'to'));
    //         index = index + 2;
    //     }
    // }


    @LoaderEnabled()
    public fetchMetadata(): Observable<any> {
       if (!this.metaDataFetchSubjectTag) {
          this.metaDataFetchSubjectTag = new ReplaySubject<any>(1);
           const tableId = this.searchParams.tableId;
        //   const lookupList = [];
        //   this.columnListOrder = [];
        //   this.entityConfigMetaData.columnList.forEach(element => {
        //     this.columnListOrder.push(element.propertyName);
        //     if ((element.type === 'DROPDOWN' ||  element.type === 'MULTIDROPDOWN') && element.dropDownId) {
        //       if (lookupList.indexOf(element.dropDownId) < 0) {
        //         lookupList.push(element.dropDownId);
        //       }
        //     }
        //   });
        //   const dropDownReq = {lookupList};
        //   const dropDowns = this.fetchDropdowns(dropDownReq);
           // const tablePref = this.getTablePref(tableId);
        //   const clientDropdownValues = this.fetchClientDropdown();
        //   const forwarderDropdownValues = this.fetchForwarderDropdown();
        //   const vendorDropdownValues = this.fetchVendorDropdown();
        //   forkJoin([dropDowns, tablePref, clientDropdownValues, forwarderDropdownValues, vendorDropdownValues]).subscribe(resp => {
             this.tablePreference = this.transformTablePreference(tableId, []);
        //     let dropDownRes = [];
        //     let clientDropDownRes = [];
        //     let forwarderDownRes = [];
        //     let vendorDropDownRes = [];
        //     if (resp[0].response) {
        //       dropDownRes = resp[0].response;
        //     } else {
        //       this.alertService.clearAll().error('Failed to load dropdown values');
        //     }
        //     if (resp[2].typeList) {
        //       clientDropDownRes = resp[2].typeList;
        //     } else {
        //       this.alertService.clearAll().error('Failed to load client dropdown values');
        //     }
        //     if (resp[3].typeList) {
        //       forwarderDownRes = resp[3].typeList;
        //     } else {
        //       this.alertService.clearAll().error('Failed to load forwarder dropdown values');
        //     }
        //     if (resp[4].typeList) {
        //       vendorDropDownRes = resp[4].typeList;
        //     } else {
        //       this.alertService.clearAll().error('Failed to load vendor dropdown values');
        //     }
        //     // initialize the page definition based on the user preferene
            const pageDefinition: PageDefinition = this.pagedData.pageDefinition;
            const userPreference = this.tablePreference.columnConfig;
            if (this.searchParams.defaultSortColumn) {
              pageDefinition.sortColumn = this.searchParams.defaultSortColumn;
            } else {
              pageDefinition.sortColumn = userPreference.configuration.pageDefinitionObject['sortColumn'];
            }
            if (userPreference.configuration.pageDefinitionObject['sortOrder'] === null) {
              pageDefinition.sortOrder = 0;
            } else {
              pageDefinition.sortOrder = userPreference.configuration.pageDefinitionObject['sortOrder'];
            }
            if (this.searchParams.defaultPageSize) {
              pageDefinition.pageSize = this.searchParams.defaultPageSize;
            } else {
              pageDefinition.pageSize = userPreference.configuration.pageDefinitionObject['pageSize'];
            }
        //     // pageDefinition.pageSize = userPreference.configuration.pageDefinitionObject['pageSize'];
        //     this.entityConfigMetaData.columnList.forEach(element => {
        //       if ((element.type === 'DROPDOWN' ||  element.type === 'MULTIDROPDOWN') && element.dropDownId) {
        //         // Object.keys(dropDownRes).forEach((key: any) => {
        //           const dropdownValues = [];
        //           const currentPropValues = dropDownRes[element.dropDownId];
        //           const propertyArray = ['client', 'forwarder', 'vendor'];
        //         if (!propertyArray.includes(element.dropDownId)) {
        //           currentPropValues.forEach(dropDownDetails => {
        //             dropdownValues.push({'key': dropDownDetails.code, 'value': dropDownDetails.description});
        //           });
        //         } else if (element.dropDownId === 'client') {
        //           clientDropDownRes.forEach(dropDownDetails => {
        //             dropdownValues.push({'key': dropDownDetails.id, 'value': dropDownDetails.name});
        //           });
        //         } else if (element.dropDownId === 'forwarder') {
        //           forwarderDownRes.forEach(dropDownDetails => {
        //             dropdownValues.push({'key': dropDownDetails.id, 'value': dropDownDetails.name});
        //           });
        //         } else if (element.dropDownId === 'vendor') {
        //           // if (tableId === 'shpprconsgnsearchtd' ||
        //           // tableId === 'shpprconsgnpopupsearchtd') {
        //           //   vendorDropDownRes.forEach(dropDownDetails => {
        //           //     dropdownValues.push({ 'key': dropDownDetails.vendorCode, 'value': dropDownDetails.name, 'code': dropDownDetails.vendorCode });
        //           //   });
        //           // } else {
        //             vendorDropDownRes.forEach(dropDownDetails => {
        //               dropdownValues.push({ 'key': dropDownDetails.id, 'value': dropDownDetails.name, 'code': dropDownDetails.vendorCode });
        //             });
        //           // }
        //         }
        //           // if (element.dropDownId === key) {
        //             element.values = this.formatDropdowns(dropdownValues, element.dropDownId);
        //             if (element.defaultDropdownValues && element.defaultDropdownValues.length > 0) {
        //               let ind = 1;
        //               element.defaultDropdownValues.forEach(defValues => {
        //                 element.values.splice(ind, 0, defValues);
        //                 ind += 1;
        //               });
        //             }
        //           // }
        //        // });
        //       }
        //     });
    //    this.getTagValues();
        if(tableId === 'addTagtd'){
          this.metadata = metaDataTagForDetails;
          this.setMetadata(metaDataTagForDetails);
        }else{
            this.metadata =  metaDataTag;
            this.setMetadata(metaDataTag);
            this.getTagValues();
        }
            this.metaDataFetchSubjectTag.next(this.metadata);
            this.metaDataFetchSubjectTag.complete();
          
         // });
       }
        return this.metaDataFetchSubjectTag.asObservable();
      }


      public getTagValues(){
       this.inputOptionsForTag = [];
       this.getvaluesForTag().subscribe(resp=>{
        if (resp.statusCode === 200 && resp.statusMessage === 'SUCCESS') {
          resp.typeList.forEach(item=>{
          const obj ={
            'key' : item.tagName,
            'value':item.tagName
           }
           this.inputOptionsForTag.push(obj);
         })
        if(this.metadata){
         this.metadata.columnList.forEach((meta)=>{
          if(meta.propertyName === 'TAG'){ 
           meta.values = this.inputOptionsForTag;    
          }
         })
        }
        }
       })
      }


      public getvaluesForTag(){
        const hdr = new HttpHeaders({ 'api': 'notification' });
        const response$ = this.http$.get('api/tag/Tag/list', { headers: hdr }).pipe(
          map((response: any) => {
            return response;
          }),
          catchError((error: any) => {
            if (error.error.length !== 0) {
              if (error.error.statusCode !== 200) {
                console.debug('Error', error.error.statusMessage);
                return observableThrowError(error.error.statusMessage);
              }
            }
          }));
          return response$
       }




       localFormatingSearchCri(searchCriteria: any , filterCondition) {
        if (!searchCriteria) {
         return {};
       }
       this.maintainSearchCondtions = (JSON.parse(JSON.stringify(searchCriteria)));
       let allConditions = null;
       // Code added to merge filter conditions with master search criteria, if filter condition is there.
       if (filterCondition && filterCondition.clauses.length > 0) {
         allConditions = JSON.parse(JSON.stringify(searchCriteria.conditions));
         if (allConditions && allConditions.length > 0) {
   
   
           allConditions[allConditions.length - 1].operator = 'AND';
         }
         allConditions.push(filterCondition);
       } else {
         allConditions = searchCriteria.conditions;
       }
       // const conditionReqList = [];
       // allConditions.forEach((condition: any, index: any) => {
       //   const clauseReqlist = [];
       //   condition.clauses.forEach((clause: any) => {
       //     if (clause.value !== undefined && clause.value !== null) {
       //       clauseReqlist.push({
       //         column: clause.column,
       //         operation: clause.operation,
       //         value: clause.value,
       //         value1: clause.value1,
       //         logicalOperation: clause.logicalOperation,
       //         typeOfField: clause.typeOfField
       //       });
       //     }
       //   });
       //   if (clauseReqlist.length !== 0) {
       //     let logOp = null;
       //     if (index < allConditions.length - 1) {
       //       logOp = condition.operator;
       //     }
       //     conditionReqList.push({
       //       mandatory: condition.isMandatory,
       //       clauses: clauseReqlist,
       //       logicalOperation: logOp
       //     });
       //   }
       // });
       // if (additionalParamMap) {
       //   const additionalClauses = [];
       //   const additionalParams = additionalParamMap;
       //   const additionalProps = Object.keys(additionalParams);
       //   if (additionalProps.length > 0) {
       //     const lastCondition = conditionReqList.slice(conditionReqList.length - 1)[0];
       //     lastCondition.logicalOperation = 'AND';
       //     additionalProps.forEach((property: string, index: number) => {
       //       additionalClauses.push({
       //         column: property,
       //         operation: '=',
       //         value: additionalParams[property],
       //         logicalOperation: index === 0 ? '' : 'AND'
       //       });
       //     });
       //     conditionReqList.push({
       //       mandatory: false,
       //       clauses: additionalClauses,
       //       logicalOperation: ''
       //     });
       //   }
       // }
        searchCriteria.conditions = allConditions;
       searchCriteria['conditions'].forEach((condition: any, index: any) => {
         condition.clauses.forEach((clause: any) => {
           clause['columnName'] = clause.column;
        })});
       return searchCriteria;
     }

    public formRowObject(cellArray: any, index: any, type: string) {
        const rowObject: any = new Object();
        rowObject.rIdx = index;
        rowObject.type = type;
        rowObject.isSelected = false;
        rowObject.cells = {};
        cellArray.forEach((item: any) => {
            const cellInfo: any = new Object();
            cellInfo.cVal = item.cVal;
            cellInfo.cValOrig = item.cVal;
            cellInfo.cValPrev = item.cVal;
            cellInfo.cName = item.cName;
            rowObject.cells[item.cName] = cellInfo;
        });
        return rowObject;
    }

    public getEditedRows(): TableRow[] {
        const allRows = this.pagedData.pageRows;
        const editedRows = [];
        // if either the from or the to row is edited, mark all the three rows as edited.
        for (let i = 0; i + 2 < allRows.length; i = i + 3) {
            if (allRows[i + 1].isEdited || allRows[i + 2].isEdited) {
                allRows[i].isEdited = true;
                allRows[i + 1].isEdited = true;
                allRows[i + 2].isEdited = true;

                editedRows.push(allRows[i], allRows[i + 1], allRows[i + 2]);
            }
        }
        return editedRows;
    }

    public createNewRow(insertPosition?: number): any {
        // if insertPostion is not given it will work as add new row , if insert postion is given new row will be inserted at that position
        if (!this.dataService.originalColumnList || this.dataService.originalColumnList.length === 0) {
            return 'meta data not loaded';
        }
        const lineItemCells = {};
        const fromCells = {};
        const toCells = {};
        // create cells for all properties
        const fullRow = this.iterateThroughPropertiesAndCreateNewCells();
        // separate them into 3 rows
        Object.keys(fullRow).forEach(originalProperty => {
            const cell = fullRow[originalProperty];
            const displayProperty = this.dataService.propertyNameToDisplayNameMap[originalProperty];
            cell.cName = displayProperty;
            if (this.dataService.lineItemProperties.indexOf(originalProperty) !== -1) {
                lineItemCells[displayProperty] = cell;
            } else if (this.dataService.fromItemProperties.indexOf(originalProperty) !== -1) {
                fromCells[displayProperty] = cell;
            } else if (this.dataService.toItemProperties.indexOf(originalProperty) !== -1) {
                toCells[displayProperty] = cell;
            }
        });

        const lineItemRow = this.createNewRowForCells(lineItemCells);
        const fromRow = this.createNewRowForCells(fromCells);
        const toRow = this.createNewRowForCells(toCells);

        if (!insertPosition) { // addmode
            // changing the logic as part of JIRA-649 to display the newly added row in the top above all rows in the grid
            if (!this.pagedData.pageRows) {
                this.pagedData.pageRows = [];
                this.pagedData.pageRows.push(lineItemRow, fromRow, toRow);
            } else {
                this.pagedData.pageRows.splice(0, 0, lineItemRow, fromRow, toRow);
            }
        } else {
            // mode === insert
            this.pagedData.pageRows.splice(insertPosition, 0, lineItemRow, fromRow, toRow);

        }
        // this.pagedData.pageRows.push(rowData);
        if (!this.searchStatus.hasResults) {
            this.searchStatus.hasResults = true;
        }
        return [lineItemRow, fromRow, toRow];
    }

    public createNewRowForCells(cells) {
        cells['isNewItem'] = { cVal: true };
        const newRow = new TableRow();
        newRow.isSelected = false;
        newRow.isEdited = true;
        newRow.isNewRow = true;
        newRow.cells = cells;
        this.pagedData.pageDefinition.recordCount++;
        newRow['rIdx'] = this.pagedData.pageRows.length + 1;

        return newRow;
    }

  
    public iterateThroughPropertiesAndCreateNewCells() {
        const cellData = {};
        this.dataService.originalColumnList.forEach((column: any) => {
            const originalPropertyName = column.propertyName;
            const propType = column.type;
            const dateDefaultValue = column.defaultVal;
            if (propType === 'TEXTFIELD') {
                let defaultValue: any;
                if (column.dataType === 'Integer') {
                    defaultValue = 0;
                } else if (column.dataType === 'String') {
                    if (column.defaultVal && column.defaultVal !== '') {
                        defaultValue = column.defaultVal;
                    } else {
                        defaultValue = '';
                    }
                } else if (column.dataType === 'Float') {
                    if (column.defaultVal && column.defaultVal !== '') {
                        defaultValue = column.defaultVal;
                    } else {
                        defaultValue = 0.000000;
                    }
                } else if (column.dataType === 'Currency') {
                    if (column.defaultVal && column.defaultVal !== '') {
                        defaultValue = column.defaultVal;
                    } else {
                        defaultValue = '0.00';
                    }
                } else {
                    defaultValue = null;
                }
                cellData[originalPropertyName] = { cVal: defaultValue, cName: originalPropertyName };
            } else if (propType === 'DATE' || propType === 'DATETIME') {
                // To set null value if no default value starts
                if (!cellData[dateDefaultValue]) {
                    cellData[originalPropertyName] = { cVal: null };
                } else {
                    if (propType === 'DATE') {
                        cellData[originalPropertyName] = { cVal: this.dateConfigService.getFormattedDisplayDate(Date.now()) };
                    } else if (propType === 'DATETIME') {
                        cellData[originalPropertyName] = { cVal: this.dateConfigService.getFormattedDisplayDateTime(Date.now()) };
                    }
                }
                // To set null value if no default value ends
            } else if (propType === 'DROPDOWN' || propType === 'EDITABLEDROPDOWN') {
                let defaultOption = '';
                if (column.values[0] && column.values[0].key) {
                    defaultOption = column.defaultVal; // To set the default value if the user does not select any options
                }
                cellData[originalPropertyName] = { cVal: defaultOption };
            } else if (propType === 'CHECKBOX' || propType === 'CHECKBOX_CHR') {
                // Adding CHECKBOX_CHR type denoting the saved value will be 'Y' or 'N'
                // CHECKBOX type denoting the saved value will be '1' or '0'
                cellData[originalPropertyName] = { cVal: false };
            } else if (propType === 'DROPDOWN_MC') {
                let defaultValue: any;
                defaultValue = '';
                cellData[originalPropertyName] = { cVal: defaultValue };
            }
        });

        return cellData;
    }

    /*This function delete the newly added row, upon clicking on the 'x' appearing on the row */
    public deleteNewRowFromModel(rowIndex): any | undefined {
        if (this.metadata === null || this.metadata['columnList'] === null) {
            return 'meta data not loaded';
        }
        if (this.pagedData.pageRows === undefined || this.pagedData.pageRows === null) {
            return 'page data not loaded';
        }
        this.pagedData.pageRows.splice(rowIndex, 3);
        this.pagedData.pageDefinition.recordCount = this.pagedData.pageDefinition.recordCount - 3;
        const evt = new SearchEvent(DELETE_ROW_EVENT);
        this.publishEvent(evt);
    }
}
