import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './layout/home/home.component';
import { RootAuthGuard } from './guards/root-auth.guard';
import { OnlyLoggedInUsersGuard } from './guards/only-loggedin-users.guard';
import { AlwaysAuthChildrenGuard } from './guards/always-auth-children.guard';
import { LoginComponent } from 'sce-core';
import { UserProfileComponent } from 'sce-core';
import { DeactivateGuard } from './guards/deactivate.guard';
import { ViewOrderComponent } from './features/control-tower/view-order/view-order.component';
import { LoginRedirectGuard } from './guards/login-redirect.guard';
import { FleetMonitoringMapComponent } from './features/control-tower/fleet-monitoring/fleet-monitoring-map/fleet-monitoring-map.component'

@NgModule({
  imports: [
    RouterModule.forRoot([
      /* Define app module routes here, e.g., to lazily load a module
         (Never place feature module routes here, use an own -routing.module.ts in the feature instead) */
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent, canActivate: [LoginRedirectGuard] },
      { path: 't', component:ViewOrderComponent},
      { path: 'fleetmap', component: FleetMonitoringMapComponent },
      {
        path: 'home', component: HomeComponent,
        canActivate: [RootAuthGuard, OnlyLoggedInUsersGuard],
        canActivateChild: [AlwaysAuthChildrenGuard],
        canDeactivate : [ DeactivateGuard ],
        children: [
           {path: 'my-profile', component: UserProfileComponent},
          //  {path: 'dispatcher-iframe', component: DispatcherIframeComponent},
           { path: 'admin',
           loadChildren: () => import('./features/admin/admin-wrapper.module').then(m => m.SCEAdminModuleLazyLoadWrapper) },
           { path: 'notification',
           loadChildren: () => import('./modules/child-template/child-template.module').
           then(m => m.ChildTemplateModule) },
           { path: 'notification',
           loadChildren: () => import('./modules/master-template/master-template.module').
           then(m => m.MasterTemplateModule) },
           { path: 'shipment',
           loadChildren: () => import('./modules/shipment-planning/shipment-planning.module').
           then(m => m.ShipmentPlanningModule) },
           { path: 'shipment',
           loadChildren: () => import('./modules/shipment-finalized/shipment-finalized.module').
           then(m => m.ShipmentFinalizedModule) },
           { path: 'shipment',
           loadChildren: () => import('./modules/shipment-excution/shipment-excution.module').
           then(m => m.ShipmentExcutionModule) },
           { path: 'order',
           loadChildren: () => import('./modules/order-event/order-event.module').
           then(m => m.OrderEventModule) },
           { path: 'order',
           loadChildren: () => import('./modules/order-processing/order-processing.module').
           then(m => m.OrderProcessingModule) },
           { path: 'order',
           loadChildren: () => import('./modules/all-order/all-order.module').
           then(m => m.AllOrderModule) },
           { path: 'control-tower',
           loadChildren: () => import('./modules/order-visibility/order-visibility.module').
           then(m => m.OrderVisibilityModule) },
           { path: 'order',
           loadChildren: () => import('./modules/mass-upload/mass-upload.module').
           then(m => m.MassUploadModule) }, 
           { path: 'control-tower',
           loadChildren: () => import('./modules/safety-report/safety-report.module').
           then(m => m.SafetyReportModule) },   
           { path: 'report',
           loadChildren: () => import('./modules/inbound/inbound.module').
           then(m => m.InboundModule) },
           { path: 'report',
           loadChildren: () => import('./modules/outbound/outbound.module').
           then(m => m.OutboundModule) },
           { path: 'report',
           loadChildren: () => import('./modules/location-variance/location-variance.module').
           then(m => m.LocationVarianceReportModule) },
           { path: 'report',
           loadChildren: () => import('./modules/handover/handover.module').
           then(m => m.HandoverModule) },
           { path: 'report',
           loadChildren: () => import('./modules/third-party-report/third-party-report.module').
           then(m => m.ThirdPartyReportModule) },
           { path: 'settings',
           loadChildren: () => import('./modules/reason-code/reason-code.module').
           then(m => m.ReasonCodeModule) },
           { path: 'settings',
           loadChildren: () => import('./modules/commodity/commodity.module').
           then(m => m.CommodityModule) },
           { path: 'settings',
           loadChildren: () => import('./modules/safety-check/safety-check.module').
           then(m => m.SafetyCheckModule) },
           { path: 'notification',
           loadChildren: () => import('./modules/server-configuration/server-configuration.module').
           then(m => m.ServerConfigurationModule) },
           { path: 'notification',
           loadChildren: () => import('./modules/view-messages/view-messages.module').
           then(m => m.ViewMessagesModule) },
           { path: 'notification',
           loadChildren: () => import('./modules/manage-event/manage-event.module').
           then(m => m.ManageEventModule) },
           { path: 'notification',
           loadChildren: () => import('./modules/dashboard/dashboard.module').
           then(m => m.DashboardModule) },
           { path: 'notification',
           loadChildren: () => import('./modules/manage-workflow/manage-workflow.module').
           then(m => m.ManageWorkflowModule) },
           { path: 'settings',
           loadChildren: () => import('./modules/transport-handling/transport-handling.module').
           then(m => m.TransportHandlingModule) },
           { path: 'basic-data', loadChildren: () => import('./features/basic-data/basic-data.module').then(m => m.BasicDataModule)},

          //  { path: 'basic-data',  loadChildren: './features/basic-data/basic-data.module#BasicDataModule' },
           { path: 'settings',
           loadChildren: () => import('./modules/system-setting/system-setting.module').
           then(m => m.SystemSettingModule) },
           { path: 'settings',
           loadChildren: () => import('./modules/push-notification/push-notification.module').
           then(m => m.PushNotificationModule) },
           { path: 'reserve',  loadChildren: () => import('./features/reserve/reserve.module').then(m => m.ReserveModule)},
           { path: 'black-card',
           loadChildren: () => import('./modules/param-cfg/param-cfg.module').
           then(m => m.BlackCardParamCfgModule) },
           { path: 'black-card',
           loadChildren: () => import('./modules/black-binding/black-binding.module').
           then(m => m.BlackCardBindingModule) },
           { path: 'black-card',
           loadChildren: () => import('./modules/black-echart/black-echart.module').
           then(m => m.BlackCardEchartModule) },
           { path: 'control-tower',
           loadChildren: () => import('./modules/service-report/service-report.module').
           then(m => m.ServiceReportModule) },
           { path: 'black-card',
           loadChildren: () => import('./modules/black-dashboard/black-dashboard.module').
           then(m => m.BlackCardDashboardModule) },
           { path: 'black-card',
           loadChildren: () => import('./modules/black-management/black-management.module').
           then(m => m.BlackCardManagementModule) },
           { path: 'control-tower',
           loadChildren: () => import('./modules/fleet-monitoring/fleet-monitoring.module').
           then(m => m.FleetMonitoringModule) },
           { path: 'report',
           loadChildren: () => import('./modules/stop-dwelling/stop-dwelling.module').
           then(m => m.StopDwellingModule) },
           
          // { path: 'global-control',
          //  loadChildren: () => import('./features/global-control/global-control.module').then(m => m.GlobalControlModule)  },
          // { path: 'setup',
          //  loadChildren: () => import('./features/setup/setup.module').then(m => m.SetupModule) }
        ]
      }
    ])
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
