import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/all-modules';

@Component({
  selector: 'lfwms-pinned-cell-renderer',
  templateUrl: './pinned-cell-renderer.component.html',
  styleUrls: ['./pinned-cell-renderer.component.css']
})
export class PinnedCellRendererComponent implements ICellRendererAngularComp {

  public isLoading: boolean = true;
  public templateId: number = 0;
  public params: any;
  public cellValue: string = '';

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    if (this.params.column.colDef.colId === 'isSelected') {
      this.templateId = 1;
    } else {
      this.cellValue = this.params.value.cVal;
    }
    this.isLoading = false;
  }

  public refresh(): boolean {
    return true;
  }

}

