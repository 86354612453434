<!-- Home Page Layout starts -->
<div class="container-fluid">

  <div class="row">
    <div class="col-md-12">

      <!-- Left Panel -->
      <sce-left-panel>
        <lfwms-left-panel-contents></lfwms-left-panel-contents>
      </sce-left-panel>
      <!-- Master Frame -->
      <div class="master-frame"
        [@openClose]="leftPanelService.isOpened() ? 'open' : 'closed'">

        <!-- Master Frame Content (Page Content excluding left panel
        and footer) class below, is to wrap header and master view
        (with [min-height: 100%-30px]), so that footer gets pushed to
        bottom of the view port regardless of content height -->
        <div class="master-frame-content">

          <!-- Header Content -->
          <div style="margin-left: -15px;">
            <lfwms-header>
            </lfwms-header>
          </div>

          <!-- Master View Wrapper -->
          <div class="master-view-wrapper">

            <!-- Breadcrumb -->
            <!-- <ng-template [ngIf]="!isHomePage" [ngIfElse]="homePageTemplate">
              <div *ngIf="isBread !== 'ordr_prcssng'">
              <lfwms-breadcrumb>
              </lfwms-breadcrumb>
              </div> -->
            <!-- </ng-template> -->

            <ng-template #homePageTemplate>
              <!-- <div class="row">
                <div #carouselViewport class="col-sm-12" 
                [ngClass]="{ 'carousel-viewport': dashboardService.showCarousel, 'carousel-viewport-home': !dashboardService.showCarousel}">
                  <sce-dynamic-carousel-view></sce-dynamic-carousel-view>
                </div>
              </div> -->

              <!-- <div class="row feed-contents">
                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12"
                  *ngFor="let item of rssFeeds | slice:0:6 ">
                  <div class="feed-col-contents">
                    <div class="date">{{ item.pubDate | date: 'dd/MM/yyyy' }}
                    </div>
                    <div class="feed-col-heading" title='{{ item.title }}'>
                      {{item.title}}
                    </div>
                    <div class="feed">
                      <div class="feed-item-description"
                        [innerHTML]="item.description">
                      </div>
                      <a href="{{ item.link }}" title="{{ item.link }}"
                        target="_blank">{{ getLabel('lbl_home_READMORE') }}</a>
                    </div>
                  </div>
                </div>
              </div> -->

            </ng-template>

            <!-- Router Outlet should never be placed as a dynamic template,
              Since ActivatedRoute can only be activated once.-->
            <router-outlet>
              <!--- [[[ Master View Router Outlet ]]] --->
              <!--- All Child Routings happens here !! --->
            </router-outlet>
            
          </div>

        </div>

      </div>

      <!-- Footer -->
      <!-- <lfwms-footer>
      </lfwms-footer> -->

    </div>
  </div>

</div>

<!-- Overlay Components starts -->

  <!-- Confirmation Dialog -->
  <sce-confirm-dialog>
  </sce-confirm-dialog>

  <!-- Alert Message -->
  <sce-alerts>
  </sce-alerts>

  <!-- Modal Dialog -->
  <lfwms-modal>
  </lfwms-modal>

  <!-- Loader Overlay -->
  <lfwms-loader>
  </lfwms-loader>
    
<!-- Overlay Components ends -->

<!-- Home Page Layout ends -->
