
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';



import { SEARCH_DEFAULT_PAGE_SIZE } from './search-constants';
import { SearchCriteria } from './models/search-filter-model';
import { SavedSearchConfig } from './models/saved-search-config';
import { ResourceService } from 'sce-core';
import { CoreServiceRegistry } from '../../core/core-service-registry';
import { SearchService } from './search.service';
import { tmsConfig } from '../../core/tms-config'

@Injectable()
export class SavedSearchService {

  public http$: HttpClient;
  public resourceService: ResourceService;

  constructor(public coreServiceRegistry: CoreServiceRegistry) {
    this.http$ = coreServiceRegistry.http$;
    this.resourceService = coreServiceRegistry.resourceService;
  }

  public getSavedSearchList(searchName: string, tableId: string) {
    const hdr = new HttpHeaders({ 'api': 'admin' });
    const urlload = tmsConfig.tms.shipmentLoadCondtion
    return this.http$.post(urlload,{
      "conditionName": searchName,
      "conditionType": ["orderProcessingsearchtd", 'orderEventsearchtd', 'massUploadsearchtd', 'allOrdersearchtd'].includes(tableId) ? "Order" : "Shipment",
      "tenantId": "0"
    });
  }

  // save and update the search criteria
  public saveSearchCriteria(searchcriteriaName: any, searchId, type, searchService): any {
    const searchCondition = this.createRequestForCriteriaSave(searchcriteriaName, searchId, type, searchService);
    return this.sendSaveSearchCriteriaRequest(searchCondition);
  }

  public sendSaveSearchCriteriaRequest(request) {
    
    const shipmentUrl = tmsConfig.tms.shipmentSaveCondtion
    const searchConditions = [];
    let isFlag = ["shpmtsearchEX", 'shipmtfinalizedtd', 'shpmtsearchtd'].includes(request.type)

    JSON.parse(request.criteria).searchCriteria.conditions[0].clauses.forEach((conditions: any) => {
      console.debug('request', conditions, isFlag);


      var userinfo = JSON.parse(localStorage.getItem('user_info'))    
      var countryKey = userinfo['userRestrictions'][0]['countryCode']; 
      
      if(conditions.value){
        if(conditions.typeOfField === "DATETIME"){
          searchConditions.push({
            conditionName: request.searchName,
            conditionType: isFlag ? "Shipment" :"Order",
            tenantId: "0",
            columnName: isFlag ? conditions.column : conditions.columnName, 
            operation: conditions.operation,
            dateMin: conditions.value,
            dateMax: conditions.value1,
            countryKey,
            tableId : request.type,
          })   
        } else {
          searchConditions.push({
            conditionName: request.searchName,
            conditionType: isFlag ? "Shipment" :"Order",
            tenantId: "0",
            columnName: isFlag ? conditions.column : conditions.columnName, 
            operation: conditions.operation,
            varcharMatch: conditions.value,
            countryKey,
            tableId : request.type,
          }) 
        }
      }                       
    }) 

    

    const response$ = this.http$.post(shipmentUrl, JSON.stringify(searchConditions)).pipe(
      map((response: any) => {
        return response;
      }),catchError((error: any) => {
        if (error.error.errors.length !== 0) {
          if (error.error.errors[0].validationCode !== 200) {
            return observableThrowError(this.resourceService.get(error.error.errors['0'].validationMessage));
          }
        }
      }),);
    return response$;
  }

  // method to create request object for save/upadte search
  public createRequestForCriteriaSave(searchName, searchId, type, searchService): any {
    const tableId = searchService.searchParams.tableId;
    const pageInfoReq = {
      tableId: tableId,
      pageNo: searchService.pagedData.pageDefinition.pageNumber,
      sortColumn: searchService.pagedData.pageDefinition.sortColumn,
      sortOrder: searchService.pagedData.pageDefinition.sortOrder,
      pageSize: SEARCH_DEFAULT_PAGE_SIZE,
      fullFetch: searchService.searchParams.isFullFetch
    };
    const payload = {
      pageInfo: pageInfoReq,
      searchCriteria: searchService.formatSearchCriteria(searchService.searchCriteria, searchService.searchParams.additionalParamMap)
    };
    const criteria = {
      'pageInfo': {
        'tableId': pageInfoReq.tableId,
        'pageNo': pageInfoReq.pageNo,
        'pageSize': pageInfoReq.pageSize,
        'fullFetch': pageInfoReq.fullFetch
      },
      'searchCriteria': payload.searchCriteria
    };
    const preferenceObject = {
      'searchId': searchId,
      'searchName': searchName,
      'type': type,
      'dataTableId': searchService.getMetadata() ? searchService.getMetadata().elementId : '',
      'criteria': JSON.stringify(criteria)
    };
    return (preferenceObject);
  }

  // method to load the search criteria from list.
  public loadSearchCriteria(criteriaId, savedSearchConfig) {
    return this.fetchSearchCriteria(criteriaId, savedSearchConfig);
  }

  // formatting the search criteria from server side format to  client side format
  public transformSearchCriteriaToModel(response, searchService) {
    let responseObject = null;
    try {
      responseObject = JSON.parse(response.criteria);
    } catch (Exception) {
      return null;
    }
    return this.createSearchCriteria(responseObject.searchCriteria.conditions, searchService);
   }

   public createSearchCriteria(conditions: any, searchService: SearchService): SearchCriteria {
    let responseObject = null;
    const conditionList = [];
    let additionalParamMap = searchService.searchParams.additionalParamMap;
    if (!additionalParamMap) {
      additionalParamMap = {};
    }
    conditions.forEach((condition, conditionIndex) => {
      const clauseReqList = [];
      condition.clauses.forEach((clause: any, clauseIndex: number) => {
        if (!additionalParamMap[clause.column] && clause.value || clause.value1) {
          clauseReqList.push({
            id: clauseIndex + 1,
            column: clause.columnName,
            operation: clause.typeOfField === 'MULTIDROPDOWN' && clause.operation === 'IN' ? '=' : clause.operation,
            value: clause.value,
            value1: clause.value1,
            logicalOperation: clause.logicalOperation,
            dashboardParam: clause.dashboardParam,
            typeOfField: clause.typeOfField
          });
        }
      });
      if (clauseReqList.length !== 0) {
        let logOp = null;
        if (conditionIndex < conditions.length - 1) {
          logOp = condition.logicalOperation;
        }
        conditionList.push({
          id: conditionIndex + 1,
          isMandatory: condition.mandatory,
          clauses: clauseReqList,
          operator: logOp,
          displayMode: 'EDIT',
          isReadOnly: false
        });
      }
    });
    if (conditionList.length > 0) {
      const lastCondition = conditionList.slice(conditionList.length - 1)[0];
      lastCondition.operator = conditionList[conditionList.length - 1].operator ? conditionList[conditionList.length - 1].operator : 'AND';
    }
    responseObject = new SearchCriteria();
    responseObject.conditions = conditionList;
    return responseObject;
  }
  
   /*This method will create search criteria based on conditions */
  // public createSearchCriteria(conditions: any, searchService: SearchService): SearchCriteria {
  //   let responseObject = null;
  //   const conditionList = [];
  //   let additionalParamMap = searchService.searchParams.additionalParamMap;
  //   if (!additionalParamMap) {
  //     additionalParamMap = {};
  //   }
  //   conditions.forEach((condition, conditionIndex) => {
  //     const clauseReqList = [];
  //     condition.clauses.forEach((clause: any, clauseIndex: number) => {
  //       if (!additionalParamMap[clause.column] && clause.value || clause.value1) {
  //         clauseReqList.push({
  //           id: clauseIndex + 1,
  //           column: clause.columnName,
  //           operation: clause.operation,
  //           value: clause.value,
  //           value1: clause.value1,
  //           logicalOperation: clause.logicalOperation,
  //           dashboardParam: clause.dashboardParam
  //         });
  //       }
  //     });
  //     if (clauseReqList.length !== 0) {
  //       let logOp = null;
  //       if (conditionIndex < conditions.length - 1) {
  //         logOp = condition.logicalOperation;
  //       }
  //       conditionList.push({
  //         id: conditionIndex + 1,
  //         isMandatory: condition.mandatory,
  //         clauses: clauseReqList,
  //         operator: logOp,
  //         displayMode: 'VIEW',
  //         isReadOnly: false
  //       });
  //     }
  //   });

  //   if (conditionList.length > 0) {
  //     const lastCondition = conditionList.slice(conditionList.length - 1)[0];
  //     lastCondition.operator = conditionList[conditionList.length - 1].operator ? conditionList[conditionList.length - 1].operator : 'AND';
  //   }
  //   responseObject = new SearchCriteria();
  //   responseObject.conditions = conditionList;
  //   return responseObject;
  // }

  // fetch the particular search
  public fetchSearchCriteria(searchId: any, savedSearchConfig: SavedSearchConfig) {
    const hdr = new HttpHeaders({ 'api': 'admin' });
    const response$ = this.http$.get(`/api/data/criteria/` + searchId + `/fetch` ,{headers:hdr} ).pipe(
      map((response: any) => {
        // This savedSearchConfig is used as a reference in manage saved search component to display search Name
        savedSearchConfig.searchName = response['searchName'];
        savedSearchConfig.searchNameOrig = response['searchName'];
        savedSearchConfig.searchId = searchId;
        return response;
      }),catchError((errors: any) => {
        if (errors.error.errors.length !== 0) {
          if (errors.error.errors[0].validationCode !== 200) {
            return observableThrowError(this.resourceService.get(errors.error.errors['0'].validationMessage));
          }
        }
      }),);
    return response$;
  }

  // method to delete search criteria
  public deleteSearchCriteria(searchIdsList: any) {
    const request = {
      'searchIds': searchIdsList
    };
  //  const hdr = new HttpHeaders({ 'api': 'notification' });
    const options = {
      headers: new HttpHeaders(
        { 'api': 'admin'
      }),
      body: request,
    };
    // const options = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //   }),
    //   body: request,
    // };

    const response$ = this.http$.delete(`api/data/search/criteria/`, options ).pipe(
      catchError((err: any) => {
        if (err.error.errors.length !== 0) {
          if (err.error.errors[0].validationCode !== 200) {
            return observableThrowError(this.resourceService.get(err.error.errors['0'].validationMessage));
          }
        }
      }));
    return response$;
  }
  // method to load all search criterias for the popup screen from side panel button
  public getAllSearchCriteriaList() {
    const hdr = new HttpHeaders({ 'api': 'admin' });
    const response$ = this.http$.get('api/data/loadsearch/ALL' , {headers : hdr}).pipe(
      catchError((errorRes: any) => {
        if (errorRes.error.errors.length !== 0) {
          if (errorRes.error.errors[0].validationCode !== 200) {
            return observableThrowError(this.resourceService.get(errorRes.error.errors['0'].validationMessage));
          }
        }
      }));
    return response$;
  }
}
