import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppStorageService, KEYS_APPSTORAGE, TokenService } from 'sce-core';
import { UserService } from 'sce-core';
import { NavigationService } from 'sce-core';

@Injectable()
export class isScreenAccess implements CanActivate {

  constructor(public tokenService: TokenService,
    public userService: UserService,
    public router: Router,
    public navService: NavigationService, public appStorageService: AppStorageService) { }
    public accessibleMenuRoute = this.appStorageService.getItem(KEYS_APPSTORAGE.MENU_ROUTING)
   
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      // Restrict routing to non permissible routes
    if (this.accessibleMenuRoute &&
      this.accessibleMenuRoute['routings'] && this.accessibleMenuRoute['routings'].length > 0 &&
      this.accessibleMenuRoute['routings'].indexOf(route.routeConfig.path) > 0) {
      return true;
    } else {
      this.navService.navigateByMenuId('home');
      return false;
    }
  }

}
