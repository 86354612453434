
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CoreServiceRegistry } from '../../../../core/core-service-registry';
import { SharedServiceRegistry } from '../../../shared-service-registry';
import { SearchableEntityBackendService } from '../searchable-entity-backend.service';
import { DetailedEntityDataService } from './detailed-entity-data.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class DetailedEntityBackendService extends SearchableEntityBackendService {

  public dataService: DetailedEntityDataService;
  public hdr = new HttpHeaders({ 'api': 'notification' });

  constructor(public coreServiceRegistry: CoreServiceRegistry,
    public sharedServiceRegistry: SharedServiceRegistry) {
    super(coreServiceRegistry, sharedServiceRegistry);
  }

  // service call to save an entity detail while creating
  public saveEntityforAdd(entityNumber: any, entityProperties: any, lineItems: any) {
    console.debug(entityProperties, lineItems);
    // For screens with line items in details page
   // if (this.dataService.entityConfig.getShowDetailLineItems()) {
      
   // } 
    
    const requestItems = this.dataService.requestFormation(entityProperties,lineItems);
    const response$ = this.http$.post(`api/recipient/`, JSON.stringify(requestItems) , {headers: this.hdr}).pipe(
      map((response: any) => {
        return response;
      }),catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            return observableThrowError(error.error.statusMessage);
          }
        }
      }),);
    return response$;
  }
  // Service call to update an entity
  public saveEntityforUpdate(entityNumber: any, entityProperties: any, lineItems: any) {
    let request;
    // For screens with line items in details page
    // if (this.dataService.entityConfig.getShowDetailLineItems()) {
    //   request = [{
    //     // 'documentId': entityNumber,
    //     'moduleProperties': entityProperties,
    //     'screenType': this.dataService.primarySearchParams.tableId,
    //     'detailItems': lineItems
    //   }];
    // } else {
    //   request = [{
    //     'moduleProperties': entityProperties,
    //     'screenType': this.dataService.primarySearchParams.tableId
    //   }];
    // }
    const requestItems = this.dataService.requestFormation(entityProperties,lineItems);

    const response$ = this.http$.put(`api/recipient/`, JSON.stringify(requestItems) , {headers: this.hdr}).pipe(
      map((response: any) => {
        return response;
      }),catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            return observableThrowError(error.error.statusMessage);
          }
        }
      }),);
    return response$;
  }

  // service call to delete an entity
  public deleteEntity(itemNumber: any): Observable<Response> {
    let request;
    // For screens with line items in details page
    request = [{
      'screenType': this.dataService.primarySearchParams.tableId,
      'moduleProperties': [{
        cName: this.dataService.primarySearchParams.idProperty,
        cVal: itemNumber
      }]
    }];
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: request,
    };
    const response$ = this.http$.delete('api/setup/', options).pipe(
      map((response: any) => {
        return response;
      }),catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            return observableThrowError(error.error.statusMessage);
          }
        }
      }),);
    return response$;
  }

  // service call to delete selected line items of an entity
  public deleteSelectedLineItemFromEntity(itemNumber: any, selectedLineItemNumbers: any): Observable<Response> {
    const lineItemListReq = [];
    const lineItemProp = this.dataService.detailsSearchParams.idProperty;
    const moduleProp = [{ cName: this.dataService.primarySearchParams.idProperty, cVal: itemNumber }];
    selectedLineItemNumbers.forEach((lineItemNo: any) => {
      lineItemListReq.push({
        'rIdx': lineItemNo.index, 'cells': [{ cName: lineItemProp, cVal: lineItemNo.value },
        { cName: this.dataService.primarySearchParams.idProperty, cVal: itemNumber }
        ]
      });
    });
    const request = [{
      'screenType': this.dataService.primarySearchParams.tableId,
      'moduleProperties': moduleProp,
      'detailItems': lineItemListReq
    }];
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: request,
    };
    const response$ = this.http$.delete('api/setup/', options).pipe(
      map((response: any) => {
        if (response['errors']) {
          if (response['errors'][0].validationCode !== 200) {
            return observableThrowError(response['errors'][0].validationMessage);
          }
        } else {
          return response;
        }
      }),catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            return observableThrowError(error.error.statusMessage);
          }
        }
      }),);
    return response$;
  }

  // service call to delete all line items of an entity
  public deleteAllLineItemsFromEntity(itemNumber: any): Observable<Response> {

    const request = [{
      'screenType': this.dataService.primarySearchParams.tableId,
      'moduleProperties': [{
        cName: this.dataService.primarySearchParams.idProperty,
        cVal: itemNumber
      }, { cName: 'isDeleteAllItem', 'cVal': true }],
      'detailItems': []
    }];
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: request,
    };
    const response$ = this.http$.delete('api/setup/', options).pipe(
      map((response: any) => {
        if (response['errors']) {
          if (response['errors'][0].validationCode !== 200) {
            return observableThrowError(response['errors'][0].validationMessage);
          }
        } else {
          return response;
        }
      }),
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            return observableThrowError(error.error.statusMessage);
          }
        }
      }),);
    return response$;
  }

  public saveSimpleEntity(requestItems: any) {
    const response$ = this.http$.put(`api/setup/`, JSON.stringify(requestItems)).pipe(
      map((response: any) => {
        return response;
      }),catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            return observableThrowError(error.error.statusMessage);
          }
        }
      }),);
    return response$;
  }

  // [LFWM-2125] - to get dynamic buttons based on module name and storerKey
  public getDynamicButtons(storerKey: string, moduleName: string): Observable<any> {
    const response$ = this.http$.get(`api/rcmconfig/` + storerKey + `/` + moduleName).pipe(
      map((response: any) => {
        return response;
      }),catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            return observableThrowError(error.error.statusMessage);
          }
        }
      }),);
    return response$;
  }

  // [2125] - - method to handle api call of dynamic button actions
  public handlingDynamicButtonAction(storerKey: string, documentKey: any, moduleName: string, actionCode: any) {
    const request = {
      'storerKey': storerKey,
      'documentKey': documentKey,
      'module': moduleName,
      'code': actionCode
    };
    const response$ = this.http$.post('api/rcmconfig/sp', JSON.stringify(request)).pipe(
      map((response: any) => {
        return response;
      }),catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.errors[0].validationCode !== 200) {
            return observableThrowError(error.error.errors['0'].validationMessage);
          }
        }
      }),);
    return response$;
  }
}
