import { Preference } from './preference.model';

export class TablePreference {

    public tableId: string;

    public favouriteConfig: Preference;
    public columnConfig: Preference;
    public columGroupConfig: Preference;
}
