export const FETCH_DASHBOARD_DETAILS = {
    'RG': {code: 'RG', id: 2223, notificationId: '8', moduleId: 'Order Group', pageTitle: 'Order Group', tableId: 'recipgroupsearchtd'},
    'OD': {code: 'OD', id: 2225, notificationId: '8', moduleId: 'NotificationRecipient', pageTitle: 'mn_operation_notification', tableId: 'recipgroupsearchtd'}
};

export const INITIALBREADALLORDER = [
    { id: 'home', displayValue: 'Home', route: '/home' },
    { id: 'tms', displayValue: 'TMS', route: undefined },
    { id: 'cntrl_tower', displayValue: 'Control Tower', route: undefined },
    { id: 'fleet_monitoring', displayValue: 'Fleet Monitoring', route: '/home/control-tower/fleet-monitoring' }
]


export const metaDataFleetMonitoringGrpTable = {
    'elementId': 'TAG_TB',
    'columnList': [
        {
            'id': '1001',
            'elementId': 'Truck ID',
            'propertyName': 'truckXid',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId':'',
            'displayType': 'Q',
            'dataType': 'String',
            'cellDataName': 'truckXid',
        },
        {
            'id': '1002',
            'elementId': 'Truck Number',
            'propertyName': 'truckNumber',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId':'',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'truckNumber',
        },
        {
            'id': '1003',
            'elementId': 'Truck Type',
            'propertyName': 'truckType',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId':'',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'truckType',
        },
        {
            'id': '1004',
            'elementId': 'Shipment ID',
            'propertyName': 'allocatedShipmentXid',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId':'',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'allocatedShipmentXid',
        },
        {
            'id': '1005',
            'elementId': 'Driver Name',
            'propertyName': 'deviceDriverName',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId':'',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'deviceDriverName',
        },
        {
            'id': '1006',
            'elementId': 'GPS Status',
            'propertyName': 'gpsStatusMeaning',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId':'',
            'displayType': 'K',
            'dataType': 'String',
            'cellDataName': 'gpsStatusMeaning',
        },
        {
            'id': '1007',
            'elementId': 'Speed',
            'propertyName': 'speed',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId':'',
            'displayType': 'P',
            'dataType': 'Number',
            'cellDataName': 'speed',
        },
        {
            'id': '1008',
            'elementId': 'Carrier ID',
            'propertyName': 'carrierXid',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId':'',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'carrierXid',
        },
        {
            'id': '1009',
            'elementId': 'Location',
            'propertyName': 'location',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId':'',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'location',
        },
        {
            'id': '1010',
            'elementId': 'Last Reporting Date',
            'propertyName': 'lastReportingDate',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId':'',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'lastReportingDate',
        },
    ]
}


export const metaDataGrpDetail = {
        'elementId': 'TAG_TB',
        'columnList': [
            {
                'id': '1001',
                'elementId': 'Tag',
                'propertyName': 'TAG',
                'type': 'TEXTFIELD',
                'defaultVal': '',
                'defaultOp': '=',
                'isMandatory': false,
                'maxLength': 20,
                'index': 45,
                'priority': 'LOW',
                'toolTip': null,
                'values': [],
                'columnGroup': 'Primary',
                'displayType': 'V',
                'dataType': 'String',
                'cellDataName': 'tag'
            },
            {
                'id': '1002',
                'elementId': 'Tag Label',
                'propertyName': 'TAG_LABEL',
                'type': 'TEXTFIELD',
                'defaultVal': '',
                'defaultOp': '=',
                'isMandatory': false,
                'maxLength': 20,
                'index': 45,
                'priority': 'LOW',
                'toolTip': null,
                'values': [],
                'columnGroup': 'Primary',
                'displayType': 'E',
                'dataType': 'String',
                'cellDataName': 'taglabel'
            },
            {
                'id': '1003',
                'elementId': 'Sample Value',
                'propertyName': 'SAMPLE_VALUE',
                'type': 'TEXTFIELD',
                'defaultVal': '',
                'defaultOp': '=',
                'isMandatory': false,
                'maxLength': 20,
                'index': 45,
                'priority': 'LOW',
                'toolTip': null,
                'values': [],
                'columnGroup': 'Primary',
                'displayType': 'E',
                'dataType': 'String',
                'cellDataName': 'samplevalue'
            },
            {
                'id': '1004',
                'elementId': 'Description',
                'propertyName': 'DESCRIPTION',
                'type': 'TEXTFIELD',
                'defaultVal': '',
                'defaultOp': '=',
                'isMandatory': false,
                'maxLength': 100,
                'index': 6,
                'priority': 'LOW',
                'toolTip': null,
                'columnGroup': 'Primary',
                'displayType': 'E',
                'dataType': 'String',
                'cellDataName': 'descriptiontg'
            },
            {
                'id': '1000',
                'elementId': 'tag Id',
                'propertyName': 'TAG_ID',
                'type': 'TEXTFIELD',
                'defaultVal': '',
                'defaultOp': '=',
                'isMandatory': false,
                'maxLength': 30,
                'index': 58,
                'priority': 'LOW',
                'toolTip': null,
                'values': [],
                'columnGroup': 'Primary',
                'displayType': 'H',
                'dataType': 'String',
                'cellDataName': 'tagId',
            },
        ]
}

export const metaDataGrp = {
    'elementId': 'RECIPIENT_GROUP_TB',
    'columnList': [
        {
            'id': '1000',
            'elementId': 'Truck ID',
            'propertyName': 'Truck ID',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'T',
            'dataType': 'String',
            'cellDataName': 'truckXid',
        },
        {
            'id': '1001',
            'elementId': ' Truck Number ',
            'propertyName': 'Truck Number',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 60,
            'index': 4,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'truckNumber'
        },
        {
            'id': '1002',
            'elementId': 'Truck Type',
            'propertyName': 'Truck Type',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 6,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'truckType'
        },
        // {
        //     'id': '1004',
        //     'elementId': 'Principal',
        //     'propertyName': 'principal',
        //     'type': 'TEXTFIELD',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': false,
        //     'maxLength': 20,
        //     'index': 6,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [],
        //     'columnGroup': 'Primary',
        //     'displayType': 'V',
        //     'dataType': 'String',
        //     'cellDataName': 'Principal'
        // },
        {
            'id': '1003',
            'elementId': 'Shipment ID',
            'propertyName': 'Shipment ID',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'allocatedShipmentXid',
        },
        {
            'id': '1005',
            'elementId': 'Driver Name',
            'propertyName': 'Driver Name',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'deviceDriverName'
        },
        // {
        //     'id': '1006',
        //     'elementId': 'Speed',
        //     'propertyName': 'speed',
        //     'type': 'TEXTFIELD',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': true,
        //     'maxLength': 60,
        //     'index': 4,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [],
        //     'columnGroup': 'Primary',
        //     'displayType': 'V',
        //     'dataType': 'Number',
        //     'cellDataName': 'speed'
        // },
        // {
        //     'id': '1007',
        //     'elementId': 'Carrier ID',
        //     'propertyName': 'carrierXid',
        //     'type': 'TEXTFIELD',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': false,
        //     'maxLength': 20,
        //     'index': 6,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [],
        //     'columnGroup': 'Primary',
        //     'displayType': 'V',
        //     'dataType': 'String',
        //     'cellDataName': 'carrierXid'
        // },
        {
            'id': '1007',
            'elementId': 'Carrier ID',
            'propertyName': 'Carrier ID',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 6,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'carrierXid'
        },
        {
            'id': '1008',
            'elementId': 'Last Reporting Date',
            'propertyName': 'Last Reporting Date',
            'type': 'DATETIME',
            'defaultVal': '',
            'defaultOp': 'between',
            'isMandatory': false,
            'maxLength': 15,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'Last Reporting Date'
        },
    ]
}
export const notificationMetaDataGrp = {
    'elementId': 'RECIPIENT_GROUP_TB',
    'columnList': [
        {
            'id': '1000',
            'elementId': 'Truck ID',
            'propertyName': 'truckId',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'T',
            'dataType': 'String',
            'cellDataName': 'truckId',
        },
        {
            'id': '1002',
            'elementId': 'Truck Type',
            'propertyName': 'truckType',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 6,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'truckType'
        },
        {
            'id': '1005',
            'elementId': 'Driver Name',
            'propertyName': 'driverName',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'driverName'
        },
        {
            'id': '1006',
            'elementId': 'Driver Contact',
            'propertyName': 'driverContact',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 60,
            'index': 4,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'Number',
            'cellDataName': 'driverContact'
        },
    ]
}