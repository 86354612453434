      <div class="leftmenu-wrapper">
        <div class="logo_wm">WM</div>
    
        <div class="favorites">
            <div class="favorites-heading" (click)="toggleFavorites()">
                <span>
                    <b>{{ getLabel('lbl_myfav') }}</b>
                    <ng-template [ngIf]="expandFavorites" [ngIfElse]="closedIconTemplate">
                        <i class="fa fa-chevron-right pull-right" aria-hidden="true"></i>
                    </ng-template>
                    <ng-template #closedIconTemplate>
                        <i class="fa fa-chevron-down pull-right" aria-hidden="true"></i>
                    </ng-template>
                </span> 
            </div>
            <ng-template [ngIf]="expandFavorites">
                <div class="favorites-content">
                    <ng-template ngFor let-item [ngForOf]="favoritesList">
                        <div class="item-row">
                            <span overflow-tooltip class="item-name"
                                (click)="navigateToFavorites(item.itemModule);">
                                {{ getLabel('mn_' + item.itemModule) }}</span>
                            <button class="delete-icon"
                                (click)="deletefavorites(item.preferenceId);
                                    $event.stopPropagation();"></button>
                        </div>
                    </ng-template>
                    <ng-template [ngIf]="favoritesList.length === 0">
                        <div class="no-item">
                            <span>{{ getLabel('msg_no_favorites') }}</span> 
                        </div>
                    </ng-template>
                </div>
            </ng-template>
        </div>
    
        <div class="recent-items">
            <div class="recent-items-heading" (click)="toggleRecentItems()">
                <span>
                    <b>{{ getLabel('lbl_recent_itm') }}</b>
                    <ng-template [ngIf]="expandRecentItems" [ngIfElse]="closedIconTemplate">
                        <i class="fa fa-chevron-right pull-right" aria-hidden="true"></i>
                    </ng-template>
                </span> 
            </div>
            <ng-template [ngIf]="expandRecentItems">
                <div class="recent-items-content">
                    <ng-template ngFor let-item [ngForOf]="recentItemsList">
                        <div class="item-row">
                            <span overflow-tooltip class="item-name"
                                (click)="navigateToDetailsPage(item.itemType, item.itemId,item.displayValue);">
                                    {{ item.displayValue }}</span>
                        </div>
                    </ng-template>
                    <ng-template [ngIf]="recentItemsList.length === 0">
                        <div class="no-item">
                            <span>{{ getLabel('msg_no_recent_itm') }}</span>
                        </div>
                    </ng-template>
                </div>
            </ng-template>
        </div>
    
        <div class="saved-searches">
            <div class="saved-searches-heading" (click)="toggleSavedSearches();">
                <span>
                    <b>{{ getLabel('lbl_saved_srch') }}</b>
                    <ng-template [ngIf]="expandSavedSearches" [ngIfElse]="closedIconTemplate">
                        <i class="fa fa-chevron-right pull-right" aria-hidden="true"></i>
                    </ng-template>
                </span>
            </div>
            <ng-template [ngIf]="expandSavedSearches">
                <div class="saved-searches-content">
                    <!-- JIRA 1365 Only latest 10 saved Search Criterias need to be displayed on the left-panel -->
                    <ng-template ngFor let-item  [ngForOf]="savedSearchesList" let-i="index">
                        <div class="item-row" *ngIf="(i < 10)">
                            <span overflow-tooltip class="item-name"
                                (click)="navigateToSearchPage(item.searchId, item.itemType)">
                                    {{ item.searchName }}</span>
                            <button class="delete-icon"
                                (click)="deleteSearchCondition(item.searchId)"></button>
                        </div>
                    </ng-template>
                    <ng-template [ngIf]="savedSearchesList.length === 0">
                        <div class="no-item">
                            <span>{{ getLabel('msg_no_savedSearch') }}</span>
                        </div>
                    </ng-template>  
                    <div class="all-search-btn-wrapr">
                        <button class="lfwms-btn-default all-search-btn" (click)="showAllSavedCriterias()">
                            {{ getLabel('btn_all_savd_srch') }}
                        </button> 
                    </div>
                </div>
            </ng-template>
        
        </div>
      </div>