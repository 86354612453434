import {Injectable, Injector, Optional, SkipSelf} from '@angular/core';
// import {TokenService} from './security/token.service';

declare var $: any;

@Injectable()
export class JqueryService {
  // private tokenService: TokenService;

  constructor(injector: Injector,
              @Optional() @SkipSelf() parent: JqueryService) {
    if (parent) {
      // If parent already loaded
      // throw new Error(`Tried to initialize singleton service LovService again.`);
      console.debug(111111);
      
    }
    // this.tokenService = injector.get(TokenService);
  }

  private getHeaders() {
    return {
      // 'Authorization': this.tokenService.getToken()
    };
  }

  getAsync(url, body?: any, options?: any): any {
    let result = {};
    $.ajax({
      url: url,
      data: body,
      async: false,
      type: 'get',
      headers: this.getHeaders(),
      success: (response: any) => {
        result = response;
      },
      ...options
    });
    return result;
  }

  postAsync(url, body?: any, options?: any): any {
    let result = {};
    $.ajax({
      url: url,
      data: body,
      async: false,
      type: 'post',
      headers: this.getHeaders(),
      success: (response) => {
        result = response;
      },
      ...options
    });
    return result;
  }

}
