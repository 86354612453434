export const menuIdRouteMappings = {
  'home': '/home',
  'my-profile': '/home/my-profile',
  'manage-user': '/home/admin/userlist',
  'asn': '/home/inbound/asn',
  'po': '/home/inbound/po',
  'trade-returns': '/home/inbound/trade-returns',
  'ixdock': '/home/inbound/inbound-xdock',
  'oxdock': '/home/outbound/outbound-xdock',
  'user-management': '/home/admin/usermanagement',
  'manage-role': '/home/admin/rolemanagement',
  'fieldmgt': '/home/admin/fieldmanagement', // new menu entry for field Management (same screen from Role Management)
  'manage-group': '/home/admin/groupmanagement', // new menu entry for Group Management (same screen from Role Management)
  'shipment-order': '/home/outbound/so',
  'pick_mgmnt': '/home/outbound/pm',
  'set_printer': '/home/reports/printerSetup',
  'view_prin_job': '/home/reports/view-my-print-jobs',
  'view_all_prin_job': '/home/reports/view-all-print-jobs',
  'so': '/home/outbound/so', // to be merged with shipment-order after menu-id change
  'tr': '/home/inbound/trade-returns', // to be merged with trade-returns after menu-id change
  'stktake_params': '/home/inventory/cycle-count',
  'rel_cc': '/home/inventory/release-cycle-count',
  'inv_adj': '/home/inventory/inventoryadjustment',
  'holiday': '/home/setup/holiday',
  'facility': '/home/setup/facility',
  'code': '/home/system/code',
  'sys_flag': '/home/system/systemflag',
  'barcode_cfg': '/home/setup/barcode-config',
  'area': '/home/setup/area',
  'section': '/home/setup/section',
  'user': '/home/setup/user',
  'server_config': '/home/system/server-config',
  'allocation': '/home/setup/allocation',
  'trnsmit_log': '/home/system/transmitlog',
  'reason': '/home/setup/reason',
  'putaway_zn': '/home/setup/putaway-zones',
  'equipment': '/home/setup/equipment',
  'device': '/home/setup/device',
  'shft_setup': '/home/setup/shift-setup',
  'lot': '/home/control/lot',
  'pre_alloc': '/home/setup/pre-allocation',
  'xdock_strtgy': 'home/setup/xdock-strategy',
  'strategy': '/home/setup/strategy',
  'assign_pick': '/home/setup/assign-pick-location',
  'assignpickloctd': '/home/setup/assign-pick-location/loc',
  'assignpickskutd': '/home/setup/assign-pick-location/sku',
  'asgn_prm_prfl': '/home/setup/assign-permission-profile',
  'asgnprofusrtd': '/home/setup/assign-permission-profile/user',
  'permproftd': '/home/setup/assign-permission-profile/permissionprofile',
  'inv_qc': '/home/inventory/inventory-qc',
  'loc': '/home/setup/location',
  'serial_no': '/home/control/serial-number',
  'cartnztn': '/home/setup/cartonization',
  'bom': '/home/setup/billofmaterial',
  'veh_info': '/home/setup/vehicle-information',
  'pack': '/home/setup/pack',
  'btbfta': '/home/inbound/btbfta',
  'task_disp': '/home/setup/task-dispatch',
  'wo_routing': '/home/inventory/worouting',
  'mstr_srl_no': '/home/control/master-serial-number',
  'putaway': '/home/setup/strategies/putaway-strategy',
  'vas_wo': '/home/inventory/work-order',
  'route_mstr': '/home/setup/route-master',
  'prmsn_profl': '/home/setup/permission-profile',
  'doc_lkup': '/home/setup/document-lookup',
  'non_inv': '/home/inventory/non-inventory-items',
  'task': '/home/control/transaction/task',
  'str_to_loc': '/home/setup/store-to-loc',
  'inv_holds': '/home/inventory/inventoryhold',
  'inv_bal': '/home/control/inventorybalance',
  'invlotlocidtd': '/home/control/inventorybalance/lotlocid',
  'invblncskutd': '/home/control/inventorybalance/sku',
  'invskufacilitytd': '/home/control/inventorybalance/skufacility',
  'invskuloctd': '/home/control/inventorybalance/skuloc',
  'invidtd': '/home/control/inventorybalance/id',
  'invlocationtd': '/home/control/inventorybalance/location',
  'invlottd': '/home/control/inventorybalance/lottable',
  'invlotidtd': '/home/control/inventorybalance/lotid',
  'invlotloctd': '/home/control/inventorybalance/lotloc',
  'invstorertd': '/home/control/inventorybalance/storer',
  'invskulocucctd': '/home/control/inventorybalance/skulocucc',
  'invlotlociducctd': '/home/control/inventorybalance/lotlociducc',
  'empty_loc': '/home/control/empty-locations',
  'abc_mgmnt': '/home/inventory/abc-management',
  'drop_id': '/home/control/drop-id',
  'ucc_mntce': '/home/inventory/ucc-maintenance',
  'archv_params': '/home/system/archive-parameters',
  'ucc_adj': '/home/inventory/uccadjustment',
  'abc_moves': '/home/inventory/abc-moves',
  'work_stn': '/home/inventory/work-station',
  'plt_imginq': '/home/inventory/pallet-image',
  'sup_alerts': '/home/control/supervisor-alerts',
  'wo': '/home/inventory/work-orders',
  'pm_txn': '/home/inventory/pm-transactions',
  'pm_mntnce': '/home/inventory/pm-maintenance',
  'pm_inv': '/home/inventory/pm-inventory',
  'vas_trnsfr': '/home/inventory/transfer',
  'tm_invmv': '/home/inventory/tm-inventory-move',
  'storer': '/home/setup/storer',
  'ninv_txn': '/home/inventory/non-inv-transaction',
  'rdt_data_cptr': '/home/control/transaction/rdt-data-capture',
  'inv_moves': '/home/inventory/inventory-moves',
  'msg': '/home/system/maintain-messages',
  'pack_hold': '/home/inventory/pack-and-hold',
  'drct_mv': '/home/inventory/direct-moves',
  'gtm_cntrl' : '/home/inventory/gtm-control',
  'srvr_app_err' : '/home/system/server-app-errors',
  'kitting': '/home/inventory/kitting',
  'vw_report': '/home/control/view-report',
  'chkup_kpi': '/home/control/checkup-kpi-maintenance',
  'ext_valdn' : '/home/system/extendedvalidation',
  'inv_txn': '/home/control/inv-transactions',
  'commodity' : '/home/setup/commodity',
  'inv_replen': '/home/inventory/inventory-replenishment',
  'id_inspcn' : '/home/inventory/id-inspection',
  'job_maint' : '/home/inventory/job-maintenance',
  'wave_release': '/home/outbound/wave-release',
  'order_parm': '/home/setup/parameter-group',
  'wave_ctrl': '/home/outbound/wave-summary',
  'pack_mgmt' : '/home/outbound/packm',
  'report_config': '/home/reports/reports-config',
  'jreport': '/home/global-control/logi-report',
  'glob_track': '/home/global-control/global-tracking',
 // 'global_control': '/home/global-control/global-tracking',
  'ship_ref': '/home/outbound/ship-reference',
  'forwarder_dashboard': '/home/global-control/forwarder-dashboard',
  'operation_dashboard': '/home/global-control/operation-dashboard',
  'booking_dashboard': '/home/global-control/booking-performance-dashboard',
  'billing_dashboard':'/home/global-control/billing-dashboard',
  'dashboard_setup': '/home/setup/dashboard-setup',
  'doc_info': '/home/control/transaction/documentinfo',
  'maintain_report': '/home/reports/maintain-report',
  'company_profile': '/home/billing/company-profile',
  'client_profile': '/home/billing/client-profile',
  'merge_rule': '/home/billing/merge-rule',
  'contract_management': '/home/billing/contract-management',
  'rule-management': '/home/billing/rule-management',
  'exchange_rate': '/home/billing/exchange-rate',
  'calendar': '/home/billing/calendar',
  'client_financial_structure': '/home/billing/client-financial-structure',
  'our_financial_structure': '/home/billing/our-financial-structure',
  'invoicing': '/home/billing/invoicing',
  'event-trigger': '/home/billing/event-trigger',
  'reinvoice-log': '/home/billing/reinvoice-log',
  'accrual': '/home/billing/accrual',
  'rep_config': '/home/billing/rep-config',
  'ev_code': '/home/billing/ev_code',
  'transaction': '/home/billing/transaction',
  'cnsldt_trans' : '/home/billing/cnsldt_trans',
  'bill-transaction': '/home/billing/bill-transaction',
  'recur_chrgs': '/home/billing/recurring-charges',
  'look-up': '/home/billing/look-up',
  'process-log': '/home/billing/process-log',
  'report-classification': '/home/billing/report-classification',
  'config-migration': '/home/billing/config-migration',
  'reactivate-invoicing': '/home/billing/reactivate-invoicing',
  'approval-matrix': '/home/billing/approval-matrix',
  'jrep_print': 'logi-report-print',
  'shpmt_bkg': '/home/poms/shipment-booking',
  'ship_visib': '/home/global-control/shipment-visibility',
  'pick_zone': '/home/setup/pick-zones',
  'dataloader_setup': '/home/setup/dataloader-setup',
  'data_loader': '/home/global-control/dataloader',
  'view_history': '/home/global-control/dataloader',
  'ordrs': '/home/poms/orders',
  'alloc_status':'/home/outbound/alloc-status',
  'downtime_config':'/home/admin/downtime-configuration',
  'shpmt_bkg_ltpoms': '/home/ltpoms/shipment-booking',
  'chnl_atribte_config': '/home/inventory/channel-attribute-configuration',
  'shppr_consgn': '/home/poms/shipper-consignee',
  'client': '/home/poms/client',
  'forwarder':'/home/poms/forwarder',
  'vendor':'/home/poms/vendor',
  'carrier': '/home/poms/carrier',
  'evt_code': '/home/poms/event-code',
  'evt_group': '/home/poms/event-group',
  'evt_mapping': '/home/poms/event-mapping',
  'evt_status': '/home/poms/event-status',
  'evt_trigger': '/home/poms/event-trigger',
  'mand_flds': '/home/poms/mandatory-fields',
  'chnl_transfer':'/home/inventory/channel-transfer',
  'invoices':'/home/poms/account-invoices',
  'soa':'/home/poms/statements-of-accounts',
  'chnl_inv_transfer': '/home/inventory/channel-inventory-transfer',
  'casemnifst': '/home/outbound/case-manifest',
  'pltmnifst': '/home/outbound/pallet-manifest',
  'chnl_inv_hold':'/home/inventory/channel-inventory-hold',
  'container': '/home/outbound/container-manifest',
  'dockdoorbkng': '/home/outbound/dock-door-booking',
  'appoint_strtgy': '/home/setup/appointment-strategy',
  'door_booking_strtgy': '/home/setup/door-booking-strategy',
  'replenishment_strtgy':'/home/setup/replenishment-strategy',
  'event': '/home/poms/event-management',
  'notification-rg': '/home/notification/notification-recipient',
  'order_visib': '/home/global-control/order-visibility',
  'shpmnt_planning':'/home/shipment/shipment-planning',
  'shpmnt_excution':'/home/shipment/shipment-excution',
  'finalized_shpmnt':'/home/shipment/shipment-finalized',
  'all_ordrs':'/home/order/all-order',
  'ordr_prcssng': '/home/order/order-processing',
  'order_event': '/home/order/order-event',
  'mass_pod_upload': '/home/order/mass-upload',
  'inbd_itf_msg': '/home/report/inbound-interface-message',
  'outbd_itf_msg': '/home/report/outbound-interface-message',
  'loc_vrnc_rprt': '/home/report/location-variance-report',
  'trnsptr':'/home/basic-data/basic-carrier',
  'location':'/home/basic-data/basic-location',
  'eqpmnt_grp':'/home/basic-data/basic-equipment-group',
  'principal':'/home/basic-data/basic-customer',
  'driver':'/home/basic-data/basic-driver',
  'fleet':'/home/basic-data/basic-truck',
  "ord_visibility": "/home/control-tower/order-visibility",
  "kpi_dashbrd": "/home/control-tower/KPI-Dashboard/search",
  'comodty': '/home/settings/commodity',
  'sfty_chk': '/home/settings/safety-check',
  'sts_rsn_cd': '/home/settings/reason-code',
  'push_noti': '/home/settings/push-notification',
  'systm_stngs': '/home/settings/system-setting',  
  'shp_unt_spc': '/home/settings/transport-handling',
  'forecastplan': '/home/reserve/shipment-dtl',
  'cr_rsrv_dshbrd': '/home/reserve/shipment-dsbd-crr-f',
  'cr_bkng_dshbrd': '/home/reserve/shipment-dsbd-crr-p',
  'ops_rsrv_dshbrd': '/home/reserve/shipment-dsbd-lf-f',
  'ops_bkng_dshbrd': '/home/reserve/shipment-dsbd-lf-p',
  'mtch_dshbrd': '/home/reserve/shipment-match',
  'frcst_ord_imprt': '/home/reserve/shipment-order-import',
  'srvr_confg': '/home/notification/server-configuration',
  'vw_msg': '/home/notification/view-messages',
  'dshbrd': '/home/notification/dashboards',
  'child_tmplt': '/home/notification/child-template',
  'master_tmplt': '/home/notification/master-template',
  'mng_evnt':'/home/notification/manage-event',
  'mng_wrkflw': 'home/notification/manage-workflow',
  'ct_message': '/home/control-tower/message',
  'thrd_prty_rprt': '/home/report/third-party-report',
  'inbd_evn_hstry': '/home/reserve/event-history1',
  'handovr_rprt': '/home/report/handover-report',
  'notification-tag':'/home/notification/notification-tag',
  'notification_scheduler':'/home/notification/notification-scheduler',
  'notification-templ':'/home/notification/notification-template',
  'notification-management': '/home/notification/notification-management',
  'blck_crd_br': '/home/black-card/black-binding',
  'blck_crd_dshbrd': '/home/black-card/black-dashboard',   
  'blck_crd_dvc_chrt': '/home/black-card/black-echart',   
  'blck_crd_mgmt': '/home/black-card/black-management',   
  'paramtr_config': '/home/black-card/param-cfg',
  'tms_dashboard': '/home/global-control/tms-dashboard',
  'cross_visib':  '/home/global-control/cross-dock-visibility',
  'so_details': '/home/outbound/so/details',
  'so_reference': '/home/outbound/ship-reference/details',
  'wave_control_details': '/home/outbound/wave-summary/wave-control',
  'accessorial_code': '/home/reserve/accessorial-code',
  'fleet_monitoring': '/home/control-tower/fleet-monitoring',
  'driver_hos_report': '/home/control-tower/service-report',
  'driver_safety_report': '/home/control-tower/safety-report'
};

export const menuIdAppMappings = {
  'tms':'wms',
  'home': 'wms',
  'my-profile': 'wms',
  'manage-user': 'wms',
  'asn': 'wms',
  'po': 'wms',
  'trade-returns': 'wms',
  'ixdock': 'wms',
  'oxdock': 'wms',
  'user-management': 'wms',
  'manage-role': 'wms',
  'fieldmgt': 'wms', // new menu entry for field Management (same screen from Role Management)
  'manage-group': 'wms', // new menu entry for Group Management (same screen from Role Management)
  'shipment-order': 'wms',
  'pick_mgmnt': 'wms',
  'set_printer': 'wms',
  'view_prin_job': 'wms',
  'view_all_prin_job': 'wms',
  'so': 'wms', // to be merged with shipment-order after menu-id change
  'tr': 'wms', // to be merged with trade-returns after menu-id change
  'stktake_params': 'wms',
  'rel_cc': 'wms',
  'inv_adj': 'wms',
  'holiday': 'wms',
  'facility': 'wms',
  'code': 'wms',
  'sys_flag': 'wms',
  'barcode_cfg': 'wms',
  'area': 'wms',
  'section': 'wms',
  'user': 'wms',
  'server_config': 'wms',
  'allocation': 'wms',
  'trnsmit_log': 'wms',
  'reason': 'wms',
  'putaway_zn': 'wms',
  'equipment': 'wms',
  'device': 'wms',
  'shft_setup': 'wms',
  'lot': 'wms',
  'pre_alloc': 'wms',
  'xdock_strtgy': 'wms',
  'strategy':'wms',
  'assign_pick': 'wms',
  'assignpickloctd': 'wms',
  'assignpickskutd': 'wms',
  'asgn_prm_prfl': 'wms',
  'asgnprofusrtd': 'wms',
  'permproftd': 'wms',
  'inv_qc': 'wms',
  'loc': 'wms',
  'serial_no':'wms',
  'cartnztn': 'wms',
  'bom': 'wms',
  'veh_info': 'wms',
  'pack': 'wms',
  'btbfta':'wms',
  'task_disp': 'wms',
  'wo_routing': 'wms',
  'mstr_srl_no': 'wms',
  'putaway':'wms',
  'vas_wo': 'wms',
  'route_mstr': 'wms',
  'prmsn_profl': 'wms',
  'doc_lkup': 'wms',
  'non_inv': 'wms',
  'task': 'wms',
  'str_to_loc':'wms',
  'inv_holds': 'wms',
  'inv_bal': 'wms',
  'invlotlocidtd': 'wms',
  'invblncskutd': 'wms',
  'invskufacilitytd': 'wms',
  'invskuloctd': 'wms',
  'invidtd': 'wms',
  'invlocationtd': 'wms',
  'invlottd': 'wms',
  'invlotidtd': 'wms',
  'invlotloctd': 'wms',
  'invstorertd': 'wms',
  'invskulocucctd': 'wms',
  'invlotlociducctd': 'wms',
  'empty_loc': 'wms',
  'abc_mgmnt': 'wms',
  'drop_id': 'wms',
  'ucc_mntce': 'wms',
  'archv_params': 'wms',
  'ucc_adj': 'wms',
  'abc_moves': 'wms',
  'work_stn': 'wms',
  'plt_imginq': 'wms',
  'sup_alerts': 'wms',
  'wo': 'wms',
  'pm_txn': 'wms',
  'pm_mntnce': 'wms',
  'pm_inv': 'wms',
  'vas_trnsfr': 'wms',
  'tm_invmv': 'wms',
  'storer': 'wms',
  'ninv_txn':'wms',
  'rdt_data_cptr': 'wms',
  'inv_moves': 'wms',
  'msg':'wms',
  'pack_hold': 'wms',
  'drct_mv': 'wms',
  'gtm_cntrl' : 'wms',
  'srvr_app_err' : 'wms',
  'kitting': 'wms',
  'vw_report': 'wms',
  'chkup_kpi': 'wms',
  'ext_valdn' : 'wms',
  'inv_txn': 'wms',
  'commodity' : 'wms',
  'inv_replen': 'wms',
  'id_inspcn' : 'wms',
  'job_maint' : 'wms',
  'wave_release': 'wms',
  'order_parm': 'wms',
  'wave_ctrl': 'wms',
  'pack_mgmt' : 'wms',
  'report_config': 'wms',
  'jreport': 'wms',
  'global_control': 'wms',
  'ship_ref': 'wms',
  'glob_track': 'gvt',
  'dataloader_setup': 'dataloader',
  'data_loader': 'dataloader',
  'dataloader': 'dataloader',
  'view_history':'dataloader',
  'booking_dashboard': 'dashboard',
  'forwarder_dashboard': 'dashboard',
  'operation_dashboard':'dashboard',
   'billing_dashboard':'dashboard',
  'dashboard_setup': 'dashboard',
  'doc_info': 'wms',
  'maintain_report': 'wms',
  'company_profile': 'billing',
  'client_profile': 'billing',
  'merge_rule': 'billing',
  'contract_management': 'billing',
  'rule-management': 'billing',
  'exchange_rate': 'billing',
  'calendar': 'billing',
  'client_financial_structure': 'billing',
  'our_financial_structure': 'billing',
  'invoicing': 'billing',
  'event-trigger':'billing',
  'rep_config': 'billing',
  'ev_code': 'billing',
  'transaction': 'billing',
  'cnsldt_trans': 'billing',
  'accrual': 'billing',
  'recur_chrgs':'billing',
  'look-up': 'billing',
  'reinvoice-log': 'billing',
  'bill-transaction':'billing',
  'process-log': 'billing',
  'report-classification': 'billing',
  'config-migration': 'billing',
  'reactivate-invoicing': 'billing',
  'approval-matrix': 'billing',
  'jrep_print':'wms',
  'shpmt_bkg': 'poms',
  'ship_visib': 'gvt',
  'pick_zone ':'wms',
  'ordrs': 'poms',
  'alloc_status':'wms',
  'downtime-configuration':'wms',
  'shpmt_bkg_ltpoms':'ltpoms',
  'chnl_atribte_config':'wms',
  'shppr_consgn': 'poms',
  'client': 'poms',
  'forwarder': 'poms',
  'vendor': 'poms',
  'carrier': 'poms',
  'evt_code': 'poms',
  'evt_group': 'poms',
  'evt_mapping': 'poms',
  'evt_status': 'poms',
  'evt_trigger': 'poms',
  'mand_flds': 'poms',
  'chnl_transfer': 'wms',
  'invoices':'poms',
  'soa':'poms',
  'chnl_inv_transfer': 'wms',
  'casemnifst': 'wms',
  'pltmnifst': 'wms',
  'chnl_inv_hold':'wms',
  'container':'wms',
  'dockdoorbkng': 'wms',
  'appoint_strtgy': 'wms',
  'door_booking_strtgy':'wms',
  'replenishment_strtgy':'wms',
  'event': 'poms',
  'notification-rg': 'notification',
  'notification-tag': 'notification',
  'notification_scheduler': 'notification',
  'notification-templ': 'notification',
  'notification-management': 'notification',
  'order_visib': 'gvt',
  'tms_dashboard': 'dashboard',
  'cross_visib': 'gvt'
};

export const menuIdNameMappingsFromBundle = {
  'home': 'Home',
  'user-admin': 'User Admin',
  'inbound': 'Inbound',
  'outbound': 'Outbound',
  'system': 'System',
  'setup': 'Setup',
  'inventory': 'Inventory',
  'documents': 'Documents',
  'po': 'PO',
  'asn': 'ASN/Receipt',
  'manage-user': 'User Management',
  'ixdock': 'XDock',
  'oxdock': 'XDock',
  'trade-returns': 'Trade Returns',
  'favourites': 'Favourites',
  'server-config': 'Server Configuration',
  'holiday': 'Holiday',
  'facility': 'Facility',
  'system-flag': 'System Flags',
  'barcode_cfg': 'Barcode Configuration',
  'area': 'Area',
  'section': 'Section',
  'allocation': 'Allocation',
  'reason': 'Reason',
  'putaway-zone': 'Putaway Zones',
  'shift-setup': 'Shift Setup',
  'lot': 'Lot',
  'pre_alloc': 'Pre Allocation',
  'xdock_strtgy': 'XDock Strategy',
  'strategy': 'Strategy',
  'loc': 'Location',
  'serial_no': 'Serial Number',
  'bom': 'Bill Of Material',
  'veh_info': 'Vehicle Information',
  'pack': 'Pack',
  'btbfta': 'BackToBackFTA',
  'task_disp': 'Task Dispatch',
  'putaway': 'Putaway Strategy',
  'vas_wo': 'Work Order',
  'trnsmit_log': 'Transmitlog',
  'route_mstr': 'Route Master',
  'prmsn_profl': 'Permission Profile',
  'doc_lkup': 'Document Lookup',
  'task': 'Task',
  'non_inv': 'Non Inventory Items',
  'mstr_srl_no': 'Master Serial Number',
  'str_to_loc': 'Store To Loc Detail',
  'inv_bal': 'Inventory Balance',
  'empty_loc': 'Empty Locations',
  'abc_mgmnt': 'ABC Management',
  'drop_id': 'Drop Id',
  'ucc_mntce': 'UCC Maintenance',
  'ucc_adj': 'UCC Adjustment',
  'abc_moves': 'ABC Moves',
  'work_stn': 'Work Station',
  'plt_imginq': 'Pallet Image Inquiry',
  'sup_alerts': 'Supervisor Alerts',
  'vas_trnsfr': 'Transfer',
  'tm_invmv': 'TM Inventory Move',
  'storer': 'Storer',
  'ninv_txn': 'Non Inventory Transaction',
  'rdt_data_cptr': 'RDT Data Capture',
  'inv_moves': 'Inventory Moves',
  'msg' : 'Maintain Messages',
  'pack_hold' : 'Pack And Hold',
  'drct_mv': 'Direct Moves',
  'gtm_cntrl': 'GTM Control',
  'srvr_app_err': 'Server Application Errors',
  'kitting': 'Kitting',
  'chkup_kpi': 'Checkup KPI Maintenance',
  'ext_valdn': 'Extended Validation',
  'commodity': 'Commodity',
  'id_inspcn': 'Id Inspection',
  'job_maint' : 'Job Maintenance',
  'wave_release': 'Wave Release',
  'parameter-group': 'Parameter Group',
  'mn_wavecontrol': 'Wave Control',
  'pack_mgmt': 'Pack Management',
  'report_config': 'Reports Configuration',
  'jreport': 'Logi Report',
  'global_control': 'Global Control',
  'booking_dashboard': 'Booking Performance Dashboard',
  'ship_ref': 'Ship Reference',
  'forwarder_dashboard': 'Forwarder Dashboard',
  'operation_dashboard' : 'Operation Dashboard',
  'billing_dashboard':'Billing Dashboard',
  // 'dashboard': 'Dashboard',
  'dashboard_setup': 'Dashboard Setup',
  'doc_info' : 'Document Info',
  'maintain_report': 'Maintain Report',
  'pick_zone': 'Pick Zones',
  'dataloader_setup':'Dataloader Setup',
  'view_history':'View History',
  'alloc_status':'Allocation Status',
  'downtime_configuration':'Downtime Configuration',
  'chnl_atribte_config':'Channel Attribute Configuration',
  'chnl_transfer':'Channel Transfer',
  'chnl_inv_transfer': 'Channel Inventory Transfer',
  'casemnifst': 'Case Manifest',
  'pltmnifst':'Pallet Manifest',
  'chnl_inv_hold': 'Channel Inventory Hold',
  'container':'Container Manifest',
  'dockdoorbkng': 'Dock Door Booking',
  'appoint_strtgy': 'Appointment Strategy',
  'door_booking_strtgy': 'Door Booking Strategy',
  'replenishment_strtgy':'Replenishment Strategy',
  'tms_dashboard': 'TMS Dashboard'
};

export const BreadcrumbExceptionMapping: Array<string> = [
    'assign_pick'
];
