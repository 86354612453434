import './shared/rxjs-operators';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { RootAuthGuard } from './guards/root-auth.guard';
import { OnlyLoggedInUsersGuard } from './guards/only-loggedin-users.guard';
import { AlwaysAuthChildrenGuard } from './guards/always-auth-children.guard';
import { DeactivateGuard } from './guards/deactivate.guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor, TimingLogInterceptor, ResponseErrorInterceptor } from './http.interceptor';
import { SceCoreModule, ResourceService,  } from 'sce-core';
import { isScreenAccess } from './guards/is-screen-access';
import { ResMapService } from './features/control-tower/order-visibility/order-visibility-table/result-data-table/renderers/res-map.service';
import { TowDefaultMapService } from './features/control-tower/components/services/default-map.service';
import { ViewOrderComponent } from './features/control-tower/view-order/view-order.component';
import { TableModule } from 'primeng-lts/table';
import { OverlayPanelModule } from 'primeng-lts/overlaypanel';
import { DropdownModule } from 'primeng-lts/dropdown'
import { InputSwitchModule } from 'primeng-lts/inputswitch';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FleetMonitoringMapComponent } from './features/control-tower/fleet-monitoring/fleet-monitoring-map/fleet-monitoring-map.component'
import { FleetMonitoringSearchService } from './service/fleet-monitoring/fleet-monitoring-search.service';
import { FleetMonitoringBackendService } from './service/fleet-monitoring/fleet-monitoring-backend.service';
import { FleetMonitoringButtonService } from './service/fleet-monitoring/fleet-monitoring-button.service';
import { FleetMonitoringDataEditorService } from './service/fleet-monitoring/fleet-monitoring-data-editor.service';
import { FleetMonitoringDataService } from './service/fleet-monitoring/fleet-monitoring-data.service';
import { FleetMonitoringActionService } from './service/fleet-monitoring/fleet-monitoring-action.service';
@NgModule({
  declarations: [
    AppComponent,
    ViewOrderComponent,
    FleetMonitoringMapComponent,
],
  imports: [
    BrowserModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayPanelModule,
    DropdownModule,
    InputSwitchModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // -- import Library modules
    SceCoreModule.forRoot(),
    // SCEAdminModule.forRoot(),
    // SCEUILibraryModule.forRoot(),
    // SCESearchModule,
    // -- END import Library modules
    CoreModule, /* Provides core services across the app. Only import in AppModule */
    LayoutModule, /* Root Layout declarations. Home/Login components only */
    SharedModule.forRoot(), /* Shared components/pipes/directives for the app */
    HttpClientModule, /*New HttpClient only available from angular 4.3+. */

    

  ],
  providers: [
    RootAuthGuard,
    OnlyLoggedInUsersGuard,
    isScreenAccess,
    AlwaysAuthChildrenGuard,
    DeactivateGuard,
    ResMapService,
    TowDefaultMapService,

    FleetMonitoringSearchService,
    FleetMonitoringBackendService,
    FleetMonitoringButtonService,
    FleetMonitoringDataEditorService,
    FleetMonitoringDataService,
    FleetMonitoringActionService,

    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimingLogInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseErrorInterceptor, multi: true },
   // ResourceService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
