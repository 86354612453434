import { Injectable } from '@angular/core';
import { AlertService } from 'sce-core';
import { SearchableEntityConfig } from '../../models/searchable-entity-config';
import { SearchableEntityDataEditorService } from './searchable-entity-data-editor.service';
import { SearchableEntityButtonService } from './searchable-entity-button.service';
import { ResourceService } from 'sce-core';
import { SharedServiceRegistry } from '../../shared-service-registry';
import { CoreServiceRegistry } from '../../../core/core-service-registry';
import { SearchService } from '../../search/search.service';
import { Subject ,  Observable } from 'rxjs';

@Injectable()
export class SearchableEntityDataService {

  public entityConfig: SearchableEntityConfig;
  public dataEditor: SearchableEntityDataEditorService;
  public buttonService: SearchableEntityButtonService;
  public searchService: SearchService;
  public alertService: AlertService;
  public resourceService: ResourceService;
  public displayMode: string = 'V';
  public originalDataMap: any;  // stores the original data
  // The subject which will send out value change of storerkey related events
  public valueChangeEventSubject = new Subject<any>();
  constructor(public coreServiceRegistry: CoreServiceRegistry,
    public sharedServiceRegistry: SharedServiceRegistry) {
    this.resourceService = coreServiceRegistry.resourceService;
    this.alertService = sharedServiceRegistry.alertService;
  }

  public setDependentServices(dataEditor, buttonService) {
    this.dataEditor = dataEditor;
    this.buttonService = buttonService;
  }

  public initializeEntityConfiguration(entityConfig: SearchableEntityConfig) {
    this.entityConfig = entityConfig;
  }

  public getEntityConfiguration(): SearchableEntityConfig {
    return this.entityConfig;
  }

  // Default implementation. Will be implemented separately for the simple and detailed data services
  public getSearchService(): SearchService {
    return null;
  }

  // method to populate the metadata map from the given metadata
  public setMetaDataMap(metadata) {
  }

  public setDisplayMode(displayMode) {
    this.displayMode = displayMode;
  }

  public createAlertMessage(inputMessage: string, property?: any) {
    if (inputMessage && inputMessage.indexOf('{}') > -1) {
      inputMessage = inputMessage.replace('{}', this.getEntityConfiguration().getPageTitle());
    }
    if (inputMessage && inputMessage.indexOf('%') > -1) {
      inputMessage = inputMessage.replace('%', property);
    }
    return inputMessage;
  }

  public handleAddNewRow(event: any) { }
  public shallowCopyDataService(targetDataService: SearchableEntityDataService) {
    if (!targetDataService) {
      return;
    }
    targetDataService.entityConfig = this.entityConfig;
    targetDataService.dataEditor = this.dataEditor;
    targetDataService.buttonService = this.buttonService;
    targetDataService.searchService = this.searchService;
    targetDataService.alertService = this.alertService;
    targetDataService.resourceService = this.resourceService;
    targetDataService.displayMode = this.displayMode;
    targetDataService.originalDataMap = this.originalDataMap;
  }
  public handleDestroyDetails() { }

  /**
* Method which publishes all the valuechange events
* @param event - Search Event
*/
  public valueChangedPublishEvent(valueChangedObj: any) {
    this.valueChangeEventSubject.next(valueChangedObj);
  }

  /**
   * Method to receive the valuechange events
   */
  public valueChangedReceiveEvent(): Observable<any> {
    return this.valueChangeEventSubject.asObservable();
  }
}
