import { SearchableEntityDataService } from '../services/searchable-entity/searchable-entity-data.service';
import { SearchableEntityDataEditorService } from '../services/searchable-entity/searchable-entity-data-editor.service';
import { DetailViewKeyEventHandlerService } from '../services/detail-view-key-event-handler.service';
import { TablePreference } from '../search/models/table-preference.model';
import { SearchableEntityButtonService } from '../services/searchable-entity/searchable-entity-button.service';
import { SearchableEntityDynamicDetailsComponent } from '../components/searchable-entity/dynamic-details-page/dynamic-details-page.component';

export class FormPageConfig {

    public layoutType: string = 'default-accordion';
    public groupSecondaryAccordions: boolean = true;
    public columnGroupComponentConfig: any = {};
    public messageConfig: any;
    public cellDataMap: any;
    public metaDataMap: any;
    public dataService: SearchableEntityDataService;
    public buttonService: SearchableEntityButtonService;
    public dataEditor: SearchableEntityDataEditorService;
    public keyEventHandler: DetailViewKeyEventHandlerService;
    public pageLabel: string;
    public userPreference: TablePreference;
    public showDetailsSection: boolean;

    public isPageLoaded: boolean;
    public isPrimarySectionLoaded: boolean;
    public isDetailSectionLoaded: boolean;

    public detailPageComponent: SearchableEntityDynamicDetailsComponent;
    public reloadDataOnTabChange: boolean = true;

    /**
     * The column group to be displayed as a floating panel
     */
    public floatingPanelColumnGroup: string;

    public openNonPrimaryWidgetsByDefault: boolean = false;
     /* Stores the properties which are to be displayed in the search filter
    and search results and to be hidden from the accordion in details page */
    public hiddenPropertiesForAccordion: any = [];
    // Flag used to hide Other Attributes heading in secondary accordion
    public hideOtherAttrLabel: boolean = false;

}
