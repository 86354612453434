import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AlertService } from 'sce-core';
import { ResourceService } from 'sce-core';
import { ConfirmDialogService } from 'sce-core';
import { SearchService } from '../../../search.service';
import { UserPreferenceService } from '../../../../../layout/home/services/user-preference.service';

@Component({
  selector: 'lfwms-table-customizer',
  templateUrl: './table-customizer.component.html',
  styleUrls: ['./table-customizer.component.css']
})
export class TableCustomizerComponent implements OnInit {

  // Numeric Array to persist the order in which displayed/hidden columns were selected
  public selectedActiveIndexes: Array<any> = [];
  // Object Map to maintain the order of displayed columns
  public columnIndexMap: any;
  // Boolean to avoid simultaneous reordering actions
  public isReordering: boolean = false;

  public hiddenPropertiesListBackup: Array<string> = [];
  public pinnedPropertiesListBackup: Array<string> = [];
  public propertyIndexMapBackup: any;
  public columnWidthsObjectBackup: any;
  public pageDefinitionObjectBackup: any;

  // DOM Reference to hold displayed columns container, for positioning the scroll depending on column focus
  @ViewChild('dContainer', { read: ElementRef }) public dContainerRef: ElementRef;

  /* Note :: customizerConfig is the input configuration object
    which holds object reference of column definitions array used in result data table */
  @Input() public customizerConfig: any;
  // Boolean input to show/hide user preferences
  @Input() public showCustomizer: boolean = false;
  // searchService used in result data table
  @Input() public searchService: SearchService;
  // Output Event to refresh column definitions in result data table
  @Output() public customized: EventEmitter<boolean> = new EventEmitter<boolean>();

  /* Since, Customize User Preferences is itself a modal component, alert messages are
     displayed within the table customizer template for 3 seconds */
  public userPreferenceMsg: string = '';
  // Object Array to hold displayed columns
  public displayedColumns: Array<any> = [];
  // Object Array to hold hidden columns
  public hiddenColumns: Array<any> = [];
  /* Pinned Columns will be stacked to top of the displayed columns
     On unpinning, these columns should go to their respective position before pinning
     To implement this logic, we maintain two separate object arrays as below */
  public displayedPinnedColumns: Array<any>;
  public displayedUnpinnedColumns: Array<any>;
  /* On pinning/reordering action, both object arrays will be splitted based on .hide property,
     the activeIndexes are updated, concated and assigned to displayedColumns */

  constructor(public resourceService: ResourceService,
    public confirmDialogService: ConfirmDialogService,
    public alertService: AlertService,
    public userPreference: UserPreferenceService) { }

  public ngOnInit() {
    this.customizerConfig.userPreferences.configuration.hiddenPropertiesList
      .map((hiddenProperty: string) => {
        this.hiddenPropertiesListBackup.push(hiddenProperty);
      });
    this.customizerConfig.userPreferences.configuration.pinnedPropertiesList
      .map((pinnedProperty: string) => {
        this.pinnedPropertiesListBackup.push(pinnedProperty);
      });
    this.propertyIndexMapBackup =
      Object.assign({}, this.customizerConfig.userPreferences.configuration.propertyIndexMap);
    this.columnWidthsObjectBackup =
      Object.assign({}, this.customizerConfig.userPreferences.configuration.columnWidthsObject);
    this.pageDefinitionObjectBackup =
      Object.assign({}, this.customizerConfig.userPreferences.configuration.pageDefinitionObject);
    this.refreshColumnsList();
  }

  public getLabel(key: any) {
    return this.resourceService.get(key);
  }

  // Method to refresh the displayed/hidden columns list
  public refreshColumnsList(preserveSelection?: boolean) {
    // Optional boolean input to clear selected columns
    if (!preserveSelection) {
      this.customizerConfig.columnDefinitions
        .forEach((column: any) => {
          column.isSelected = false;
        });
    }
    if (this.customizerConfig.columnDefinitions.length > 0) {
      /* Each column will have a unique activeIndex
         Hence the customizerConfig.columnDefinitions shall only be sorted once
         columns with isRestricted = true will not be available in user preferences */
      this.customizerConfig.columnDefinitions
        .sort((a: any, b: any) => {
          return ((a.activeIndex < b.activeIndex) ? -1 : ((a.activeIndex > b.activeIndex) ? 1 : 0));
        });
      // Filtering hidden columns
      this.hiddenColumns = this.customizerConfig.columnDefinitions
        .filter((column: any) => {
          return (column.hide && !column.isRestricted);
        });
      // Filtering displayed columns
      this.displayedColumns = this.customizerConfig.columnDefinitions
        .filter((column: any) => {
          return (!column.hide && !column.isRestricted);
        });
      // Filtering pinned displayed columns
      this.displayedPinnedColumns = this.displayedColumns
        .filter((column: any) => column.pinned );
      // Filtering unpinned displayed columns
      this.displayedUnpinnedColumns = this.displayedColumns
        .filter((column: any) => !column.pinned );
      // Concat lists of pinned and unpinned columns
      this.displayedColumns = this.displayedPinnedColumns
        .concat(this.displayedUnpinnedColumns);
      // Refresh the order of displayed columns
      this.columnIndexMap = {};
      this.displayedColumns
        .forEach((column: any, index: number) => {
          this.columnIndexMap[column.colId] = index;
        });
    }
  }

  // Click handler for displayed column
  public handleColumnClick(column: any, $event: any) {
    column.isSelected = !column.isSelected;
    this.setSelectedActiveIndexes(column);
    // Scroll needs to be repositioned only if column was selected
    if (column.isSelected) {
      this.setScrollFocus(this.columnIndexMap[column.colId]);
    }
  }

  // Displayed columns can only be pinned to left
  public handleColumnPinning(column: any, $event: any) {
    column.pinned = column.pinned ? undefined : 'left';
    $event.stopPropagation();
    this.refreshColumnsList(true);
  }

  public moveToDisplayedColumnsList() {
    this.userPreferenceMsg = '';
    const selectedColumns: Array<any> = this.hiddenColumns
      .filter((column: any) => {
        return column.isSelected;
      });
    if (selectedColumns.length > 0) {
      selectedColumns
        .forEach((column: any) => {
          column.isSelected = true;
          column.hide = false;
        });
      this.refreshColumnsList(true);
    } else if (selectedColumns.length === 0) {
      // Msg: Please select a hidden field
      this.showUserPreferenceMsg(this.resourceService.get('msg_slct_hid_fld'));
    }
  }

  public moveToHiddenColumnsList() {
    this.userPreferenceMsg = '';
    const selectedColumns: Array<any> = this.displayedColumns
      .filter((column: any) => {
        return column.isSelected;
      });
    if (selectedColumns.length > 0) {
      selectedColumns
        .forEach((column: any) => {
          if (column.cellRendererParams.cellMetaData.isMandatory ||
            (column.colId === this.customizerConfig.idProperty && this.customizerConfig.showIdPropertyAsHyperLink)) {
            // Msg: Hyperlink/Mandatory fields cannot be made hidden
            this.showUserPreferenceMsg(this.resourceService.get('msg_hyperlink'));
          } else {
            this.selectedActiveIndexes.splice(this.selectedActiveIndexes.indexOf(column.activeIndex), 1);
            column.isSelected = true;
            column.hide = true;
          }
        });
      this.refreshColumnsList(true);
    } else if (selectedColumns.length === 0) {
      // Msg: Please select a displayed field
      this.showUserPreferenceMsg(this.resourceService.get('msg_slct_disp_fld'));
    }
  }

  public setSelectedActiveIndexes(column: any) {
    if (column.isSelected) {
      this.selectedActiveIndexes.push(column.activeIndex);
    } else {
      this.selectedActiveIndexes.splice(this.selectedActiveIndexes.indexOf(column.activeIndex), 1);
    }
  }

  public reorderColumn(isToTop: boolean) {
    if (this.selectedActiveIndexes.length > 0 && !this.isReordering) {
      this.isReordering = true;
      this.userPreferenceMsg = '';
      // Only last selected column can be reordered. Other selected columns will get cleared
      const lastSelectedActiveIndex: number =
        this.selectedActiveIndexes[this.selectedActiveIndexes.length - 1];
      this.selectedActiveIndexes = [lastSelectedActiveIndex];
      let targetArray: Array<any>;
      this.displayedColumns
        .forEach((column: any) => {
          column.isSelected = (column.activeIndex === lastSelectedActiveIndex);
          if (column.isSelected) {
            targetArray =
              (column.pinned) ? this.displayedPinnedColumns : this.displayedUnpinnedColumns;
          }
        });
      if (targetArray.length > 1) {
        let sourceIndex = targetArray
          .findIndex((column: any) => (column.activeIndex === lastSelectedActiveIndex));
        const swapActiveIndexes = (indexSource: number, indexTarget: number) => {
          const tempActiveIndex: number = targetArray[indexSource].activeIndex;
          targetArray[indexSource].activeIndex = targetArray[indexTarget].activeIndex;
          this.selectedActiveIndexes = [targetArray[indexSource].activeIndex];
          targetArray[indexTarget].activeIndex = tempActiveIndex;
        };
        if (sourceIndex !== (isToTop ? 0 : (targetArray.length - 1))) {
          // Second parameter of below method is targetIndex (ie, scroll focus target)
          swapActiveIndexes(sourceIndex, (isToTop ? --sourceIndex : ++sourceIndex));
        }
        this.refreshColumnsList(true);
        this.setScrollFocus(sourceIndex);
      } else {
        // Msg: Please select a displayed field
        this.showUserPreferenceMsg(this.resourceService.get('msg_slct_disp_fld'));
      }
      this.isReordering = false;
    }
  }

  public setScrollFocus(targetIndex: number) {
    /* Note ::
    -> scrollWindowHeight is the height of displayedColumns div
    -> 40px is the height of each displayed column span
    -> 0px is the default & minimum value of scrollTop property of
      the displayed columns container div
    -> 3px is the minimum value of offset.top [ie, targetOffset ] property of
      the first displayed column span */
    const columnSpanHeight: number = 40;
    const scrollWindowHeight: number
      = this.dContainerRef.nativeElement.clientHeight;
    const offsetMin: number = this.dContainerRef.nativeElement.scrollTop;
    const offsetMax: number = this.dContainerRef
      .nativeElement.scrollTop + scrollWindowHeight;
    const targetOffset: number = ((targetIndex + 1) * columnSpanHeight) + 3;
    if (offsetMin > (targetOffset - (1.5 * columnSpanHeight))) {
      this.dContainerRef.nativeElement
        .scrollTop = targetOffset - (2 * columnSpanHeight);
    } else if (offsetMax < (targetOffset + (0.5 * columnSpanHeight))) {
      this.dContainerRef.nativeElement
        .scrollTop = targetOffset + columnSpanHeight - scrollWindowHeight;
    }
  }

  public cancelCustomization() {
    this.userPreferenceMsg = '';
    this.selectedActiveIndexes = [];
    this.customizerConfig.userPreferences.configuration
      .propertyIndexMap = this.propertyIndexMapBackup;
    this.customizerConfig.userPreferences.configuration
      .columnWidthsObject = this.columnWidthsObjectBackup;
    this.customizerConfig.userPreferences.configuration
      .pageDefinitionObject = this.customizerConfig.pageDefinitionObjectBackup;
    this.showCustomizer = false;
    this.customized.emit();
  }

  public resetCustomization() {
    this.userPreferenceMsg = '';
    this.selectedActiveIndexes = [];
    this.confirmDialogService.confirmDialog(this.resourceService.get('msg_reset_pref'))
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.customizerConfig.columnDefinitions
            .map((column: any) => {
              if (!column.isRestricted) {
                column.pinned = undefined;
                column.hide = false;
                column.activeIndex = column.index;
                column.width = (column.minWidth === 267) ? 267 : 250;
              }
            });
          this.customizerConfig.columnDefinitions
            .sort((a: any, b: any) => {
              return ((a.activeIndex < b.activeIndex) ? -1 : ((a.activeIndex > b.activeIndex) ? 1 : 0));
            });
        }
        this.refreshColumnsList();
        this.customizerConfig.userPreferences.configuration.pageDefinitionObject = {'sortColumn': null, 'sortOrder': null, 'pageSize': '10'};
      });
  }

  public showUserPreferenceMsg(msg: string) {
    this.userPreferenceMsg = msg;
    setTimeout(() => {
      this.userPreferenceMsg = '';
    }, 3000);
  }

  public saveCustomization() {
    this.userPreferenceMsg = '';
    if (this.displayedColumns.length < 1) {
      this.showUserPreferenceMsg('Atleast one column should be displayed.');
      return;
    }
    this.customizerConfig.userPreferences.configuration.pinnedPropertiesList = [];
    this.customizerConfig.userPreferences.configuration.hiddenPropertiesList = [];
    this.customizerConfig.userPreferences.configuration.propertyIndexMap = {};
   /* LFWM-1953 - Grid widths lost when saving from customizer
    Removing any changes done to grid column width since its never touched from customizer panel
    */
   // this.customizerConfig.userPreferences.configuration.columnWidthsObject = {};
    if ((this.displayedColumns.length >= 5) ||
      (this.displayedColumns.length + this.hiddenColumns.length <= 5)) {
      this.customizerConfig.columnDefinitions
        .map((column: any) => {
          if (!column.isRestricted) {
            if (column.pinned) {
              this.customizerConfig.userPreferences.configuration.pinnedPropertiesList.push(column.colId);
            }
            if (column.hide) {
              this.customizerConfig.userPreferences.configuration.hiddenPropertiesList.push(column.colId);
            }
            if (column.activeIndex !== column.index) {
              this.customizerConfig.userPreferences.configuration.propertyIndexMap[column.colId] = column.activeIndex;
            }
             /* LFWM-1953 - Grid widths lost when saving from customizer.
              if (true || ((column.minWidth === 267) && (column.width !== 267))
               || ((column.minWidth === 150) && (column.width !== 250))) {
               this.customizerConfig.userPreferences.configuration.columnWidthsObject[column.colId] = column.width;
             }
             */
          }
        });
      // Verify if any user preferences were modified
      if (this.isPreferencesModified()) {
        this.userPreference.saveTablePreferences(this.customizerConfig.userPreferences)
          .subscribe((data: any) => {
            this.customizerConfig.userPreferences.preferenceId = data.preferenceId;
            this.alertService.success(this.resourceService.get('msg_usr_pref_succ'));
            this.showCustomizer = false;
            this.customized.emit();
          }, () => {
            this.showUserPreferenceMsg(this.resourceService.get('msg_pref_sav_fail'));
          });
      } else {
        this.alertService.warn(this.resourceService.get('msg_no_mod_sav'));
        this.showCustomizer = false;
        this.customized.emit();
      }
    } else {
      this.showUserPreferenceMsg(this.resourceService.get('msg_disp_fields'));
    }
  }

  public isPreferencesModified(): boolean {
    if (this.hiddenPropertiesListBackup.length !== this.customizerConfig
      .userPreferences.configuration.hiddenPropertiesList.length) {
      return true;
    } else {
      for (let i = 0; i < this.hiddenPropertiesListBackup.length; i++) {
        if (this.hiddenPropertiesListBackup[i]
            !== this.customizerConfig.userPreferences.configuration.hiddenPropertiesList[i]) {
          return true;
        }
      }
    }
    if (this.pinnedPropertiesListBackup.length !== this.customizerConfig
      .userPreferences.configuration.pinnedPropertiesList.length) {
      return true;
    } else {
      for (let i = 0; i < this.pinnedPropertiesListBackup.length; i++) {
        if (this.pinnedPropertiesListBackup[i]
            !== this.customizerConfig.userPreferences.configuration.pinnedPropertiesList[i]) {
          return true;
        }
      }
    }
    if (Object.keys(this.propertyIndexMapBackup).length
        !== Object.keys(this.customizerConfig.userPreferences.configuration.propertyIndexMap).length) {
      return true;
    } else {
      Object.keys(this.propertyIndexMapBackup)
        .forEach((key: string, index: number) => {
          if ((Object.keys(this.propertyIndexMapBackup)[index]
                !== Object.keys(this.customizerConfig.userPreferences.configuration.propertyIndexMap)[index])
            || (this.propertyIndexMapBackup[key]
                  !== this.customizerConfig.userPreferences.configuration.propertyIndexMap[key])) {
            return true;
          }
        });
    }
    if (Object.keys(this.columnWidthsObjectBackup).length
        !== Object.keys(this.customizerConfig.userPreferences.configuration.columnWidthsObject).length) {
      return true;
    } else {
      Object.keys(this.columnWidthsObjectBackup)
        .forEach((key: string, index: number) => {
          if ((Object.keys(this.columnWidthsObjectBackup)[index]
                !== Object.keys(this.customizerConfig.userPreferences.configuration.columnWidthsObject)[index])
            || (this.columnWidthsObjectBackup[key]
                  !== this.customizerConfig.userPreferences.configuration.columnWidthsObject[key])) {
            return true;
          }
        });
    }
    Object.keys(this.pageDefinitionObjectBackup)
      .forEach((key: string) => {
        if (this.columnWidthsObjectBackup[key]
            !== this.customizerConfig.userPreferences.configuration.columnWidthsObject[key]) {
          return true;
        }
      });
    return false;
  }

}
