export const tmsConfig = {
  baseUrlUI: '/',
  logger: 'console',
  wms: {
    baseUrlUI: '/',
    wmsJreportBaseUrl: 'https://lflreportdev.lfuat.net',
    wmsJreportBaseUat: 'https://lflreport.lfuat.net',
    wmsJreportBaseProd: 'https://report.lflogistics.net',
    wmsUat: 'sceuat.lflogistics.net',
    wmsProd: 'sce.lflogistics.net',
    commonApiBaseUrl: '../',
    wmsJreportBaseProdAmericas: 'https://americas-logireport.fulfillment.maersk.com',
    wmsJreportBaseProdAsia: 'https://asia-logireport.fulfillment.maersk.com',
    wmsJreportBaseProdEur: 'https://europe-logireport.fulfillment.maersk.com',
    wmsJreportBaseCdt: 'https://cdt-logireport.fulfillment.maersk.com',
    wmsProdAmericas: 'americas-wms.fulfillment.maersk.com',
    wmsProdAsia: 'asia-wms.fulfillment.maersk.com',
    wmsProdEur: 'europe-wms.fulfillment.maersk.com',
    wmsCdt: 'cdt-wms.fulfillment.maersk.com'
  },
  gvt: {
    baseUrlUI: '/gvt',
    gvtApiBaseUrl: '../gvtcommon/'
  },
  dashboard: {
    baseUrlUI: '/dashboard',
    dashboardApiBaseUrl: '../dashboardservice/'
  },
  tms: {
    // baseUrl: 'http://222.65.28.22:38080',
    // baseUrl: 'https://fleet.dispatcher-uat.lflogistics.net',
    baseUrl: 'https://dispatcher-dev.lflogistics.net/api',
    getUserInfoUri: '/iam/v1/sync/users/getUserInfos',
    menuQueryUri: 'iam/hzero/v1/menus/tree',
    shipmentQueryUri: '/htms/v2/0/shipments/queryShipments',
    newshipmentQueryUri: '/htms/v2/0/shipments/matchQueryShipments',
    carrierShipmentCountUri: '/htms/v2/0/shipments/queryCount',
    // shipment-planning or shipment-finalized
    shipmentPlanningQuery: '/htms/v1/0/search-conditions/queryShipment', // 通用查询接口
    shipmentSaveCondtion: '/htms/v1/0/search-conditions/saveCondition', // 查询条件保存接口
    shipmentLoadCondtion: '/htms/v1/0/search-conditions/queryCondition', // 查询条件加载接口
    shipmentPlanningOrderQuery: '/htms/v2/0/shipments/queryRelatedOrder', // 关联订单查询接口
    shipmentPlanningUnplanQuery: '/htms/v2/0/shipments/queryUnplannedOrder', // 未关联订单查询接口
    shipmentPlanningFinalized: '/htms/v2/0/shipments/batchSaveNew/finalize', // 运单finalize接口
    shipmentPlanningChildOrder: '/htms/v2/0/shipments/queryRelatedOrderByStop', // 查询站点的order数据
    shipmentBatchDropOrder: '/htms/v2/0/shipments/batchDropOrder', // 拖动order数据进行取消关联
    shipmentBatchDragOrder: '/htms/v2/0/shipments/batchDragOrder', // 拖动order数据进行关联
    shipmentDriverInfo: '/htms/v2/0/shipments/getDriverInfo', // 获取司机信息
    shipmentGetRoutes: '/htms/v2/0/shipments/getRoutes', // 获取画线
    shipmentGpsMobileRputes: '/htms/v2/0/shipments/heartbeat/routes', //获取gps/MOBILE画线
    shipmentDoRoutes: '/htms/v2/0/shipments/doRoutes', // 前端变更画线
    shipmentDoResequence: '/htms/v2/0/shipments/doResequence', // 前端拖拽排序
    shipmentRelatedOrderByStop: '/htms/v2/0/shipments/queryRelatedOrderByStop', // 查询stop关联的order
    shipmentPlanningUnFinalized: '/htms/v2/0/shipments/batchSaveNew/unfinalize', // 运单unfinalize接口
    shipmentMapUpdate: '/htms/v2/0/shipments/batchSaveNew/update', // 拖拽地图后保存更新信息
    getBusinessUnitGid: '/htms/v2/0/shipments/getBusinessUnitGid/', // 获取GId
    queryEquipmentsInfo: '/htms/v2/0/shipments/queryEquipmentsInfo/', // 获取司机车辆信息
    assigndriver: '/htms/v2/0/shipments/batchSaveNew/assigndriver', // 分配司机
    unassigndriver: '/htms/v2/0/shipments/batchSaveNew/unassigndriver', // 取消分配司机
    shipmentEXTransfer: '/htms/v2/0/shipments/batchSaveNew/transfer', // 重新分配司机
    shipmentEXCancelTransfer: '/htms/v2/0/shipments/batchSaveNew/canceltransfer', // 取消重新分配司机
    submitLocationUri: '/htms/v1/0/basic/location/submit',
    shipmentNewUpDate: '/htms/v1/0/locationVariances/saveLocationFront', // 更新update地址
    submitResetPassword: '/htms/v1/0/basic/driver/passwordReset',
    deleteShipment: '/htms/v2/0/shipments/delShipment',
    queryCallingCode: '/htms/v2/0/shipments/queryCallingCode/',
    queryLocationHeartbeat: '/htms/v2/0/shipments/queryLocationHeartbeat', // heartbeat 功能
    exportShipment: '/htms/v2/0/shipments/export/', // export
    truckClose: '/mobile/v1/0/truck-close',

    //controlTower
    newcontrolTowerQueryUri: '/order/api/v1/0/order-movement/selectOrdersByCondition',   //controlTower列表查询接口
    safetyReportExportUri: '/htms/v1/0/shipment-event-history/export/',
    safetyReportQueryUri: '/htms/v1/0/shipment-event-history/search-conditions',
    controlTowerOrderDetailUri: '/tower/v1/0/dds/selectOrderTrack', // controlTower orderID调用地图
    controlTowerQueryUri: '/tower/v1/0/dds/selectOrders',   //controlTower列表查询接口
    // controlTowerOrderDetailUri: '/tower/v1/0/dds/selectOrderTrack', // controlTower orderID调用地图
    locationQueryUri: '/htms/v1/0/basic/location/query',
    controlTowerEventTrackingQueryUri: '/tower/v1/0/dds/queryEventTrackings',
    controlTowerArrivedRateQueryUri: '/order/api/v1/0/order-movement/selectArrivedRate',  //KDI 饼图
    controlTowerPodQueryUri: '/order/api/v1/0/order-movement/selectPod',
    controlTowerShipmentQueryUri: '/htms/v2/0/shipments/dashboard/shipmentCount',
    controlTowerOrderQueryUri: '/htms/v2/0/shipments/dashboard/orderCount',
    controlTowerRateDetailsQueryUri: '/tower/v1/0/dds/selectRateDetails', //饼图详情页
    // selectOrdersByXidNew: '/tower/api/order/dds/selectOrdersByXidNew', //加密页面
    selectOrdersByXidNew: '/order/api/v1/0/order-movement/selectOrdersByXidNew', //加密页面
    selectBusinessUnit: '/tower/api/order/dds/selectBusinessUnit',
    selectPrompt: '/tower/api/order/dds/selectPrompt',
    controlTowerServiceReportQueryUrl: '/htms/v1/0/search-conditions/driver-hoss',
    serviceReportExport: '/htms/v1/0/driver-hoss/export/',
    fleetMonitoringQuery : '/htms/v1/0/search-conditions/vehicles-monitoring',
    
    // mass-upload 
    queryMassUpload: '/htms/v1/0/mass-upload-pod/queryOrder', //查询列表
    queryDetailMassUpload: '/htms/v1/0/mass-upload-pod/getOrderDetails', //查询详情页面
    queryHistoryMassUpload: '/htms/v1/0/mass-upload-pod/getEventTrackings', //历史查询
    massFileUpload: '/htms/v1/0/mass-upload-pod/upload', //单文件上传
    massBatchFileUpload: '/htms/v1/0/mass-upload-pod/batchUpload', //批量上传
    massSaveFileUpload: '/htms/v1/0/mass-upload-pod/batchSave', //批量上传保存
    massHistoryFileUpload: '/htms/v1/0/mass-upload-pod//getEventTrackings', //查看历史操作数据
    queryPrincial: '/htms/v1/0/mass-upload-pod/getP', //查询值集
    //push-notification
    saveNotification: '/htms/v1/0/notification/save', // save
    queryUser: '/htms/v1/0/notification/query',
    // system-setting
    queryStatus: '/htms/v1/0/countrySetting/statusList',
    queryReason: '/htms/v1/0/countrySetting/reasonList',
    submitSetting: '/htms/v1/0/countrySetting/submitSetting',
    querySystemSetting: '/htms/v1/0/countrySetting/list',
    deleteSetting: '/htms/v1/0/usermanuals/removeUsermanuals',
    uploadSetting: '/htms/v1/0/usermanuals/upload',
    uploadOrderEvent: '/order/api/v1/0/order-movement/event/upload/excel',
    downloadOrderEvent: '/order/api/v1/0/order-movement/event/download/excel',
    downloadSetting: '/htms/v1/0/usermanuals/download',
    getPDFStatus: '/htms/v1/0/basic/operationRecord/queryReport',
    getPDFBussiness: '/htms/v1/0/basic/operationRecord/queryBusiness',
    getPdfUrl: '/htms/v1/0/basic/operationRecord/getPdfUrl',

    // child-template
    queryTemplateList: '/notification/v1/0/template/list',
    templateAdd: '/notification/v1/0/template/add', // template add
    templateUpdate: '/notification/v1/0/template/update', // template update
    templateDelete: '/notification/v1/0/template/deletetemplate', // template delete
    queryTemplate: '/notification/v1/0/template/details', // query detail
    queryPanner: '/notification/v1/0/template/getallpartner', // query Panner
    queryPlaceholder: '/notification/v1/0/template/getallplaceholders',

    queryThirdData: '/htms/v1/0/third-party-scan-headers/getList',
    downThirdData: '/mobile/v1/{organizationId}/third-party-scan-headers/exportExcel',
    importQueryUri: '/htms/v1/0/import/data',
    importDataUri: '/htms/v1/0/import/data/data-import',
    importUploadUri: '/htms/v1/0/import/data/data-upload',
    importVerifyUri: '/htms/v1/0/import/data/data-validate',
    importHistoryUri: '/htms/v1/0/import/manager',
    templateDownloadUri: '/htms/v1/0/import/template/{}/excel',
    templateUploadUri: '/htms/v1/0/import/data/data-upload',


    orderQueryUri: '/htms/v1/0/movement/queryAllNewOrderList',
    orderDtlQueryUri: '/htms/v2/0/shipments/queryAllOrderList',
    // orderDtlQueryUri: '/htms/v1/0/htms/movement/queryAllOrderList',
    lovQueryUri: '/htms/v1/lovs/sql/data',
    codeValueQueryUri: '/hpfm/v1/lovs/value',
    excuteMacroUri: '/htms/v1/0/tp/macro/excuteMacro',
    updateMacroUri: '/htms/v2/0/shipments/update/shipment',
    equipmentQueryUri: '/htms/v2/0/shipments/queryEquipments',
    equipmentGroupQueryUri: '/htms/v1/0/basic/equipment/group/query', // 获取车辆类型
    equipmentGroupSubmitUri: '/htms/v1/0/basic/equipment/group/submit',
    driverQueryUri: '/htms/v1/0/basic/driver/cuxQuery', // 获取司机信息
    driverBasicQueryUri: '/htms/v1/0/basic/driver/query',
    driverSubmitUri: '/htms/v1/0/basic/driver/submit',
    carrierQueryUri: '/htms/v1/0/basic/servprov/query',
    carrierQueryUriALL: '/htms/v1/0/basic/servprov/queryAll',
    carrierSubmitUri: '/htms/v1/0/basic/servprov/submit',
    queryTruckUri: '/htms/v1/0/basic/equipment/query',
    customerQueryUri: '/htms/v1/0/basic/customer/query',
    customerSubmitUri: '/htms/v1/0/basic/customer/submit',
    opRecordQueryUri: '/htms/v1/0/basic/operationRecord/queryOperationRecord',
    orderRouteCheckUri: '/htms/v2/0/shipments/dataCheck',
    shipmentCountUri: '/htms/v2/0/shipments/queryCountShimpment',
    shipmentEventHistoryListUri: '/kmq/v1/0/goods/api',
    queryTruckDetailUri: '/htms/v1/0/basic/equipment/',
    submitTruckUri: '/htms/v1/0/equipment-temps/submit',
    submitTruckStatusUri: '/htms/v1/0/basic/equipment/equipmentSubmitStatus',
    saveEventUri: '/htms/v2/0/blackCardBoard/saveEvent',
    getAllShipmentId: '/htms/v1/0/basic/operationRecord/queryOperationRecord',
    getAllOrderId: '/htms/v1/0/basic/operationRecord/queryOrderRecord',
  },
  order: {
    orderEventQuery: '/order/api/v1/0/order-movement/event/listPage',
    orderProcessingQuery: '/order/api/v1/0/order-movement/processing/listPage',
    allOrderQuery: '/order/api/v1/0/order-movement/all/listPage',
    orderAllExport: '/order/api/v1/0/order-movement/all/export/',
    orderProcessingExport: '/order/api/v1/0/order-movement/processing/export/',
    allOrderDelete : '/order/api/v1/0/order-movement/processing/delete',
    orderQueryDetail: '/order/api/v1/0/order-movement/processing/listPage'
  },
  report: {
    inboundQuery: '/htms/v1/0/invoke-inbounds/listPage',
    outboundQuery: '/htms/v1/0/invoke-outbounds/listPage',
    locationQuery: '/htms/v1/0/locationVariances/listPage',
    handoverQuery: '/htms/v1/0/handed-overs/list',
    pushevent: '/htms/v2/0/shipments/pushevent',
    getReasonCode: '/htms/v1/0/invoke-outbounds/getReasonCode',
    stopDwellingQuery: '/htms/v2/0/shipments/stop/dwelling/search',
    stopDwellingExport: '/htms/v2/0/shipments/stop/dwelling/report/excel/',
  },
  settings:{
    reasonCodeQuery : '/htms/v1/0/statusAndCode/list',
    commodityQuery : '/htms/v1/0/basic/commodity/query',
    saveCheckQuery : '/htms/v1/0/task/list',
    transportQuery : '/htms/v1/0/basic/transport-handlings/query',
    getAccessrialCodeList : '/htms/v1/0/basic/accessorial/query',
    getAccessrialDetail : '/htms/v1/0/basic/accessorial/queryDetail',
    getAccessrialClassList : '/htms/v1/0/basic/accessorial/queryClass',
    setAccessrialAddClass : '/htms/v1/0/basic/accessorial/addClass',
    accessrialDeleteClass : '/htms/v1/0/basic/accessorial/deleteClass',
  },
  notification: {
    serverconfigurationQuery: '/notification/v1/0/serverMaster/list',
    viewMessagesQuery: '/notification/v1/0/dashboard/list',
    alertpreview: '/notification/v1/0/template/alertpreview',
    getTemplateCategory: '/notification/v1/0/master/getcategorybylkcode',
    getTemplatePlaceholders: '/notification/v1/0/template/getallplaceholders',
    getTemplatePartner: '/notification/v1/0/template/getallpartner',
    getTemplateCountry: '/notification/v1/0/template/getallcountry',
    setSmsplaceholder: '/notification/v1/0/template/addsmsplaceholder',
    getallmastertemplate: '/notification/v1/0/template/getallmastertemplate',
    retryrerun: '/notification/v1/0/dashboard/retryrerun',
    dashboardExport: '/notification/v1/0/dashboard/export',
    smsSuccessQuery: '/notification/v1/0/dashboard/smsSuccessFailedCount',
    emailSuccessQuery: '/notification/v1/0/dashboard/emailSuccessFailedCount',
    getTemplateType: '/notification/v1/0/content/getFileTemplates',
    downloadTemplate: '/notification/v1/0/content/downloadTemplate',
    importTemplateFile: '/notification/v1/0/content/importFile',
    queryWorkflow: '/notification/v1/0/workFlow/getworkflowcontrolfront',
    getsystemworkflow: '/notification/v1/0/workFlow/getsystemworkflow',
    queryTables: '/notification/v1/0/workFlow/searchTables',
    getworkflowcontrol: '/notification/v1/0/workFlow/getworkflowcontrol',
    getUniqueWorkflowControl: '/notification/v1/0/template/uniqueWorkflowControl',
    saveworkflowcontrol: '/notification/v1/0/workFlow/saveworkflowcontrol',
    deleteworkflowcontrolbyid: '/notification/v1/0/workFlow/deleteworkflowcontrolbyid',
    getManageEventlist: '/notification/v1/0/template/getallsuggestionrules',
    getOffsetStages: '/notification/v1/0/template/getOffsetStages',
    getRecipient: '/notification/v1/0/template/getRecipient',
    getalleventname: '/notification/v1/0/template/getalleventname',
    getviewtemplate: '/notification/v1/0/template/viewtemplate',
    getvalidateOffsetStageEnd: '/notification/v1/0/template/validateOffsetStageEnd',
    saveeventworkflow: '/notification/v1/0/template/saveeventworkflow',
    editeventworkflow: '/notification/v1/0/template/editeventworkflow',
    saveSuggestionRuleData: '/notification/v1/0/template/saveSuggestionRuleData',
    getWorkflowData: '/notification/v1/0/template/getWorkflowData',
    geteventbyeventid: '/notification/v1/0/template/geteventbyeventid',
    deleteworkflowbyeventid: '/notification/v1/0/template/deleteworkflowbyeventid',
    saveTSMasterParamName: '/notification/v1/0/template/saveplaceholder',
    deleteTSMasterParamName: '/notification/v1/0/template/deleteplaceholder',
    insertHtml: '/notification/v1/0/template/getTableHtml',
  },
  blackcard: {
    queryParamCfg: '/htms/v1/0/parameters/getParameterInfo',
    saveParamCfg: '/htms/v1/0/parameters/updateParameter',
    getBingdingRecordUri: '/htms/v2/0/blackCardBoard/getBingdingRecord',
    updateBingdingRecord: '/htms/v2/0/blackCardBoard/updateBingdingRecord',
    getDeviceTotalSummaryUri: '/htms/v2/0/locatingInformations/getDeviceTotal',
    getBlcInOutSttcsChrtUri: '/htms/v2/0/blackCardBoard/getReAndOther',
    getBlackCardBoarUri: '/htms/v2/0/blackCardBoard/getBlackCardBoar',
    getBlackBoardColorUri: '/htms/v2/0/blackCardBoard/getBlackBoardColor',
    saveEventUri: '/htms/v2/0/blackCardBoard/saveEvent',
    getNotificationUri: '/htms/v2/0/blackCardBoard/getNotification',
    blackCardQueryUri: '/htms/v2/0/blackCardBoard/deviceQuery?deviceType=GPS',
    deviceSimpleQueryUri: '/htms/v2/0/locatingInformations/getDeviceInfo',
    checkBlackCardUseUri: '/htms/v2/0/locatingInformations/receiveCheck',
    blackCardOpRecordQueryUri: '/htms/v2/0/tbl/deviceOperation/query',
  },
  ajShipment: {
    ChangeCarrier: '/htms/v1/0/parameters/getParameterInfo',
    MergeShipment: '/htms/v1/0/parameters/updateParameter',
    SplitShipment: '/htms/v2/0/shipments/split/shipment',
    ChangeTime: '/htms/v2/0/shipments/update/shipment/date',
    getCarrierList: '/htms/v2/0/shipments/lov/carrier',
    getTruckType: '/htms/v2/0/shipments/lov/truckType',
    getCarrierPCT: '/htms/v2/0/shipments/change/truck/recalculate',
    updateShipment: '/htms/v2/0/shipments/change/shipment',
    mergeShipment: '/htms/v2/0/shipments/merge/shipment',
  }
}
