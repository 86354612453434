import { Injectable } from '@angular/core';
import { SharedServiceRegistry } from '../../../shared-service-registry';
import { CoreServiceRegistry } from '../../../../core/core-service-registry';
import { SearchableEntityDataEditorService } from '../searchable-entity-data-editor.service';
import { DetailedEntityDataService } from './detailed-entity-data.service';


@Injectable()
export class DetailedEntityDataEditorService extends SearchableEntityDataEditorService {

  public dataService: DetailedEntityDataService;

  constructor(public coreServiceRegistry: CoreServiceRegistry,
    public sharedServiceRegistry: SharedServiceRegistry) {
    super(coreServiceRegistry, sharedServiceRegistry);
  }

  public refreshAutoPopulatedLookupProperty(row: any, baseProperty: string,
    propertyMap: any, isStartEvent: boolean, hasError: boolean) {
    // if this is a start event
    let isLoading: boolean = false;
    if (isStartEvent && row.cells[baseProperty].cVal && row.cells[baseProperty].cVal !== '') {
      isLoading = true;
    }
    const dependantProps = Object.keys(propertyMap);
    dependantProps.forEach(property => {
      if (property !== baseProperty) {
        if (isStartEvent) {
          row.cells[property].cValPrev = row.cells[property].cVal;
          row.cells[property].cVal = '';
        }
        row.cells[property].isLoading = isLoading;
      }
    });
    if (!isLoading) {
      row.cells[baseProperty].isValueInvalid = hasError;
      const propToFocus = hasError ? baseProperty : this.getNextPropertyToFocus(baseProperty);
      let agGridApi;
      if (this.dataService.getDetailsSearchService()) {
        agGridApi = this.dataService.getDetailsSearchService().getPagedData().aggridApi;
      } else {
        agGridApi = null;
      }
      const rowId = 0;
      if (agGridApi && agGridApi.getFocusedCell()) {
        agGridApi.setFocusedCell(agGridApi.getFocusedCell().rowIndex, propToFocus);
      } else {
        row.focusedCellId = propToFocus;
      }
    }
  }

  /**
   * method used to emit event when property value using lookup
   */
  public valueChanged(property, prevVal, currentValue) {
    if (prevVal === currentValue) {
      return;
    }
    const valueChangedObject = new Object();
    valueChangedObject['property'] = property;
    valueChangedObject['prevVal'] = prevVal;
    valueChangedObject['newVal'] = currentValue;
    this.dataService.valueChangedPublishEvent(valueChangedObject);

  }
}
