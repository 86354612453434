export function transToLocationTime(UTCDateString){
  if(!UTCDateString){
    return '-';
  }
  function formatFunc(str) {
    return str > 9 ? str : '0' + str
  }

  //转换为UTC时间
  function timestampToUtc(timestamp) {
    var newtimestamp = timestamp.split(' ')
    newtimestamp[0] = newtimestamp[0] + 'T'
    newtimestamp[1] = newtimestamp[1] + ".000Z"
    return newtimestamp.join('')
  }

  var date2

  if(UTCDateString.length == '10'){
    date2 = new Date(timestampToUtc(UTCDateString + ' ' + '00:00:00'));
  }else{
    date2 = new Date(timestampToUtc(UTCDateString));
  }
  var year = date2.getFullYear();
  var mon = formatFunc(date2.getMonth() + 1);
  var day = formatFunc(date2.getDate());
  var hour = date2.getHours();
  var noon = hour >= 12 ? 'PM' : 'AM';
  // hour = hour>=12?hour-12:hour;
  hour = formatFunc(hour);
  var min = formatFunc(date2.getMinutes());
  var seconds = formatFunc(date2.getSeconds());
  var dateStr 
  // console.log(dateStr)
  if(UTCDateString.length == '10'){
    dateStr= year+'-'+mon+'-'+day
  }else{
    dateStr= year+'-'+mon+'-'+day+' '+hour +':'+min+':'+seconds+' '+noon;
  }
  return dateStr;
}

export function debounce(fn:any, t:any){
  const delay = t || 500
  let timer
  console.log(fn)
  // console.log(typeof fn)
  return function() {
    const args = arguments
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, args)
    }, delay)
  }
}