import {AlertService, ResourceService, UserService} from 'sce-core';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {SearchService} from '../../../../../shared/search/search.service';
import {SearchParams} from '../../../../../shared/search/models/search-params.model';
import {SearchStatus} from '../../../../../shared/search/models/search-status.model';
import {UserPreferenceService} from '../../../../../layout/home/services/user-preference.service';
// import {DatePickerConfigService} from 'sce-ui-lib';
import {tmsConfig} from '../../../../../core/tms-config';
import {TablePreference} from '../../../../../shared/search/models/table-preference.model';
import {PageDefinition} from '../../../../../shared/search/models/page-definition.model';
import {TableRow} from '../../../../../shared/search/models/table-row.model';
// import {tmsConstant} from '../../../../../core/tms-constant';
import {CodeValueService} from '../../../common/services/code-value.service';
import {DefaultMapService} from '../../../maps/service/default-map.service';
import {DateUtil} from '../../../common/utils/date-util';
import { DatePickerConfigService } from '../../../../../shared/components/datepicker/datepicker-config.service';
import { tmsConstant } from 'src/app/features/basic-data/tms-constant';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class DashboardCrrSearchService extends SearchService {
  protected metaDataFetchSubjectShipment: ReplaySubject<any>;
  public selectedModule;
  public resultsAccordionTitle: string = '';
  public displayMode: any = 'E';
  protected mapLocation: any = {};
  protected codeValueService: CodeValueService;
  public mapService: DefaultMapService;
  public truckTypes: any = [];

  putRowData: any;
  countryCode: any;
  Gid: any;
  success: boolean;
  marksList: any[]=[];
  opList: any[]=[];

  constructor(public http$: HttpClient,
              public searchParams: SearchParams,
              public searchStatus: SearchStatus,
              public resourceService: ResourceService,
              public userPreferenceService: UserPreferenceService,
              public alertService: AlertService,
              public dateConfigService: DatePickerConfigService,
              public userService: UserService,
              ) {
    super(http$, searchParams, searchStatus, resourceService, userPreferenceService, alertService, dateConfigService, userService);

  }

  public getLabel(key) {
    this.resourceService.get(key);
  }

  public setCodeValueService(codeValueService: CodeValueService) {
    this.codeValueService = codeValueService;
  }
  public codeValueMap: Map<String, any[]> = new Map<String, any[]>();

  getList(code){
    let params: HttpParams = new HttpParams();
    params = params.set('page', "0").set('size', "10").set('lovCode',code)
    return this.http$.get((tmsConfig.tms.codeValueQueryUri),{ params: params }).toPromise()
  }
   getData(data,code){
    const res = [{key: '', 'value': '- Is Active -', code: '', name: '- Is Active -'}];
    data.forEach((row) => {
      res.push({key: row['value'], value: row['meaning'], code: row['value'], name: row['meaning']});
    });
      this.codeValueMap.set(code, res)
      let obj= this.codeValueMap.get(code)
      return obj
   }
  public fetchMetadata(gid?: any): Observable<any> {
    let params: HttpParams = new HttpParams();
   var userinfo = JSON.parse(localStorage.getItem('user_info'));
   var countryKey = userinfo['userRestrictions'][0]['countryCode'];
    if (!this.metaDataFetchSubjectShipment) {
      this.metaDataFetchSubjectShipment = new ReplaySubject<any>(1);
      // if a metadata fetch is not in progress, create a new observable. Else return the already active one
      let tableId = this.searchParams.tableId;
      this.tableIdforSearch = tableId;
      this.getList(tmsConstant.HTMS_SHIPMENT_MARKS).then((res:any)=>{
          let data= this.getData(res,tmsConstant.HTMS_SHIPMENT_MARKS)
           this.marksList = data;
        })
      this.http$.get(tmsConfig.tms.getBusinessUnitGid + countryKey, {params: params})
        .subscribe((res: any) => {
      this.fetchTruckTypes(res).then((res: any) => {
        const arr = [{key: '', value: '-Select-'}];
        res.content.forEach((row) => {
          row.key = row.id;
          row.value = row.name;
          arr.push(row);
        });
        this.truckTypes = arr;
        this.getMetaData();
        //  the tablepreferences coresponding to tableId will be fetched
        this.tableIdforTablePref = this.tableIdforTablePref ? this.tableIdforTablePref : tableId;
        tableId = this.tableIdforTablePref;
        this.getTablePref(tableId).subscribe(resp => {
          this.tablePreference = resp;
          // initialize the page definition based on the user preferene
          const pageDefinition: PageDefinition = this.pagedData.pageDefinition;
          const userPreference = this.tablePreference.columnConfig;
          pageDefinition.sortColumn = userPreference.configuration.pageDefinitionObject['sortColumn'];
          pageDefinition.sortOrder = userPreference.configuration.pageDefinitionObject['sortOrder'];
          pageDefinition.pageSize = userPreference.configuration.pageDefinitionObject['pageSize'];
          // send the metadata received event
          this.metaDataFetchSubjectShipment.next(this.metadata);
          this.metaDataFetchSubjectShipment.complete();
        });
      });
    })
    }
    return this.metaDataFetchSubjectShipment.asObservable();
  }


  public fetchAutoSuggestionResults(isNextPage: boolean): Observable<Response> {
    this.pagedData.pageRows = [];
    const tableId = this.searchParams.tableId;
    if (isNextPage) {
      this.pagedData.pageDefinition.pageNumber++;
    } else {
      this.pagedData.pageDefinition.pageNumber = 1;
    }
    const pageInfoReq = {
      tableId: tableId,
      pageNo: this.pagedData.pageDefinition.pageNumber,
      pageSize: this.pagedData.pageDefinition.pageSize,
      fullFetch: this.searchParams.isFullFetch
    };
    const payload = {
      pageInfo: pageInfoReq,
      searchCriteria: this.formatSearchCriteria(this.searchCriteria, this.searchParams.additionalParamMap)
    };
    const dataUrl$ = this.http$.post((tmsConfig.tms.equipmentQueryUri), JSON.stringify(payload)).pipe( map((response: any) => {
      // these code is added to avoid duplicate entries when consecutives keys are pressed fastly
      if (!isNextPage) {
        this.pagedData.pageRows = [];
      }
      // return this.transformSearchResponse(response);
      return response
    }),
    catchError((error: any) => {
      return Observable.throw('Failed to fetch  Search Results :: ' + error.message);
    }))

    return dataUrl$;
  }



  public fetchTruckTypes(gid) {
    this.Gid  = gid
    let params: HttpParams = new HttpParams();
    var permission =  JSON.parse(localStorage.getItem('tms_button_permission'))
    var tenantId = permission['userRestrictions'][0]?.restrictions.filter(item=>item.restrictionTypeName == 'Tenant ID')[0]?.restrictionValue || 0
    params=params.set("page",'0').set("size",'0').set("businessUnitGid", gid).set('tenantID',tenantId)
    return this.http$.get((tmsConfig.tms.equipmentGroupQueryUri ),{params:params})
      .toPromise();


  }

  public getMetaData(): any {
    console.debug('tableId', this.searchParams.tableId);
    if(this.searchParams.tableId == 'dashboard-if'){
      this.metadata = {
        'statusCode': 200, 'statusMessage': 'SUCCESS', 'moduleId': 'shipmenttd',
        'elementId': 'SHIPMENT_TB', 'defaultOperator': 'AND',
        'columnList': [
          {
            'id': null, 'elementId': 'id', 'propertyName': 'id', 'displayType': 'H'
          },
          {
            'id': null, 'elementId': '_token', 'propertyName': '_token', 'displayType': 'H'
          },
          {
            'id': null, 'elementId': 'driverGid', 'propertyName': 'driverGid', 'displayType': 'H'
          },
          {
            'id': null, 'elementId': 'equipmentGid', 'propertyName': 'equipmentGid', 'displayType': 'H'
          },
          {
            'id': null, 'elementId': 'equipmentName', 'propertyName': 'equipmentName', 'displayType': 'H'
          },
          // {
          //   'id': null, 'elementId': 'equipmentGroupXid', 'propertyName': 'equipmentGroupXid', 'displayType': 'H'
          // },
          // {
          //   'id': null, 'elementId': 'equipmentGroupName', 'propertyName': 'equipmentGroupName', 'displayType': 'H'
          // },
          {
            'id': null, 'elementId': 'actualEquipmentGroupXid', 'propertyName': 'actualEquipmentGroupXid', 'displayType': 'H'
          },
          {
            'id': null, 'elementId': 'actualEquipmentGroupName', 'propertyName': 'actualEquipmentGroupName', 'displayType': 'H'
          },
          {
            'id': null, 'elementId': 'shipmentType', 'propertyName': 'shipmentType', 'displayType': 'H'
          },
          {
            'id': null, 'elementId': 'businessUnitGid', 'propertyName': 'businessUnitGid', 'displayType': 'H'
          },
          {
            'id': null, 'elementId': 'operationDescribe', 'propertyName': 'operationDescribe', 'displayType': 'H'
          },
          {
            'id': null, 'elementId': 'operationReasonName', 'propertyName': 'operationReasonName', 'displayType': 'H'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_shipment_xid'), 'propertyName': 'xid',
            'type': 'TEXTFIELD', 'defaultVal': null, 'defaultOp': '=', 'isMandatory': true,
            'maxLength': 10, 'index': 1, 'priority': 'Low', 'toolTip': '', 'values': [],
            'columnGroup': 'Primary', 'displayType': 'V', 'dataType': 'String'
          },
          {
            'id': null,
            'elementId': this.resourceService.get('lbl_tms_marks'),
            'propertyName': 'monitorStatus',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 10,
            'index': 2,
            'priority': 'LOW',
            'toolTip': '',
            'values':  this.marksList,
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null,
            'elementId': this.resourceService.get('lbl_tms_shipment_status'),
            'propertyName': 'shipmentPlanStatus',
            'type': 'TEXTFIELD',
            'defaultVal': null,
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 10,
            'index': 3,
            'priority': 'LOW',
            'toolTip': '',
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null,
            'elementId': this.resourceService.get('lbl_tms_special_instruction'),
            'propertyName': 'specialInstruction',
            'type': 'TEXTFIELD',
            'defaultVal': null,
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 10,
            'index': 3.5,
            'priority': 'LOW',
            'toolTip': '',
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_truck_type_requirement'), 'propertyName': 'equipmentGroupName', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 4,
            'priority': 'LOW', 'toolTip': '', 'values': this.truckTypes, 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          // {
          //   'id': null, 'elementId': this.resourceService.get('lbl_tms_actual_truck_type'), 'propertyName': 'actualEquipmentGroupName', 'type': 'TEXTFIELD',
          //   'defaultVal': '', 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 5,
          //   'priority': 'LOW', 'toolTip': '', 'values': this.truckTypes, 'columnGroup': 'Primary', 'displayType': 'V',
          //   'dataType': 'String'
          // },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_truck_number'), 'propertyName': 'equipmentNumber', 'type': 'TEXTFIELD',
            'defaultVal': '', 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 6,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_driver_name'), 'propertyName': 'driverName', 'type': 'TEXTFIELD',
            'defaultVal': '', 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 7,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_driver_contact'), 'propertyName': 'driverNumber', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 8,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_driver_email'), 'propertyName': 'email', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 9,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_planned_pickup_date'), 'propertyName': 'planStartShippingDate', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'Date'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_planned_delivery_date'), 'propertyName': 'planDeliveryDate', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'Date'
          },
          {
            'id': null, 'elementId': 'Carrier Id', 'propertyName': 'servprovGid', 'displayType': 'H'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_carrier_name'), 'propertyName': 'servprovName', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_origin_location_id'), 'propertyName': 'sourceLocationXid', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_source_location_name'), 'propertyName': 'sourceLocationName', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_origin_address'), 'propertyName': 'sourceAddress', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_origin_province'), 'propertyName': 'sourceProvince', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_origin_city'), 'propertyName': 'sourceCity', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_origin_district'), 'propertyName': 'sourceDistrict', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_origin_postcode'), 'propertyName': 'sourcePostcode', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_destination_location_id'), 'propertyName': 'destLocationXid', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_dest_location_name'), 'propertyName': 'destLocationName', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_destination_address'), 'propertyName': 'destAddress', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_destination_state'), 'propertyName': 'destProvince', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_destination_city'), 'propertyName': 'destCity', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_destination_district'), 'propertyName': 'destDistrict', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_destination_postcode'), 'propertyName': 'destPostcode', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_weight'), 'propertyName': 'totalGrossWeight', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
          {
            'id': null, 'elementId': this.resourceService.get('lbl_tms_volume'), 'propertyName': 'totalGrossVolume', 'type': 'TEXTFIELD',
            'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
            'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
            'dataType': 'String'
          },
        ]
      };
    } else {
 this.metadata = {
      'statusCode': 200, 'statusMessage': 'SUCCESS', 'moduleId': 'shipmenttd',
      'elementId': 'SHIPMENT_TB', 'defaultOperator': 'AND',
      'columnList': [
        {
          'id': null, 'elementId': 'id', 'propertyName': 'id', 'displayType': 'H'
        },
        {
          'id': null, 'elementId': '_token', 'propertyName': '_token', 'displayType': 'H'
        },
        {
          'id': null, 'elementId': 'driverGid', 'propertyName': 'driverGid', 'displayType': 'H'
        },
        {
          'id': null, 'elementId': 'equipmentGid', 'propertyName': 'equipmentGid', 'displayType': 'H'
        },
        {
          'id': null, 'elementId': 'equipmentName', 'propertyName': 'equipmentName', 'displayType': 'H'
        },
        // {
        //   'id': null, 'elementId': 'equipmentGroupXid', 'propertyName': 'equipmentGroupXid', 'displayType': 'H'
        // },
        // {
        //   'id': null, 'elementId': 'equipmentGroupName', 'propertyName': 'equipmentGroupName', 'displayType': 'H'
        // },
        {
          'id': null, 'elementId': 'actualEquipmentGroupXid', 'propertyName': 'actualEquipmentGroupXid', 'displayType': 'H'
        },
        {
          'id': null, 'elementId': 'actualEquipmentGroupName', 'propertyName': 'actualEquipmentGroupName', 'displayType': 'H'
        },
        {
          'id': null, 'elementId': 'shipmentType', 'propertyName': 'shipmentType', 'displayType': 'H'
        },
        {
          'id': null, 'elementId': 'businessUnitGid', 'propertyName': 'businessUnitGid', 'displayType': 'H'
        },
        {
          'id': null, 'elementId': 'operationDescribe', 'propertyName': 'operationDescribe', 'displayType': 'H'
        },
        {
          'id': null, 'elementId': 'operationReasonName', 'propertyName': 'operationReasonName', 'displayType': 'H'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_shipment_xid'), 'propertyName': 'xid',
          'type': 'TEXTFIELD', 'defaultVal': null, 'defaultOp': '=', 'isMandatory': true,
          'maxLength': 10, 'index': 1, 'priority': 'Low', 'toolTip': '', 'values': [],
          'columnGroup': 'Primary', 'displayType': 'V', 'dataType': 'String'
        },
        {
          'id': null,
          'elementId': this.resourceService.get('lbl_tms_marks'),
          'propertyName': 'monitorStatus',
          'type': 'TEXTFIELD',
          'defaultVal': '',
          'defaultOp': '=',
          'isMandatory': true,
          'maxLength': 10,
          'index': 2,
          'priority': 'LOW',
          'toolTip': '',
          'values':  this.marksList,
          'columnGroup': 'Primary',
          'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null,
          'elementId': this.resourceService.get('lbl_tms_shipment_status'),
          'propertyName': 'shipmentPlanStatus',
          'type': 'TEXTFIELD',
          'defaultVal': null,
          'defaultOp': '=',
          'isMandatory': true,
          'maxLength': 10,
          'index': 3,
          'priority': 'LOW',
          'toolTip': '',
          'values': [],
          'columnGroup': 'Primary',
          'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null,
          'elementId': this.resourceService.get('lbl_tms_special_instruction'),
          'propertyName': 'specialInstruction',
          'type': 'TEXTFIELD',
          'defaultVal': null,
          'defaultOp': '=',
          'isMandatory': true,
          'maxLength': 10,
          'index': 3.5,
          'priority': 'LOW',
          'toolTip': '',
          'values': [],
          'columnGroup': 'Primary',
          'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_truck_type_requirement'), 'propertyName': 'equipmentGroupName', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 4,
          'priority': 'LOW', 'toolTip': '', 'values': this.truckTypes, 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_actual_truck_type'), 'propertyName': 'actualEquipmentGroupGid', 'type': 'DROPDOWN',
          'defaultVal': '', 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 5,
          'priority': 'LOW', 'toolTip': '', 'values': this.truckTypes, 'columnGroup': 'Primary', 'displayType': 'E',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_truck_number'), 'propertyName': 'equipmentNumber', 'type': 'TEXTFIELD',
          'defaultVal': '', 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 6,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'EL',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_driver_name'), 'propertyName': 'driverName', 'type': 'TEXTFIELD',
          'defaultVal': '', 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 7,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'EL',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_container_no'), 'propertyName': 'containerNo', 'type': 'TEXTFIELD',
          'defaultVal': '', 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 7,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'E',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_seal_no'), 'propertyName': 'sealNo', 'type': 'TEXTFIELD',
          'defaultVal': '', 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 7,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'E',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_trailer_no'), 'propertyName': 'trailerNo', 'type': 'TEXTFIELD',
          'defaultVal': '', 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 7,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'E',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_driver_contact'), 'propertyName': 'driverNumber', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 8,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_driver_email'), 'propertyName': 'email', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 9,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_planned_pickup_date'), 'propertyName': 'planStartShippingDate', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'Date'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_planned_delivery_date'), 'propertyName': 'planDeliveryDate', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'Date'
        },
        {
          'id': null, 'elementId': 'Carrier Id', 'propertyName': 'servprovGid', 'displayType': 'H'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_carrier_name'), 'propertyName': 'servprovName', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_origin_location_id'), 'propertyName': 'sourceLocationXid', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_source_location_name'), 'propertyName': 'sourceLocationName', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_origin_address'), 'propertyName': 'sourceAddress', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_origin_province'), 'propertyName': 'sourceProvince', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_origin_city'), 'propertyName': 'sourceCity', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_origin_district'), 'propertyName': 'sourceDistrict', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_origin_postcode'), 'propertyName': 'sourcePostcode', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_destination_location_id'), 'propertyName': 'destLocationXid', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_dest_location_name'), 'propertyName': 'destLocationName', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_destination_address'), 'propertyName': 'destAddress', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_destination_state'), 'propertyName': 'destProvince', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_destination_city'), 'propertyName': 'destCity', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_destination_district'), 'propertyName': 'destDistrict', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_destination_postcode'), 'propertyName': 'destPostcode', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_weight'), 'propertyName': 'totalGrossWeight', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_volume'), 'propertyName': 'totalGrossVolume', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 10,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
      ]
    };
    }

    return this.metadata;
  }

  public getPayload(): any {
    const payload = this.formatSearchCriteria(this.searchCriteria, this.searchCriteria, this.filterCondition);
    console.debug(payload,this.searchCriteria,this.searchCriteria,'-----payload',this.filterCondition)
    Object.keys(payload).forEach((k) => {
      if (k.includes('Date')) {
        payload[k] = DateUtil.formatDate(payload[k]);
      }
      if (payload[k] === '') {
        payload[k] = null;
      }
    });
    return payload;
  }



  public queryUserInfo(){
    return this.http$.post('/htms/api/user/sce/getUserInfo', JSON.stringify({key: JSON.parse(localStorage.getItem('jwt_token')).token})).pipe(
      map((response: any) => {
        return response;
      }));
  }

  getBusinessUnitGid(value){
    let params: HttpParams = new HttpParams();
   return this.http$.get(tmsConfig.tms.getBusinessUnitGid+value, {params: params}).pipe(
    map((response: any) => {
      this.Gid = response
      return response;
    }));
  }

  public searchPage(page:any, size:any, id:any,searchCondition:any){
    const payload = this.getPayload();
    var permission =  JSON.parse(localStorage.getItem('tms_button_permission'))
    var gid = JSON.parse(localStorage.getItem('businessUnitGid'))
    var carrierId = permission['userRestrictions'][0]?.restrictions.filter(item=>item.restrictionTypeName == 'Carrier')[0]?.restrictionValue || null
    payload.businessUnitGid = id || gid
    if(carrierId){
      payload.carrier = carrierId
    }
    var newpayload = {
      ...payload,
      ...searchCondition
    }
    var tenantId = permission['userRestrictions'][0]?.restrictions.filter(item=>item.restrictionTypeName == 'Tenant ID')[0]?.restrictionValue || 0
    var permissionAddUrl = `&tenantID=${tenantId}`

    var matchFlag = (window.location.pathname == '/home/reserve/shipment-match/search')
    const resultData: any = this.http$.post((`${matchFlag?tmsConfig.tms.newshipmentQueryUri:tmsConfig.tms.shipmentQueryUri}?page=${page}&size=${size}${permissionAddUrl}`), newpayload);
    return resultData.pipe( map((response) => {
    return response
    }),
    catchError((error: any) => {
          if (error) {
              return error;
          }
   }))





  }

  public fetchSearchResults():Observable<any>{
    var gid = JSON.parse(localStorage.getItem('businessUnitGid'))
    this.pagedData.pageRows = [];
    const pageInfoReq = {
      pageNo: this.pagedData.pageDefinition.pageNumber < 1 ? 0 : this.pagedData.pageDefinition.pageNumber - 1,
      sortColumn: this.pagedData.pageDefinition.sortColumn,
      sortOrder: this.pagedData.pageDefinition.sortOrder,
      pageSize: this.pagedData.pageDefinition.pageSize,
    };
    const payload = this.getPayload();
    var permission =  JSON.parse(localStorage.getItem('tms_button_permission'))
    var carrierId = permission['userRestrictions'][0]?.restrictions.filter(item=>item.restrictionTypeName == 'Carrier')[0]?.restrictionValue || null
    payload.businessUnitGid = gid
    var tenantId = permission['userRestrictions'][0]?.restrictions.filter(item=>item.restrictionTypeName == 'Tenant ID')[0]?.restrictionValue || 0
    var permissionAddUrl = `&tenantID=${tenantId}`
    var matchFlag = (window.location.pathname == '/home/reserve/shipment-match/search')
    if(carrierId){
      payload.carrier = carrierId
      const resultData: any = this.http$.post((`${matchFlag?tmsConfig.tms.newshipmentQueryUri:tmsConfig.tms.shipmentQueryUri}?page=${pageInfoReq.pageNo}&size=${pageInfoReq.pageSize}${permissionAddUrl}`), payload);
      return resultData.pipe( map((response) => {
      return response
      }),
      catchError((error: any) => {
            if (error) {
                return error;
            }
    }))
    } else {
      const resultData: any = this.http$.post((`${matchFlag?tmsConfig.tms.newshipmentQueryUri:tmsConfig.tms.shipmentQueryUri}?page=${pageInfoReq.pageNo}&size=${pageInfoReq.pageSize}${permissionAddUrl}`), payload);
      return resultData.pipe( map((response) => {
      return response
      }),
      catchError((error: any) => {
            if (error) {
                return error;
            }
    }))
    }

    // const resultData: any = this.http$.post((`${tmsConfig.tms.shipmentQueryUri}?page=${pageInfoReq.pageNo}&size=${pageInfoReq.pageSize}`), payload);
    // return resultData.pipe( map((response) => {
    //   return response
    // }),
    // catchError((error: any) => {
    //       if (error) {
    //           return error;
    //       }
    //       this.pagedData.pageDefinition.recordCount = 0;
    //       this.pagedData.pageRows.splice(0, 0);
    //       this.searchStatus.hasResults = true;
    //       this.searchStatus.recordCount = 0;

    //  })
    // )


  }

  /**
   * Transforms the search response to the client format
   *
   * @param response - Server response
   */
  public transformSearchResponse(response: any) {
    const pDef: PageDefinition = this.pagedData.pageDefinition;
    const pData: Array<TableRow> = this.pagedData.pageRows;
    this.pagedData.focusedRow = null;
    this.pagedData.focusedCellId = null;
    pDef.pageNumber = +response['number'] + 1;
    pDef.sortColumn = response['sortColumn'];
    pDef.sortOrder = +response['sortOrder'];
    pDef.recordCount = +response['totalElements'];
    pDef.pageSize = +response['size'];
    /* [LFWM-1956] - for Inventory Screen - fetching aggregateRecords from loadbyPage response to show aggregate of some columns
    for records which is present in server side [this aggregateRecords used only in Inventory Screen] */
    pDef.aggregateRecords = response['aggregateRecords']; //
    for (let i = 0; i < response['content'].length; i++) {
      // Adding code for DataTable View Structure Transformation
      const columnList = this.metadata.columnList;
      const rowDefinition = response['content'][i];
      rowDefinition.cells = [];
      rowDefinition.rIdx = i + 1;
      const rowObject: any = new Object();
      rowObject.rIdx = rowDefinition.rIdx;
      // Flag to select/deselect the respective Checkbox (default : unchecked)
      rowObject.isSelected = false;
      // Iterating and inserting the server response cells to each Row Object in RowData Array
      rowObject.cells = [];
      columnList.forEach((cell) => {
        rowDefinition.cells.push({
          cName: cell.propertyName,
          cVal: rowDefinition[cell.propertyName]
        });
      });
      rowDefinition.cells.forEach((cell: any) => {
        const cellInfo: any = new Object();
        cellInfo.cVal = cell.cVal;
        cellInfo.cValOrig = cell.cVal;
        cellInfo.cValPrev = cell.cVal;
        cellInfo.cName = cell.cName;
        rowObject.cells[cell.cName] = cellInfo;
      });
      pData.push(rowObject);
    }
  }

  public fetchSearchResults2(param?: any): Observable<any> {
    // const payload = {servprovGid: this.userService.userInfo.servprovGid, ...param};
    const payload = {...param};
    var permission =  JSON.parse(localStorage.getItem('tms_button_permission'))
    var tenantId = permission['userRestrictions'][0]?.restrictions.filter(item=>item.restrictionTypeName == 'Tenant ID')[0]?.restrictionValue || 0
    var permissionAddUrl = `&tenantID=${tenantId}`
    const dataUrl$: any
      = this.http$.post((tmsConfig.tms.shipmentQueryUri, `?page=0&size=10${permissionAddUrl}`), payload);
    return dataUrl$
      .map((response: any) => response)
      .catch((errorObject: any) => {
        if (errorObject.error && errorObject.error.errors && errorObject.error.errors[0]) {
          this.alertService.clearAll().error(errorObject.error.errors[0].validationMessage);
        }
      })
      .finally(() => {
      });
  }

  public formatSearchCriteria(searchCriteria, additionalParamMap, filterCondition?: any) {
    const allConditions = [];
    let clauseReq = {};
    if (searchCriteria && searchCriteria.conditions && searchCriteria.conditions.length > 0) {
      searchCriteria.conditions.forEach((condition) => {
        clauseReq = {...clauseReq, ...condition};
      });
    }
    // Code added to merge filter conditions with master search criteria, if filter condition is there.
    if (filterCondition && filterCondition.clauses.length > 0) {
      allConditions.push(filterCondition);
    }
    allConditions.forEach((condition: any, index: any) => {
      condition.clauses.forEach((clause: any) => {
        if (clause.value !== undefined && clause.value !== null) {
          clauseReq[clause.column] = clause.value;
        }
      });
    });
    return clauseReq;
  }

  public getTablePref(tableId): Observable<any> {
    return this.http$.get(`assets/public/` + tableId + '.json').pipe(  map((response: any) => {
      return this.transformTablePreference(tableId, response);
    }),catchError((error: any) => {
      return Observable.throw('Failed to fetch  Dashboard Modules ' + error.message);
  }))

  }


  getFocusedRow0(): any {
    const obj = this.getFocusedRow();
    let row = {cells: {}};
    if (obj && obj.row) {
      row = obj.row;
    } else {
      row = this.getAllRows()[0];
    }
    row = row || {cells: {}};
    const row2 = {};
    Object.keys(row.cells).forEach((key) => {
      row2[key] = row.cells[key].cVal;
    });
    return row2;
  }

  getFocusedRow2(data): any {
   this.getMaps(data)
    return data[0];
  }

   // 传值调用地图 map
   getMaps(data) {
    // 重置地图
    document.getElementById("map").innerHTML ='';
    this.mapService.initMap('map');
    let DeliveredState;
    DeliveredState = {
      Page: 'OrderTrack',
      requestedDeliveryDate: data.requestedDeliveryDate,
      shipmentPlannedEndDate: data.shipmentPlannedEndDate,
      shipmentPlannedStartDate: data.shipmentPlannedStartDate,
      sourceCity: data.sourceCity,
      destCity: data.destCity,
      diffDistance: data.diffDistance,
      time: data.time,
      orderStatus: data.orderStatus,
      eventStatusCode: data.eventStatusCode,
      shipmentXid: data.xid,
      // gpsLat: res.gpsLat,
      // gpsLon: res.gpsLon,
      // correctFlag: res.correctFlag,
      sourceLon: data.sourceLon,
      sourceLat: data.sourceLat,
      destLon: data.destLon,
      destLat: data.destLat,
      url: data.url
  }
    this.mapService.refreshMap([[data.sourceLon, data.sourceLat], [data.destLon, data.destLat]], DeliveredState)

   }

   // 获取画线
   getPolyline(id) {
    let parmes = {
        id
    }
    var url = tmsConfig.tms.shipmentGetRoutes;
    const response$: any = this.http$.post(url + '?page=0&size=10', JSON.stringify(parmes)).pipe(
        map((response: any) => {
            return response;
        }),
        catchError((error: any) => {
            return this.resourceService.get(error.message);
        }),);
    return response$;
}

  getSelectedRows0(): Array<any> {
    const arr: Array<any> = [];
    this.getSelectedRows().forEach((row: any) => {
      const row2 = {};
      Object.keys(row.cells).forEach((key) => {
        row2[key] = row.cells[key].cVal;
      });
      arr.push(row2);
    });
    return arr;
  }

  public async refreshMap(row) {
    console.debug(row , this.mapLocation.xid);

    if (row.xid === this.mapLocation.xid) {
      return;
    }
    this.mapLocation.xid = row.xid;
    const sXid = row.sourceLocationXid;
    const dXid = row.destLocationXid;

    const sRes = await this.getLngAndLatByXid(sXid);
    const dRes = await this.getLngAndLatByXid(dXid);
    let arr = [];
    sRes['content'].forEach(tmp => {
      if (tmp.xid === sXid) {
        arr.push(tmp);
      }
    });
    sRes['content'] = arr;
    arr = [];
    dRes['content'].forEach(tmp => {
      if (tmp.xid === dXid) {
        arr.push(tmp);
      }
    });
    dRes['content'] = arr;
    arr = null;

    this.mapLocation.sLng = (sRes['content'][0] || {}).lon;
    this.mapLocation.sLat = (sRes['content'][0] || {}).lat;
    this.mapLocation.dLng = (dRes['content'][0] || {}).lon;
    this.mapLocation.dLat = (dRes['content'][0] || {}).lat;

    const points = [[this.mapLocation.sLng, this.mapLocation.sLat], [this.mapLocation.dLng, this.mapLocation.dLat]];
    this.mapService.refreshMap(points);
  }

  getLngAndLatByXid(xid: string) {
    const payload = {xid: xid};
    var permission =  JSON.parse(localStorage.getItem('tms_button_permission'))
    var tenantId = permission['userRestrictions'][0]?.restrictions.filter(item=>item.restrictionTypeName == 'Tenant ID')[0]?.restrictionValue || 0
    var permissionAddUrl = `&tenantID=${tenantId}`
    const dataUrl$: any
      = this.http$.get((`${tmsConfig.tms.locationQueryUri}?xid=${xid}${permissionAddUrl}`));
    return dataUrl$
      .toPromise();
  }


  async saveChanges(value) {
    // const data = this.convertToData();
    const data = value;
    if (!data || !data.length) {
      this.alertService.clearAll().error('no data to save!');
      return;
    }
    let count = 0;
    let List:any=[]
    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      // if carrier dsbd shipment typs is F and opstatus is pending,and actualEquipmentGroupGid is null
      // if (row.shipmentType === tmsConstant.HTMS_SHIPMENT_TYPE_F && row.opStatus === tmsConstant.HTMS_SHIPMENT_OP_STATUS_3000) {

      // if carrier dsbd opstatus is pending,and actualEquipmentGroupGid is null
      if (row.opStatus === 'PENDING') {
        if (!row.actualEquipmentGroupGid) {
          row.actualEquipmentGroupGid = row.equipmentGroupGid;
          row.actualEquipmentGroupXid = row.equipmentGroupXid;
          row.actualEquipmentGroupName = row.equipmentGroupName;
        }
      }
      const vehicle: any = {
        'driverGid': row.driverGid || null,
        'driverNumber': row.driverNumber||'',
        'driverName': row.driverName||'',
        'actualEquipmentGroupGid': row.actualEquipmentGroupGid||null,
        'actualEquipmentGroupXid': row.actualEquipmentGroupXid||'',
        'actualEquipmentGroupName': row.actualEquipmentGroupName||'',
        'equipmentGid': row.equipmentGid || null,
        'equipmentName': row.equipmentName||'',
        'equipmentNumber': row.equipmentNumber||'',
        'id': row.id
      };
      List.push(vehicle)

    }
    const result = await this.doAssignVehicle(List);
      if (result.success) {
        count++;
      }
    if (count === data.length) {
      this.alertService.clearAll().success(this.resourceService.get('lbl_tms_msg_data_submit'));
      this.success=true
    } else {
      this.alertService.clearAll().error('Failed!');
    }
    // if (count > 0) {
    //   this.performSearch();
    // }
  }

  private convertToData(): any[] {
    const editedRows = this.getEditedRows();
    const data = [];
    // this.getSelectedRows().filter(row => !row.isEdited).forEach(row => editedRows.push(row));
    editedRows.forEach((row) => {
      const obj = {};
      Array(row['cells']).forEach((cell) => {
        Object.keys(cell).forEach((k) => obj[k] = cell[k].cVal);
      });
      data.push(obj);
    });
    return data;
  }

  doDeclineShipment(shipment: any) {
    const idList = [{id: shipment['id']}];
    const payload = {
      'paramIn': {
        // 'beforeOperation': '',
        // 'afterOperation': '',
        // 'operationCode': '',
        'driverGid': 0,
        'driverNumber': '',
        'driverName': '',
        'actualEquipmentGroupGid': 0,
        'actualEquipmentGroupXid': '',
        'actualEquipmentGroupName': '',
        'equipmentGid': 0,
        'equipmentName': '',
        'equipmentNumber': '',

        'operationDescribe': shipment.remark,
        'operationResonName': shipment.reason,
        'operationResonCode': shipment.reason,
        'list': idList
      },
      'macroCode': 'LF_SHIPMENT_DECLINE'
    };
    const result: any = this.http$.post((tmsConfig.tms.excuteMacroUri), payload);
    return result.map((res) => res)
      .catch((errorObject: any) => {
        if (errorObject.error && errorObject.error.errors && errorObject.error.errors[0]) {
          this.alertService.clearAll().error(errorObject.error.errors[0].validationMessage);
        }
      })
      .finally(() => {
      });
  }

  doAssignVehicle(vehicle: any, macroCode?: string) {
    // const payload = {
    //   'paramIn': {
    //     'driverGid': vehicle.driverGid,
    //     'driverNumber': vehicle.driverPhone,
    //     'driverName': vehicle.driverName,
    //     'actualEquipmentGroupGid': vehicle.equipmentGroupGid,
    //     'actualEquipmentGroupXid': vehicle.equipmentGroupXid,
    //     'actualEquipmentGroupName': vehicle.equipmentGroupName,
    //     'equipmentGid': vehicle.id,
    //     'equipmentName': vehicle.equipmentName,
    //     'equipmentNumber': vehicle.carNumber || vehicle.equipmentNumber,
    //     // 'opStatus': vehicle.opStatus,
    //     'list': [{'id': vehicle.shipmentId || this.getFocusedRow0().id}]
    //   },
    //   'macroCode': macroCode || 'LF_SHIPMENT_ASSIGN_VEHICLE'
    // };
    const result: any = this.http$.post(('/htms/v2/0/shipments/accept'), vehicle);
    return result.toPromise();
  }

  doAssignVehicle2(vehicle: any) {
    // public handleLookupEvent2(currentRow: any, property: any, selectResult, primeFocusInput?: any)
    this.searchParams.tableEditor['handleLookupEvent2'](this.getFocusedRow().row, 'equipmentNumber', vehicle);
  }

  doReassignVehicle(vehicle: any) {
    const payload = {
      'paramIn': {
        'driverGid': vehicle.driverGid || '0',
        'driverNumber': vehicle.driverPhone || '',
        'driverName': vehicle.driverName || '',
        'actualEquipmentGroupGid': vehicle.equipmentGroupGid,
        'actualEquipmentGroupName': vehicle.equipmentGroupName || '',
        'equipmentGid': vehicle.id || '0',
        'equipmentName': vehicle.equipmentName || '',
        'equipmentNumber': vehicle.carNumber || vehicle.equipmentNumber,
        'list': [{'id': vehicle.shipmentId || this.getFocusedRow0().id}],
        'operationDescribe': vehicle.operationDescribe,
        'operationResonName': vehicle.operationReasonName,
        'operationResonCode': null,
        'opStatus': tmsConstant.HTMS_SHIPMENT_OP_STATUS_4000,
      },
      'macroCode': 'LF_SHIPMENT_REASSIGN_EQUIPMENT'
    };
    const result: any = this.http$.post((tmsConfig.tms.excuteMacroUri), payload);
    return result.toPromise();
  }

  doReassignVehicle2(vehicle: any) {
    // public handleLookupEvent2(currentRow: any, property: any, selectResult, primeFocusInput?: any)
    console.debug(this.getFocusedRow(),'----this.getFocusedRow()')
    this.searchParams.tableEditor['handleLookupEvent2'](this.getFocusedRow().row, 'equipmentNumber', vehicle);
  }

  doReassignCarrier(shipment: any, carrier: any) {
    const payload = {
      'paramIn': {
        'servprovGid': carrier[0].id,
        'list': [{'id': shipment.id}]
      },
      'macroCode': 'LF_SHIPMENT_REASSIGN_CARRIER'
    };
    const result: any = this.http$.post((tmsConfig.tms.excuteMacroUri), payload);
    return result.pipe(map((res) => res),
    catchError((errorObject: any) => {
      return  errorObject
        // this.alertService.clearAll().error(errorObject.error);

    })
    )



  }

  doAlterShipment(modifyObj: any, shipment: any) {
    const payload = {
      'paramIn': {
        'servprovGid': (modifyObj.servprovGid || shipment.servprovGid),
        'equipmentGid': (modifyObj.equipmentGid || shipment.equipmentGid),
        'equipmentNumber': modifyObj.equipmentNumber || shipment.equipmentNumber,
        'equipmentGroupGid': (modifyObj.equipmentGroupGid || shipment.equipmentGroupGid),
        'list': [{'id': shipment.id}]
      },
      'macroCode': 'LF_A_SHIPMENT_MODIFY'
    };
    const result: any = this.http$.post(tmsConfig.tms.baseUrl.concat(tmsConfig.tms.excuteMacroUri), payload);
    return result.map((res) => res)
      .catch((errorObject: any) => {
        if (errorObject.error && errorObject.error.errors && errorObject.error.errors[0]) {
          this.alertService.clearAll().error(errorObject.error.errors[0].validationMessage);
        }
      })
      .finally(() => {
      });

  }

  doConfirmShipment(selectedShipments) {
    // const selectedShipments = this.getSelectedRows0();
    if (!selectedShipments || !selectedShipments.length) {
      this.alertService.clearAll().error(this.resourceService.get('lbl_tms_msg_select_shipment'));
      return;
    }
    const idList = [];
    const shipmentType = selectedShipments[0].shipmentType;
    const monitorStatusY = selectedShipments[0].monitorStatus === 'Y';
    selectedShipments.forEach((row) => {
      idList.push({id: row.id});
    });
    const payload = {
      'paramIn': {
        'list': idList,
      },
      'macroCode': tmsConstant.HTMS_SHIPMENT_TYPE_F === shipmentType ? 'LF_F_SHIPMENT_CONFIRM' :
        !monitorStatusY ? 'LF_A_SHIPMENT_CONFIRM' : 'LF_A_SHIPMENT_CONFIRM_DIO'
      // 'macroCode': 'LF_F_SHIPMENT_CONFIRM'
    };

    const dataUrl$: any
      = this.http$.post((tmsConfig.tms.excuteMacroUri), payload);
    return dataUrl$.pipe( map((response: any) => {
      return response;
    }),
    catchError((errorObject: any) => {
     return errorObject
        // this.alertService.clearAll().error(errorObject.error.errors[0].validationMessage);

    })
    )



  }
}
