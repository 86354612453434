import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {SearchParams} from '../../../../../shared/search/models/search-params.model';
import {SearchStatus} from '../../../../../shared/search/models/search-status.model';
import {AlertService, ResourceService, UserService} from 'sce-core';
import {UserPreferenceService} from '../../../../../layout/home/services/user-preference.service';
// import {DatePickerConfigService} from 'sce-ui-lib';
import {Observable, ReplaySubject} from 'rxjs';
// import {tmsConstant} from '../../../../../core/tms-constant';
import {tmsConfig} from '../../../../../core/tms-config';
import {DashboardCrrSearchService} from '../../dashboard-crr-home/services/dashboard-crr-search.service';
import {PageDefinition} from '../../../../../shared/search/models/page-definition.model';
import { DatePickerConfigService } from '../../../../../shared/components/datepicker/datepicker-config.service';
import { tmsConstant } from 'src/app/features/basic-data/tms-constant';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ShipmentMatchSearchService extends DashboardCrrSearchService {

  public truckTypes: any = [];
  public forecastShipments: number[] = [];
  public actualShipments: number[] = [];
  public columnDef: any[] = [];
  public crrData:any = [];
  public Gid:any;

  constructor(public http$: HttpClient,
              public searchParams: SearchParams,
              public searchStatus: SearchStatus,
              public resourceService: ResourceService,
              public userPreferenceService: UserPreferenceService,
              public alertService: AlertService,
              public dateConfigService: DatePickerConfigService,
              public userService: UserService,
              ) {

    super(http$, searchParams, searchStatus, resourceService, userPreferenceService, alertService, dateConfigService, userService);
 
  }


 reset() {
    const condition = this.searchCriteria.conditions[0];
    const defaultCondition = this.searchCriteria.defaultSearchCondition || {};
    const defaultConditionKeys = Object.keys(defaultCondition);
    Object.keys(condition).forEach(k => {
      if (defaultConditionKeys.includes(k)) {
        condition[k] = defaultCondition[k];
      } else {
        condition[k] = '';
      }
    });
 }

  public fetchMetadata(userInfo?: any): Observable<any> {
    let params: HttpParams = new HttpParams();
    var userinfo = JSON.parse(localStorage.getItem('user_info'));
    var countryKey = userinfo['userRestrictions'][0]['countryCode'];
    if (!this.metaDataFetchSubjectShipment) {
      this.metaDataFetchSubjectShipment = new ReplaySubject<any>(1);
      // if a metadata fetch is not in progress, create a new observable. Else return the already active one
      let tableId = this.searchParams.tableId;
      this.tableIdforSearch = tableId;
      this.http$.get(tmsConfig.tms.getBusinessUnitGid + countryKey, {params: params})
      .subscribe((res: any) => { 
      const result = this.fetchTruckTypes(res);
      result.then((res: any) => {
        const arr = [{key: '', value: `- ${this.resourceService.get('lbl_tms_truck_type')} -`}];
        res.content.forEach((row) => {
          row.key = row.id;
          row.value = row.xid;
          arr.push(row);
        });
        this.truckTypes = arr;

        this.setMetadata2();

        //  the tablepreferences coresponding to tableId will be fetched
        this.tableIdforTablePref = this.tableIdforTablePref ? this.tableIdforTablePref : tableId;
        tableId = this.tableIdforTablePref;
        this.getTablePref(tableId).subscribe(resp => {
          this.tablePreference = resp;
          // initialize the page definition based on the user preferene
          const pageDefinition: PageDefinition = this.pagedData.pageDefinition;
          const userPreference = this.tablePreference.columnConfig;
          pageDefinition.sortColumn = userPreference.configuration.pageDefinitionObject['sortColumn'];
          pageDefinition.sortOrder = userPreference.configuration.pageDefinitionObject['sortOrder'];
          pageDefinition.pageSize = userPreference.configuration.pageDefinitionObject['pageSize'];
          // send the metadata received event
          this.metaDataFetchSubjectShipment.next(this.metadata);
          this.metaDataFetchSubjectShipment.complete();
        });
      });
      })
    }
    return this.metaDataFetchSubjectShipment.asObservable();
  }

  // public searchPage(page:any, size:any){
  //   const payload = this.getPayload();
  //   var permission =  JSON.parse(localStorage.getItem('tms_button_permission'))
  //   var carrierId = permission['userRestrictions'][0]?.restrictions.filter(item=>item.restrictionTypeName == 'Carrier')[0]?.restrictionValue || null 
  //   if(carrierId){
  //     payload.carrier = carrierId
  //     const resultData: any = this.http$.post((`${tmsConfig.tms.shipmentQueryUri}?page=${page}&size=${size}`), payload);
  //     return resultData.pipe( map((response) => {
  //     return response
  //     }),
  //     catchError((error: any) => {
  //           if (error) {
  //               return error;
  //           }      
  //   }))
  //   } else {
  //     const resultData: any = this.http$.post((`${tmsConfig.tms.shipmentQueryUri}?page=${page}&size=${size}`), payload);
  //     return resultData.pipe( map((response) => {
  //     return response
  //     }),
  //     catchError((error: any) => {
  //           if (error) {
  //               return error;
  //           }      
  //   }))
  //   }  
  // }

  public getMetaData(): any {
    return this.metadata;
  }

  getBusinessUnitGid(value){
    let params: HttpParams = new HttpParams();
   return this.http$.get(tmsConfig.tms.getBusinessUnitGid+value, {params: params}).pipe(
    map((response: any) => {  
      this.Gid = response
      return response;
    }));
  }


  public fetchTruckTypes(res) {
    var permission =  JSON.parse(localStorage.getItem('tms_button_permission'))
    var tenantId = permission['userRestrictions'][0]?.restrictions.filter(item=>item.restrictionTypeName == 'Tenant ID')[0]?.restrictionValue || 0
    var permissionAddUrl = `&tenantID=${tenantId}`
    return this.http$.get(tmsConfig.tms.equipmentGroupQueryUri +`?page=0&size=0${permissionAddUrl}&businessUnitGid=` + res)
      .toPromise();
  }

  public searchPages(){
    const payload = this.getPayload();
    var userinfo = JSON.parse(localStorage.getItem('user_info'));
    var countryKey = userinfo['userRestrictions'][0]['countryCode'];
    var permission =  JSON.parse(localStorage.getItem('tms_button_permission'))
    var carrierId = permission['userRestrictions'][0]?.restrictions.filter(item=>item.restrictionTypeName == 'Carrier')[0]?.restrictionValue || null 
    if(carrierId){
      payload.carrier = carrierId     
    } 
    this.Gid  = JSON.parse(localStorage.getItem('businessUnitGid')) ; 
    var tenantId = permission['userRestrictions'][0]?.restrictions.filter(item=>item.restrictionTypeName == 'Tenant ID')[0]?.restrictionValue || 0
    var permissionAddUrl = `&tenantID=${tenantId}`
    var matchFlag = (window.location.pathname == '/home/reserve/shipment-match/search')
    if(!this.Gid){
      this.getBusinessUnitGid(countryKey).subscribe(data => {
        payload.businessUnitGid = data
        const result = this.http$.post((`${matchFlag?tmsConfig.tms.newshipmentQueryUri:tmsConfig.tms.shipmentQueryUri}?page=0&size=10${permissionAddUrl}`),payload)
        result.subscribe((res: any) => {
          this.crrData = res
        })
      })
    } else {
      payload.businessUnitGid = this.Gid;
      const result = this.http$.post((`${matchFlag?tmsConfig.tms.newshipmentQueryUri:tmsConfig.tms.shipmentQueryUri}?page=0&size=10${permissionAddUrl}`),payload)
      result.subscribe((res: any) => {
        this.crrData = res
      })
    }  

  }
  public loadTrucks() {
    var userinfo = JSON.parse(localStorage.getItem('user_info'));
    var countryKey = userinfo['userRestrictions'][0]['countryCode'];
    this.getBusinessUnitGid(countryKey).subscribe(resdata => {
      var payload = {
        businessUnitGid : resdata,
        ...this.getPayload()
      }
    this.http$.post(tmsConfig.tms.shipmentCountUri, payload).pipe(  map(res => res))
    .subscribe((res: any) => {
      const shipmentType = {};
      const column: any = {};
      this.forecastShipments = [];
      this.actualShipments = [];
      this.columnDef = [];
      res.content.forEach((row) => {
        if (shipmentType[row.shipmentType] != null) {
          shipmentType[row.shipmentType].push(row);
        } else {
          shipmentType[row.shipmentType] = [];
        }


        if (column[row.equipmentGroupGid] == null) {
          this.columnDef.push({
            'elementId': row.equipmentName || 'Unknown', 'propertyName': row.equipmentGroupGid
          });
        }
        column[row.equipmentGroupGid] = row.equipmentGroupGid;

      });

      const forecasts = res.content.filter(row => row.shipmentType === tmsConstant.HTMS_SHIPMENT_TYPE_F);
      const actuals = res.content.filter(row => row.shipmentType === tmsConstant.HTMS_SHIPMENT_TYPE_P);

      this.columnDef.forEach(col => {
        let fcsTotal = 0;
        forecasts.forEach(row => {
          if (col.propertyName === row.equipmentGroupGid) {
            fcsTotal = row.total;
          }
        });
        this.forecastShipments.push(fcsTotal);
        let actTotal = 0;
        actuals.forEach(row => {
          if (col.propertyName === row.equipmentGroupGid) {
            actTotal = row.total;
          }
        });
        this.actualShipments.push(actTotal);
      });
    });
    })
    
  }

  public mark() {
    const data = [];    
    // this.dataService.searchService.getEditedRows().forEach((row) => {
    //   Object.keys(row.cells).forEach((key) => {
    //     if ('monitorStatus' === key) {
    //       data.push({id: row.cells['id'].cVal, monitorStatus: row.cells[key].cVal});
    //     }
    //   });
    // });
    if (!data.length) {
      this.alertService.clearAll().error('No data changed!');
      return;
    }

    // const results = [];
    // for (let i = 0; i < data.length; i++) {
    //   const row = data[i];
    //   const payload = {
    //     'paramIn': {
    //       'monitorStatus': row.monitorStatus,
    //       'field': 'monitorStatus',
    //       'value': row.monitorStatus,
    //       'list': [{'id': row.id}]
    //     },
    //     'macroCode': 'LF_SHIPMENT_CAPACITY_ABNORMAL'
    //   };
    //   const result: any = await this.coreServiceRegistry.http$.post(tmsConfig.tms.baseUrl.concat(tmsConfig.tms.excuteMacroUri), payload)
    //     .toPromise();
    //   results.push(result);
    // }
    // const successList = results.filter((row: any) => row.success);
    // if (successList.length === data.length) {
    //   this.sharedServiceRegistry.alertService.clearAll().success('Success');
    // } else {
    //   this.sharedServiceRegistry.alertService.clearAll().error('Submit ' + data.length + ' rows, success ' + successList.length + ' rows !');
    // }
    // if (successList.length) {
    //   this.search();
    // }
  }

  public loadTruck() {
    var userinfo = JSON.parse(localStorage.getItem('user_info'));
    var countryKey = userinfo['userRestrictions'][0]['countryCode'];
    this.getBusinessUnitGid(countryKey).subscribe(resdata => {
      var payload = {
        businessUnitGid : resdata,
        ...this.getPayload()
      }
      this.http$.post(tmsConfig.tms.shipmentCountUri, payload).pipe(  map(res => res))
      .subscribe((res: any) => {
        const shipmentType = {};
        const column: any = {};
        this.forecastShipments = [];
        this.actualShipments = [];
        this.columnDef = [];
        res.content.forEach((row) => {
          if (shipmentType[row.shipmentType] != null) {
            shipmentType[row.shipmentType].push(row);
          } else {
            shipmentType[row.shipmentType] = [];
          }
  
  
          if (column[row.equipmentGroupGid] == null) {
            this.columnDef.push({
              'elementId': row.equipmentName || 'Unknown', 'propertyName': row.equipmentGroupGid
            });
          }
          column[row.equipmentGroupGid] = row.equipmentGroupGid;
  
        });
  
        const forecasts = res.content.filter(row => row.shipmentType === tmsConstant.HTMS_SHIPMENT_TYPE_F);
        const actuals = res.content.filter(row => row.shipmentType === tmsConstant.HTMS_SHIPMENT_TYPE_P);
  
        this.columnDef.forEach(col => {
          let fcsTotal = 0;
          forecasts.forEach(row => {
            if (col.propertyName === row.equipmentGroupGid) {
              fcsTotal = row.total;
            }
          });
          this.forecastShipments.push(fcsTotal);
          let actTotal = 0;
          actuals.forEach(row => {
            if (col.propertyName === row.equipmentGroupGid) {
              actTotal = row.total;
            }
          });
          this.actualShipments.push(actTotal);
        });
      });
    })
  }

  private setMetadata2() {
    this.metadata = {
      'statusCode': 200, 'statusMessage': 'SUCCESS', 'moduleId': 'shipmenttd',
      'elementId': 'SHIPMENT_TB', 'defaultOperator': 'AND',
      'columnList': [
        {
          'id': null, 'elementId': 'id', 'propertyName': 'id', 'displayType': 'H'
        },
        {
          'id': null, 'elementId': '_token', 'propertyName': '_token', 'displayType': 'H'
        },
        {
          'id': null, 'elementId': 'driverGid', 'propertyName': 'driverGid', 'displayType': 'H'
        },
        {
          'id': null, 'elementId': 'equipmentGid', 'propertyName': 'equipmentGid', 'displayType': 'H'
        },
        {
          'id': null, 'elementId': 'equipmentName', 'propertyName': 'equipmentName', 'displayType': 'H'
        },
        {
          'id': null, 'elementId': 'actualEquipmentGroupGid', 'propertyName': 'actualEquipmentGroupGid', 'displayType': 'H'
        },
        {
          'id': null, 'elementId': 'Carrier Id', 'propertyName': 'servprovGid', 'displayType': 'H'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_shipment_xid'), 'propertyName': 'xid',
          'type': 'TEXTFIELD', 'defaultVal': null, 'defaultOp': '=', 'isMandatory': true,
          'maxLength': 10, 'index': 1, 'priority': 'Low', 'toolTip': '', 'values': [],
          'columnGroup': 'Primary', 'displayType': 'V', 'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_match_id'), 'propertyName': 'attribute1',
          'type': 'TEXTFIELD', 'defaultVal': null, 'defaultOp': '=', 'isMandatory': true,
          'maxLength': 10, 'index': 1, 'priority': 'Low', 'toolTip': '', 'values': [],
          'columnGroup': 'Primary', 'displayType': 'V', 'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_shipment_type'), 'propertyName': 'shipmentType',
          'type': 'TEXTFIELD', 'defaultVal': null, 'defaultOp': '=', 'isMandatory': true,
          'maxLength': 10, 'index': 1, 'priority': 'Low', 'toolTip': '',
          'values': this.codeValueService.getCodeValues(tmsConstant.HTMS_SHIPMENT_TYPE),
          'columnGroup': 'Primary', 'displayType': 'V', 'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_shipment_status'), 'propertyName': 'shipmentPlanStatus', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 1,
          'values': this.codeValueService.getCodeValues(tmsConstant.HTMS_SHIPMENT_OP_STATUS),
          'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_carrier_code'), 'propertyName': 'servprovXid', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 1,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        { 
          'id': null, 'elementId': this.resourceService.get('lbl_tms_carrier_name'), 'propertyName': 'servprovName', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 1,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'v',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_marks'), 'propertyName': 'monitorStatus',
          'type': 'DROPDOWN', 'defaultVal': null, 'defaultOp': '=', 'isMandatory': true,
          'maxLength': 10, 'index': 1, 'priority': 'Low', 'toolTip': '',
          'values': this.codeValueService.getCodeValues(tmsConstant.HTMS_SHIPMENT_MARKS),
          'columnGroup': 'Primary', 'displayType': 'V', 'dataType': 'String'
        },
        // {
        //   'id': null, 'elementId': this.resourceService.get('lbl_tms_marks'), 'propertyName': 'monitorStatus',
        //   'type': 'DROPDOWN', 'defaultVal': null, 'defaultOp': '=', 'isMandatory': true,
        //   'maxLength': 10, 'index': 1, 'priority': 'Low', 'toolTip': '',
        //   'values': this.codeValueService.getCodeValues(tmsConstant.HTMS_SHIPMENT_MARKS),
        //   'columnGroup': 'Primary', 'displayType': 'E', 'dataType': 'String'
        // },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_truck_type'), 'propertyName': 'equipmentGroupName', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 1,
          'priority': 'LOW', 'toolTip': '', 'values': this.truckTypes, 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_truck_number'), 'propertyName': 'equipmentNumber', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 1,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_driver_name'), 'propertyName': 'driverName', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 1,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_planned_pickup_date'), 'propertyName': 'planStartShippingDate', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'index': 1,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'Date'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_planned_delivery_date'), 'propertyName': 'planDeliveryDate', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'index': 1,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'Date'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_source_location_name'), 'propertyName': 'sourceLocationName', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 1,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
        {
          'id': null, 'elementId': this.resourceService.get('lbl_tms_dest_location_name'), 'propertyName': 'destLocationName', 'type': 'TEXTFIELD',
          'defaultVal': null, 'defaultOp': '=', 'isMandatory': true, 'maxLength': 10, 'index': 1,
          'priority': 'LOW', 'toolTip': '', 'values': [], 'columnGroup': 'Primary', 'displayType': 'V',
          'dataType': 'String'
        },
      ]
    };
  }
}
