import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { JReportService } from '../jreport-service';
import { NavigationService } from '../navigation.service';
import { TokenService } from '../security/token.service';
import { UserService } from '../security/user.service';

@Component({
  selector: 'lfwms-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public logo_lf_logistics = 'assets/images/svg/logo-lf-logistics.svg';
  public logo_wm_full = 'assets/images/svg/logo-wm-full.svg';
  public logo_wm = 'assets/images/svg/logo-wm.svg';
  // public logo_footer_lifungcompany = 'assets/images/logo_footer_lifungcompany.png';
  public logo_footer_lifungcompany = 'assets/images/svg/logo-footer-lifungcompany.svg';
  public username: string;
  public password: string;
  public newPassword: string;
  public confirmNewPassword: string;
  public errorMsg: string;
  public selectedCountry: number;
  public cntryDropdownOpened: boolean;
  public selectedLanguage: number;
  public langDropdownOpened: boolean;
  public loginForm: boolean = true;
  public passwordChangeForm: boolean = false;
  public countryLanguageSelectionForm: boolean = false;
  public showError: boolean = false;
  public invalidLogin: boolean = false;
  public countries: any[];
  public languages: any[];
  public okClick: boolean = true;
  public messageLines: string[] = ['Your account has been expired, please contact your account provider for extension'];
  public isExpiryNot: boolean = false;

  public displayCountry: any = {};
  public displayLanguage: any = {};

  constructor(public userService: UserService,
    public tokenService: TokenService,
    public navService: NavigationService,
    public activatedRoute: ActivatedRoute,
    public jReportService: JReportService) {
  }

  public ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.navService.setPageTitle('SCE-LogIn');
    });

    // this.activatedRoute.queryParams.subscribe(param => {
    //   if (param.userName) {
    //     this.username = param.userName;
    //   }
    //   if (param.password) {
    //     this.password = param.password;
    //   }
    //   let accessToken = '';
    //   if (param.access_token) {
    //     accessToken = param.access_token;
    //   }      
    //   if (accessToken && accessToken !== this.tokenService.getToken()) {
    //     const tokenExpiry = (+new Date() + 24 * 3600 * 1000);
    //     this.tokenService.setToken(this.username, accessToken, tokenExpiry);
    //   } else {
    //     const tokenExpiry = (+new Date() + 24 * 3600 * 1000);
    //     this.tokenService.setToken(this.username, accessToken, tokenExpiry);
    //   }
    // });
    if (this.userService.isAuthenticatedUser() && this.userService.userInfo) {
      this.navService.navigateByMenuId('home');
    } else {
      // this.showError=true;
      // this.errorMsg="No Valid sessions.! Please Login.;"
    }
  }

  /*@author: abidhmuhsinp
  Login: method onclick of login button
  */
  public login() {
    if (this.username === undefined || this.password === undefined) {
      this.errorMsg = 'Invalid Credentials !';
      this.showError = true;
    } else {
      this.userService.login(this.username, this.password).subscribe(logInState => {
        if (!logInState) {
          this.okClick = true;
          this.isExpiryNot= this.userService.isExpired;
          this.invalidLogin = true;
          this.errorMsg = 'Invalid Credentials !';
          this.showError = true;
        } else {
          this.jReportService.loginJReport(this.password).subscribe(logInState2 => {
            console.log(logInState2);
          //LFWM-2093- setting timeout for jreport
           this.jReportService.setJreportTimeOut();
          }, (error1) => {
            console.log('jreportlogInStateerror', error1);
          });
          if (logInState.showChangePwd) {
            this.showError = false;
            this.loginForm = !this.loginForm;
            this.passwordChangeForm = !this.passwordChangeForm;
          } else if (logInState.showCountryLang) {
            this.showError = false;
            this.retrieveCountryLangList();
            // this.passwordChangeForm = this.passwordChangeForm;
            this.loginForm = !this.loginForm;
            this.countryLanguageSelectionForm = !this.countryLanguageSelectionForm;
          } else {
            this.navService.navigateByMenuId('home');
          }

        }
      }, (error) => {
        // LFWM-2851 -deactivated
        if(error.includes("deactivated")){
          this.errorMsg = error;
        //LFWM-2996 temperory fix will move to backend
        }else if(error.includes("many attempts")){  
          this.errorMsg = error;
        } else {
          this.errorMsg = 'Invalid Credentials !';
        }
        this.showError = true;
      });


    }
  }

  /*@author: abidhmuhsinp
  Change password:  onclick of changepwd form
  */
  public changepassword() {
    if (this.newPassword.trim() === '' || this.newPassword !== this.confirmNewPassword) {
      this.showError = true;
      this.errorMsg = 'Passwords do not match!';
      return;
    }
    this.userService.changePassword(this.confirmNewPassword).subscribe(response => {
      if (response.changePwdStatus) {
        this.showError = false;
        this.passwordChangeForm = !this.passwordChangeForm;
        if(this.userService.userInfo.defaultCountry === undefined || this.userService.userInfo.defaultCountry === null){
          this.retrieveCountryLangList();
          this.countryLanguageSelectionForm = !this.countryLanguageSelectionForm;
        } else {
          this.navService.navigateByUrl('/home');
        }
        return;
      } else { 
        this.showError = true;
        // this.errorMsg=response.errorMsg || 'Server Error..Please try again!';
        this.errorMsg = 'Server Error..Please try again!';
        return;
      }
    }, (error) => {
      this.errorMsg = error.replace(/\r\n/gi, "<br>");;
      this.showError = true;
    });
  }

  /*@author: abidhmuhsinp
  Update prefrences: called onclick of CntryLang preferences APPLY button
  */
  public saveCntryLang() {
    this.userService.updatePreferences(
      this.selectedCountry,
      this.selectedLanguage).subscribe((data: any) => this.navService.navigateByUrl('/home'));
  }

  /*@author: abidhmuhsinp
  function to fetch and set Country list and Language list in CntryLang prefreneces dropdown
  */
  public retrieveCountryLangList() {

    this.selectedCountry = this.userService.userInfo.defaultCountry;
    this.selectedLanguage = this.userService.userInfo.defaultLanguage;
    this.displayCountry = { name: 'Select Country' };
    this.displayLanguage = { name: 'Select Language' };

    this.userService.getEnabledCountries().subscribe(data => {
      this.countries = data;
      for (const country of data) {
        if (country.countryId === this.userService.userInfo.defaultCountry) {
          this.displayCountry = country;
          break;
        }
      }
    });
    this.userService.getAllLanguages().subscribe(data => {
      this.languages = data;
      for (const language of data) {
        if (language.languageId === this.userService.userInfo.defaultLanguage) {
          this.displayLanguage = language;
          break;
        }
      }
    });
  }
  public setSelectedCountry(country) {
    this.selectedCountry = country.countryId;
    this.displayCountry = country;
    this.cntryDropdownOpened = false;
  }

  public setSelectedLanguage(language) {
    this.selectedLanguage = language.languageId;
    this.displayLanguage = language;
    this.langDropdownOpened = false;
  }
  public cancel() {
    this.okClick = false
  }
}
