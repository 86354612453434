
import { GridViewKeyEventHandlerService } from '../../services/grid-view-key-event-handler.service';
import { ICellRendererAngularComp, ICellEditorAngularComp, IHeaderAngularComp } from '@ag-grid-community/angular';

export class DataTableConfig {

    // flag indicating if the data table is editable
    public editable: boolean = false;

    // whether the table should support filtering
    public supportFiltering: boolean = false;

    // show the id property as a hyperlink
    public showIdAsAHyperLink: boolean = false;

    // customize row selection column width
    public isSelectedCellWidth: number = 40;

    // additional Icon inside row selection column
    public additionalSelectionIcon: string = '';

    // default cell renderer
    public defaultCellRenderer: any; // ICellRendererAngularComp;

    // any custom cell renderers to be specified
    public columnRendererMapping: { [propName: string]: any } = {};

    // default cell editor
    public defaultCellEditor: any; // ICellEditorAngularComp;

    // any custom cell editors to be specified
    public columnEditorMapping: { [propName: string]: any } = {};

    // default header renderer
    public defaultHeaderComponent: any; // IHeaderAngularComp;

    // any custom header renderers to be specified
    public columnHeaderMapping: { [propName: string]: any } = {};

    // default pinned cell renderer
    public defaultPinnedCellRenderer: any; // ICellRendererAngularComp

    // any custom pinned cell renderers to be specified
    public columnPinnedRendererMapping: { [propName: string]: any } = {};

    // custom row Height for every rows
    public rowHeight: any = 30;

    // custom row Style for every rows
    public rowStyle: any;

    // custom function to render column as editable or not
    public isColumnEditable: any;

    // [jira-535] to handle key event
    public keyEventHandler: GridViewKeyEventHandlerService;

    // this array will contain all the hidden fields that needs to be enabled
    public hiddenPropertiesForTable: any = [];

    // flag to make first option of dropdownad default value
    public setFirstValuesAsDefault: boolean = true;

    // flag to enable type ahead in the table header
    public showTypeAheadInHeader: boolean = false;

    // flag to start row edit when table mode is switched
    public autoTriggerEdit: boolean = true;

    // flag to disable on click edit
    public suppressClickEdit: boolean = false;
// This is used to hide the index column of the table
    public indexColumnVisible: boolean = true;
    // Used to set whether a column width should be resizeable or not
    public enableColResize: boolean = true;

    // flag to enable  pagination
    public enablePagination: boolean = true;
    // flag to enable  filter
    public enableFilter: boolean = true;
    // flag to enable  ping
    public enablePing: boolean = true;

    public columnWidth: number ;
    public minColumnWidth: number;

    public gridHeight: number ;
    public tablePreferencDisable: boolean = false;
    public specialWidthColumn: Array<string> = [];
    public specialWidthDetails: any = {};
}
