import { Injectable } from '@angular/core';
// import { UserService } from 'sce-core';
import * as coordtransform from 'coordtransform';
import * as elementResizeDetectorMaker from '../../../../../../assets/js/element-resize-detector.min';
import { ResMapService } from 'src/app/features/control-tower/order-visibility/order-visibility-table/result-data-table/renderers/res-map.service';
// import { UserService } from 'sce_core_library/dist/sce-core/sce-core';
import { ResourceService } from 'sce-core';
import { JqueryService } from '../../../jquery.service';
import { SharedServiceRegistry } from 'src/app/shared/shared-service-registry';


declare var BMap: any;
declare var BMapLib: any;
declare var H: any;
declare var $: any;

// 交货状态以及页面
interface DeliveredState {
  polylineList: Array<any>,
  bubblesContent: Array<any>,
  tableId: string
}

@Injectable()
export class DefaultMapService {
  private map: any;
  public isBaidu: boolean = false;
  private countryCode: string;
  private platform: any;
  private searchService: any;
  ui: any;
  defaultLayers: any;
  mapId: any;
  mapbox: any;
  upDateStatus: boolean = true;
  alertService: any;
  constructor(
    public ResMapService: ResMapService,
    public resourceService: ResourceService,
    public sharedServiceRegistry: SharedServiceRegistry,
  ) {
    this.alertService = sharedServiceRegistry.alertService;
  }

  // constructor(protected JqueryService: JqueryService) {
  // }

  /**
   * 普通地图初始化方法
   * @param mapId map element id
   * @param mapType baidu/here
   * @param zoomendCallback 鼠标滚动回调函数
   */
  async initMap(mapId: any, mapType?: any, zoomendCallback?: Function) {
    // mapType = 'here';
    if (!mapType) {
      if (!mapType) {
        if (!this.countryCode) {
          var userinfo = JSON.parse(localStorage.getItem('user_info'))
          console.debug('userinfo', userinfo);
          var countryCode = userinfo['userRestrictions'][0]['countryCode'];
          this.countryCode = countryCode

        }
      }
      mapType = 'here';
      // if (this.countryCode === 'CHN') {
      //   mapType = 'baidu';
      // } else {
      // }
    }

    if (!['baidu', 'here'].includes(mapType)) {
      throw Error('Unknown map type!');
    }
    if ('baidu' === mapType) {
      this.initMapForBaidu(mapId);
      this.isBaidu = true;
    } else if ('here' === mapType) {
      this.initMapForHere(mapId);
    }
  }

  /**
   * 浮标地图初始化方法
   * @param mapId map element id
   * @param mapType baidu/here
   * @param zoomendCallback 鼠标滚动回调函数
   */
  async initMapMarker(mapId: any, mapType?: string, zoomendCallback?: Function) {
    mapType = 'here';
    // if (!mapType) {
    //   if (!this.countryCode) {

    //   }
    //   if (this.countryCode === 'CHN') {
    //     mapType = 'baidu';
    //   } else {
    //     mapType = 'here';
    //   }
    // }
    if (!['baidu', 'here'].includes(mapType)) {
      throw Error('Unknown map type!');
    }
    if ('baidu' === mapType) {
      this.initMapForBaidu(mapId, true, zoomendCallback);
      this.isBaidu = true;
    } else if ('here' === mapType) {
      this.initMapForHere(mapId, true, zoomendCallback);
    }
  }

  private initMapForBaidu(mapId, isMarker?: boolean, zoomendCallback?: Function) {
    this.map = new BMap.Map(mapId);
    this.map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
    if (zoomendCallback) {
      this.map.addEventListener('zoomend', (e) => {
        zoomendCallback(this.map, e);
      });
    }
  }

  private initMapForHere(mapId, isMarker?: boolean, zoomendCallback?: Function) {
    // Step 1: initialize communication with the platform
    // In your own code, replace variable window.apikey with your own apikey
    // let app_id = '6oIzBTM9e52Q3JjCx9F7';
    this.mapId = mapId
    this.mapbox = document.getElementById(mapId)
    console.log('123123', document.getElementById(mapId), mapId);

    let app_id = 'hJUucGrj24JGSks3eOOa';
    let app_code = 'IRLOlY26vxDjdEecraV0Ug';
    const engineType = H.Map.EngineType['HARP'];
    if (this.countryCode !== 'CHN') {
      app_id = '0llBksHdu9QwrVCsv6fZ';
      app_code = '1XVD3gSvC5l9IWaPrfraSg';
    }

    this.platform = new H.service.Platform({
      'app_id': app_id,
      'app_code': app_code,
      'apiKey': 'BgEr8-hf2JLj3lJQ78fVj_Aft6cLHAB0wC2Q2UX_Ld8'
    });
    this.defaultLayers = this.platform.createDefaultLayers({engineType});

    let center = {
      lng: 121.01216330000612,
      lat: 14.626782928240136
    },
      zoom = 10
    switch (this.countryCode) {
      case 'SGP':
        // 处理新加坡的情况
        center = {
          lng: 103.84698176424699,
          lat: 1.2879088130720053
        }
        zoom = 10
        break;
      case 'PHL':
        // 处理菲律宾的情况
        center = {
          lng: 122.84205032203178,
          lat: 12.487274133684409
        }
        zoom = 6
        break;
      case 'IND':
        // 处理印度的情况
        center = {
          lng: 79.49377720566672,
          lat: 23.141502762227965
        }
        zoom = 5
        break;
      // case 'CHN':
      //   // 处理中国的情况 , 
      //   center = {
      //     lng: 120.93729592652939,
      //     lat: 23.493738840854004
      //   }
      //   zoom = 10
      //   break;
      case 'TWN':
        // 处理台湾的情况
        center = {
          lng: 120.93729592652939,
          lat: 23.493738840854004
        }
        zoom = 7
        break;
      case 'HKG':
        // 处理香港的情况
        center = {
          lng: 114.17783847588858,
          lat: 22.32723650634764
        }
        zoom = 10
        break;
      case 'KOR':
        // 处理韩国的情况
        center = {
          lng: 127.73329961244468,
          lat: 36.63890914723077
        }
        zoom = 6
        break;
      case 'THA':
        // 处理泰国的情况
        center = {
          lng: 101.18335451058692,
          lat: 15.413404338709604
        }
        zoom = 6
        break;
      case 'JPN':
        // 处理日本的情况
        center = {
          lng: 137.9763470738093,
          lat: 36.37019238699781
        }
        zoom = 6
        break;
      case 'IDN':
        // 处理印度尼西亚的情况
        center = {
          lng: 122.47667266005902,
          lat: -4.419335237329017
        }
        zoom = 6
        break;
      case 'MYS':
        // 处理马来西亚的情况
        center = {
          lng: 101.90522560988889,
          lat: 3.794216742068887
        }
        zoom = 6
        break;
      case 'VNM':
        // 处理越南的情况
        center = {
          lng: 108.07605500676014,
          lat: 14.584378482916483
        }
        zoom = 6
        break;
      case 'AUS':
        // 处理澳大利亚的情况 , 
        center = {
          lng: 134.81384501813528,
          lat: -24.57124606793033
        }
        zoom = 6
        break;
      case 'USA':
        // 处理美国的情况, 
        center = {
          lng: -101.67112744375159,
          lat: 39.564220327930535
        }
        zoom = 4
        break;
      default:
        // 处理其他情况
        return
    }
    console.log(this.defaultLayers, '========>')
    // Step 2: initialize a map - this map is centered over Europe
    try {
      this.map = new H.Map(
        document.getElementById(mapId),
        (this.defaultLayers.vector || this.defaultLayers).normal.map,
        {
          engineType,
          center,
          zoom,
          pixelRatio: window.devicePixelRatio || 1
        });

      // add a resize listener to make sure that the map occupies the whole container
      // 监听地图盒子大小变化
      this.boxSize(mapId)
    } catch (e) {
      console.error(e);
    }
    const router = this.platform.getRoutingService(null, 8);


    //  Step 3: make the map interactive
    // MapEvents enables the event system
    // Behavior implements default interactions for pan/zoom (also on mobile touch environments)
    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));

    // Create the default UI components
    // const ui = H.ui.UI.createDefault(this.map, defaultLayers);

    // add zoom changed envent
    if (zoomendCallback) {
      this.map.addEventListener('mapviewchangeend', (e) => {
        zoomendCallback(this.map);
      });
    }
    // this.addInfoBubble(this.map)

  }
  // 监听地图盒子大小变化
  boxSize(mapId) {
    const erd = elementResizeDetectorMaker();
    erd.listenTo(document.getElementById(mapId), element => {
      const width = element.offsetWidth;
      console.debug(width);

      this.map.getViewPort().resize()
    });
    window.addEventListener('resize', () => this.map.getViewPort().resize());
  }
  private refreshBaiduMap(points: Array<Array<number>>) {
    const newPoints: any[] = [];
    points.forEach((point: Array<number>) => {
      newPoints.push(new BMap.Point(point[0], point[1]));
    });

    this.map.centerAndZoom(newPoints[0], 10); // 初始化地图，设置中心点坐标和地图级别
    this.map.clearOverlays(); // 清除地图上所有覆盖物

    const map = this.map;
    const content = '';
    let lushu;
    // 实例化一个驾车导航用来生成路线
    const drv = new BMap.DrivingRoute('---', {
      onSearchComplete: function (res) {
        if (drv.getStatus() === 0) {
          const plan = res.getPlan(0);
          let arrPois = [];
          for (let j = 0; j < plan.getNumRoutes(); j++) {
            const route = plan.getRoute(j);
            arrPois = arrPois.concat(route.getPath());
          }
          map.addOverlay(new BMap.Polyline(arrPois, { strokeColor: 'blue' }));
          map.setViewport(arrPois);

          lushu = new BMapLib.LuShu(map, arrPois, {
            defaultContent: content, // "从天安门到百度大厦"
            autoView: true, // 是否开启自动视野调整，如果开启那么路书在运动过程中会根据视野自动调整
            icon: new BMap.Icon('/jsdemo/img/car.png', new BMap.Size(52, 26), { anchor: new BMap.Size(27, 13) }),
            speed: 4500,
            enableRotation: true, // 是否设置marker随着道路的走向进行旋转
            landmarkPois: []
          });
        }
      }
    });
    drv.search(newPoints[0], newPoints[newPoints.length - 1]);

    // 创建点标记
    const markers = [];
    points.forEach(point => {
      markers.push(new BMap.Marker(new BMap.Point(point[0], point[1])));
    });
    // 在地图上添加点标记
    markers.forEach(marker => {
      this.map.addOverlay(marker);
    });
  }

  private refreshHereMap(points: Array<Array<number>>, DeliveredState?: DeliveredState, gps?: Boolean, mobile?: Boolean, routeLineList?: any) {
    if (!this.map) {
      return;
    }
    this.ResMapService.dropDateStatue.subscribe(res => {
      this.upDateStatus = res
      console.debug(this.upDateStatus);

    })
    // this.map.setCenter({ lat: points[0][0], lng: points[1][1] })
    // points.forEach((ele, index) => {
    //   this.addSVGMarkers(ele, index)
    // });
    var group = new H.map.Group();
    this.map.addObject(group);
    let coordinates = [];
    DeliveredState.bubblesContent.forEach((ele, index) => {
      console.log(ele);
      if (ele.lat && ele.lng) {
        coordinates.push({
          lat: ele.lat, lng: ele.lng
        })

      }
      var marker
      if (ele?.coordinateOffset) {
        marker = this.offsetSVGMarkers(ele.coordinateOffset, index)
      } else if (ele?.heartbeat) {
        console.debug(ele?.heartbeat);
        marker = this.heartBeatSVGMarkers(ele?.heartbeat, index)
      } else {
        marker = this.addSVGMarkers(ele, index, DeliveredState.tableId)
      }
      if (marker) {
        group.addObject(marker);

      }
    })
    console.log(DeliveredState.polylineList, 'DeliveredState.polylineList=====>')
    DeliveredState.polylineList.forEach(ele => {
      const linestring = H.geo.LineString.fromFlexiblePolyline(ele);
      const routeLine = new H.map.Polyline(linestring, {
        style: { strokeColor: 'blue', lineWidth: 10 }
      })
      this.map.addObject(routeLine);
    });
    if(gps && !mobile) {
      routeLineList.forEach(ele => {
        const linestring = H.geo.LineString.fromFlexiblePolyline(ele);
        const routeLine = new H.map.Polyline(linestring, {
          style: { strokeColor: '#0a2070', lineWidth: 6 }
        })
        this.map.addObject(routeLine);
      });
    }
    if(mobile && !gps) {
      routeLineList.forEach(ele => {
        const linestring = H.geo.LineString.fromFlexiblePolyline(ele);
        const routeLine = new H.map.Polyline(linestring, {
          style: { strokeColor: '#e79315', lineWidth: 2 }
        })
        this.map.addObject(routeLine);
      });
    }
    if(mobile && gps) {
      routeLineList.forEach(ele => {
        if(ele.type === 'GPS') {
          ele.routeList.forEach(item => {
            const linestring = H.geo.LineString.fromFlexiblePolyline(item);
            const routeLine = new H.map.Polyline(linestring, {
              style: { strokeColor: '#0a2070', lineWidth: 3 }
            })
            this.map.addObject(routeLine);
          })
        } else {
          ele.routeList.forEach(item => {
            const linestring = H.geo.LineString.fromFlexiblePolyline(item);
            const routeLine = new H.map.Polyline(linestring, {
              style: { strokeColor: '#e79315', lineWidth: 5 }
            })
            this.map.addObject(routeLine);
          })
        }
        
      });
    }
    this.map.addObject(group);

    var bounds = group.getBoundingBox();
    var center = bounds.getCenter();
    this.map.setCenter(center);
    // this.map.setViewBounds(bounds);
    let distance;
    distance = this.calculateMaxDistance(coordinates)
    console.log(distance);
    console.log(this.calculateZoomLevel(distance));
    this.map.setZoom(this.calculateZoomLevel(distance));
    //   this.map.getViewModel().setLookAtData({
    //     bounds: group.getBoundingBox()
    // });
  }
  // 绘制svg
  addSVGMarkers(points, index, tableId) {
    console.log(index, 'indexindexindex')
    //Create the svg mark-up
    var bearsIcon;
    var that = this
    var group = new H.map.Group();
    let UI = H.ui.UI.createDefault(this.map, this.defaultLayers);
    var bearsMarker = bearsMarker + index;
    var svgMarkup = '<svg  width="24" height="24" xmlns="http://www.w3.org/2000/svg">' +
      '<rect stroke="black" fill="${FILL}" x="1" y="1" width="22" height="22" />' +
      '<text x="12" y="18" font-size="12pt" font-family="Arial" font-weight="bold" ' +
      'text-anchor="middle" fill="${STROKE}" >${num}</text></svg>';
    if (index == 0) {

      bearsIcon = new H.map.Icon(svgMarkup.replace('${FILL}', 'white').replace('${STROKE}', 'orange').replace('${num}', points.stopNo))
      bearsMarker = new H.map.Marker({ lat: points.lat, lng: points.lng },
        { icon: bearsIcon });
      bearsMarker.setData()

      // add 'tap' event listener, that opens info bubble, to the group
      bearsMarker.addEventListener('tap', function (evt) {
        // event target is the marker itself, group is a parent event target
        // for all objects that it contains
        var x = evt.currentPointer.viewportX;
        var y = evt.currentPointer.viewportY;

        var bubble = new H.ui.InfoBubble(that.map.screenToGeo(x, y - 12), {
          // read custom data
          content: evt.target.getData()
        });
        // show info bubble
        UI.addBubble(bubble);
      }, false);
      let html = `<div style="width: 100% ;font-size:100%;line-height: 100%">
      <span><b>${this.getLabel('lbl_tms_location_code')}:</b><span id="newsLocationid">&nbsp;${points.LocationID}</span></span><br/>
      <span><b>${this.getLabel('lbl_tms_location_name')}:</b><span id="newsLocationName">&nbsp;${points.LocationName}&nbsp;</span></span><br/>
      <span><b>${this.getLabel('lbl_tms_latitude')}:</b><span id="newsLatitude">&nbsp;${points.lat}</span></span><br/>
      <span><b>${this.getLabel('lbl_tms_longitude')}:</b><span id="newsLongitude">&nbsp;${points.lng}</span> </span><br/>
      <span id="newsId" ><b>${this.getLabel('lbl_tms_weight')}:</b><span>&nbsp;${points.Weight}&nbsp;</span></span><br/>
      <span id="newsSeq" ><b>${this.getLabel('lbl_tms_volume')}:</b><span>&nbsp;${points.volume}&nbsp;</span></span><br/>
        </div>`;
      bearsMarker.setData(html);
      return bearsMarker
      // group.addObject(bearsMarker);


      // this.groups.push({ lat: points[0], lng: points[1] });
      // this.map.addObject(bearsMarker);
      // this.addInfoBubble(bearsMarker, index)
    } else {
      bearsIcon = new H.map.Icon(svgMarkup.replace('${FILL}', 'green').replace('${STROKE}', '#FFF').replace('${num}', points.stopNo))
      bearsMarker = new H.map.Marker({ lat: points.lat, lng: points.lng }, { icon: bearsIcon });

      bearsMarker.setData()

      let ui = H.ui.UI.createDefault(this.map, this.defaultLayers);
      // add 'tap' event listener, that opens info bubble, to the group
      bearsMarker.addEventListener('tap', function (evt) {
        // event target is the marker itself, group is a parent event target
        // for all objects that it contains
        if (['shpmtsearchEX'].includes(tableId)) {
          that.test(index, points)
        }
        var x = evt.currentPointer.viewportX;
        var y = evt.currentPointer.viewportY;

        var bubble = new H.ui.InfoBubble(that.map.screenToGeo(x, y - 12), {
          // read custom data
          content: evt.target.getData()
        });
        // show info bubble
        UI.addBubble(bubble);
      }, false);
      let html
      if (['shpmtsearchEX'].includes(tableId)) {
        html = `<div style="width: 100% ;font-size:100%;line-height: 100%">
        <span><b>${this.getLabel('lbl_tms_location_code')}:</b><span id="newsLocationid">&nbsp;${points.LocationID}</span></span><br/>
        <span><b>${this.getLabel('lbl_tms_location_name')}:</b><span id="newsLocationName">&nbsp;${points.locationName}&nbsp;</span></span><br/>
        <span><b>${this.getLabel('lbl_tms_latitude')}:</b><span id="newsLatitude">&nbsp;${points.lat}</span></span><br/>
        <span><b>${this.getLabel('lbl_tms_longitude')}:</b><span id="newsLongitude">&nbsp;${points.lng}</span> </span><br/>
        <span id="newsId" ><b>${this.getLabel('lbl_tms_weight')}:</b><span>&nbsp;${points.weight}&nbsp;</span></span><br/>
        <span id="newsSeq" ><b>${this.getLabel('lbl_tms_volume')}:</b><span>&nbsp;${points.volume}&nbsp;</span></span><br/>
          <button  value='${index}' style='float:right; border-radius:4px; background:#42b0d5; border:none;color:#fff;' id='${index}'>${this.getLabel('btn_update')}</button>
          </div>`;
      } else {
        html = `<div style="width: 100% ;font-size:100%;line-height: 100%">
          <span><b>${this.getLabel('lbl_tms_location_code')}:</b><span id="newsLocationid">&nbsp;${points.LocationID}</span></span><br/>
          <span><b>${this.getLabel('lbl_tms_location_name')}:</b><span id="newsLocationName">&nbsp;${points.locationName}&nbsp;</span></span><br/>
          <span><b>${this.getLabel('lbl_tms_latitude')}:</b><span id="newsLatitude">&nbsp;${points.lat}</span></span><br/>
          <span><b>${this.getLabel('lbl_tms_longitude')}:</b><span id="newsLongitude">&nbsp;${points.lng}</span> </span><br/>
          <span id="newsId" ><b>${this.getLabel('lbl_tms_weight')}:</b><span>&nbsp;${points.weight}&nbsp;</span></span><br/>
          <span id="newsSeq" ><b>${this.getLabel('lbl_tms_volume')}:</b><span>&nbsp;${points.volume}&nbsp;</span></span><br/>
          </div>`;
      }
      bearsMarker.setData(html);
      return bearsMarker
      // group.addObject(bearsMarker);

      // this.groups.push({ lat: points[0], lng: points[1] });
      // this.map.addObject(bearsMarker);

      // this.addInfoBubble(bearsMarker, index)
    }
  }
  // 绘制偏移点svg
  offsetSVGMarkers(points, index) {
    var bearsIcon;
    var that = this
    var groups = new H.map.Group();
    let UI = H.ui.UI.createDefault(this.map, this.defaultLayers);
    var bearsMarker = bearsMarker + index;
    var svgMarkup = '<svg  width="24" height="24" xmlns="http://www.w3.org/2000/svg">' +
      '<rect stroke="black" fill="${FILL}" x="1" y="1" width="22" height="22" />' +
      '<text x="12" y="18" font-size="8pt" font-family="Arial" font-weight="bold" ' +
      'text-anchor="middle" fill="${STROKE}" >${num}</text></svg>';
    if (points.distance) {
      bearsIcon = new H.map.Icon(svgMarkup.replace('${FILL}', 'red').replace('${STROKE}', 'white').replace('${num}', points.stopNo + '-' + points.stopType))
      bearsMarker = new H.map.Marker({ lat: points.OffsetLat, lng: points.OffsetLon },
        { icon: bearsIcon });
      bearsMarker.setData()

      // add 'tap' event listener, that opens info bubble, to the group
      bearsMarker.addEventListener('tap', function (evt) {
        // event target is the marker itself, group is a parent event target
        // for all objects that it contains
        var x = evt.currentPointer.viewportX;
        var y = evt.currentPointer.viewportY;

        var bubble = new H.ui.InfoBubble(that.map.screenToGeo(x, y - 12), {
          // read custom data
          content: evt.target.getData()
        });
        // show info bubble
        UI.addBubble(bubble);
      }, false);
      let html = `<div style="width: 100% ;font-size:100%;line-height: 100%">
      <span><b>${this.getLabel('lbl_tms_distance_variance_km')}</b><span id="newsLocationid">&nbsp; ${points.distance.toFixed(2)}&nbsp;Km</span></span><br/>
        </div>`;
      bearsMarker.setData(html);
      return bearsMarker
      // groups.addObject(bearsMarker);


      // this.groups.push({ lat: points[0], lng: points[1] });
      // this.map.addObject(bearsMarker);
      // this.addInfoBubble(bearsMarker, index)
    }
  }
  heartBeatSVGMarkers(points, index) {
    var bearsIcon;
    var that = this
    var groups = new H.map.Group();
    let UI = H.ui.UI.createDefault(this.map, this.defaultLayers);
    var bearsMarker = bearsMarker + index;
    var zoomThreshold = 12;
    var svgMarkup = '<svg  width="10" height="10" xmlns="http://www.w3.org/2000/svg">' +
      '<circle cx="4" cy="4" r="3" fill="${FILL}" stroke-width="2"/></svg>';
    if (index) {
      console.log('这里设置颜色')
      bearsIcon = new H.map.Icon(svgMarkup.replace('${FILL}', 'red'))
      bearsMarker = new H.map.Marker({ lat: points.lat, lng: points.lng },
        { icon: bearsIcon });
      bearsMarker.setData()
      var bubbleContent = `lat: ${points.lat} lng:${points.lng}<br>transmitDate:${this.UTCTime(points.transmitDate)}`;  
      // add 'tap' event listener, that opens info bubble, to the group
      bearsMarker.addEventListener('tap', function (evt) {
        // event target is the marker itself, group is a parent event target
        // for all objects that it contains
        console.log(that.map.getZoom(), 'that.map.getZoom()that.map.getZoom()that.map.getZoom()')
        if (that.map.getZoom() >= zoomThreshold) { 
          var x = evt.currentPointer.viewportX;
          var y = evt.currentPointer.viewportY;
  
          var bubble = new H.ui.InfoBubble(that.map.screenToGeo(x, y - 12), {
            // read custom data
            content: bubbleContent
          });
          // show info bubble
          UI.addBubble(bubble);
          }
        
      }, false);
      return bearsMarker
      groups.addObject(bearsMarker);


      // this.groups.push({ lat: points[0], lng: points[1] });
      this.map.addObject(bearsMarker);
      // this.addInfoBubble(bearsMarker, index)
    }
  }
  UTCTime(date){
    if (date) {
      var localDate = new Date(date); // 请根据需要替换为你的本地时间
      var utcDate = localDate.toLocaleString("en-US", {timeZone: "UTC", hour12: false})
      // let dates =  new Date(utcDate)
      // let formattedTime = dates.toISOString().replace("T", " ").replace("Z", "");
      console.log("UTC date: " + utcDate);
      // console.log("UTC date: " + formattedTime);
      let dateParts = utcDate.split(', ')[0].split('/');
    let timePart = utcDate.split(', ')[1];
    if (timePart.startsWith("24")) {
      timePart = "00" + timePart.slice(2);
    }
    let year = dateParts[2];
    let month = dateParts[0].padStart(2, '0');
    let day = dateParts[1].padStart(2, '0');
    
    let formattedDate = `${year}-${month}-${day} ${timePart}.000`;
    console.log(formattedDate);
    return formattedDate
    }else{
    return
    }
  }
  // 更新地图upDate
  test = (index, points) => {
    let upDate;
    setTimeout(() => {
      upDate = document.getElementById(index)
      if (upDate) {
        upDate.onclick = () => {
          if (!this.upDateStatus) {
            console.debug(this.upDateStatus);
            this.alertService.clearAll().error(this.getLabel('lbl_tms_msg_save_before_update_loc'))
            return
          }
          this.ResMapService.mapInfUpDate.next(points)
        }
      }
    }, 200);
  }
  getLabel(key) {
    return this.resourceService.get(key);
  }
  groups = []
  // 原生Ajax
  Ajax = {
    get: function (url, callback) {
      // XMLHttpRequest对象用于在后台与服务器交换数据
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, false);
      xhr.onreadystatechange = function () {
        // readyState == 4说明请求已完成
        if (xhr.readyState == 4) {
          if (xhr.status == 200 || xhr.status == 304) {
            callback(xhr.responseText);
          }
        }
      }
      xhr.send();
    },

    // data应为'a=a1&b=b1'这种字符串格式，在jq里如果data为对象会自动将对象转成这种字符串格式
    post: function (url, data, callback) {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', url, false);
      // 添加http头，发送信息至服务器时内容编码类型
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200 || xhr.status == 304) {
            callback(xhr.responseText);
          }
        }
      }
      xhr.send(data);
    }
  }
  // 计算天小时分钟
  getDuration: (second: number) => string = (second) => {
    var days = Math.floor(second / 86400);
    var hours = Math.floor((second % 86400) / 3600);
    var minutes = Math.floor(((second % 86400) % 3600) / 60);
    // var seconds = Math.floor(((second % 86400) % 3600) % 60);
    // var duration = days + "day" + hours + "hours" + minutes + "minutes" + seconds + "秒";
    var duration;
    if (days == 0) {
      duration = hours + "&nbsp;H&nbsp;" + minutes + "&nbsp;Min";
      if (hours == 0) {
        duration = minutes + "&nbsp;Min";
      }
    } else {
      duration = days + "&nbsp;Day&nbsp;" + hours + "&nbsp;H&nbsp;" + minutes + "&nbsp;Min";
    }
    return duration;
  }
  // 自定义函数：计算平均距离（使用 Haversine 公式）
  calculateMaxDistance(coords) {
    var maxDistance = 0;

    for (var i = 0; i < coords.length - 1; i++) {
      for (var j = i + 1; j < coords.length; j++) {
        var distance = this.calculateDistance(coords[i], coords[j]);
        if (distance > maxDistance) {
          maxDistance = distance;
        }
      }
    }

    return maxDistance; // 单位：米
  }
  calculateDistance(coord1, coord2) {
    var earthRadius = 6371000; // 地球半径（米）

    // 将纬度和经度从度数转换为弧度
    var lat1Rad = this.degToRad(coord1.lat);
    var lng1Rad = this.degToRad(coord1.lng);
    var lat2Rad = this.degToRad(coord2.lat);
    var lng2Rad = this.degToRad(coord2.lng);

    // 使用 Haversine 公式计算两个坐标点之间的距离
    var deltaLat = lat2Rad - lat1Rad;
    var deltaLng = lng2Rad - lng1Rad;
    var a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
      Math.cos(lat1Rad) * Math.cos(lat2Rad) *
      Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var distance = earthRadius * c;

    return distance; // 单位：米
  }

  // 将度数转换为弧度
  degToRad(deg) {
    return deg * (Math.PI / 180);
  }

  calculateZoomLevel(distance) {
    if (distance > 2000000) {
      return 5;
    } else if (distance < 700000 && distance > 500000) {
      return 7;
    } else if (distance < 500000 && distance > 200000) {
      return 7;
    } else if (distance < 200000 && distance > 100000) {
      return 8;
    } else if (distance < 100000 && distance > 50000) {
      return 9;
    } else if (distance < 50000 && distance > 20000) {
      return 10;
    } else if (distance < 20000 && distance > 10000) {
      return 12;
    } else if (distance < 10000 && distance > 5000) {
      return 13;
    } else if (distance < 5000) {
      return 14;
    } else {
      return 10
    }
  }
  //计算经纬度距离
  Rad(d) {
    return d * Math.PI / 180.0;//经纬度转换成三角函数中度分表形式。
  }
  //lat 纬度
  //lng 经度
  GetDistance(lat1, lng1, lat2, lng2) {
    var radLat1 = this.Rad(lat1);
    var radLat2 = this.Rad(lat2);
    var a = radLat1 - radLat2;
    var b = this.Rad(lng1) - this.Rad(lng2);
    var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
      Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
    s = s * 6378.137;// 地球半径;
    s = Math.round(s * 10000) / 10; //输出为米
    return s;
  }

  //计算中心点
  rad2degr(rad) { return rad * 180 / Math.PI; }
  degr2rad(degr) { return degr * Math.PI / 180; }

  /**
   * @param latLngInDeg array of arrays with latitude and longtitude
   *   pairs in degrees. e.g. [[latitude1, longtitude1], [latitude2
   *   [longtitude2] ...]
   *
   * @return array with the center latitude longtitude pairs in 
   *   degrees.
   */
  getLatLngCenter(latLngInDegr) {
    var LATIDX = 0;
    var LNGIDX = 1;
    var sumX = 0;
    var sumY = 0;
    var sumZ = 0;

    for (var i = 0; i < latLngInDegr.length; i++) {
      var lat = this.degr2rad(latLngInDegr[i][LATIDX]);
      var lng = this.degr2rad(latLngInDegr[i][LNGIDX]);
      // sum of cartesian coordinates        sumX += Math.cos(lat) * Math.cos(lng);
      sumY += Math.cos(lat) * Math.sin(lng);
      sumZ += Math.sin(lat);
    }

    var avgX = sumX / latLngInDegr.length;
    var avgY = sumY / latLngInDegr.length;
    var avgZ = sumZ / latLngInDegr.length;

    // convert average x, y, z coordinate to latitude and longtitude    var lng = Math.atan2(avgY, avgX);
    var hyp = Math.sqrt(avgX * avgX + avgY * avgY);
    var lat = Math.atan2(avgZ, hyp);

    return ({ lat: this.rad2degr(lat), lng: this.rad2degr(lng) });
  }
  /**
   * 刷新地图
   * @param points 坐标：[[lng, lat], [lng, lat], ...]
   */
  refreshMap(points: Array<Array<number>>, DeliveredState?: DeliveredState, gps?: boolean, mobile?: boolean, routeLineList?:any) {
    if (this.isBaidu) {
      this.refreshBaiduMap(points);
    } else {
      this.refreshHereMap(points, DeliveredState, gps, mobile, routeLineList);
    }
  }

  /**
   * 刷新浮标地图
   * @param points 坐标：[[lng, lat], [lng, lat], ...]
   * @param notTransformPoint 是否不需要转换坐标：BD09<-->WGS84
   */
  refreshMapMarker(points: number[][], notTransformPoint?: boolean) {
    if (this.isBaidu) {
      this.refreshBaiduMapMarker(points);
    } else {
      let points2 = null;
      if (notTransformPoint) {
        points2 = points;
      } else {
        points2 = this.bd09_to_wgs84(points);
      }
      this.refreshHereMapMarker(points2);
    }
  }

  private refreshBaiduMapMarker(points: number[][]) {
    this.map.clearOverlays(); // 清除地图上所有覆盖物
    this.map.centerAndZoom(new BMap.Point(points[0][0], points[0][1]), 10);

    // 创建点标记
    const markers = [];
    points.forEach(point => {
      markers.push(new BMap.Marker(new BMap.Point(point[0], point[1])));
    });
    // 在地图上添加点标记
    markers.forEach(marker => {
      this.map.addOverlay(marker);
    });
  }

  private refreshHereMapMarker(points: number[][]) {
    this.map.setCenter({
      lng: points[0][0],
      lat: points[0][1]
    });

    const objs = this.map.getObjects();
    this.map.removeObjects(objs);

    // Add a marker for each location found
    for (let i = 0; i < points.length; i++) {
      const position = {
        lng: points[i][0],
        lat: points[i][1]
      };
      const marker = new H.map.Marker(position);
      this.map.addObject(marker);
    }
  }


  public clearOverlays() {
    if (this.isBaidu) {
      this.map.clearOverlays(); // 清除地图上所有覆盖物
    } else {
      const objs = this.map.getObjects();
      this.map.removeObjects(objs);
    }
  }


  // [{
  //   point: [lon, lot],
  //   text: 'text',
  //   style: {},
  // }]
  refreshMapCircleLable(labels: any[], clearOverlays?: boolean) {
    try {
      if (this.isBaidu) {
        this.refreshBaiduMapCircleLable(labels, clearOverlays);
      } else {
        this.refreshHereMapCircleLable(labels, clearOverlays);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private refreshBaiduMapCircleLable(labels: any[], clearOverlays?: boolean) {
    if (clearOverlays) {
      this.map.clearOverlays(); // 清除地图上所有覆盖物
    }
    this.map.centerAndZoom(new BMap.Point(labels[0].point[0], labels[0].point[1]), labels[0].zoom || 5);
    labels.forEach(lbl => {
      const opts = {
        position: new BMap.Point(lbl.point[0], lbl.point[1]),    // 指定文本标注所在的地理位置
        // offset: new BMap.Size(30, -30),    //设置文本偏移量
      }

      var label = new BMap.Label(lbl.text, opts);  // 创建文本标注对象
      label.setStyle(lbl.style);

      label.addEventListener('mouseover', (evt) => { evt.domEvent.target.style.opacity = 0.6; });
      label.addEventListener('mouseout', (evt) => { evt.domEvent.target.style.opacity = 1; })

      this.map.addOverlay(label);
    });
  }

  private refreshHereMapCircleLable(labels: any[], clearOverlays?: boolean) {
    const objs = this.map.getObjects();
    this.map.removeObjects(objs);

    this.map.setCenter({
      lng: labels[0].point[0],
      lat: labels[0].point[1]
    });
    // Add a marker for each location found
    for (let i = 0; i < labels.length; i++) {
      const point: number[] = labels[i].point;
      const position = {
        lng: point[0],
        lat: point[1]
      };
      const marker = this.createDomMarker(position, labels[i]);
      this.map.addObject(marker);
    }
  }

  public createDomMarker(position, option: any): any {
    const outerElement = document.createElement('div');
    const innerElement = document.createElement('label');

    outerElement.style.userSelect = 'none';
    outerElement.style.cursor = 'default';

    innerElement.style.color = 'red';

    innerElement.style.paddingTop = '2px';
    innerElement.style.paddingLeft = '4px';



    // add negative margin to inner element
    // to move the anchor to center of the div
    innerElement.style.marginTop = '-10px';
    innerElement.style.marginLeft = '-10px';

    var style = option.style;

    Object.keys(style).forEach(function (k) {
      innerElement.style[k] = style[k];
    })
    outerElement.appendChild(innerElement);

    // Add text to the DOM element
    innerElement.innerHTML = option.text;

    function changeOpacity(evt) {
      evt.target.style.opacity = 0.6;
    };

    function changeOpacityToOne(evt) {
      evt.target.style.opacity = 1;
    };

    //create dom icon and add/remove opacity listeners
    const domIcon = new H.map.DomIcon(outerElement, {
      // the function is called every time marker enters the viewport
      onAttach: function (clonedElement, domIcon, domMarker) {
        clonedElement.addEventListener('mouseover', changeOpacity);
        clonedElement.addEventListener('mouseout', changeOpacityToOne);
      },
      // the function is called every time marker leaves the viewport
      onDetach: function (clonedElement, domIcon, domMarker) {
        clonedElement.removeEventListener('mouseover', changeOpacity);
        clonedElement.removeEventListener('mouseout', changeOpacityToOne);
      }
    });

    // Marker for Chicago Bears home
    return new H.map.DomMarker(position, { icon: domIcon });
  }



  // for here map
  public bd09_to_wgs84(bd09Points: Array<any[]>): Array<any[]> {
    const wgs84Points: Array<any[]> = [];
    if (bd09Points && bd09Points.length) {
      bd09Points.forEach((p) => {
        let p2: number[] = coordtransform.bd09togcj02(p[0], p[1]);
        p2 = coordtransform.gcj02towgs84(p2[0], p2[1]);
        wgs84Points.push(p2);
      });
    }
    return wgs84Points;
  }



  public showNavigationControl() {
    if (this.isBaidu) {
      this.map.addControl(new BMap.NavigationControl());
    }
  }


  public enableLocalSearch(onSearchComplete): any {
    if (this.isBaidu) {
      this.searchService = new BMap.LocalSearch(this.map, {
        renderOptions: { map: this.map },
        onSearchComplete: onSearchComplete
      });
      this.searchService
    } else {
      this.searchService = this.platform.getSearchService();
    }

    this.searchService;
    return this.searchService;
  }


  public searchByText(text, onSearchComplete) {
    if (this.isBaidu) {
      this.searchService.search(text);
    } else {
      this.searchService.geocode({
        q: text
      }, (result) => {
        onSearchComplete(result);
      }, (e) => {
        console.debug(e);
      });
    }

  }

  public addEventListener(eventName: string, fn: Function) {
    this.map.addEventListener(eventName, fn);
  }

  public getGeocoder() {
    if (this.isBaidu) {
      return new BMap.Geocoder();
    } else {
      return {};
    }
  }

}
