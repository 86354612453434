export const FETCH_DASHBOARD_DETAILS = {
    'FD': {code: 'FD', id: 2223, notificationId: '8', moduleId: 'TAG', pageTitle: 'mn_notification-tag', tableId: 'tagsearchtd'},
    'OD': {code: 'OD', id: 2225, notificationId: '8', moduleId: 'Operation NotificationTag', pageTitle: 'mn_operation_notification', tableId: 'tagsearchtd'},
   // 'TG': {code: 'TG', id: 2223, notificationId: '8', moduleId: 'Tag', pageTitle: 'Tag', tableId: 'dshbdsearchtd'},
    
};

export const INITIALBREADCRUMPFD = [
    { id: "home", displayValue: "Home", route: "/home" },
    { id: "global_control", displayValue: "Global Control", route: undefined },
    { id: "perf_mgmt", displayValue: "Performance tag", route: undefined },
    { id: "forwarder_notification", displayValue: "Forwarder NotificationTag", route: "/home/global-control/forwarder-notification" },
]

export const INITIALBREADCRUMRTG = [
    { id: 'home', displayValue: 'Home', route: '/home' },
    { id: 'setup', displayValue: 'Setup', route: undefined },
    { id: 'notification', displayValue: 'Notification', route: undefined },
    { id: 'tag', displayValue: 'Tag', route: '/home/notification/notification-tag' }

]




export const INITIALBREADCRUMPOD = [
    { id: "home", displayValue: "Home", route: "/home" },
    { id: "global_control", displayValue: "Global Control", route: undefined },
    { id: "perf_mgmt", displayValue: "Performance tag", route: undefined },
    { id: "operation_notification", displayValue: "Operation NotificationTag", route: "/home/global-control/operation-notification" }
]

export const metaDataTag = {
    'elementId': 'TAG_TB',
    'columnList': [
        {
            'id': '1001',
            'elementId': 'Application Code',
            'propertyName': 'APP_NAME',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId':'',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'appcode',
            'moduleId': 'GroupNameTd'
        },
        {
            'id': '1002',
            'elementId': 'Country/Region',
            'propertyName': 'COUNTRY_NAME',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 20,
            'index': 45,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId':'',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'countryname'
        },
        {
            'id': '1003',
            'elementId': 'COUNTRY_ID',
            'propertyName': 'COUNTRY_ID',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 60,
            'index': 4,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'H',
            'dataType': 'String',
            'cellDataName': 'country'
        },
        {
            'id': '1004',
            'elementId': 'Event Code',
            'propertyName': 'EVENT_CODE',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 20,
            'index': 45,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId':'',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'evtcode'
        },
        {
            'id': '1005',
            'elementId': 'Tag',
            'propertyName': 'TAG',
            'type': 'DROPDOWN',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId': '',
            'displayType': 'E',
            'dataType': 'String',
            'cellDataName': 'tag'
        },
        {
            'id': '1006',
            'elementId': 'Tag Label',
            'propertyName': 'TAG_LABEL',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
            'cellDataName': 'taglabel'
        },
        {
            'id': '1007',
            'elementId': 'Sample Value',
            'propertyName': 'SAMPLE_VALUE',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
            'cellDataName': 'samplevalue'
        },
        {
            'id': '1008',
            'elementId': 'Description',
            'propertyName': 'DESCRIPTION',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 100,
            'index': 6,
            'priority': 'LOW',
            'toolTip': null,
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
            'cellDataName': 'descriptiontg'
        },
        {
            'id': '1009',
            'elementId': 'CreatedBy',
            'propertyName': 'CREATED_BY',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'createdby'
        },
        {
            'id': '1010',
            'elementId': 'Created Date/Time',
            'propertyName': 'CREATED_DATE',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'createddate'
        },
        {
            'id': '1011',
            'elementId': 'Last Updated By',
            'propertyName': 'LAST_UPDATED_BY',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'lastupdateby'
        },
        {
            'id': '1012',
            'elementId': 'Last Updated Date/Time',
            'propertyName': 'LAST_UPDATED_DATE',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'lastdate'
        },
        {
            'id': '1000',
            'elementId': 'tag Id',
            'propertyName': 'TAG_ID',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'H',
            'dataType': 'String',
            'cellDataName': 'tagId',
        },
       
    ]
    }


export const metaDataTagForDetails = {
        'elementId': 'TAG_TB',
        'columnList': [
            {
                'id': '1001',
                'elementId': 'Tag',
                'propertyName': 'TAG',
                'type': 'TEXTFIELD',
                'defaultVal': '',
                'defaultOp': '=',
                'isMandatory': false,
                'maxLength': 20,
                'index': 45,
                'priority': 'LOW',
                'toolTip': null,
                'values': [],
                'columnGroup': 'Primary',
                'displayType': 'V',
                'dataType': 'String',
                'cellDataName': 'tag'
            },
            {
                'id': '1002',
                'elementId': 'Tag Label',
                'propertyName': 'TAG_LABEL',
                'type': 'TEXTFIELD',
                'defaultVal': '',
                'defaultOp': '=',
                'isMandatory': false,
                'maxLength': 20,
                'index': 45,
                'priority': 'LOW',
                'toolTip': null,
                'values': [],
                'columnGroup': 'Primary',
                'displayType': 'E',
                'dataType': 'String',
                'cellDataName': 'taglabel'
            },
            {
                'id': '1003',
                'elementId': 'Sample Value',
                'propertyName': 'SAMPLE_VALUE',
                'type': 'TEXTFIELD',
                'defaultVal': '',
                'defaultOp': '=',
                'isMandatory': false,
                'maxLength': 20,
                'index': 45,
                'priority': 'LOW',
                'toolTip': null,
                'values': [],
                'columnGroup': 'Primary',
                'displayType': 'E',
                'dataType': 'String',
                'cellDataName': 'samplevalue'
            },
            {
                'id': '1004',
                'elementId': 'Description',
                'propertyName': 'DESCRIPTION',
                'type': 'TEXTFIELD',
                'defaultVal': '',
                'defaultOp': '=',
                'isMandatory': false,
                'maxLength': 100,
                'index': 6,
                'priority': 'LOW',
                'toolTip': null,
                'columnGroup': 'Primary',
                'displayType': 'E',
                'dataType': 'String',
                'cellDataName': 'descriptiontg'
            },
            {
                'id': '1000',
                'elementId': 'tag Id',
                'propertyName': 'TAG_ID',
                'type': 'TEXTFIELD',
                'defaultVal': '',
                'defaultOp': '=',
                'isMandatory': false,
                'maxLength': 30,
                'index': 58,
                'priority': 'LOW',
                'toolTip': null,
                'values': [],
                'columnGroup': 'Primary',
                'displayType': 'H',
                'dataType': 'String',
                'cellDataName': 'tagId',
            },
        ]
    }
