<ng-template [ngIf]="!isLoading">
    <div class="view-balances-popup-container">
        <div class="vb-rel-container">
            <div class="vb-abs-container">
                <table class="vb-hdr-tbl">
                    <tr>
                        <td class="vb-hdr-lbls">
                            <span class="vb-hdr-lbl"> {{ getLabel('storer_Id') }} : </span>
                            <span class="vb-hdr-val">{{ storerKey }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="vb-hdr-lbls">
                            <span class="vb-hdr-lbl"> {{ getLabel('sku') }} : </span>
                            <span class="vb-hdr-val">{{ sku }}</span>
                        </td>
                        <td class="vb-hdr-btn">
                            <button class="vb-tgl-btn" (click)="toggleZeroBalances()"
                                [ngClass]="{ 'disabled': disableZeroBalanceToggle }">
                                {{ showZeroBalances ? getLabel('btn_HideZero_bal') : getLabel('btn_ShowZero_bal') }}
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <lfwms-result-data-table
            [showSelectOption]="false"
            [searchService]="searchService"
            [tableMode]="'V'">
        </lfwms-result-data-table>
    </div>
</ng-template>