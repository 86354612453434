import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SearchableEntityDataService } from './searchable-entity-data.service';
import { ConfirmDialogService } from 'sce-core';
import { AlertService } from 'sce-core';
import { ResourceService } from 'sce-core';
import { CoreServiceRegistry } from '../../../core/core-service-registry';
import { SharedServiceRegistry } from '../../shared-service-registry';


@Injectable()
export class SearchableEntityBackendService {

  public dataService: SearchableEntityDataService;

  public http$: HttpClient;
  public confirmDialogService: ConfirmDialogService;
  public alertService: AlertService;
  public resourceService: ResourceService;

  constructor(public coreServiceRegistry: CoreServiceRegistry,
    public sharedServiceRegistry: SharedServiceRegistry) {
      this.http$ = coreServiceRegistry.http$;
      this.resourceService = coreServiceRegistry.resourceService;
      this.alertService = sharedServiceRegistry.alertService;
      this.confirmDialogService = sharedServiceRegistry.confirmDialogService;
  }

  public setDependentServices(dataService: SearchableEntityDataService) {
    this.dataService = dataService;
  }

}
