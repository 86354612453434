import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { CoreServiceRegistry } from './core-service-registry';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  exports: [],
  providers: [
    CoreServiceRegistry
  ]

})
export class CoreModule {

  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    /*Import CoreModule only at root level or AppModule.
    To prevent re-imports in submodules throw exception */
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
