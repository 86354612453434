import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[scroll-Bottom]'
})
export class ScrollBottomDirective {
    constructor() {
    }

    @Output()
    public scrollBottom = new EventEmitter();

    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        if (!(event.target.scrollTop === 0)) {
            if (event.target.offsetHeight + event.target.scrollTop === event.target.scrollHeight) {
                this.scrollBottom.emit(true);
            }
        }
    }
}
