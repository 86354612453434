import { Component, OnInit, OnDestroy,Input,OnChanges, AfterViewInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Observable ,  Subscription ,  interval } from 'rxjs';
import { ResourceService, UserService } from 'sce-core';
import { NavigationService } from 'sce-core';
import { TokenService } from 'sce-core';
import { SessionTimerService } from 'sce-core';
import { LeftPanelService } from './services/left-panel.service';
import { HomeService } from './home.service';
import { ActivatedRoute } from '@angular/router';
import { PrinterSetupComponent } from 'sce-core';
import { ModalService, DatePickerConfigService } from 'sce-core';
import { DashboardService } from 'sce-core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
@Component({
  selector: 'lfwms-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('openClose', [
      state('open', style({
        marginLeft: '350px',
      })),
      state('closed', style({
        marginLeft: '0px'
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ])
  ]
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

  public lastMouseMoveTimeStamp: number = 0;
  public updateFeedsTimer: Subscription;
  public navigationSubscription: Subscription;
  public rssFeedsSubscription: Subscription;
  public carouselImageUrls: string[];
  public rssFeeds: any;
  public isHomePage: boolean = true;
  public showOverlay: boolean = true;
  public isBread:any;
  public header = new HttpHeaders({ 'api': 'admin' });

  @ViewChild('carouselViewport')
  public carouselViewportRef: ElementRef;


  /*  $event.timeStamp::
      Number of milliseconds elapsed since the beginning of the
      current document creation till the occurence of mouse movement. */
  @HostListener('document:mousemove', ['$event'])
  onMouseMove($event: any) {
    if (this.sessionTimerService.isAutoRefreshIntervalActive) {
    const latestMouseMoveTimeStamp: number = Math.floor($event.timeStamp);
      if ((latestMouseMoveTimeStamp - this.lastMouseMoveTimeStamp) > 10000) {
        // lastMouseMoveTimeStamp should be reset to 0
        this.tokenService.refreshToken();
        this.lastMouseMoveTimeStamp = latestMouseMoveTimeStamp;
      }
    }
  }

  constructor(public navService: NavigationService,
      public homeService: HomeService,
      public resourceService: ResourceService,
      public tokenService: TokenService,
      public sessionTimerService: SessionTimerService,
      public activatedRoute: ActivatedRoute,
      public leftPanelService: LeftPanelService,
      public modalService: ModalService,
      public dateConfigService: DatePickerConfigService,
      public userService: UserService,
      public dashboardService: DashboardService,
      public sanitizer: DomSanitizer,
      public http$: HttpClient) {}

  public ngOnInit() {
    // Start autorefresh timers for token expiry
    this.sessionTimerService.start();
    this.isBread = localStorage.getItem('menuId')
    this.activatedRoute.params
      .subscribe(() => {
        this.navService.setPageTitle(this.resourceService.get('lbl_home'));
      });
    this.fetchRssFeeds();
    // this.carouselImageUrls = [ 'assets/images/carousel_1.min.png',
    //   'assets/images/carousel_2.min.png',
    //   'assets/images/carousel_3.min.png'];
    this.navigationSubscription = this.navService.getNavigatedMenuIdBroadcast()
      .subscribe((id: string) => {
        /* Subscribe to the navigation service so that whenever
          the menu id is changed, home page static content will be hidden */
        if (id === 'set_printer') {
            this.openPrinterSetupPopupForMenuClick();
        }
        if (id === 'home') {
          this.isHomePage = true;
          // Update every 5minutes.
          const value = (5000 * 60);
          this.updateFeedsTimer = interval(value).subscribe(() => this.fetchRssFeeds());

          // this.updateFeedsTimer = Observable.interval(5000 * 60)
          //   .subscribe(() => this.fetchRssFeeds());
           //LFWM-2161 Trigger function to show down time notification to the loggedin user
            // this.userService.setDowntimeNotification();
        } else {
          this.isHomePage = false;
          if (this.updateFeedsTimer) {
            this.updateFeedsTimer.unsubscribe();
          }
        }

        if (id === 'my-profile') {
          this.isHomePage = false;
          }

      });
  
      this.getAppPrivRestriction(8).subscribe(resp => { 
        const privilegeCodes = []
        if (resp['userPermissions']) {
          resp['userPermissions'].forEach((privilege, index) => {
              privilegeCodes.push(privilege.privilegeCode);
          });
        } 
        resp['userPermissionsList'] = privilegeCodes      
        localStorage.setItem('tms_button_permission', JSON.stringify(resp))
      }, (error2) => {
        console.log('BillingPrivilegeError', error2);
      });
  }

  public ngAfterViewInit() {
  }

 

  // Method to fetch rss feeds
  public fetchRssFeeds() {
    this.rssFeedsSubscription = this.homeService.fetchRssFeed()
      .subscribe((rssData: any) => {
        this.rssFeeds = rssData.items;
      });
  }

  // Method used to resolve the labels from resource bundle
  public getLabel(key: string) {
    return this.resourceService.get(key);
  }

  public ngOnDestroy() {
    if (this.updateFeedsTimer) {
      this.updateFeedsTimer.unsubscribe();
    }
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    if (this.rssFeedsSubscription) {
      this.rssFeedsSubscription.unsubscribe();
    }
  }

  public openPrinterSetupPopupForMenuClick() {
    this.modalService
      .showModal(PrinterSetupComponent,
        { overlayHeader: this.getLabel('lbl_default_rdt')
          + ' / ' + this.getLabel('lbl_Bartender_printer'),
          showHeader: true,
          overlayHeight: 520,
          overlayWidth: 1024
        });
  }

  /* This method initializes the date configuaration for date-picker/ calendar in ui-library dynamically after homepage is loaded.
   Updated with user specific configuration stored in UserService in core-library which is retrieved adn stored in userservice after login.

   #TODO-LIBRARY - doesnt work since datfomat is loaded in background after homepage loads.
   Might need to use some events. Currently directly injecting into sce-core dateconfigservice 
   from within sce-core userservice by using direct reference.
  */
  public setupGlobalDateTimeFormatForUser() {

  // this.dateConfigService.defaultDateDisplayFormat = this.userService.dateTimeConfig.defaultDateDisplayFormat;
  // this.dateConfigService.defaultTimeDisplayFormat = this.userService.dateTimeConfig.defaultTimeDisplayFormat;
  // this.dateConfigService.defaultTimeZone = this.userService.dateTimeConfig.defaultTimeZone;

  }

  public getAppPrivRestriction(appId): Observable<any> {
    return this.http$.get( environment.wmsApiBaseUrl + 'api/users/' + appId + '/privrestriction', { headers: this.header }).pipe(map((response: any) => {
      const privilegeCodes = [];
      if (response.userPermissions) {
        response.userPermissions.forEach((privilege, index) => {
          privilegeCodes.push(privilege.privilegeCode);
        });
      }
      response.userPermissionsList = privilegeCodes;
      return response;
    }),catchError((error) => {
      return Observable.throw(error);
    }));
  }

}
